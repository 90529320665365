import Card from "@pathwright/ui/src/components/card/Card"
import GroupFeaturesForm from "./GroupFeaturesForm"

const GroupFeaturesCard = ({ card }) => (
  <Card title="Features" card={card}>
    <GroupFeaturesForm
      onPristine={() => card.setCardDirty(false)}
      onDirty={() => card.setCardDirty(true)}
    />
  </Card>
)

GroupFeaturesCard.displayName = "GroupFeaturesCard"

export default GroupFeaturesCard
