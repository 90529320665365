import { IconButton, Spinner } from "@chakra-ui/react"
import { download } from "@pathwright/media-utils"
import { DownloadIcon } from "@pathwright/pathicons"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { useMuxEmbedQuery, useUpdateVideoMP4 } from "./mux-graphql"
import { downloadIsReady } from "./utils"

// This component takes a Mux Video ID and renders a button that, when clicked,
// will either download the video (if MP4 support is enabled) or generate an MP4
// rendition of the video, poll until it's ready, and then download it.

type MuxDownloadButtonProps = {
  id: string
  downloadURL?: string
  renderButton?: ({
    onClick,
    isLoading
  }: {
    onClick: (args?: { downloadVideo?: boolean }) => void
    isLoading: boolean
  }) => ReactNode
}

const POLL_RATE = 5000

const MuxDownloadButton = ({
  id,
  downloadURL: propsDownloadURL,
  renderButton
}: MuxDownloadButtonProps) => {
  const pollRef = useRef<any>(null)
  const setMP4Support = useUpdateVideoMP4()
  const { embed, loading, error, refetch } = useMuxEmbedQuery({ id })
  const [pollingMP4, setPollingMP4] = useState(false)
  const [shouldDownloadVideo, setShouldDownloadVideo] = useState(false)

  const downloadURL = useMemo(
    () => propsDownloadURL || embed?.download?.url,
    [propsDownloadURL, embed]
  )

  // Poll for MP4 download URL
  useEffect(() => {
    if (!pollingMP4 || downloadIsReady(embed)) return

    pollRef.current = setInterval(() => {
      refetch()
    }, POLL_RATE)

    return () => {
      clearInterval(pollRef.current)
      pollRef.current = null
    }
  }, [pollingMP4])

  // Stop polling once the MP4 is ready
  useEffect(() => {
    if (pollingMP4 && downloadIsReady(embed)) {
      clearInterval(pollRef.current)
      pollRef.current = null
      if (downloadURL && shouldDownloadVideo) {
        download(`video-${id}`, downloadURL)
        setShouldDownloadVideo(false)
      }
      setPollingMP4(false)
    }
  }, [embed, pollingMP4])

  const onClick = ({ downloadVideo = true } = {}) => {
    if (!downloadVideo || downloadURL) {
      setShouldDownloadVideo(false)
    } else {
      setShouldDownloadVideo(true)
    }

    if (!downloadURL) {
      setPollingMP4(true)
      setMP4Support(id, true)
    } else {
      return downloadVideo ? download(`video-${id}`, downloadURL) : null
    }
  }

  const isLoading = pollingMP4

  return renderButton ? (
    renderButton({ onClick, isLoading })
  ) : (
    <IconButton
      aria-label="Download"
      onClick={() => onClick()}
      icon={isLoading ? <Spinner /> : <DownloadIcon />}
      isLoading={isLoading}
      isDisabled={(loading || error) as boolean}
    />
  )
}

export default MuxDownloadButton
