import Card from "@pathwright/ui/src/components/card/Card"
import GroupCommerceFormContainer from "./GroupCommerceFormContainer"

const GroupCommerceCard = ({ card }) => (
  <Card title="Commerce" card={card}>
    <GroupCommerceFormContainer
      onPristine={() => card.setCardDirty(false)}
      onDirty={() => card.setCardDirty(true)}
    />
  </Card>
)

GroupCommerceCard.displayName = "GroupCommerceCard"

export default GroupCommerceCard
