import { CouponExistsInput, useCouponExistsQuery } from "../api/generated"
import OrderDiscountForm, { OrderDiscountFormProps } from "./OrderDiscountForm"

type ConditionalOrderDiscountFormProps = (
  | CouponExistsInput["path"]
  | CouponExistsInput["plan"]
) &
  OrderDiscountFormProps

function ConditionalOrderDiscountForm({
  applyDiscountCode,
  clearDiscountCode,
  searchParamKey,
  ...couponExistsInput
}: ConditionalOrderDiscountFormProps) {
  const input =
    "resourceId" in couponExistsInput
      ? {
          path: couponExistsInput
        }
      : "schoolSubscriptionPlanId" in couponExistsInput
      ? {
          plan: couponExistsInput
        }
      : undefined

  const { data } = useCouponExistsQuery({
    variables: { input },
    skip: !input
  })

  return data?.couponExists ? (
    <OrderDiscountForm
      applyDiscountCode={applyDiscountCode}
      clearDiscountCode={clearDiscountCode}
      searchParamKey={searchParamKey}
    />
  ) : null
}

export default ConditionalOrderDiscountForm
