import Alert from "@pathwright/ui/src/components/alert/Alert"
import View from "@pathwright/ui/src/components/ui/View"
import LazyAdaptedAuthContainer from "@pathwright/web/src/modules/auth/LazyAdaptedAuthContainer"
import joinStore from "join/store/join-store"
import get from "lodash/get"
import { observer } from "mobx-react"
import { getStoreUrl } from "store/utils"
import { resetClientStore } from "../../ClientRoutes"
import "./Join.css"
import RegisterHeader from "./RegisterHeader"

const SignUpOrInContainer = ({
  inviteToken,
  providedEmail,
  providedFirstName,
  providedLastName
}) => {
  // The user creates their school membership by clicking "Continue"
  // thereby completing the flow. At this point we need to refetch queries
  // since they now have new perms.
  const onComplete = () => {
    window.App.navigate(getStoreUrl())
    // Refetch Apollo queries.
    resetClientStore()
    // Refetch bootstrapped data.
    // This will check the session and trigger bootstrapping the school store. Bit messy, but it works.
    window.App.getStore("auth").action.checkSession()
  }

  return (
    <LazyAdaptedAuthContainer
      inviteToken={inviteToken}
      userPropertyDefaults={{
        email: providedEmail,
        firstName: providedFirstName,
        lastName: providedLastName
      }}
      onComplete={onComplete}
      promptUserToJoinSpace
    />
  )
}

const Join = () => {
  const { invite_token, invitation, invitation_error } = joinStore
  const { to_email, first_name, last_name } = get(invitation, "extra", {})

  return (
    <View.Primary className="Join">
      <RegisterHeader
        closeTo={getStoreUrl()}
        invitation={invitation && invitation.extra ? invitation : null}
      />
      <div className="Join__error-wrapper">
        <Alert
          error={invitation_error}
          onClear={joinStore.action.clearErrors}
        />
      </div>
      <SignUpOrInContainer
        inviteToken={invite_token}
        providedEmail={to_email}
        providedFirstName={first_name}
        providedLastName={last_name}
      />
    </View.Primary>
  )
}

Join.displayName = "Join"

export default observer(Join)
