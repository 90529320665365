import { HStack, Heading, Text, VStack } from "@chakra-ui/react"
import moment from "moment"
import styled from "styled-components"
import { dueTimeStatus } from "../../completion/utils"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Pill = styled.span`
  padding: 1px 5px 2px;
  border-radius: 10px;
  white-space: nowrap;
  line-height: 10px;
  font-size: 10px;
  color: white;
  background-color: ${(p) => p.color};
`

const ReportPathCompletionLabel = ({ pathItem }) => {
  if (!pathItem.completion) {
    return null
  }

  const {
    userpoints_earned,
    userpoints_value,
    is_complete,
    is_late,
    completion_date,
    is_auto_graded
  } = pathItem.completion

  let alertLabel = null
  if (is_late && userpoints_value) {
    if (is_complete && !is_auto_graded) {
      alertLabel = dueTimeStatus(completion_date, pathItem.due)
    } else if (!is_complete) {
      alertLabel = `Due ${moment(pathItem.due).fromNow()}`
    }
  }

  let pointsLabel = null
  if (userpoints_value) {
    if (is_auto_graded) {
      pointsLabel = `pts`
    } else {
      pointsLabel = `/${userpoints_value}`
    }
  }

  let alertColor = null
  if (is_late) {
    alertColor = "#FF851B"
  }

  return (
    <HStack>
      {alertLabel && <Pill color={alertColor}>{alertLabel}</Pill>}
      <VStack spacing={0} alignItems="flex-end">
        {pointsLabel && (
          <Heading as="span" size="sm" m={0}>
            {userpoints_earned > 0 && <span>+</span>}
            {userpoints_earned}
          </Heading>
        )}
        {pointsLabel && (
          <Text as="span" fontSize=".8em" color="gray.400">
            {pointsLabel}
          </Text>
        )}
      </VStack>
    </HStack>
  )
}

ReportPathCompletionLabel.displayName = "ReportPathCompletionLabel"

export default ReportPathCompletionLabel
