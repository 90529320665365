const User = require("auth/models/user").default
const Cookie = require("js-cookie")

class Session extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  constructor(...args) {
    super(...args)
    this._verifyAuthToken = this._verifyAuthToken.bind(this)
  }

  defaults() {
    return {
      is_authenticated: false,
      auth_token: null
    }
  }

  url() {
    // hacky
    let ssl = true
    if (
      window.App.getStore("school").getState().school.is_ssl_disabled ||
      bootstrappedData.DEBUG === true
    ) {
      ssl = false
    }
    return this.urlRoot(`api/private/session/`)
  }

  initialize() {
    // Signed-in
    this.on("change:auth_token change:user", function () {
      this._checkUser()
      return this._checkAuthenticated()
    })

    this.on("change:auth_token", this._verifyAuthToken)
    this.on("change:csrf_token", this._updateCSRFTokenCookie)
    this._verifyAuthToken()
    this._updateCSRFTokenCookie()
    return this._checkUser()
  }

  _checkUser() {
    if (this.get("user") && !this.get("user") instanceof User) {
      return this.set("user", new User(this.get("user")), { trigger: false })
    }
  }

  _checkAuthenticated() {
    if (this.get("auth_token")) {
      return window.App.execute("set:auth_token", this.get("auth_token"))
    }
  }

  _verifyAuthToken() {
    // Verifies that the client token matches
    // the one set from the server
    const session_token = this.get("auth_token")
    const user_token = window.App.request("get:auth_token")
    if (session_token !== user_token) {
      if (!session_token || session_token === undefined) {
        if (user_token != null) {
          console.warn("invalid token found in browser... clearing", user_token)
          return window.App.execute("clear:auth_token")
        }
      } else {
        // Update user token to match
        console.warn(
          "unmatched token found in browser... setting",
          user_token,
          session_token
        )
        return window.App.execute("set:auth_token", session_token)
      }
    }
  }

  // Sync the csrf token with the cookie.
  // This ensures auth token and csrf foken remain in sync.
  _updateCSRFTokenCookie() {
    if (this.get("csrf_token")) {
      Cookie.set("csrftoken", this.get("csrf_token"))
    } else {
      Cookie.remove("csrftoken")
    }
  }

  isNew() {
    // Override this so Backbone will GET
    return !this.get("is_authenticated")
  }

  clearAuth() {
    this.clearAuthToken()
    window.userData = null
    if (window.bootstrappedData != null) {
      window.bootstrappedData.user = null
    }
    return window.App.vent.trigger("session:unauthenticated", this)
  }

  clearAuthToken() {
    if (App.request("get:auth_token") != null) {
      return window.App.execute("clear:auth_token")
    }
  }

  destroy() {
    const d = new $.Deferred()
    Backbone.Model.prototype.destroy.call(this, {
      success: (model, result) => {
        this.clearAuth()
        this.clear({ silent: true })
        this.set(result)
        return d.resolve(this)
      },
      error(err) {
        return d.reject(err)
      }
    })
    return d
  }

  check(options) {
    const d = new $.Deferred()
    this.fetch(options)
      .done((result) => {
        // _checkAuthenticated takes care of the trigger
        return d.resolve(this)
      })
      .fail((err) => {
        // not logged in
        this.clearAuth()
        return d.resolve(this)
      })
      .always(() => {
        return window.App.vent.trigger("session:checked", this)
      })
    return d.promise()
  }

  toJSON() {
    const data = super.toJSON()
    // Serialize the user
    if (data.user instanceof Backbone.Model) {
      data.user = data.user.toJSON()
    }
    return data
  }
}

export default Session
