import useDebounce from "@pathwright/ui/src/components/hooks/useDebounce"
import Fuse from "fuse.js"
import { useMemo } from "react"

type UseFuseOptions<T> = Fuse.IFuseOptions<T> & {
  includeMatches?: boolean
  includeScore?: boolean
}

type FusedResult<T> =
  | T
  | { item: T; matches?: Fuse.FuseResult<T>["matches"]; score?: number }

const useFuse = <T>(
  items: T[],
  search: string,
  fuseOptions: UseFuseOptions<T> = {},
  delay = 300
): FusedResult<T>[] => {
  const { includeMatches = false, includeScore = false } = fuseOptions

  // Debounce the search term to avoid excessive re-renders
  const searchTerm = useDebounce(search, delay)

  // Initialize Fuse.js with memoization
  const fuse = useMemo(() => {
    return new Fuse<T>(items, {
      threshold: 0.3,
      ...fuseOptions
    })
  }, [items, fuseOptions])

  // Perform search and return results based on `includeMatches` & `includeScore`
  const fusedResults = useMemo(() => {
    // If there is no search term, return all items
    if (!searchTerm?.length) {
      if (!includeMatches && !includeScore) {
        return items
      }

      return items.map((item) => ({
        item,
        ...(includeMatches ? { matches: [] } : {}),
        ...(includeScore ? { score: 0 } : {})
      }))
    }

    const results = fuse.search(searchTerm)

    if (includeMatches || includeScore) {
      return results.map(({ item, matches, score }) => ({
        item,
        ...(includeMatches && matches ? { matches } : {}),
        ...(includeScore && score !== undefined ? { score } : {})
      }))
    }

    return results.map(({ item }) => item) // Default behavior (items only)
  }, [searchTerm, fuse, includeMatches, includeScore])

  return fusedResults
}

export default useFuse
