import { Badge, HStack, Text, TextProps } from "@chakra-ui/react"
// import { PathiconProps } from "@pathwright/app-web/src/components/pathicon/ExpensivePathicon"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { RegistrationRoleEnum } from "../../api/generated"
import { SelectedCohortRole, SelectedResourceRole } from "./state"

const roleOptionMap = getRoleOptionMap()

const observerMap = {
  enum: RegistrationRoleEnum.Observer,
  int: -1,
  options: []
}

const learnerMap = {
  enum: RegistrationRoleEnum.Student,
  int: 5,
  options: [roleOptionMap.learn]
}

const moderatorMap = {
  enum: RegistrationRoleEnum.Moderator,
  int: 10,
  options: [roleOptionMap.teach, roleOptionMap.learn]
}

const teacherMap = {
  enum: RegistrationRoleEnum.Teacher,
  int: 15,
  options: [roleOptionMap.teach]
}

const editorMap = {
  enum: RegistrationRoleEnum.Editor,
  int: 20,
  options: [roleOptionMap.design]
}

const registrationRoleMaps = [
  observerMap,
  learnerMap,
  moderatorMap,
  teacherMap,
  editorMap
]

type RegistrationRoleMap = (typeof registrationRoleMaps)[number]

// Map registration role ints to role maps.
export const registrationRoleIntMap = new Map(
  registrationRoleMaps.reduce((acc, roleMap) => {
    acc.push([roleMap.int, roleMap])
    return acc
  }, [] as [number, RegistrationRoleMap][])
)

// Map registration role enums to role maps.
export const registrationRoleEnumMap = new Map(
  registrationRoleMaps.reduce((acc, roleMap) => {
    acc.push([roleMap.enum, roleMap])
    return acc
  }, [] as [RegistrationRoleEnum, RegistrationRoleMap][])
)

type Badge = {
  // icon?: PathiconProps["icon"]
  icon?: string
  label: string
}

export type RoleOptionValue = "design" | "teach" | "learn"

export interface RoleOption {
  badge: Badge
  description: string
  value: RoleOptionValue
}

type RoleOptionMap = Record<RoleOptionValue, RoleOption>

export function getRoleOptions(): RoleOption[] {
  // Defining with fn in case we need to translate the lagels (which we will).
  const roleOptions: RoleOption[] = [
    {
      badge: {
        icon: "edit",
        label: "Design"
      },
      description: "Design Path & content for all Cohorts",
      value: "design"
    },
    {
      badge: {
        icon: "group",
        label: "Teach"
      },
      description: "Invite, schedule, & give feedback",
      value: "teach"
    },
    {
      badge: {
        icon: "eye",
        label: "Learn"
      },
      description: "Track your progress.",
      value: "learn"
    }
  ]

  return roleOptions
}

export function getRoleOptionMap(): RoleOptionMap {
  const roleOptions = getRoleOptions()
  const roleOptionMap = roleOptions.reduce((acc, roleOption) => {
    acc[roleOption.value] = roleOption
    return acc
  }, {} as RoleOptionMap)

  return roleOptionMap
}

export const pathRoles: SelectedResourceRole[] = [RegistrationRoleEnum.Editor]
export const cohortRoles: SelectedCohortRole[] = [
  RegistrationRoleEnum.Student,
  RegistrationRoleEnum.Moderator,
  RegistrationRoleEnum.Teacher
]

export function serializeRoleBadgesToRegistrationRoles(
  roleOptions: RoleOption[]
) {
  function getRole<T extends SelectedResourceRole | SelectedCohortRole>(
    roles: T[]
  ): T | null {
    // Get the number of roles from the related role map that are found in
    // the selected options.
    function getSize(role: T) {
      const registrationRoleOptions = registrationRoleEnumMap.get(
        role!
      )!.options
      return registrationRoleOptions!.filter((registrationRoleOption) =>
        roleOptions.some(
          (roleOption) => roleOption.value === registrationRoleOption.value
        )
      ).length
    }

    return (
      roles
        // Filter out any roles that don't fully map to the selected options.
        .filter((role) => {
          return getSize(role)
        })
        // Sort the role options to the front of the list that have the most
        // roles intersecting with the selected options.
        .sort((roleA, roleB) => {
          return getSize(roleB) - getSize(roleA)
        })[0] || null
    )
  }

  let resourceRole = getRole(pathRoles)
  let cohortRole = getRole(cohortRoles)

  return {
    resourceRole,
    cohortRole
  }
}

function RoleBadge({
  badge,
  iconOnly,
  ...props
}: { badge: Badge; iconOnly?: boolean } & TextProps) {
  return (
    <HStack
      size="xs"
      fontWeight="bold"
      m={0}
      borderRadius="10px"
      my={1}
      px={2}
      py={1}
      color="white"
      // Matching bg color on Path mode controls.
      bg={"rgb(42, 46, 51)"}
      shadow="md"
      {...props}
    >
      {/* @ts-expect-error */}
      {!!badge.icon && <Pathicon icon={badge.icon} size=".8em" />}
      {!iconOnly && (
        <Text as="span" color="inherit">
          {badge.label}
        </Text>
      )}
    </HStack>
  )
}

export default RoleBadge
