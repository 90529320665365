// These function are used by route `onEnter` handlers to map route state to library/resource/path store state

const getSection = (section) => {
  const defaultSection = "about"
  const previousSection = App.getStore("resource").previous("section")
  const sections = ["about", "path", "community"]

  if (!sections.includes(section)) {
    return previousSection || defaultSection
  }
  return section
}

export const loadResource = function (nextState, overrideSection) {
  const { resourceSlug, cohortId, section } = nextState.match.params
  const resourceStore = window.App.getStore("resource")
  return resourceStore.action.selectResourceBySlug(
    resourceSlug,
    cohortId,
    getSection(section || overrideSection)
  ).promise
}

// The expection here is that any route needing to load the path will also be using a component
// wrapped with the PathProvider which handles loading the path. Here, we simply need to resolve a promise once the path store has the path.
const loadPath = () => {
  return new Promise((resolve, reject) => {
    const { offering } = App.getStore("resource").getState()
    const { path } = App.getStore("path").getState()
    // Ensure we aren't proceeding with a path intended for some
    // other cohort.
    if (path && path.offering_id === offering.id) {
      resolve(path)
    } else {
      App.listenToOnce(App.getStore("path"), "change:path", () =>
        resolve(App.getStore("path").getState().path)
      )
    }
  })
}

export const loadResourcePath = function (nextState) {
  return loadResource(nextState).then(loadPath)
}

export const loadResourcePathStep = function (nextState) {
  const { pathItemSourceId } = nextState.match.params
  return loadResourcePath(nextState).then(() =>
    window.App.getStore("step").action.setSelectedStepByID(pathItemSourceId)
  )
}

export const loadResourcePathLesson = function (nextState) {
  const { pathItemSourceId } = nextState.match.params
  return loadResourcePath(nextState).then(() =>
    window.App.getStore("path").action.selectGroupByID(pathItemSourceId)
  )
}
