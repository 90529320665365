import { IconButton, Stack, VStack } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import Tab from "@pathwright/ui/src/components/tab/Tab"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import { useUIThemeContext } from "@pathwright/ui/src/components/ui/PathwrightUI"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { capitalCase } from "capital-case"
import gql from "graphql-tag"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import styled from "styled-components"
import UserInbox from "../inbox/UserInbox"
import { getSchoolMembershipRoleLabel } from "../membership/constants"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import MaxLastSeen from "../user/MaxLastSeen"
import USER_FRAGMENT from "../user/graphql/user-fragment"
import ProfileActivity from "./ProfileActivity"
import ProfileRegistrations from "./ProfileRegistrations"

export const USER_PROFILE_QUERY = gql`
  query UserQuery($id: Int!) {
    user(id: $id) {
      ...User
      membership {
        id
        role
        last_active
        last_seen
      }
    }
  }
  ${USER_FRAGMENT}
`

const StyledContainer = styled(View.Secondary)`
  position: relative;
  display: flex;
  flex-direction: column;

  .Portable:not(:has(.Panel--cardstack)) & {
    ${media.min.phone`max-height:calc(100vh - 100px);`};
  }

  .CompletionsList__nav {
    display: none;
  }
`

const StyledHeader = styled.header`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-top: 0;
  background-color: white;
  padding: 0px 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > div:first-child {
    margin-right: 20px;
  }

  > div:nth-child(2) {
    flex-grow: 1;
  }
`

const StyledTabs = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  background-color: white;
`

const StyledTab = styled(Tab)`
  margin: 0;
`

const StyledScrollView = styled(ScrollView)`
  background-color: transparent !important;
  border-radius: 0 0 10px 10px;
  position: relative;
  min-height: 250px;
  flex-grow: 1;

  .UserInbox {
    margin-top: 0 !important;
  }
`

const StyledDropdownTrigger = styled(DropdownTrigger)`
  position: absolute !important;
  right: 5px;
  top: -35px;
  z-index: 10;
`

const ProfileReview = ({ userId }) => {
  return <UserInbox userId={userId} />
}

const ProfileBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "#666")};
  /* background-color: ${(props) => props.color}; */
  border-radius: 12px;
  position: relative;

  cursor: ${(p) => (p.href ? "pointer" : "default")};

  label {
    cursor: ${(p) => (p.href ? "pointer" : "default")};
    font-weight: normal;
    margin-left: 4px;
  }
`

const ProfileBadge = ({
  icon,
  tip,
  value,
  color,
  href,
  showCopyButton,
  style = {}
}) => {
  const [copying, setCopying] = useState(false)

  const container = href ? "a" : "div"
  return (
    <ChakraTooltip title={tip}>
      <ProfileBadgeContainer
        as={container}
        href={href}
        className="ProfileBadge"
        color={color}
      >
        <Pathicon icon={icon} />
        <label
          style={{
            ...style
          }}
        >
          {value}
        </label>
        {showCopyButton ? (
          <IconButton
            aria-label="Copy"
            bg="transparent"
            _hover={{ bg: "transparent" }}
            cursor="pointer"
            icon={
              copying ? <Pathicon icon="check" /> : <Pathicon icon="copy" />
            }
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              navigator.clipboard.writeText(value)
              setCopying(true)
              setTimeout(() => setCopying(false), 3000)
            }}
            position="absolute"
            right={"-18px"}
            top={"5px"}
            minW="none"
          />
        ) : null}
      </ProfileBadgeContainer>
    </ChakraTooltip>
  )
}

const tabNames = {
  registrations: "Paths",
  activity: "Activity",
  review: "Review"
}

const tabKeys = Object.freeze(Object.keys(tabNames))

const Profile = ({ tabKey: tabKeyProp, showTabs, userId }) => {
  const [tabKey, setTabKey] = useState(tabKeyProp || "registrations")
  const { t } = useTranslate()
  const theme = useUIThemeContext()

  useEffect(() => {
    if (tabKeyProp && tabKeyProp !== tabKey) setTabKey(tabKeyProp)
  }, [tabKeyProp])

  const { school } = usePathwrightContext()

  const userQuery = useQuery(USER_PROFILE_QUERY, {
    variables: {
      id: userId
    }
  })

  const { error, loading } = userQuery
  const user = get(userQuery, "data.user")

  if (loading) {
    return null
  }

  if (error) {
    console.warn(error.message)
    return (
      <BlankSlate
        icon="caution-triangle"
        body={`An unexpected error occurred: ${error.message}`}
      />
    )
  }

  return (
    <StyledContainer className="ProfileCard" rounded>
      {tabKey === "activity" && <ThemeBackground roundedBottom />}
      <StyledHeader className="ProfileCard__header">
        <div>
          <Avatar style={{ marginBottom: 10 }} user={user} size={90} />
        </div>
        <VStack align={["center", "flex-start"]} spacing={0}>
          <Text.H4>{user.full_name}</Text.H4>
          <Text.Body style={{ marginTop: 10 }}>{user.profile.bio}</Text.Body>

          <Stack
            mt={2}
            mb={"10px"}
            direction={["column", "row"]}
            spacing={[1, 2]}
            align={["flex-start", "center"]}
            wrap="wrap"
            w="100%"
          >
            {user.membership.role > 5 && (
              <ProfileBadge
                color={theme.primaryBrandColor}
                tip={`${school.name} ${getSchoolMembershipRoleLabel(
                  user.membership.role,
                  t
                )}`}
                icon="seal-check"
                value={capitalCase(
                  getSchoolMembershipRoleLabel(user.membership.role, t)
                )}
              />
            )}
            <ProfileBadgeContainer>
              <MaxLastSeen user={user} />
            </ProfileBadgeContainer>
            <ProfileBadge
              icon="envelope"
              href={`mailto:${user.email}`}
              value={user.email}
              showCopyButton
              style={{
                textOverflow: "ellipsis",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}
            />
          </Stack>
        </VStack>
      </StyledHeader>
      <StyledTabs className="ProfileCard__tabs">
        {showTabs.map((showTabKey) => (
          <StyledTab
            key={showTabKey}
            active={tabKey === showTabKey}
            onClick={() => setTabKey(showTabKey)}
          >
            {tabNames[showTabKey]}
          </StyledTab>
        ))}
      </StyledTabs>
      <StyledScrollView className="ProfileCard__tab_content">
        {tabKey === "activity" ? (
          <ProfileActivity userId={user.id} singleColumn />
        ) : tabKey === "registrations" ? (
          <ProfileRegistrations userId={user.id} />
        ) : (
          <ProfileReview userId={user.id} />
        )}
      </StyledScrollView>
    </StyledContainer>
  )
}

Profile.displayName = "Profile"

Profile.propTypes = {
  tabKey: PropTypes.oneOf(tabKeys),
  showTabs: PropTypes.arrayOf(PropTypes.oneOf(tabKeys)),
  userId: PropTypes.number.isRequired
}

Profile.defaultProps = {
  showTabs: tabKeys
}

export default Profile
