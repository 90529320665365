import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import PathwrightAccountPermissions from "lib/core/components/pathwright/PathwrightAccountPermissions"
import { ConnectStores } from "lib/core/store"
import GroupDetailsForm from "./GroupDetailsForm"

const getLinkData = (t) => ({
  features: {
    icon: "bullet-list",
    label: t("Edit Cohort Description"),
    meta: t("Describe any unique benefits in this Cohort."),
    to: `${window.location.pathname}features/`
  },
  access: {
    icon: "calendar",
    label: t("Configure availability"),
    meta: t("Set registration windows, seat, and access limits."),
    to: `${window.location.pathname}access/`
  },
  communitySettings: {
    icon: "group",
    label: t("Configure community settings"),
    meta: t("Enable or disable Community interactions"),
    to: `${window.location.pathname}community/`
  },
  commerce: {
    icon: "tag-dollar-sign",
    label: t("Configure Commerce"),
    meta: t("Set a price to join or add to a Subscription plan."),
    to: `${window.location.pathname}commerce/`
  },
  archive: {
    icon: "trash",
    label: t("Delete Cohort"),
    meta: t("Permanently delete this Cohort."),
    to: `${window.location.pathname}archive/`,
    dangerous: true
  }
})

const GroupDetailsCard = function (props) {
  const { card, group, t } = props
  const canDelete = !!group?.id && !group?.is_curriculum
  const linksData = getLinkData(t)

  return (
    <Card card={card} title={group?.name || ""}>
      {group ? (
        <>
          <GroupDetailsForm
            onPristine={() => card.setCardDirty(false)}
            onDirty={() => card.setCardDirty(true)}
          />
          <CardLink {...linksData.features} />
          <CardLink {...linksData.access} />
          <CardLink {...linksData.communitySettings} />
          <PathwrightAccountPermissions offering={group}>
            {({ can_edit_offering_commerce }) => {
              return (
                can_edit_offering_commerce && (
                  <CardLink
                    disabled={!!group.parent_offering_id}
                    tooltip={
                      !!group.parent_offering_id
                        ? "Currently unavailable for collection cohorts"
                        : null
                    }
                    {...linksData.commerce}
                  />
                )
              )
            }}
          </PathwrightAccountPermissions>
          {canDelete ? <CardLink {...linksData.archive} /> : undefined}
        </>
      ) : (
        <LoadingCircle center />
      )}
    </Card>
  )
}

GroupDetailsCard.displayName = "GroupDetailsCard"

const mapStateToProps = function (state, ownProps) {
  const { group } = state.groupAdminStore.state
  return _.assign(
    {},
    {
      group
    },
    ownProps
  )
}

const connectStores = [
  {
    store: "groupAdmin",
    watch: ["group"]
  }
]

export default withTranslate(
  ConnectStores(GroupDetailsCard, connectStores, {}, mapStateToProps)
)
