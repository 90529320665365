import classnames from "classnames"
import { isObject } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { getCardTo } from "../../../../../client/web/src/modules/utils/urls"
import { useCardStackContext } from "../card/CardStack"
import {
  getStateFromRoute,
  getUrlFromRoute,
  isAbsoluteUrl,
  isLeftClickEvent,
  isModifiedEvent
} from "./utils"

export const LinkContext = React.createContext({ target: null })

const Link = React.forwardRef(
  (
    {
      to,
      href: hrefProp,
      target: targetProp,
      download,
      disabled,
      onClick,
      style,
      className,
      children,
      ariaLabel,
      ariaLabelledBy,
      tabIndex
    },
    ref
  ) => {
    const linkContext = useContext(LinkContext)
    const cardStackContext = useCardStackContext()
    // context isn't always available (i.e. local legacy/web)
    const history = useHistory()

    const toUrl = useMemo(() => {
      // Get the url from the to prop (which may be an object).
      let toUrl = to ? getUrlFromRoute(to) : null

      if (toUrl) {
        if (cardStackContext) {
          // If we're within a cardStack context, let's also convert to a card url.
          toUrl = getCardTo(history.location, toUrl)
          // If the url starts with a keyword used by card routes, we can safely alter the
          // toUrl to become the panel query param.
          // This is a bit hacky, but cleans up the UX a little, avoiding the split second
          // redirect handled by <CardMatchRedirect />.
        } else if (toUrl.startsWith("/manage/")) {
          // Doing this bypasses necessary redirects handled in Routes.
          // TODO: We could try applying the redirects in the CardMatchRedirect.
          // toUrl = `${history.location.pathname}?${AUGMENTATION_PANEL_QUERY_PARAM}=${toUrl}`
        }
      }

      return toUrl
    }, [to, history.location])

    // NOTE: a to prop is always expected, from which we can derive the
    // href and target if not provided
    const href = hrefProp ? hrefProp : toUrl
    let target = targetProp || (isAbsoluteUrl(href) ? "_blank" : null)

    // checking the hrefProp meaning that we'll only default to allowing
    // the href attribute to be handle if explicitly passed
    let allowHrefHandling = !!hrefProp && !!target

    if (linkContext?.target) {
      target = linkContext?.target
      allowHrefHandling = !!href && !to?.card
    }

    // ensure we're only passing href and target props if these attributes
    // have value (don't want to set an href of null on the link)
    const derivedProps = {}
    if (href) derivedProps.href = href
    if (target) derivedProps.target = target

    const handleClick = useCallback(
      (event) => {
        // handle click before preemptively returning
        if (typeof onClick === "function") onClick(event)
        // return early if route should not be navigated to
        if (isModifiedEvent(event)) {
          // Don't propagate the event when even modifier is present.
          event.stopPropagation()
          return
        }
        if (!isLeftClickEvent(event)) return
        if (to && isAbsoluteUrl(to)) {
          console.warn(
            `Supplied an absolute url as a "to=" prop. Try using "href" instead. You're "target=" attribute may not function as intended.`
          )
          window.location = to
        }

        if (allowHrefHandling) return
        if (download) return

        if (to && !event.defaultPrevented) {
          // Attempt to get the pushCard method. If we have the `pushCard` method
          // in context, we simply need a card to push, otherwise we can only use
          // the `forcePushCard` method if the `to` prop doesn't contain a valid url.
          const pushCard =
            isObject(to) && to.card
              ? toUrl
                ? cardStackContext?.pushCard
                : cardStackContext?.forcePushCard
              : null

          // attempt to push the card
          if (pushCard) {
            pushCard({ ...to.card })
          } else {
            history.push(toUrl, getStateFromRoute(to))
          }
        }
        // finally, prevent the defualt to stop normal navigation handling
        event.preventDefault()
      },
      [to, target, download, onClick]
    )

    return (
      <a
        ref={ref}
        className={classnames("Link", className)}
        style={style}
        download={download}
        disabled={disabled}
        onClick={handleClick}
        {...derivedProps}
        aria-labelledby={ariaLabelledBy}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      >
        {children}
      </a>
    )
  }
)

Link.displayName = "Link"

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string
}

export default Link
