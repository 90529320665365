import { Spinner } from "@chakra-ui/react"
import React, { Suspense } from "react"

const AdaptedAuthContainer = React.lazy(
  () =>
    import(
      "./AdaptedAuthContainer" /* webpackChunkName: "AdaptedAuthContainer" */
    )
)

function LazyAdaptedAuthContainer(props: any) {
  return (
    <Suspense fallback={<Spinner />}>
      <AdaptedAuthContainer {...props} />
    </Suspense>
  )
}

export default LazyAdaptedAuthContainer
