import { getClient, PW_AUTH_TOKEN_KEY } from "../../api/client"
import {
  SignOutDocument,
  SignOutMutation,
  SignOutMutationVariables
} from "../../api/generated"

// This an expensive import if all we want to do is remove the key.
// import { discardAuthEmailFormStateStorage } from "../../views/auth/utils/authEmailFormStateStorage"

export type TokenEventDetail = {
  token: string | null
  source: "auth"
}

// Extending the CustomEventMap with our own `PW_AUTH_TOKEN_KEY` event key.
interface CustomEventMap {
  [PW_AUTH_TOKEN_KEY]: CustomEvent<TokenEventDetail>
}

// Extending the event methods on Window to recognize our custome events typed in `CustomEventMap`
declare global {
  interface Window {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Window, ev: CustomEventMap[K]) => void
    ): void
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Window, ev: CustomEventMap[K]) => void
    ): void
    dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void
  }
}

const handleAuthTokenEvents = () => {
  const dispatchAuthTokenEvent = (token: string | null) => {
    window.dispatchEvent(
      new CustomEvent<TokenEventDetail>(PW_AUTH_TOKEN_KEY, {
        detail: {
          token,
          source: "auth"
        }
      })
    )
  }

  // Let's handle any `PW_AUTH_TOKEN_KEY` event not triggered by our "auth" token handlers.
  // This allows for syncing auth tokens issued by outside sources, like our bookmarklet
  // authentication shortcut (https://www.notion.so/pathwright/Sign-in-as-anyone-with-a-SuperUser-override-5b0ada88ef4e47e79c419f3baae1941a#2455d3b96ada42309574635fa82f3e4d).
  window.addEventListener(PW_AUTH_TOKEN_KEY, async (e) => {
    if (e.detail.source !== "auth") {
      if (e.detail.token) {
        // If an auth token is currently stored and a user is authenticating,
        // then, to ensure the previous user's session has ended, we first sign them out.
        if (localStorage.getItem(PW_AUTH_TOKEN_KEY)) {
          const client = getClient()
          await client.mutate<SignOutMutation, SignOutMutationVariables>({
            mutation: SignOutDocument
          })
        }
        handleAuthToken(e.detail.token)
      } else {
        clearAuthenticationToken()
      }
    }
  })

  return dispatchAuthTokenEvent
}

const dispatchAuthTokenEvent = handleAuthTokenEvents()

export const getAuthenticationToken = () => {
  return localStorage.getItem(PW_AUTH_TOKEN_KEY)
}

export const hasAuthenticationToken = () => {
  return getAuthenticationToken() !== null
}

// NOTE: to avoid importing all of form state storage as a dep,
// we're just hard coding the form state storage key.

export const clearAuthenticationToken = async () => {
  localStorage.removeItem(PW_AUTH_TOKEN_KEY)
  dispatchAuthTokenEvent(null)
  const client = getClient()
  await client.resetStore()
  // discardAuthEmailFormStateStorage()
  sessionStorage.removeItem("formState:auth-email")
  return null
}

export const handleAuthToken = async (token: string) => {
  localStorage.setItem(PW_AUTH_TOKEN_KEY, token)
  dispatchAuthTokenEvent(token)
  const client = getClient()
  await client.resetStore()
  // discardAuthEmailFormStateStorage()
  sessionStorage.removeItem("formState:auth-email")
  return null
}
