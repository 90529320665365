import { useApolloClient } from "@apollo/client"
import { DocumentNode, FragmentDefinitionNode } from "graphql"
import React from "react"
import {
  PositionedObjectPositionInput,
  PositioningContextInput,
  useUpdatePositionedObjectsMutation
} from "../api/generated"
import {
  Position,
  PositionOptions,
  PositionedObj,
  assignPositions,
  getPositions,
  sortByPosition
} from "./fractional-positioning"

type PositioningContextValue<T extends PositionedObject> = {
  updatePositionedObject: UpdatePositionedObject<T>
}

// Position can be null (for unpositioned objects).
export type PositionedObject<T = PositionedObj> = T & {
  position: Position
  // NOTE: string is too specific since it doesn't match literals (like "Registration").
  // Also, we're conditionally requiring the __typename field, though completely expecting
  // it's existance. This is because the field is optional on the generated types, but if
  // we were to require it, it would need to be supplied in many other contexts, which we
  // don't care to do.
  __typename?: any
}

type PositioningContextProviderProps<T extends PositionedObject> = {
  items: T[]
  itemKey: string
  // For updating the cache optimistically for immediate response.
  // TODO: need to handle updating the items list in the cache.
  // This could possibly be handled in InMemoryCache merge functions.
  itemFragmentDocument: DocumentNode
  // The context within which we are positioning the items.
  context: PositioningContextInput
  // Presumably the UI listing the items.
  children: JSX.Element
}

type UpdatePositionedObject<T extends PositionedObject> = (options: {
  item: T
  index: number
}) => Promise<void>

// Sort objs by position, taking into account null positions, sorting them last.
// This can be used to order the objs in state or writing the list into the cache.
// Its too complex to automatiacally update the cache for queries, so not providing
// that out of the box.
export function sortPositionedObjects<T extends any>(
  positionedObjects: PositionedObject<T>[]
) {
  // We have to finagle the typing to insist that PositionedObject<T> is acceptable
  // as PositionedObject[] then cast back to PositionedObject<T> to preserve typing.
  // Since sortByPosition doesn't mutate the list objects, this is safe.
  return sortByPosition(
    positionedObjects as PositionedObject[]
  ) as PositionedObject<T>[]
}

export const PositioningContext = React.createContext<
  PositioningContextValue<any>
>(null as unknown as PositioningContextValue<any>)

export const usePositioningContext = () => React.useContext(PositioningContext)

// Wrap a list of positioned objects providign a means to update an item's position.
export function PositioningContextProvider<T extends PositionedObject>({
  items,
  itemKey,
  itemFragmentDocument,
  context,
  children
}: PositioningContextProviderProps<T>) {
  const client = useApolloClient()
  const [updatePositionedObjectsMutation] = useUpdatePositionedObjectsMutation()

  // Executes a mutation to update the position of the provided item. The index is
  // used to determine the item's new position in the list.
  const updatePositionedObject: UpdatePositionedObject<T> = async ({
    item,
    index
  }) => {
    const positionOptions = {
      list: items,
      item,
      index
      // TODO: remove the need for this override. It seems TS can't know
      // that the items in list T[] match the same typing of the item T.
    } as PositionOptions<T>
    // We get the position of the item as it will be once moved to the new index.
    const positions = getPositions(positionOptions)
    // Get list of items with newly assigned positions.
    const positionedItems = assignPositions(positionOptions)
    // Get list of sorted items.
    const sortedItems = sortPositionedObjects(positionedItems)

    // Get list of obj positions to send in mutation.
    const objPositionInputs = positions.map((position, i) => {
      // Work backwards from positions length, so we can get the position
      // of the item in order as it appears in the positions array.
      const itemsIndex = index + i - (positions.length - 1)
      const item = sortedItems[itemsIndex]

      // Key the item with id.
      const key = `${itemKey}:${item.id}`

      return {
        objKey: key,
        objPosition: position
      }
    })

    // Updates cache as side-effect while reducing the positions to a list of
    // previous positions.
    function updateCache(
      objPositionInputs: PositionedObjectPositionInput[]
    ): PositionedObjectPositionInput[] {
      return objPositionInputs.reduce((acc, objPositionInput) => {
        const itemId = objPositionInput.objKey.split(":").slice(-1)[0]

        // Let's "optimisically" update the cache with the new position.
        // Note: we can't use apollo client's optimisticResponse since that's for
        // providing optimistic result of a mutation, but we're updating something else.
        const fragment = {
          id: `${item.__typename!}:${itemId}`,
          fragment: itemFragmentDocument,
          // Sometimes we must specify the fragmentName to avoid conflicts.
          fragmentName: (
            itemFragmentDocument.definitions[0] as FragmentDefinitionNode
          ).name.value
        }

        const data = client.readFragment(fragment)

        // Record the previous position.
        acc.push({
          ...objPositionInput,
          objPosition: data.position
        })

        // Write the new position.
        client.writeFragment({
          ...fragment,
          data: {
            ...data,
            position: objPositionInput.objPosition
          }
        })

        return acc
      }, [] as PositionedObjectPositionInput[])
    }

    // Record previous item positions in case we need to roll back.
    const prevObjPositionInputs = updateCache(objPositionInputs)

    // Now let's actually execute the mutation.
    await updatePositionedObjectsMutation({
      variables: {
        context,
        positions: objPositionInputs
      },
      // It's not critical that we roll back this update if we fail, but nice to do so.
      onError() {
        updateCache(prevObjPositionInputs)
      }
    })
  }

  return (
    <PositioningContext.Provider value={{ updatePositionedObject }}>
      {children}
    </PositioningContext.Provider>
  )
}
