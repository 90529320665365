import { Box, Button } from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useEffect } from "react"
import { exhaustiveGuard } from "../../lib/typing"
import CreateOrSelectResource from "./CreateOrSelectResource"
import PathCreatorForm, { OnFlowComplete } from "./PathCreatorForm"
import PathCreatorNav from "./PathCreatorNav"
import {
  PathCreatorDispatch,
  PathCreatorHistoryProps,
  PathCreatorProps,
  PathCreatorState,
  itemEquivalence,
  pathCreatorStateHistoryRevisor,
  usePathCreatorHistoryReducer,
  usePathCreatorReducer
} from "./state"

function AddPath({ onAddPath }: { onAddPath: () => void }) {
  const { t } = useTranslate()
  return (
    <Button
      onClick={onAddPath}
      leftIcon={<Pathicon icon="plus" />}
      cursor="pointer"
      w="100%"
      colorScheme="whiteAlpha"
    >
      {t("Add Path")}
    </Button>
  )
}

// Concerned with creating or selecting a Path (resource)
// and then with creating or selecting a Cohort.
function PathCreator({
  targetUserId,
  onFlowComplete,
  pathCreatorState,
  pathCreatorDispatch,
  historyState,
  historyDispatch
}: PathCreatorProps &
  PathCreatorHistoryProps & {
    targetUserId: number
    onFlowComplete?: OnFlowComplete
  }) {
  // Initial action is to enter the path creator flow.
  function handleOnAddPath() {
    pathCreatorDispatch({
      type: "set_status",
      status: "create_or_select_resource"
    })
  }

  // Note: for exhastive guard check to work, have to pull out
  // the state.status and use in both the switch and exhastive guard check.
  const { status } = pathCreatorState

  switch (status) {
    case "initial":
    case "complete":
      return <AddPath onAddPath={handleOnAddPath} />
    case "create_or_select_resource":
      return (
        <CreateOrSelectResource
          itemKey="resource"
          pathCreatorDispatch={pathCreatorDispatch}
        />
      )
    case "select_resource":
    case "create_resource":
    case "create_or_select_cohort":
    case "create_cohort":
    case "select_cohort":
    case "select_role":
    case "submit_with_resource_role":
    case "submit_with_cohort_role":
      return (
        <PathCreatorForm
          targetUserId={targetUserId}
          onFlowComplete={onFlowComplete}
          pathCreatorState={pathCreatorState}
          pathCreatorDispatch={pathCreatorDispatch}
          historyState={historyState}
          historyDispatch={historyDispatch}
        />
      )
    default:
      return exhaustiveGuard(status)
  }
}

export type PathCreatorFlowHookProps = {
  onChange?: (state: PathCreatorState) => void
  onComplete?: OnFlowComplete
}

export function usePathCreatorFlow({
  onChange,
  onComplete
}: PathCreatorFlowHookProps): Omit<PathCreatorFlowProps, "targetUserId"> {
  const [pathCreatorState, pathCreatorDispatch] = usePathCreatorReducer()
  const [historyState, historyDispatch] = usePathCreatorHistoryReducer({
    pathCreatorState,
    pathCreatorDispatch
  })

  // Wrapping the pathCreatorDispatch fn in order to take advantage of history.
  // We can revise history from the point at which we find a history item
  // with a matching status. Rather than simply dispatching the path creator
  // action, we can revise history back to the matching item, replacing with
  // the provided item, revising history up to the present, and updating the
  // history index to the most recent item.
  // This is basically a way to retain usable state which we would otherwise lose
  // if we were to simply push a "previous" history item to the front.
  const modifiedPathCreatorDispatch: PathCreatorDispatch = (action) => {
    if (action.type === "set") {
      // Find index of history item matching provided status.
      const historyItemIndex = historyState.items.findLastIndex(
        (historyItem) => historyItem.status === action.value.status
      )
      // Get history item at that index.
      const historyItem = historyState.items[historyItemIndex]

      // If we have a history item, let's revise history back to this index
      // and then forward, resetting the current history index to most recent.
      // This ensures current state of flow/form matches the furthest progressed state.
      if (historyItem) {
        if (itemEquivalence(historyItem, action.value)) {
          if (historyItemIndex !== historyState.items.length - 1) {
            return historyDispatch({
              type: "go",
              index: historyItemIndex
            })
          }
          return historyDispatch({
            type: "reviseForward",
            index: historyItemIndex,
            item: action.value,
            reviser: pathCreatorStateHistoryRevisor
          })
        }
      }
    }

    return pathCreatorDispatch(action)
  }

  useEffect(() => {
    if (pathCreatorState.status === "complete") {
      // In one dispatch, reset history and patch creator state
      // back to the "initial" status.
      historyDispatch({
        type: "reset"
      })
    }
  }, [pathCreatorState])

  useEffect(() => {
    onChange?.(pathCreatorState)
  }, [pathCreatorState])

  return {
    onFlowComplete: onComplete,
    pathCreatorState,
    pathCreatorDispatch,
    historyState,
    historyDispatch
  }
}

type PathCreatorFlowProps = {
  targetUserId: number
  onFlowComplete?: OnFlowComplete
} & PathCreatorProps &
  PathCreatorHistoryProps

function PathCreatorFlow({
  targetUserId,
  onFlowComplete,
  pathCreatorState,
  pathCreatorDispatch,
  historyState,
  historyDispatch
}: PathCreatorFlowProps) {
  return (
    <Box className="PathCreatorFlow" pos="relative" w="100%">
      <PathCreator
        targetUserId={targetUserId}
        onFlowComplete={onFlowComplete}
        pathCreatorState={pathCreatorState}
        // Using the modified dispatcher has resulted in buggy behavior that has
        // been difficult to diagnose, so forgoing for now.
        // pathCreatorDispatch={modifiedPathCreatorDispatch}
        pathCreatorDispatch={pathCreatorDispatch}
        historyState={historyState}
        historyDispatch={historyDispatch}
      />
      <PathCreatorNav
        historyState={historyState}
        historyDispatch={historyDispatch}
        pos="absolute"
        right="-2"
        top="0"
        transform="translateX(100%)"
      />
    </Box>
  )
}

export default PathCreatorFlow
