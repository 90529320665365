export const sampleImage =
  "https://source.unsplash.com/900x900/?building,architecture"
export const sampleHtml =
  "<p>The art or practice of designing and constructing buildings.</p>"

// term === front of card, definition === back of card
export const createSampleFlashcard = (num = 1) => ({
  order: num,
  definition: {
    image: num !== 1 ? sampleImage : "",
    html: num === 1 ? sampleHtml : ""
  },
  term: {
    image: "",
    html: `<p>Front of card ${num}</p>`
  }
})

export default {
  type: "Flashcards",
  icon: "cards",
  helpLink:
    "https://help.pathwright.com/create-a-course/adding-content-with-blocks/flashcards-block-new",
  menu: {
    category: "interactive",
    order: 2
  },
  layouts: {
    flashcards: {
      key: "flashcards",
      label: "Flashcards",
      data: {
        flashcards: [createSampleFlashcard(1), createSampleFlashcard(2)]
      }
    }
  },
  schema: `
    enum LegacyFlashcardType {
      HTML
      IMAGE
    }

    type FlashcardMedia {
      html: String
      image: String @media
      altText: String
      showHTML: Boolean
      showImage: Boolean
      audio: String @media
      type: LegacyFlashcardType
    }

    type Flashcard {
      order: Int!
      term: FlashcardMedia!
      definition: FlashcardMedia!
    }

    type Flashcards implements Block {
      id: ID!
      flashcards: [Flashcard]!
    }
  `
}
