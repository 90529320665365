// @ts-ignore
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"

// @ts-ignore
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"

// @ts-ignore
import Modal from "@pathwright/ui/src/components/modal/Modal"

import { Box, Input, VStack } from "@chakra-ui/react"
import KeyboardFormSubmit from "@pathwright/web/src/modules/components/formik/KeyboardFormSubmit"
import { Field, Form, Formik } from "formik"
import {
  GroupFragment,
  RegistrationFragment,
  ResourceFragment
} from "../../api/generated"

type ConfirmRemovalProps = {
  registration: RegistrationFragment
  cohort: GroupFragment
  resource: ResourceFragment
  onCancel: () => void
  onRemove: () => Promise<any>
}

const RegistrationRemove = ({
  registration,
  cohort,
  resource,
  onCancel,
  onRemove
}: ConfirmRemovalProps) => {
  const { t } = useTranslate()
  const isCollection = resource?.resource_type === 30
  const tPrefix = isCollection
    ? "cohort.member.actions.remove_from_collection"
    : "cohort.member.actions.remove"

  return (
    <Modal onClose={onCancel}>
      <VStack
        h="100%"
        justifyContent="center"
        sx={{
          pointerEvents: "none !important"
        }}
      >
        <Box pointerEvents="auto" bg="white" borderRadius="20px">
          <Formik
            initialValues={{ confirm: "" }}
            validate={(values) => {
              if (values.confirm !== "remove") return { confirm: "required" }
            }}
            validateOnMount
            onSubmit={onRemove}
          >
            {(form) => (
              <Form>
                <KeyboardFormSubmit />
                <BlankSlate
                  layer="overlay"
                  icons={null}
                  icon="caution-triangle"
                  heading={t(`${tPrefix}.heading`, {
                    user: registration.user!.first_name,
                    // Cohort may be source cohort, in which case there will be no name,
                    // so use resource name instead.
                    cohort: cohort?.name || resource?.name
                  })}
                  body={t(`${tPrefix}.body`, {
                    user: registration.user!.first_name
                  })}
                  preActionContent={
                    <Field
                      as={Input}
                      name="confirm"
                      placeholder={t(`${tPrefix}.confirm_input`)}
                      autoComplete="off"
                      mt={4}
                      mb="0 !important"
                      autoFocus
                    />
                  }
                  primaryAction={{
                    children: t(`${tPrefix}.confirm_prompt`, {
                      user: registration.user!.first_name
                    }),
                    dangerous: true,
                    disabled: !form.isValid || form.isSubmitting,
                    submitting: form.isSubmitting,
                    type: "submit"
                  }}
                  secondaryAction={{
                    children: t(`${tPrefix}.cancel`),
                    onClick: onCancel
                  }}
                  stack
                />
              </Form>
            )}
          </Formik>
        </Box>
      </VStack>
    </Modal>
  )
}

export default RegistrationRemove
