import { useMutation } from "@apollo/client"
import { useDisclosure } from "@chakra-ui/react"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import path from "path-browserify"
import {
  useActiveCertificateQuery,
  useCertificateDownloadUrlQuery
} from "../api/generated"
import useCohortPermissions from "../cohort/useCohortPermissions"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import ManageRegistration from "../registration/ManageRegistration"
import DELETE_REGISTRATION_MUTATION from "../registration/graphql/delete-registration-mutation"
import RegistrationRemove from "../registration/list/RegistrationRemove"
import { canAdministrate } from "../user/permissions"

export const ConfirmRemoval = ({
  registration,
  onRemoveRegistration,
  onClose
}) => {
  const [mutation] = useMutation(DELETE_REGISTRATION_MUTATION, {
    variables: {
      registration_id: registration.id,
      group_id: registration.group.id
    },
    update: (cache) => {
      onRemoveRegistration?.(registration, cache)
      onClose()
    }
  })

  return (
    <RegistrationRemove
      registration={registration}
      cohort={registration.group}
      resource={registration.resource}
      onRemove={mutation}
      onCancel={onClose}
    />
  )
}

// Separate function for rendering the dropdown item for the certificate
// download url to encapsulate queries, only running when parent menu is open.
export function CertificateDownloadDropdownItem({ registration }) {
  const { t } = useTranslate()
  const pwContext = usePathwrightContext()

  // Hack to allow for backend registration and graphql registration.
  const pathId = registration.pathId ?? registration.path?.id
  const progress = registration.progress ?? registration.path?.progress

  // Only query either query when meeting these necessary conditions.
  const skipQuery =
    !pwContext.school.has_certificate ||
    !canAdministrate(pwContext) ||
    progress < 1

  // We query sepeartely for an active certificate. This will allow
  // for differentiating between a legacy and new certificate. We only
  // currently support downloading certificate on behalf of another user
  // for newer style certificates.
  // Keeping this as a separate query means we just have to run this query
  // once and can skip running the actual certificate download url query if
  // there is an active newer certifiacte.
  const activeCertificateQuery = useActiveCertificateQuery({
    skip: skipQuery
  })
  const hasActiveCertificate = !!activeCertificateQuery.data?.certificate?.id
  // Query for the certificate download url for this path.
  const certificateDownloadUrlQuery = useCertificateDownloadUrlQuery({
    variables: { pathId },
    skip: skipQuery || !hasActiveCertificate,
    fetchPolicy: "network-only"
  })
  const ceritificateDownloadUrl =
    certificateDownloadUrlQuery.data?.certificate?.download_url

  // As long as we have an active ceritificate, we can safely render the item.
  return hasActiveCertificate ? (
    <DropdownItem
      icon="certificate"
      label={t("Download certificate")}
      href={ceritificateDownloadUrl}
    />
  ) : null
}

const ProfileRegistrationControls = ({
  registration,
  onRemoveRegistration
}) => {
  const { t } = useTranslate()
  const cohortPerms = useCohortPermissions({ cohortId: registration.group_id })
  const registrationRemoveDiscolosure = useDisclosure()

  if (!cohortPerms.hasModeratorLevelAccess) {
    return null
  }

  return (
    <>
      <DropdownTrigger
        preventHide={registrationRemoveDiscolosure.isOpen}
        renderDropdown={() => {
          return (
            <DropdownMenu>
              {registration.role <= 15 && (
                <DropdownItem
                  icon="gear"
                  label={t("Configure")}
                  to={{
                    pathname: path.join(
                      window.location.pathname,
                      `registration/${registration.id}/?cohort=${registration.group.id}`
                    ),
                    card: {
                      component: ManageRegistration,
                      props: {
                        groupId: registration.group.id,
                        registrationId: registration.id,
                        onSubmitSuccess:
                          App.getStore("member").action
                            .respondToMemberRoleUpdate
                      }
                    }
                  }}
                  closeOnClick={true}
                />
              )}
              <CertificateDownloadDropdownItem registration={registration} />
              <DropdownItem
                icon="slash"
                label={t("Remove")}
                onClick={registrationRemoveDiscolosure.onOpen}
                preventHide={registrationRemoveDiscolosure.isOpen}
                {...registrationRemoveDiscolosure.getButtonProps()}
              />
            </DropdownMenu>
          )
        }}
      >
        <IconButton
          icon="ellipses-horizontal"
          iconProps={{ style: { color: `rgba(1,1,1, .5)` } }}
        />
      </DropdownTrigger>
      {registrationRemoveDiscolosure.isOpen && (
        <ConfirmRemoval
          registration={registration}
          onRemoveRegistration={onRemoveRegistration}
          onClose={registrationRemoveDiscolosure.onClose}
        />
      )}
    </>
  )
}

ProfileRegistrationControls.displayName = "ProfileRegistrationControls"

export default ProfileRegistrationControls
