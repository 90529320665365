import { graphql } from "@apollo/client/react/hoc"
import Button from "@pathwright/ui/src/components/button/Button"
import Link from "@pathwright/ui/src/components/link/Link"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Text from "@pathwright/ui/src/components/ui/Text"
import compose from "lodash/flowRight"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import moment from "moment"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import { Component } from "react"
import styled from "styled-components"
import TOTAL_MEMBERS_WITH_FILTER_QUERY from "../../membership/graphql/total-members-with-filters-query"
import {
  SUBSCRIPTION_CHARGE_TYPE_INVOICE,
  SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE,
  SUBSCRIPTION_INTERVAL_BIANNUALLY,
  SUBSCRIPTION_INTERVAL_LABELS,
  SUBSCRIPTION_INTERVAL_MONTHLY,
  SUBSCRIPTION_INTERVAL_YEARLY
} from "../../subscription/constants"
import {
  BILLING_HELP_ARTICLES,
  BILLING_MODEL_HYBRID,
  BILLING_MODEL_PAYG,
  BILLING_MODEL_USER
} from "../constants"
import { InvoiceType, SubscriptionType } from "../types"
import { getLineItemsForInvoice, usdNoDec } from "../utils"
import ManageBillingOverdueBill from "./ManageBillingOverdueBill"

const StyledContainer = styled.div`
  padding: 20px 0;
  border-radius: 5px;

  position: relative;
  header,
  section {
    margin-bottom: 30px;
  }

  header {
    text-align: center;
  }
`

export const StyledToolbox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.planColor};

  i {
    color: ${(p) => p.planColor};
  }
`

export const StyledSubtitle = styled(Text.Meta)`
  max-width: 400px;
  font-size: 1em;
  display: inline-block;
`

const CategorySection = styled.div`
  position: relative;

  &:nth-child(n + 3):before {
    content: "";
    background-color: ${(p) => (p.showBorder ? "lightgray" : "transparent")};
    position: absolute;
    top: 0;
    left: 0;
    left: 30px;
    right: calc(100% - 30px);
    width: calc(100% - 60px);
    height: 1px;
  }
`

export const Category = styled.div`
  padding: 10px 30px 0px;
`

const CategoryTitle = styled.div`
  padding: 10px 0 7px;
  display: flex;
  justify-content: space-between;
`

const CategoryItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 2rem;
`

export const CategoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  /* border-bottom: dashed 1px rgba(0, 0, 0, 0.2); */
  &:last-child {
    border-bottom: none;
  }

  > * {
    padding: 3px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      /* text-align: right; */
    }
  }
`

export const LinkItem = styled.div`
  display: flex;
  padding: 5px 0;

  a.Link {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.4);
    text-decoration: underline;
  }
`

export const StyledPricing = styled(Text.Meta)`
  font-size: 0.9em;
  color: #333;
`

export class ManageBillingInvoiceItemDetails extends Component {
  get invoiceType() {
    return this.props.invoice.invoice_json.billing_model
  }

  get isEstimate() {
    return !this.props.invoice.id
  }

  get planDetails() {
    return this.props.invoice.invoice_json.plan_details
  }

  get planName() {
    switch (this.invoiceType) {
      case BILLING_MODEL_USER:
        return this.planDetails.name
      case BILLING_MODEL_HYBRID:
        return `Up to ${this.planDetails.included_members} ${pluralize(
          "member",
          this.planDetails.included_members
        )}`
      case BILLING_MODEL_PAYG:
        return "Pay as you go"
    }
  }

  get billableMembersLabel() {
    switch (this.invoiceType) {
      case BILLING_MODEL_USER:
        return "Learners"
      case BILLING_MODEL_HYBRID:
        return "Active Members"
      case BILLING_MODEL_PAYG:
        return ""
    }
  }

  get numBillableMembers() {
    switch (this.invoiceType) {
      case BILLING_MODEL_USER:
        return this.props.invoice.invoice_json.user.learners.billable
      case BILLING_MODEL_HYBRID:
        return this.props.invoice.invoice_json.hybrid.members.total
      case BILLING_MODEL_PAYG:
        return null
    }
  }

  get lineItemsForInvoice() {
    return getLineItemsForInvoice(
      this.props.invoice,
      this.props.subscription,
      this.props.totalMembersWithAccess,
      this.props.schoolScholarship
    )
  }

  get billingHelpArticle() {
    return BILLING_HELP_ARTICLES[this.invoiceType].length > 0
      ? BILLING_HELP_ARTICLES[this.invoiceType]
      : BILLING_HELP_ARTICLES[this.invoiceType][
          this.planDetails.learner_counting
        ]
  }

  // Hacky way to get the invoice interval
  get invoiceIntervalLabel() {
    const diff =
      new Date(this.props.invoice.cycle_end).getTime() -
      new Date(this.props.invoice.cycle_start).getTime()
    const daysDiff = Math.floor(diff / (1000 * 60 * 60 * 24))

    const daysInterval = {
      [SUBSCRIPTION_INTERVAL_MONTHLY]: 30,
      [SUBSCRIPTION_INTERVAL_BIANNUALLY]: 182,
      [SUBSCRIPTION_INTERVAL_YEARLY]: 365
    }

    const interval =
      Object.keys(daysInterval).find(
        (interval) =>
          daysDiff > daysInterval[interval] - 10 &&
          daysDiff < daysInterval[interval] + 10
      ) || SUBSCRIPTION_INTERVAL_MONTHLY

    return SUBSCRIPTION_INTERVAL_LABELS[interval]
  }

  get isAnnual() {
    return (
      this.props.invoice.invoice_json.billing_interval ===
      SUBSCRIPTION_INTERVAL_YEARLY
    )
  }

  get isManual() {
    return (
      get(this.props.subscription, "charge_type") ===
      SUBSCRIPTION_CHARGE_TYPE_INVOICE
    )
  }

  get isCanceled() {
    return !isEmpty(get(this.props.subscription, "subscription.canceled_dtime"))
  }

  get canceledDate() {
    return moment(get(this.props.subscription, "subscription.canceled_dtime"))
  }

  get isNoCharge() {
    return (
      get(this.props.subscription, "charge_type") ===
      SUBSCRIPTION_CHARGE_TYPE_NO_CHARGE
    )
  }

  render() {
    if (this.props.loading) {
      return <LoadingCircle />
    }

    if (!this.props.invoice || !this.props.invoice.invoice_json) {
      return (
        <StyledContainer>
          <header>
            <StyledSubtitle>
              We couldn't locate your plan information. Please contact{" "}
              <Link to="mailto:hello@pathwright.com">
                mailto:hello@pathwright.com
              </Link>{" "}
              to correct your plan configuration.
            </StyledSubtitle>
          </header>
        </StyledContainer>
      )
    }

    return (
      <StyledContainer>
        <header>
          <StyledToolbox
            planColor={
              this.planDetails ? `#${this.planDetails.color}` : "black"
            }
          >
            <Pathicon icon="toolbox" />
          </StyledToolbox>
          <Text.H3
            style={{
              color: this.planDetails ? `#${this.planDetails.color}` : null,
              marginBottom: "0.7rem"
            }}
          >
            {this.planName}
          </Text.H3>
          <StyledSubtitle>
            {this.isManual || this.isNoCharge
              ? `Your ${this.isAnnual ? "annual" : "monthly"} bill estimate`
              : `${
                  this.isCanceled
                    ? this.canceledDate.isAfter()
                      ? `Subscription cancelled. Members will retain access until ${this.canceledDate.format(
                          "MMM Do, YYYY"
                        )}.`
                      : `Subscription cancelled on ${this.canceledDate.format(
                          "MMM Do, YYYY"
                        )}.`
                    : `Your ${
                        this.isAnnual ? "annual" : "monthly"
                      } bill estimate for use between ${moment(
                        this.props.invoice.cycle_start
                      ).format("MMM Do, YYYY")} and ${moment(
                        this.props.invoice.cycle_end
                      ).format("MMM Do, YYYY")}.`
                }
            `}
          </StyledSubtitle>
        </header>
        {Object.entries(this.lineItemsForInvoice).map(
          ([key, categories], i) => (
            <CategorySection showBorder={key !== "totals"} key={i}>
              {categories.map(
                ({ label, total, icon, meta, link, items }, i) => (
                  <Category
                    key={i}
                    style={{
                      backgroundColor: key === "totals" ? "whitesmoke" : ""
                    }}
                  >
                    <CategoryTitle>
                      <div style={{ display: "flex" }}>
                        <Pathicon
                          icon={icon || "toolbox"}
                          style={{
                            marginRight: "1rem",
                            color: icon ? "rgba(0, 0, 0, 0.4)" : "rgba(0,0,0,0)"
                          }}
                        />
                        <Text.H4
                          style={{
                            fontWeight: key === "totals" ? "400" : "700"
                          }}
                        >
                          {label}{" "}
                          <Text.Meta
                            style={{
                              fontSize: "0.8em",
                              color: "rgba(0,0,0,0.4)",
                              fontWeight: "400"
                            }}
                          >
                            {meta}
                          </Text.Meta>
                        </Text.H4>
                      </div>
                      <StyledPricing>{usdNoDec(total)}</StyledPricing>
                    </CategoryTitle>
                    {!!items && (
                      <CategoryItems>
                        {items.map(({ label, total }, i) => (
                          <CategoryItem key={i}>
                            <Text.Meta>{label}</Text.Meta>
                            {/* {showSubtotals ? <Text.Meta>{usdNoDec(total)}</Text.Meta> : null} */}
                          </CategoryItem>
                        ))}
                        {!!link && (
                          <LinkItem>
                            <Link to={link.url} target="_blank">
                              {link.label}
                            </Link>
                          </LinkItem>
                        )}
                      </CategoryItems>
                    )}
                  </Category>
                )
              )}
            </CategorySection>
          )
        )}
        <ManageBillingOverdueBill
        // testing only
        // subscription={get(this.props.subscription, "subscription")}
        // invoice={this.props.invoice}
        >
          {(OverdueComponent) => (
            <StyledContainer style={{ backgroundColor: "whitesmoke" }}>
              <Category>{OverdueComponent}</Category>
            </StyledContainer>
          )}
        </ManageBillingOverdueBill>
        <Category>
          <Text.Meta>
            Have questions about your bill?{" "}
            <Button
              styleType="text"
              href={this.billingHelpArticle}
              target="_blank"
            >
              Learn more.
            </Button>
          </Text.Meta>
        </Category>
      </StyledContainer>
    )
  }
}

ManageBillingInvoiceItemDetails.displayName = "ManageBillingInvoiceItemDetails"
ManageBillingInvoiceItemDetails.propTypes = {
  subscription: SubscriptionType,
  invoice: InvoiceType,
  loading: PropTypes.bool,
  schoolScholarship: PropTypes.object
}

export default compose(
  graphql(TOTAL_MEMBERS_WITH_FILTER_QUERY, {
    options: () => ({
      variables: {
        hasAccess: true,
        role_filter: { eq: "student" }
      }
    }),
    props: ({ data }) => {
      const { error, loading, refetch, school } = data
      return {
        error,
        loading,
        refetch,
        totalMembersWithAccess: get(school, "members.total")
      }
    }
  })
)(ManageBillingInvoiceItemDetails)
