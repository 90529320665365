import AssignCard from "@pathwright/web/src/modules/assign/AssignCard"
import Dashboard from "@pathwright/web/src/modules/dashboard/Dashboard"
import UserReportCard from "@pathwright/web/src/modules/path/report/UserReportCard"
import ProfileCard from "@pathwright/web/src/modules/profile/ProfileCard"
import ManageRegistration from "@pathwright/web/src/modules/registration/ManageRegistration"
import { captureMessage, withScope } from "@sentry/browser"
import { createCardRoutes } from "lib/core/routing/card-routing"
import { ConnectStores } from "lib/core/store"
import path from "path-browserify"
import { useEffect } from "react"

const withCurrentPathReportCard = (UserReportCardContainer) =>
  ConnectStores(
    UserReportCardContainer,
    [{ store: "path", watch: ["path"] }],
    {},
    (state, ownProps) => ({
      pathId: state.pathStore.state.path && state.pathStore.state.path.id,
      ...ownProps
    }),
    false /* cacheComponent */
  )

const CurrentPathReportCardContainer = withCurrentPathReportCard(UserReportCard)

function UserOrPathReportCard({ pathId, ...rest }) {
  return pathId ? (
    <UserReportCard pathId={pathId} {...rest} />
  ) : (
    <CurrentPathReportCardContainer {...rest} />
  )
}

function CaptureDuplicatedPathId(props) {
  const pathId = parseInt(props.match.params.pathId)
  const collectionResourcePathId = parseInt(
    props.match.params.collectionResourcePathId
  )
  const duplicated = pathId === collectionResourcePathId

  // Log the duplicate path IDs and navigate away, popping off the last
  // path ID in the URL.
  useEffect(() => {
    if (duplicated) {
      const issue = {
        message: "Found duplicate path IDs specified in report card url.",
        extra: ["duplicatedPathId", pathId]
      }

      withScope((scope) => {
        scope.setExtra(...issue.extra)
        captureMessage(issue.message)
        console.warn(issue.message, ...issue.extra)
      })

      App.navigate(path.join(props.location.pathname, "../"))
    }
  }, [duplicated])

  return !duplicated ? (
    <UserReportCard {...props} pathId={collectionResourcePathId} />
  ) : null
}

export const reportCardRoutes = {
  report: {
    path: ":pathId(\\d+)/",
    component: (props) => (
      <UserOrPathReportCard
        {...props}
        pathId={parseInt(props.match.params.pathId)}
      />
    ),
    childCards: {
      resourceReport: {
        path: ":collectionResourcePathId(\\d+)/",
        component: CaptureDuplicatedPathId
      }
    }
  }
}

export const assignCardRoutes = {
  ["add-to-path"]: {
    getDefaultLaunchedFrom: () => "/dashboard/",
    component: (props) => (
      <AssignCard
        card={props.card}
        userId={parseInt(props.location.query.user)}
        communityGroupId={parseInt(props.location.query.community_group)}
      />
    )
  }
}

export const registrationConfigCardRoutes = {
  registration: {
    path: "registration/:registrationId(\\d+)?/",
    reversePath: "../../",
    component: (props) => (
      <ManageRegistration
        card={props.card}
        groupId={parseInt(
          props.match.params.cohortId || props.location.query.cohort
        )}
        registrationId={parseInt(props.match.params.registrationId)}
        onSubmitSuccess={
          App.getStore("member").action.respondToMemberRoleUpdate
        }
      />
    )
  }
}

export const profileCardRoute = {
  profile: {
    path: ":userId(\\d+)/",
    getDefaultLaunchedFrom: () => "/dashboard/",
    component: (props) => (
      <ProfileCard {...props} userId={parseInt(props.match.params.userId)} />
    ),
    childCards: {
      ...assignCardRoutes,
      ...reportCardRoutes,
      ...registrationConfigCardRoutes
    }
  }
}

export default createCardRoutes("/user/", profileCardRoute, Dashboard)
