import {
  HomeRegistrationsQueryVariables,
  OrderByDir,
  PositioningContextTypeInput
} from "../api/generated"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// This is really just a stop-gap to share vars with other queries
export const useHomeRegistrationVars = (): HomeRegistrationsQueryVariables => {
  const { me } = usePathwrightContext()

  return {
    first: 1042,
    positioningContext: {
      contextType: PositioningContextTypeInput.HomeScreen,
      contextKey: `user:${me.id}`
    },
    orderBy: [{ position: OrderByDir.Asc }, { created: OrderByDir.Asc }],
    softArchived: { eq: null }
  }
}

export const useHomeSoftArchivedRegistrationVars =
  (): HomeRegistrationsQueryVariables => {
    return {
      first: 1042,
      orderBy: [{ created: OrderByDir.Asc }],
      softArchived: { neq: null }
    }
  }
