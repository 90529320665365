class User extends require("lib/static-shim").default(
  require("lib/utils/model").default
) {
  initialize(options) {
    this.options = options
    super.initialize(options)
    // TODO: remove after auth permissions refactor
    const can_manage =
      this.get("is_superuser") || this.get("membership")?.is_school_admin
    return this.set("can_manage_school", can_manage)
  }

  url() {
    if (this.id) {
      return this.urlRoot(`/api/private/user/${this.id}/`)
    } else {
      return this.urlRoot(`/api/private/user/`)
    }
  }

  defaults() {
    return {
      is_authenticated: false,
      can_manage_school: false,
      profile: {}
    }
  }

  signOut() {
    const promise = $.ajax(Pathwright.getURL("api-user-auth"), {
      type: "DELETE"
    })
    promise.done((resp) => {
      this.clear({ silent: true })
      return this.set("is_authenticated", false)
    })
    promise.fail((resp) => {
      return console.error("Error signing out: ", resp)
    })
    return promise
  }

  getFullName() {
    return `${this.get("first_name")} ${this.get("last_name")}`
  }

  getAbbName() {
    if (this.get("first_name") && this.get("last_name")) {
      return `${this.get("first_name")} ${this.get("last_name").slice(0, 1)}.`
    }
  }

  toJSON() {
    const data = super.toJSON()
    data.abb_name = this.getAbbName()
    data.full_name = this.getFullName()
    return data
  }
}

User.initClass()

export default User
