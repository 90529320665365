import { useMutation, useQuery } from "@apollo/client"
import { FormControl, FormErrorMessage, HStack, Input } from "@chakra-ui/react"
import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import {
  getFormError,
  validate
} from "@pathwright/ui/src/components/form/utils"
import useDidMountEffect from "@pathwright/ui/src/components/hooks/useDidMountEffect"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import OutsideClickWrapper from "@pathwright/ui/src/components/overlay/OutsideClickWrapper"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import LazyQuillEditor from "@pathwright/ui/src/components/quill/LazyQuillEditor"
import { TOOLBAR_CONFIG_TYPES } from "@pathwright/ui/src/components/quill/toolbar/QuillToolbar"
import Tooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import { media } from "@pathwright/ui/src/components/utils/styles"
import CustomEvent from "custom-event"
import { Formik } from "formik"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { useRef, useState } from "react"
import styled from "styled-components"
import { useDiscussionPostLazyQuery } from "../../api/generated"
import useCohortMembersStats from "../../cohort/useCohortMembersStats"
import useCohortPermissions from "../../cohort/useCohortPermissions"
import { usePathwrightClient } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import useTags from "../../tag/useTags"
import { DISCUSSION_TYPE_QUESTION } from "../constants"
import CREATE_DISCUSSION_MUTATION from "../graphql/create-discussion-mutation"
import UPDATE_DISCUSSION_MUTATION from "../graphql/update-discussion-mutation"
import { useDiscussionListContext } from "../list/DiscussionListContainer"
import { getDiscussionContext } from "../list/item/utils"
import { invalidateDiscussionCache } from "../list/utils"
import { discussionContextPropType } from "../propTypes"
import DiscussionFormNotify from "./DiscussionFormNotify"
import DiscussionFormTags from "./DiscussionFormTags"
import { DISCUSSION_NOTIFY_OPTIONS } from "./constants"

const COHORT_CONTEXT_QUERY = gql`
  query DiscussionCohortContextQuery($id: Int!) {
    cohort: group(id: $id) {
      id
      is_source_cohort
    }
  }
`

const useShouldEnableNotify = ({ cohortId, discussionExists }) => {
  const query = useQuery(COHORT_CONTEXT_QUERY, {
    variables: {
      id: cohortId
    },
    skip: discussionExists
  })

  // Cannot notify about existing discussion, only new discussion.
  if (discussionExists) return false

  // Cannot notify about source cohort discussion.
  return query.data?.cohort?.is_source_cohort === false
}

const Container = styled.section`
  --inline-discussion-spacing: 1em;
  --inline-discussion-timing: 0.2s;

  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: ${(p) =>
    p.expanded
      ? "var(--inline-discussion-spacing)"
      : "calc(var(--inline-discussion-spacing) / 2)"};
  background: ${(p) =>
    p.inverted ? "rgba(255, 255, 255, 0.33)" : "tranparent"};
  border-radius: 10px;
  transition: padding var(--inline-discussion-timing) ease;
  cursor: ${(p) => (p.expanded ? "initial" : "text")};

  /* ensure Avatar is visible on smaller screensizes */
  max-width: calc(100% - 70px);
  min-width: min(calc(100% - 70px), 100%);

  /* Avatar is hidden on phone screensize */
  ${(p) => (p.expanded ? media.max.phone`max-width: 100%;` : "")}

  .DiscussionInlineForm__title {
    position: relative;
    display: flex;
    align-items: flex-start;

    .Avatar {
      position: absolute;
      left: calc(
        -1 * var(--avatar-size) - ${(p) => (p.expanded ? "calc(var(--inline-discussion-spacing) / 2)" : "calc(var(--inline-discussion-spacing) / 4)")}
      );
      transition: left var(--inline-discussion-timing) ease;

      /* hide Avatar on small screens when expanded */
      ${(p) => (p.expanded ? media.max.phone` display: none;` : "")}
    }
  }

  .UserTextInput,
  .RichTextInput {
    min-width: 100%;
  }

  ${(p) =>
    p.inverted &&
    `.Avatar,
    .SubmitButton,
    .TagSelector {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }`}

  form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${media.min
      .phone`padding-left: calc(var(--inline-discussion-spacing) / 2);`}
  }

  .TagSelector {
    margin-bottom: calc(var(--inline-discussion-spacing) / 2);
  }

  .TextInput {
    padding: 0;
    width: 100%;

    .TextInput__input input {
      ${(p) => !p.expanded && "border-color: transparent"};
      transition: border-color var(--inline-discussion-timing) ease,
        background-color var(--inline-discussion-timing) ease;
    }

    .TextInput__input input[name="title"] {
      background-color: ${(p) => (p.expanded ? "white" : "transparent")};
    }

    ${(p) =>
      p.inverted &&
      `.styled-field > span {
      color: white;
    }`}
  }

  .ql-editor {
    /* roughly 2 lines, assuming line-height is 1.3em */
    min-height: 2.6em;
  }

  .ql-container {
    background: white;
  }
`

const ExpandedContainer = styled.div`
  width: 100%;
  opacity: ${(p) => (p.expanded ? 1 : 0)};
  transition: opacity var(--inline-discussion-timing) ease;

  &:not(:empty) {
    margin-top: calc(var(--inline-discussion-spacing) / 2);
  }
`

const SubmitActionContainer = styled.div`
  display: flex;
  align-items: center;
  /* If DiscussionFormNotify is not present, the SubmitActionContainer
    will fill the entire SubmitContainer and be centered. */
  flex-grow: 1;
  justify-content: center;

  > span {
    margin-left: 0.4em;
    margin-right: 0.4em;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;
  margin-top: calc(var(--inline-discussion-spacing) / 2);
  ${media.max.phone`flex-direction: column;`}

  > .DiscussionFormNotify {
    /* Whenever DiscussionFormNotify is present, take up remaining space
      on the row */
    flex-grow: 10000000;
    ${media.max.phone`margin-bottom: var(--inline-discussion-spacing);`}
  }

  .Tooltip {
    min-width: 200px;
    white-space: normal;
    text-align: left;
  }
`

const NotifySuccess = ({ context, onClear }) => {
  const { t } = useTranslate()
  const cohortMembersStats = useCohortMembersStats(context)

  return (
    <PopupAlert success onClear={onClear}>
      {t("{{ count, number }} person was notified.", {
        defaultValue_plural: "{{ count, number }} people were notified.",
        count: cohortMembersStats.send_discussion_notifications
      })}
    </PopupAlert>
  )
}

// TODO: DiscussionForm has become unweildy now that it supports updating an
// existing discussion. Would be great to separate concerns and dumb down the
// form a bit.
const DiscussionForm = ({
  context: contextProp,
  discussion,
  isDiscussionQuestion,
  titleOptions,
  inverted,
  expanded: preferredExpanded,
  onToggleExpanded,
  onCancel,
  onSubmit
}) => {
  const [expanded, setExpanded] = useState(preferredExpanded)
  const [showBody, setShowBody] = useState(preferredExpanded)
  const [didNotify, setDidNotify] = useState(false)
  const titleInputRef = useRef(null)
  const { t } = useTranslate()
  const client = usePathwrightClient()
  const pwContext = usePathwrightContext()
  const discussionListContext = useDiscussionListContext()
  const screensize = useScreensizeContext()
  const [getDiscussionPostQuery] = useDiscussionPostLazyQuery()
  // discussion context can be supplied via prop or context
  const discussionContext =
    contextProp ||
    (discussionListContext
      ? discussionListContext.context
      : getDiscussionContext(discussion))
  const cohortPerms = useCohortPermissions({
    cohortId: discussionContext.cohort_id
  })
  const shouldEnableNotify = useShouldEnableNotify({
    cohortId: discussionContext.cohort_id,
    discussionExists: !!discussion
  })

  // Only interested in expanded when it has changed from initial state.
  useDidMountEffect(() => {
    onToggleExpanded && onToggleExpanded(expanded)
    if (expanded) titleInputRef.current.focus()
  }, [expanded])

  const [createDiscussion] = useMutation(CREATE_DISCUSSION_MUTATION, {
    variables: {
      context: discussionContext
    },
    // HACK ALERT: https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
    update: (cache) => {
      invalidateDiscussionCache(discussionListContext)
    }
  })

  const [updateDiscussion] = useMutation(UPDATE_DISCUSSION_MUTATION, {
    variables: {
      context: discussionContext
    }
  })

  // The tags that initially exist, if discussion.
  const discussionTags = discussion
    ? discussion.tagLinks.map((tagLink) => tagLink.tag)
    : []
  const { updateSelectedTags } = useTags({
    context: discussionContext,
    selectedTags: discussionTags
  })

  const handleSubmit = async (form) => {
    const mutate = discussion ? updateDiscussion : createDiscussion
    const variables = {
      post: {
        title: form.values.title,
        body: form.values.body,
        is_discussion_question: form.values.isDiscussionQuestion,
        // Currently all step and community discussions are set as "question" types
        // regardless of isDiscussionQuestion.
        type: DISCUSSION_TYPE_QUESTION
      }
    }

    if (discussion) {
      // Id required when updating existing discussion.
      variables.id = discussion.id
    } else {
      // Only supply notify arg when creating discussion.
      variables.notify = form.values.notify
    }

    const result = await mutate({
      variables
    })

    const mutatedDiscussion =
      result.data.createDiscussion || result.data.updateDiscussion

    // Select the tags now that the discussion has been created.
    await updateSelectedTags(form.values.selectedTags, {
      discussion_context_id: mutatedDiscussion.context_id
    })

    if (discussion) {
      // Refetching discussion ensures tag links are updated in cache.
      // TODO: only refetch if selected tags changed.
      await getDiscussionPostQuery({
        variables: {
          id: discussion.id,
          context: discussionContext
        }
      })
    }

    if (discussionListContext) {
      // reset discussion list sorting and filtering to their defaults
      const { initialSort, initialFilter, onSort, onFilter, refetch } =
        discussionListContext

      onSort(initialSort)
      onFilter(initialFilter)
      // refetch the default list
      await refetch()
    }

    // Only reset to initial state if we created a discussion.
    if (!discussion) {
      // reset form and collapse after successful submission (TODO: ensure successful)
      form.resetForm()
      setExpanded(preferredExpanded)
      // alert user to the fact that they notified x number of people
      setDidNotify(form.values.notify === DISCUSSION_NOTIFY_OPTIONS.NOTIFY)
    }

    // Alert listeners of discussion being updated/created.
    const eventName = discussion ? "discussion:updated" : "discussion:created"
    const event = new CustomEvent(eventName, {
      detail: { discussion: mutatedDiscussion }
    })
    document.dispatchEvent(event)

    onSubmit(mutatedDiscussion)
  }

  const initialValues = discussion
    ? {
        title: discussion.title,
        body: discussion.body,
        isDiscussionQuestion: discussion.is_discussion_question,
        selectedTags: discussionTags,
        notify: DISCUSSION_NOTIFY_OPTIONS.DO_NOT_NOTIFY
      }
    : {
        title: "",
        body: "",
        isDiscussionQuestion,
        selectedTags: [],
        // notify may update automatically after contextual permissions
        // are applied in DiscussionFormNotify.
        notify: DISCUSSION_NOTIFY_OPTIONS.DO_NOT_NOTIFY
      }

  const titleInputIsInverted = inverted && !expanded

  if (!pwContext.me || !cohortPerms.hasLearnerLevelAccess) return null

  return (
    <Formik
      initialValues={initialValues}
      validate={validate((key, value, values) => {
        if (!expanded) return
        switch (key) {
          case "title":
            if (!value) return t("Please enter a title.")
            if (value.length > 255) return t("Please enter a title that's no more than 255 characters.") // prettier-ignore
            break
        }
      })}
    >
      {(form) => {
        const titleIsInvalid =
          expanded &&
          Object.keys(form.touched).length &&
          !!getFormError(form, "title", false)

        return (
          <>
            <OutsideClickWrapper
              shouldListen={expanded}
              onOutsideClick={() => {
                // OK to clear all errors as the only validation performed is to
                // check if the title has a value
                form.setErrors(
                  Object.keys(form.errors).reduce(
                    (errors, key) => ({
                      ...errors,
                      [key]: null
                    }),
                    {}
                  )
                )

                // only collapse UIs if not interacting with some related, yet not nested, UIs
                // hacky, but no ideal way to know that the QuillEditor extended UI is being interacted with
                if (
                  !document.querySelector(".TagManagerCard") &&
                  !document.querySelector(".EmbedCard") &&
                  !document.querySelector("#__filestack-picker")
                ) {
                  const bodyIsEmpty =
                    !form.values.body || form.values.body === "<p><br></p>"
                  // for now, only collapse if the form is untouched
                  if (
                    !form.values.title &&
                    bodyIsEmpty &&
                    !form.values.selectedTags.length
                  )
                    setExpanded(preferredExpanded)
                  // hiding the QuillEditor if has no value
                  if (bodyIsEmpty) setShowBody(preferredExpanded)
                }
              }}
            >
              <Container
                className="DiscussionForm"
                role="application"
                aria-expanded={expanded}
                expanded={expanded}
                onClick={() => setExpanded(true)}
                inverted={inverted}
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  {expanded && (
                    <DiscussionFormTags
                      context={discussionContext}
                      selectedTags={form.values.selectedTags}
                      onChange={(selectedTags) =>
                        form.setFieldValue("selectedTags", selectedTags)
                      }
                      inverted={inverted}
                    />
                  )}
                  <div className="DiscussionInlineForm__title">
                    {
                      // In order to show user's Avatar, must not be a "discussion prompt",
                      // and user either must be creating a new discussion or editing their own discussion.
                      !form.values.isDiscussionQuestion &&
                        (!discussion ||
                          discussion.user.id === pwContext.user.id) && (
                          <Avatar
                            user={pwContext.me}
                            size={screensize === "sm" ? 30 : 40}
                          />
                        )
                    }
                    <FormControl
                      // Only show error if body field has been touched.
                      isInvalid={titleIsInvalid}
                    >
                      <Input
                        ref={titleInputRef}
                        type="text"
                        id="discussion-title"
                        name={"title"}
                        background={
                          titleInputIsInverted ? "transparent" : "white"
                        }
                        outline="none"
                        style={{
                          outlineWidth: "1px"
                        }}
                        _hover={{
                          outline: "none"
                        }}
                        px="5px"
                        _placeholder={{
                          color: titleInputIsInverted
                            ? "rgba(255,255,255,0.6)"
                            : "rgba(0,0,0,0.2)"
                        }}
                        borderColor={
                          titleInputIsInverted
                            ? "transparent"
                            : "rgba(0, 0, 0, 0.2)"
                        }
                        // variant="filled"
                        // isRequired
                        placeholder={
                          // TODO: cleanup logic.
                          // Using prompt from a selected tag when only that tag is selected and has a "prompt" (description).
                          (form.values.selectedTags &&
                            form.values.selectedTags.length === 1 &&
                            form.values.selectedTags[0].description) ||
                          titleOptions.placeholder ||
                          t("What would you like to discuss?")
                        }
                        value={form.values.title}
                        onChange={(e) => form.handleChange(e)}
                        onBlur={(e) => form.handleBlur(e)}
                      />
                      {titleIsInvalid ? (
                        <FormErrorMessage
                          color={inverted ? "white" : "rgba(0, 0, 0, 0.4)"}
                        >
                          <HStack>
                            <Pathicon icon="x" size="1em" />
                            <span>
                              {getFormError(form, "title", false)?.[0]}
                            </span>
                          </HStack>
                        </FormErrorMessage>
                      ) : null}
                    </FormControl>
                  </div>
                  <ExpandedContainer expanded={expanded}>
                    {expanded &&
                      (showBody ? (
                        <LazyQuillEditor
                          name="body"
                          html={form.values.body}
                          onChange={(html) =>
                            form.setFieldValue(
                              "body",
                              html,
                              false /* shouldValidate */
                            )
                          }
                          onBlur={() => form.setFieldTouched("body")}
                          onDelete={() => {
                            if (
                              !form.values.body ||
                              form.values.body === "<p><br></p>"
                            )
                              setShowBody(preferredExpanded)
                          }}
                          // Autofocus the QuillEditor when it mounts as long as body has no value
                          // and we aren't mounting the DiscussionForm in its expanded state.
                          autofocus={!form.values.body && !preferredExpanded}
                          toolbar={TOOLBAR_CONFIG_TYPES.FULL}
                          createCustomToolbar={({ getMergedToolbarConfig }) =>
                            getMergedToolbarConfig({
                              excludedOptions: "header"
                            })
                          }
                          theme="snow"
                        />
                      ) : (
                        <TextInput
                          placeholder={t(
                            "Add more information, images, etc. (optional)"
                          )}
                          onFocus={() => setShowBody(true)}
                          inverted={inverted}
                        />
                      ))}
                    {expanded && (
                      <SubmitContainer>
                        {shouldEnableNotify && (
                          <DiscussionFormNotify
                            context={discussionContext}
                            inverted={inverted}
                          />
                        )}
                        <SubmitActionContainer>
                          <Tooltip
                            fitContent
                            title={
                              !form.dirty || !form.isValid
                                ? t("Please enter a title.")
                                : ""
                            }
                          >
                            <SubmitButton
                              styleType="primary"
                              size="large"
                              onClick={() => handleSubmit(form)}
                              disabled={
                                !form.dirty ||
                                !form.isValid ||
                                !form.values.title
                              }
                              brand
                              inverted={inverted}
                            >
                              {discussion ? t("Update") : t("Post")}
                            </SubmitButton>
                          </Tooltip>
                          {!!discussion && (
                            <>
                              <span>{t("or")}</span>
                              <Button
                                styleType="text"
                                onClick={onCancel}
                                label={t("Cancel")}
                                inverted={inverted}
                              />
                            </>
                          )}
                        </SubmitActionContainer>
                      </SubmitContainer>
                    )}
                  </ExpandedContainer>
                </form>
              </Container>
            </OutsideClickWrapper>
            {didNotify && (
              <NotifySuccess
                context={discussionContext}
                onClearn={() => setDidNotify(false)}
              />
            )}
          </>
        )
      }}
    </Formik>
  )
}

DiscussionForm.displayName = "DiscussionForm"

DiscussionForm.propTypes = {
  context: discussionContextPropType,
  isDiscussionQuestion: PropTypes.bool,
  titleOptions: PropTypes.shape({
    placeholder: PropTypes.string
  }),
  inverted: PropTypes.bool,
  // Force the form to always be expanded.
  expanded: PropTypes.bool,
  onToggleExpanded: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

DiscussionForm.defaultProps = {
  isDiscussionQuestion: false,
  titleOptions: {},
  inverted: true,
  expanded: false,
  onToggleExpanded: () => {},
  onCancel: () => {},
  onSubmit: () => {}
}

export default DiscussionForm
