import { Box } from "@chakra-ui/react"
import React from "react"
import RangedControl from "./RangedControl"

const AudioProgressUI = ({ children }) => (
  <Box width="100%" maxWidth="375px" margin="0 auto">
    {children}
  </Box>
)

const AudioProgress = (props) => {
  const { progress, onSeek, onStartScrub, onStopScrub } = props

  if (!progress && progress !== 0) return <AudioProgressUI children={null} />

  return (
    <AudioProgressUI>
      <RangedControl
        value={progress}
        onSeek={onSeek}
        aria-label="Audio Progress"
        updateOnScrub // Possible performance bottleneck
        onStartScrub={onStartScrub}
        onStopScrub={onStopScrub}
      />
    </AudioProgressUI>
  )
}

export default AudioProgress
