import { PW_AUTH_TOKEN_KEY, setSpace } from "@pathwright/web-new/src/api/client"
import { TokenEventDetail } from "@pathwright/web-new/src/lib/utils/auth-token"
import get from "lodash/get"
import React, { useContext, useEffect } from "react"
import {
  ContextQueryResult,
  MeFragment,
  useContextQuery
} from "../api/generated"
import { withContext } from "../utils/component"
import {
  PATHWRIGHT_READY_EVENT,
  USER_SIGNED_IN_EVENT,
  USER_SIGNED_OUT_EVENT,
  dispatchGlobalEvent
} from "../utils/dispatcher"
import withPathwrightClient from "./PathwrightClient"
import PathwrightContextSchoolMissingError from "./PathwrightContextSchoolMissingError"

// @ts-ignore
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"

type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

type PathwrightContext = NonNullableFields<
  Required<NonNullable<ContextQueryResult["data"]>>
> & {
  user: Required<NonNullable<ContextQueryResult["data"]>>["me"]
  onAuthChange?: OnAuthChange
}

type OnAuthChange = (token: string | null) => void

const initialState = {
  me: null,
  school: null,
  translation: null,
  // @ts-ignore: possibly fields we want to no longer support
  token: null
} as unknown as PathwrightContext

export const PathwrightContext =
  React.createContext<PathwrightContext>(initialState)

export const usePathwrightContext = () => {
  return useContext(PathwrightContext)
}

export const withPathwrightContext = (Component: React.Component) =>
  withContext(PathwrightContext, Component)

export const useOnAuthChange = (callback: (me?: MeFragment | null) => void) => {
  const { me } = usePathwrightContext()

  usePreviousEffect(
    ([prevMe]: [MeFragment]) => {
      if (get(prevMe, "id") !== get(me, "id")) {
        callback && callback(me)
      }
    },
    [me]
  )
}

// Note: extending addEventListener with the PW_AUTH_TOKEN_KEY event here
// due to a blocks build error.
// Property 'detail' does not exist on type 'Event'.

// Extending the CustomEventMap with our own `PW_AUTH_TOKEN_KEY` event key.
interface CustomEventMap {
  [PW_AUTH_TOKEN_KEY]: CustomEvent<TokenEventDetail>
}

// Extending the event methods on Window to recognize our custome events typed in `CustomEventMap`
declare global {
  interface Window {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Window, ev: CustomEventMap[K]) => void
    ): void
  }
}

// Sync auth with /client.
export const useAuthChange = (onAuthChange?: OnAuthChange) => {
  useEffect(() => {
    const handler = ({
      detail: { token, source }
    }: CustomEvent<TokenEventDetail>) => {
      // Ignore `PW_AUTH_TOKEN_KEY` events coming from other sources besides "auth".
      if (source === "auth") {
        onAuthChange?.(token)
      }
    }
    window.addEventListener(PW_AUTH_TOKEN_KEY, handler)

    return () => window.removeEventListener(PW_AUTH_TOKEN_KEY, handler)
  }, [])
}

type PathwrightContextProviderProps = {
  children: JSX.Element | JSX.Element[]
  onAuthChange?: OnAuthChange
  authenticated?: boolean
  onError?: (error: any) => void
}

export const PathwrightContextProvider = (
  props: PathwrightContextProviderProps
) => {
  const contextQuery = useContextQuery()
  const { data, error, loading } = contextQuery

  const onAuthChange = async (authToken: string | null) => {
    if (authToken !== null) {
      localStorage.setItem("auth_token", authToken)
    } else {
      localStorage.removeItem("auth_token")
    }
    await props.onAuthChange?.(null)
  }

  // sync auth with /client
  useAuthChange(props.onAuthChange)

  // // sync auth with /client
  // useEffect(() => {
  //   if (data) {
  //     if (
  //       (!props.authenticated && data.me) ||
  //       (props.authenticated && !data.me)
  //     ) {
  //       props.onAuthChange()
  //     }
  //   }
  // }, [data?.me?.id])

  // sync auth with /legacy/client
  useEffect(() => {
    if (data) {
      if (
        (props.authenticated && !data.me) ||
        (!props.authenticated && data.me)
      ) {
        props.onAuthChange?.(null)
      }
    }
  }, [props.authenticated])

  // handle custom events when context data changes
  useEffect(() => {
    if (contextQuery.data) {
      // handle when initial context data becomes available
      if (contextQuery.previousData) {
        const prevMe = contextQuery.previousData.me
        const me = contextQuery.data.me
        if (!!prevMe !== !!me) {
          // handle change in auth
          if (me) {
            dispatchGlobalEvent(USER_SIGNED_IN_EVENT, {
              user: me
            })
          } else {
            dispatchGlobalEvent(USER_SIGNED_OUT_EVENT)
          }
        }
      } else {
        dispatchGlobalEvent(PATHWRIGHT_READY_EVENT, {
          user: contextQuery.data.me
        })
      }
    }
  }, [contextQuery.data?.me])

  // Need to sync school with /client space.
  // Very important for using correct user + space in /client UIs.
  useEffect(() => {
    if (contextQuery.data?.school) {
      setSpace(contextQuery.data.school.id)
    }
  }, [contextQuery.data?.school])

  useEffect(() => {
    if (error && props.onError) props.onError(error)
  }, [error])

  const context: PathwrightContext =
    !loading && !error && data
      ? {
          ...data,
          user: data.me,
          onAuthChange,
          // @ts-ignore: likely no need to support the query since we can use the useContextQuery anyhow.
          query: contextQuery
        }
      : { ...initialState, onAuthChange }

  return (
    <PathwrightContext.Provider value={context}>
      {!loading && !error ? (
        <PathwrightContextSchoolMissingError
          hasData={!!data}
          school={data?.school}
        >
          {props.children}
        </PathwrightContextSchoolMissingError>
      ) : null}
    </PathwrightContext.Provider>
  )
}

PathwrightContextProvider.displayName = "PathwrightContextProvider"

export const PathwrightClientContextProvider = withPathwrightClient(
  PathwrightContextProvider
)
