import { Box, Link } from "@chakra-ui/react"
import { DownloadIcon } from "@pathwright/pathicons"
import PropTypes from "prop-types"
import React from "react"
import { AudioButtonUI } from "./ui"

export const DownloadButton = ({ source }:{
  source: string
}) => {
  source = source.includes("filestackcontent") ? `${source}?dl=true` : source

  return (
    <Box width="55px" m="0px">
      <Link
        href={source}
        target="_blank"
        rel="noopener noreferrer"
        download={true}
      >
        <AudioButtonUI
          height="40px"
          width="50px"
          aria-label="Download"
          icon={<DownloadIcon size={"24px"} color="rgb(41, 46, 53)" />}
        />
      </Link>
    </Box>
  )
}

DownloadButton.propTypes = {
  source: PropTypes.string.isRequired
}

export default DownloadButton
