import { Spinner, Text, VStack } from "@chakra-ui/react"
import { useEffect } from "react"
import {
  HomeRegistrationFragment,
  HomeRegistrationFragmentDoc,
  HomeRegistrationsQueryVariables,
  useHomeRegistrationsQuery
} from "../../api/generated"
import {
  PositioningContextProvider,
  sortPositionedObjects
} from "../../positioning/PositioningContext"
import { useHomeContext } from "../HomeContext"
import FloatingTip from "../components/FloatingTip"
import PathCardContainer from "../components/pathCard/PathCardContainer"

// Filter out an Source Cohort registrations for which there is only 1 active Cohort
// which the user is registered for (better UX).
function filterOutSourceRegistration(
  registrations: HomeRegistrationFragment[]
) {
  return registrations.filter(
    (registration) =>
      // Include non-Source Cohorts.
      !registration.group?.is_source_cohort ||
      // Include Source Cohorts which don't have exactly 1 active Cohort.
      registration.resource?.groups?.total !== 1 ||
      // Include Source Cohorts for which the user doesn't have a Cohort registration.
      registrations.every(
        (r) => r.group?.source_cohort_id !== registration.group?.id
      )
  )
}

function filterRootRegistration(registration: HomeRegistrationFragment) {
  return !registration.group?.parent_section_id
}

function filterNestedRegistration(
  rootRegistration: HomeRegistrationFragment,
  registration: HomeRegistrationFragment
) {
  return rootRegistration.group?.id === registration.group?.parent_section_id
}

const RegistrationsContainer = ({
  variables
}: {
  variables: HomeRegistrationsQueryVariables
}) => {
  const { data, loading } = useHomeRegistrationsQuery({
    variables
  })

  const registrationNodes =
    data?.me?.registrations?.edges?.map((edge) => edge?.node!) || []

  const registrations = filterOutSourceRegistration(
    sortPositionedObjects(registrationNodes)
  )

  // Focus added PathCard.
  const { pathCreatorCompletion, setPathCreatorCompletion } = useHomeContext()
  useEffect(() => {
    if (pathCreatorCompletion) {
      // When no other element is focused (or default body).
      if (!document.hasFocus() || document.activeElement?.tagName === "BODY") {
        const link = document.querySelector(
          `#PathCard__${pathCreatorCompletion.cohort.id} .PathCard__link-overlay`
        ) as HTMLElement
        link?.focus()
      }
      // Always clear state?
      setPathCreatorCompletion(null)
    }
  }, [pathCreatorCompletion])

  if (loading) {
    return (
      <VStack align="center" spacing={1} w="full" p={2}>
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="whiteAlpha.200"
          color="whiteAlpha.900"
          size="lg"
        />
        <Text fontWeight="light" color="whiteAlpha.900" fontSize="sm" m={0}>
          Loading registrations
        </Text>
      </VStack>
    )
  }

  const registrationsStack = (
    <VStack
      spacing={3}
      position="relative"
      w="full"
      h="full"
      borderRadius={25}
      align="flex-start"
      justify="flex-start"
      // minH="800px"
    >
      {registrations
        .filter(filterRootRegistration)
        .map((rootRegistration, i) => (
          <PathCardContainer
            key={rootRegistration.id}
            registration={rootRegistration}
            nestedRegistrations={registrations.filter((registration) =>
              filterNestedRegistration(rootRegistration, registration)
            )}
          />
        ))}
    </VStack>
  )

  return (
    <VStack w="100%" h="100%" pos="relative">
      <FloatingTip />
      {variables.positioningContext ? (
        <PositioningContextProvider
          items={registrations}
          itemKey="registration"
          itemFragmentDocument={HomeRegistrationFragmentDoc}
          context={variables.positioningContext}
        >
          {registrationsStack}
        </PositioningContextProvider>
      ) : (
        registrationsStack
      )}
    </VStack>
  )
}

export default RegistrationsContainer
