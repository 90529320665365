import { PauseIcon, PlayIcon } from "@pathwright/pathicons"
import PropTypes from "prop-types"
import React from "react"
import { AudioButtonUI } from "./ui"

export const PlayButton = ({ onTogglePlaying, playing }) => {
  return (
    <AudioButtonUI
      className="play-button"
      m="0 1rem"
      aria-label="Play Button"
      icon={playing ? <PauseIcon size="32px" /> : <PlayIcon size="32px" />}
      onClick={onTogglePlaying}
    />
  )
}

PlayButton.propTypes = {
  onTogglePlaying: PropTypes.func.isRequired,
  playing: PropTypes.bool
}

export default PlayButton
