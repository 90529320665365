import { HStack } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Link from "@pathwright/ui/src/components/link/Link"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import PropTypes from "prop-types"
import styled from "styled-components"
import Controls from "../lib/Controls"

const StyledContainer = styled.div`
  height: 50px;
  position: relative;
  ${(props) =>
    props.to &&
    `&:hover {
		background-color: rgba(0,0,0,0.03);
	}`}
`

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 20px;
  .Avatar {
    margin-right: 10px;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 10px;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    display: flex;
    align-items: baseline;
    font-weight: 700;
  }

  .pathicon-x-circle {
    color: #aaa;
  }
  .pathicon-check-circle {
    color: forestgreen;
  }
`

const StyledMeta = styled.div`
  display: flex;
  align-items: baseline;
  height: 15px;
  line-height: 15px;
  font-size: 13px;
  color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-letter {
      display: block;
      text-transform: capitalize;
    }
    &:not(:last-child):after {
      content: "•";
      margin: 0 4px;
    }
  }
`

const UserListItem = (props, context) => {
  const { user, to, label, meta, controls, extra, isArchived } = props
  return (
    <StyledContainer to={to}>
      <StyledWrapper $isArchived={isArchived}>
        <HStack opacity={isArchived ? 0.65 : 1} w="100%">
          {to && <StyledLink to={to} />}
          <Avatar user={user} size="30px" />
          <StyledInfo>
            <label>
              {user.display_name}
              &nbsp;
              {label && label.icon && (
                <Tooltip
                  title={label.tooltip || ""}
                  transition="transition.slideRightIn"
                  placement="right"
                >
                  <Pathicon icon={label.icon} />
                </Tooltip>
              )}
            </label>
            {meta && (
              <StyledMeta>
                {meta.map((item, i) => {
                  return <span key={i}>{item}</span>
                })}
              </StyledMeta>
            )}
          </StyledInfo>
          {extra}
        </HStack>
        {controls && <Controls controls={controls} />}
      </StyledWrapper>
    </StyledContainer>
  )
}

UserListItem.displayName = "UserListItem"
UserListItem.propTypes = {
  user: PropTypes.shape({
    display_name: PropTypes.string
  }),
  label: PropTypes.shape({
    icon: PropTypes.string,
    tooltip: PropTypes.string
  }),
  meta: PropTypes.arrayOf(PropTypes.string),
  constrols: PropTypes.array,
  extra: PropTypes.any
}

export default UserListItem
