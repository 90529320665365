import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
import withLauncher from "@pathwright/ui/src/components/launcher/withLauncher"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import gql from "graphql-tag"
import { getEntries } from "../home/getStoredActiveEntries"
import useHomeActiveEntries from "../home/useHomeActiveEntries"
import Place from "./Place"
import UserPreview from "./UserPreview"

const PlaceLauncher = withLauncher({
  Component: Place,
  Preview: ThemeBackground
})

// Get the teach path for this user, which may be the new /home/unified/
// path depending on if they have enabled the new home screen.
function useTeachPath() {
  const { entries, getPath } = useHomeActiveEntries()
  // If use has true data for the final entry of the "paths.unified" entry
  // then they have the new home screen enabled.
  const hasHome = !!getEntries("paths.unified", entries)?.slice(-1)?.[0]?.data
  const teachPath = hasHome ? getPath("paths.unified") : getPath("paths.teach")
  return teachPath
}

const UserPlace = withTranslate(({ user, teacherRegistrations, Trans }) => {
  const teachPath = useTeachPath()
  const hasRegistrations = teacherRegistrations.edges.length > 0

  return user ? (
    <PlaceLauncher
      to={teachPath}
      icon={user}
      name={user.first_name}
      action={hasRegistrations ? "Visit your home" : "Teach a course"}
      isBlank={!hasRegistrations}
      blankImage="teaching"
      blankDescription="Guide people through the courses you teach."
      footer={
        // prettier-ignore
        // Trans formatting integral to translation json!
        teacherRegistrations.total > 0 &&
        hasRegistrations && (
          <Trans
            parent="p"
            i18nKey="dashboard.places.user.footer"
            count={teacherRegistrations.total}>
            Teaching {{ count: teacherRegistrations.total }} cohorts
          </Trans>
        )
      }
    >
      {hasRegistrations && (
        <UserPreview
          groups={teacherRegistrations.edges
            .map((edge) => edge.node.group)
            .reverse()}
        />
      )}
    </PlaceLauncher>
  ) : null
})

UserPlace.displayName = "UserPlace"

UserPlace.fragments = {
  user: gql`
    fragment UserDisplay on User {
      id
      first_name
      last_name
      display_name
      profile {
        id
        image(width: 100, height: 100)
      }
    }
  `,
  teacherRegistrations: gql`
    fragment TeacherRegistrations on User {
      registrations(first: 6, role_filter: { gt: student }) {
        total
        edges {
          node {
            group {
              id
              name
              seats_filled
              resource {
                id
                slug
              }
              path(type: source) {
                id
              }
            }
          }
        }
      }
    }
  `
}

export default UserPlace
