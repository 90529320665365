import styled from "styled-components"
import { createPathFromItems } from "../utils"
import ReportPathDivider from "./ReportPathDivider"
import ReportPathGroup from "./ReportPathGroup"
import ReportPathResource from "./ReportPathResource"
import ReportPathStep from "./ReportPathStep"

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    padding-left: 20px;
    padding-right: 20px;
  }

  & > li & li {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ReportActions .Dropdown__Item {
    min-width: 140px;
  }
`

type PathItems = ReturnType<typeof createPathFromItems>

const ReportPathList = ({ pathItems }: { pathItems: PathItems }) => {
  return (
    <List>
      {pathItems.map((pathItem) => (
        <li key={pathItem.id}>
          {pathItem.type === "divider" ? (
            <ReportPathDivider pathItem={pathItem} />
          ) : pathItem.type === "lesson" ? (
            <>
              <ReportPathGroup pathItem={pathItem} />
              <ReportPathList pathItems={pathItem.items as PathItems} />
            </>
          ) : pathItem.type === "resource" ? (
            // TODO: custom resource UI
            <ReportPathResource pathItem={pathItem} />
          ) : pathItem.type === "step" ? (
            <ReportPathStep pathItem={pathItem} />
          ) : null}
        </li>
      ))}
    </List>
  )
}

ReportPathList.displayName = "ReportPathList"

export default ReportPathList
