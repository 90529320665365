import {
  Box,
  BoxProps,
  HStack,
  Text,
  UseDisclosureReturn
} from "@chakra-ui/react"
import { MotionProps, motion } from "framer-motion"
import {
  AugmentationToolbarHookReturn,
  AugmentationToolbarOption,
  useAugmentationToolbar
} from "../../components/augmentation/AugmentationToolbar"
import Pathicon from "../../pathicon/Pathicon"
import { useHomeRegistrationVars } from "../HomeRegistrationQueryVars"
import useRemovedRegistration from "../useRemovedRegistration"
import HomeLibraryAugmentation from "./HomeLibraryAugmentation"

const LibraryRenderComponent: AugmentationToolbarOption["RenderComponent"] = (
  props
) => {
  const registration = useRemovedRegistration(useHomeRegistrationVars())
  // Only running the animation when a registration has been removed.
  const iconMotionProps: MotionProps & { key?: number } = registration
    ? {
        // The key prop is a simple trick for manually triggering the
        // animation to execute.
        key: registration.id,
        animate: {
          scale: [1.2, 1]
        },
        initial: { scale: 0.8 },
        transition: { type: "spring" }
      }
    : {}

  // The set aside icon + "+1" animates outward then disappears.
  const setAsideMotionProps: BoxProps & MotionProps = registration
    ? {
        // The key prop is a simple trick for manually triggering the
        // animation to execute.
        key: registration.id,
        animate: {
          opacity: [1, 1, 0],
          transform: "translate(100%, -50%)",
          display: "block"
        },
        initial: {
          opacity: 0,
          transform: "translate(0, -50%)",
          display: "none"
        },
        transition: { type: "spring" }
      }
    : {}

  return (
    <motion.div {...iconMotionProps}>
      <AugmentationToolbarOption {...props} />
      <Box
        as={motion.div}
        pos="absolute"
        top="50%"
        transform="translate(0, -50%)"
        display="none"
        {...setAsideMotionProps}
      >
        <HStack>
          <Pathicon icon="sidebar-bottom" transform="rotate(-90deg)" />
          <Text as="span">+1</Text>
        </HStack>
      </Box>
    </motion.div>
  )
}

export function useHomeAugmentationToolbar() {
  return useAugmentationToolbar([
    {
      key: "library",
      icon: "book",
      label: "Library",
      "aria-label": "",
      RenderComponent: LibraryRenderComponent
    }
  ])
}

type HomeAugmentationProps = {
  toolbar: AugmentationToolbarHookReturn
  disclosure?: UseDisclosureReturn
}

const HomeAugmentation = ({ toolbar, disclosure }: HomeAugmentationProps) => {
  return (
    <Box minW="300px" p={2}>
      {toolbar.selectedKey === "library" && (
        <HomeLibraryAugmentation toolbar={toolbar} disclosure={disclosure} />
      )}
    </Box>
  )
}

export default HomeAugmentation
