import {
  Box,
  HStack,
  Heading,
  Image,
  Link,
  LinkOverlay,
  Text,
  VStack
} from "@chakra-ui/react"
import { forwardRef, useMemo } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

import { HomeRegistrationFragment } from "../../../api/generated"
import Pathicon from "../../../pathicon/Pathicon"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { BORDER_RADIUS } from "../../constants"
import { getCertificateDownloadUrl, getResourceLink } from "../../utils"
import GroupAvatars from "../GroupAvatars"
import PathCardButton from "./PathCardButton"
import PathCardRegistrationControls from "./PathCardRegistrationControls"

const CARD_BORDER_RADIUS = 25

type PathCardBackgroundProps = {
  resource: string
}

const PathCardBackground = ({ resource }: PathCardBackgroundProps) => {
  return (
    <Box
      pos="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      borderRadius={CARD_BORDER_RADIUS}
      overflow="hidden"
    >
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage={resource}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        zIndex={-1}
      />
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="blackAlpha.700"
        zIndex={-1}
      />
    </Box>
  )
}

type PathCardProps = {
  registration: HomeRegistrationFragment
  nestedRegistrations?: HomeRegistrationFragment[]
}

const PathCard = forwardRef<HTMLDivElement, PathCardProps>(
  ({ registration, nestedRegistrations }, ref) => {
    const resource = registration.resource!
    const pwContext = usePathwrightContext()

    const certificateDownloadUrl = useMemo(
      () => getCertificateDownloadUrl({ pwContext, registration }),
      []
    )

    const pathLink = useMemo(() => getResourceLink(registration), [])

    return (
      <Box
        ref={ref}
        id={`PathCard__${registration.group?.id}`}
        className="PathCard"
        w="100%"
        borderRadius={CARD_BORDER_RADIUS}
        pos="relative"
        shadow="base"
        _hover={{ shadow: "xl" }}
        // zIndex={10}
      >
        <PathCardBackground resource={resource.cover_image} />
        <LinkOverlay
          className="PathCard__link-overlay"
          as={ReactRouterLink}
          to={pathLink}
          w="100%"
          textDecoration="none"
        />
        <VStack
          p="30px"
          borderRadius={CARD_BORDER_RADIUS}
          spacing={2}
          align="flex-start"
          justify="center"
        >
          <Heading
            as="h2"
            size="h5"
            color="whiteAlpha.900"
            fontWeight="bold"
            noOfLines={2}
            m={0}
          >
            {resource.name}
          </Heading>
          {
            //!moderator
            registration.role === 10 && (
              <GroupAvatars
                groupId={registration?.group?.id as number}
                showGroupName
              />
            )
          }
          {
            //!teacher
            registration.role === 15 && (
              <GroupAvatars
                groupId={registration?.group?.id as number}
                showGroupName
              />
            )
          }

          <HStack w="100%" gap={4} sx={{ _empty: { display: "none" } }}>
            {resource && registration.role === 5 && (
              <PathCardButton
                role="learner"
                href={pathLink}
                registration={registration}
              />
            )}
            {resource && registration.role === 10 && (
              <PathCardButton
                role="moderator"
                href={pathLink}
                registration={registration}
              />
            )}
            {registration.role === 20 &&
              registration.resource?.groups?.total! <= 1 && (
                <PathCardButton
                  role="design_source"
                  href={pathLink}
                  registration={registration}
                />
              )}

            {registration.group?.is_master && registration.role === 20 && (
              <PathCardButton
                role="designer_teacher"
                registration={registration}
                href={pathLink}
              />
            )}

            {certificateDownloadUrl && (
              <Link
                py={1}
                px={4}
                borderRadius={BORDER_RADIUS}
                _hover={{
                  textDecoration: "none",
                  bg: "blackAlpha.600",
                  shadow: "md"
                }}
                textDecoration="none"
                color="whiteAlpha.900"
                href={certificateDownloadUrl}
                download
              >
                <HStack>
                  <Pathicon icon="certificate-fill" />
                  <Text
                    m={0}
                    fontSize="sm"
                    fontWeight="bold"
                    color="whiteAlpha.900"
                  >
                    View Certificate
                  </Text>
                </HStack>
              </Link>
            )}
          </HStack>
          {!!nestedRegistrations?.length && (
            <HStack
              maxW="100%"
              overflow="auto"
              bg="whiteAlpha.400"
              borderRadius="md"
              p={1}
              spacing={1}
            >
              {nestedRegistrations.map((registration) => (
                <Link
                  key={registration.id}
                  as={ReactRouterLink}
                  to={getResourceLink(registration)}
                  cursor="pointer"
                  lineHeight={0}
                >
                  <Image
                    src={registration.resource?.cover_image}
                    maxW="50px"
                    borderRadius="md"
                    alignSelf="flex-start"
                    fallback={
                      <Box
                        w="50px"
                        h="37.5px"
                        bg="gray.200"
                        borderRadius="md"
                      />
                    }
                    _hover={{
                      opacity: ".8"
                    }}
                  />
                </Link>
              ))}
            </HStack>
          )}
        </VStack>
        <PathCardRegistrationControls registration={registration} />
      </Box>
    )
  }
)

export default PathCard
