import { getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import LazyStandaloneSpaceSignupFlowContainer from "@pathwright/web/src/modules/space/signup/LazyStandaloneSpaceSignupFlowContainer"
import { Redirect } from "react-router-dom"
import SignupContainer from "./SignupContainer"

// Using "paths" subdomain as a check, since we can't depend on
// bootstrapped data.
function isPathwrightSchool() {
  let url = window.location

  // When on local host, let's check against the stored school config url.
  if (process.env.NODE_ENV !== "production" && getIsLocalHost()) {
    url = new URL(localStorage.getItem("school_config_url"))
  }

  return url.host.startsWith("paths.")
}

const SignupRoute = (props) => {
  return isPathwrightSchool() && !props.match.params.licensor_id ? (
    <LazyStandaloneSpaceSignupFlowContainer />
  ) : (
    // We need to redirect to a route where our legacy bootstrapped data + dependent
    // stores are booted.
    <Redirect
      to={
        props.location.pathname.replace("/sign-up/", "/sign-up/v1/") +
        props.location.search
      }
    />
    // <SignupContainer {...props} />
  )
}

SignupRoute.path = "/sign-up/:licensor_id(\\d+)?/"

export const legacySignup = {
  path: "/sign-up/v1/:licensor_id(\\d+)?/",
  component: SignupContainer
}

export default SignupRoute
