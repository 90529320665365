import { Avatar, AvatarGroup, HStack, Text } from "@chakra-ui/react"
import {
  GroupListItemRegistrationFragment,
  HomeCohortMembersFragment,
  useHomeCohortMembersQuery
} from "../../api/generated"

import {
  // @ts-ignore
  getAvatarFallbackBG,
  // @ts-ignore
  getAvatarInitials
} from "@pathwright/ui/src/components/avatar/Avatar"
import { Fragment } from "react"

type GroupAvatarsProps = {
  group: HomeCohortMembersFragment
  showGroupName?: boolean
  fontColor?: "whiteAlpha.900" | "blackAlpha.900"
  noOfLines?: number
}

// Dumb component that accepts the group.
export const GroupAvatars = ({
  group,
  showGroupName,
  fontColor = "whiteAlpha.900",
  noOfLines
}: GroupAvatarsProps) => {
  // Avoid using the default profile image.
  function getImage(
    registration: GroupListItemRegistrationFragment
  ): string | undefined {
    const image = registration?.user?.profile?.image
    return (image?.indexOf("profile-default") || 0) === -1 ? image! : undefined
  }

  // Get standard fallback avatar color.
  function getBgColor(registration: GroupListItemRegistrationFragment) {
    const initials = getAvatarInitials(registration.user)
    const color = getAvatarFallbackBG({
      user: registration.user,
      userId: registration.user!.id,
      initials
    })
    // Don't provide bg color when image is available.
    return getImage(registration) ? undefined : color
  }

  const registrations: GroupListItemRegistrationFragment[] = []
  // Push the user's registration into the first position.
  if (group.user_registration) registrations.push(group.user_registration)
  // Push all other users registrations after, filtering out the user's
  // registraiton if found in the list.
  registrations.push(
    ...(group.registrations?.edges
      ?.map((edge) => edge?.node!)
      .filter((node) => node.id !== registrations[0]?.id) || [])
  )

  // Chakra UI controls the excess count and bases it off of the number
  // of children beyond the max prop, so we have to manually render the
  // excess children. Whoopee.
  const excessRegistrations = new Array(
    group.registrations?.total! - registrations.length
  ).fill(null)

  return (
    <HStack w="full" pos="relative" zIndex={10} spacing={2}>
      {registrations.length && (
        <AvatarGroup size="xs" max={3} spacing={-1}>
          {registrations.map((registration) => (
            <Avatar
              key={registration.id}
              name={registration.user!.full_name!}
              src={getImage(registration)}
              bg={getBgColor(registration)}
              color="white"
              borderWidth={0}
              zIndex={10}
              borderColor={fontColor}
            />
          ))}
          {excessRegistrations.map((_, i) => (
            <Fragment key={i} />
          ))}
        </AvatarGroup>
      )}
      {showGroupName && (
        <Text
          size="xs"
          fontWeight={"medium"}
          m={0}
          color={fontColor}
          noOfLines={noOfLines}
        >
          {group.name}
        </Text>
      )}
    </HStack>
  )
}

type GroupAvatarsContainerProps = {
  groupId: number
} & Omit<GroupAvatarsProps, "group">

// Container component that queries the group by id.
const GroupAvatarsContainer = ({
  groupId,
  ...rest
}: GroupAvatarsContainerProps) => {
  const { data } = useHomeCohortMembersQuery({
    variables: {
      groupId: groupId
    }
  })

  return data?.group ? <GroupAvatars group={data.group} {...rest} /> : null
}

export default GroupAvatarsContainer
