import { ReactNode } from "react"
import BlocksConfigProvider, {
  BlocksConfigContextType
} from "../config/BlocksConfigProvider"
import { useEditorBlocks } from "../editor/EditorStateProvider"
import GraphQLSyncer from "../syncer/GraphQLSyncer"
import { SyncerContextType, useSyncer } from "../syncer/SyncerContext"
import ViewerStateProvider, {
  ViewerContextValueType,
  ViewerStateProviderPropsType
} from "./ViewerStateProvider"

export const ViewerStateWithSyncer = ({
  children,
  draft
}: {
  children: ReactNode | ((props: ViewerContextValueType) => ReactNode)
  // This allows student data to be saved in Editor Preview mode
  draft?: boolean
}) => {
  const syncerState: SyncerContextType = useSyncer()
  const { blocks: editorBlocks } = useEditorBlocks()

  const { content } = syncerState

  return (
    <ViewerStateProvider
      {...(syncerState as Omit<ViewerStateProviderPropsType, "children">)}
      content={{
        ...content!,
        blocks: editorBlocks?.length ? editorBlocks : content!.blocks
      }}
      draft={draft}
    >
      {children}
    </ViewerStateProvider>
  )
}

type BlocksViewContainerProps = BlocksConfigContextType & {
  children: ReactNode | ((props: ViewerContextValueType) => ReactNode)
  Syncer?: any
  renderLoading?: () => JSX.Element
}

export const BlocksViewContainer = ({
  children,
  Syncer,
  renderLoading,
  ...config
}: BlocksViewContainerProps) => {
  const SyncProvider = Syncer || GraphQLSyncer

  const syncerProps: Record<string, any> = {}

  if (!Syncer) {
    syncerProps.graphQLEndpoint = config.graphQLEndpoint
    syncerProps.accountID = config.accountID
  }

  return (
    <BlocksConfigProvider {...config}>
      <SyncProvider {...syncerProps} renderLoading={renderLoading}>
        <ViewerStateWithSyncer>{children}</ViewerStateWithSyncer>
      </SyncProvider>
    </BlocksConfigProvider>
  )
}

export default BlocksViewContainer
