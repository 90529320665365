import { Box, UseDisclosureReturn, VStack } from "@chakra-ui/react"
import { useState } from "react"
import { HomeRegistrationsQueryVariables } from "../../api/generated"
import { AugmentationToolbarHookReturn } from "../../components/augmentation/AugmentationToolbar"
import { useHomeSoftArchivedRegistrationVars } from "../HomeRegistrationQueryVars"
import RegistrationsContainer from "../containers/RegistrationsContainer"
import HomeAugmentationFilters, {
  HomeAugmentationFilter
} from "./HomeAugmentationFilters"
import HomeAugmentationHeader from "./HomeAugmentationHeader"

type HomeLibraryAugmentationFilter = {
  useVariables: () => HomeRegistrationsQueryVariables
} & HomeAugmentationFilter

const filters: HomeLibraryAugmentationFilter[] = [
  {
    key: "soft_archived",
    label: "Set aside",
    icon: "sidebar-bottom",
    iconProps: {
      transform: "rotate(-90deg)"
    },
    useVariables: useHomeSoftArchivedRegistrationVars
  }
]

type HomeLibraryAugmentationProps = {
  toolbar: AugmentationToolbarHookReturn
  disclosure?: UseDisclosureReturn
}

const HomeLibraryAugmentation = ({
  toolbar,
  disclosure
}: HomeLibraryAugmentationProps) => {
  const [filterKey, setFilterKey] =
    useState<HomeAugmentationFilter["key"]>("soft_archived")
  const filter = filters.find((filter) => filter.key === filterKey)!
  const registrationVariables = filter.useVariables()

  return (
    <VStack w="100%" minH="100%" maxH="100%">
      <VStack alignItems="flex-start" w="100%" p={2}>
        <HomeAugmentationHeader toolbar={toolbar} disclosure={disclosure} />
        <HomeAugmentationFilters
          filters={filters}
          value={filterKey}
          onChange={setFilterKey}
        />
      </VStack>
      <Box overflow="auto">
        <RegistrationsContainer variables={registrationVariables} />
      </Box>
    </VStack>
  )
}

export default HomeLibraryAugmentation
