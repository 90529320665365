import { graphql } from "@apollo/client/react/hoc"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import DotFlow from "@pathwright/ui/src/components/flow/DotFlow"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"
import {
  PathwrightContext,
  usePathwrightContext
} from "../../pathwright/PathwrightContext"
import USER_SCHOOL_SUBSCRIPTION_QUERY from "../../user/graphql/user-school-subscription-query"
import { withContextAsProp, withStateAsProps } from "../../utils/component"
import SCHOOL_GIFT_SUBSCRIPTION_QUERY from "../graphql/school-gift-subscription-query"
import withRedeemSchoolSubscriptionMutation from "../graphql/withRedeemSchoolSubscriptionMutation"
import RedeemSchoolGiftSubscriptionAuthGate from "./RedeemSchoolGiftSubscriptionAuthGate"
import RedeemSchoolGiftSubscriptionGift from "./RedeemSchoolGiftSubscriptionGift"
import RedeemSchoolGiftSubscriptionLookup from "./RedeemSchoolGiftSubscriptionLookup"
import SchoolSubscriptionAlreadySubscribed from "./SchoolSubscriptionAlreadySubscribed"
import SchoolSubscriptionComplete from "./SchoolSubscriptionComplete"

const RedeemSchoolGiftSubscriptionFlow = (props) => {
  const { me } = usePathwrightContext()
  const dots = [
    [
      {
        isComplete: !!me,
        component: RedeemSchoolGiftSubscriptionAuthGate
      }
    ],
    [
      {
        isComplete:
          !props.school_subscription ||
          !!props.redeem_school_subscription_mutation_result,
        component: SchoolSubscriptionAlreadySubscribed,
        props: {
          school_subscription: props.school_subscription
        }
      },
      {
        isComplete: !!props.gift_subscription,
        component: RedeemSchoolGiftSubscriptionLookup,
        props: {
          code: props.code,
          handleCode: (code) => props.withStateAsProps({ code }),
          submitting: props.gift_subscription_loading,
          error: props.gift_subscription_error
        }
      },
      {
        isComplete: !!props.school_subscription,
        component: RedeemSchoolGiftSubscriptionGift,
        props: {
          gift_subscription: props.gift_subscription,
          redeemGiftSubscription: props.redeemSchoolGiftSubscription,
          giftSubscriptionRedeeming:
            props.redeem_school_subscription_mutation_saving,
          giftSubscriptionError: props.redeem_school_subscription_mutation_error
        }
      }
    ]
  ]

  return (
    <Card card={props.card} title="Redeem Your Gift Subscription" noaction>
      <CardBlock>
        <DotFlow
          dots={dots}
          freeze={
            props.loading || props.redeem_school_subscription_mutation_saving
          }
          completionComponent={() => (
            <SchoolSubscriptionComplete
              school_subscription={props.gift_subscription}
            />
          )}
        />
      </CardBlock>
    </Card>
  )
}

RedeemSchoolGiftSubscriptionFlow.displayName =
  "RedeemSchoolGiftSubscriptionFlow"
RedeemSchoolGiftSubscriptionFlow.propTypes = {
  code: PropTypes.string.isRequired
}

export default compose(
  withStateAsProps({
    code: new URLSearchParams(window.location.search).get("gc") || null
  }),
  withContextAsProp(PathwrightContext, "userId", "me.id"),
  graphql(SCHOOL_GIFT_SUBSCRIPTION_QUERY, {
    options: ({ code }) => ({
      variables: {
        code
      }
    }),
    skip: ({ code, userId }) => !code || !userId,
    props: ({ data, ownProps }) => ({
      gift_subscription_loading: data.loading,
      loading: data.loading,
      gift_subscription_error: data.error
        ? data.error
        : !data.loading && !get(data, "school.gift_subscription")
        ? `Gift subscription for code "${ownProps.code}" does not exist.`
        : null,
      gift_subscription: get(data, "school.gift_subscription")
    })
  }),
  graphql(USER_SCHOOL_SUBSCRIPTION_QUERY, {
    skip: ({ userId }) => !userId,
    props: ({ data, ownProps }) => ({
      loading: data.loading || ownProps.loading,
      school_subscription_error: data.error,
      school_subscription_loading: data.loading,
      school_subscription: get(data, "me.school_subscription")
    })
  }),
  withRedeemSchoolSubscriptionMutation
)(RedeemSchoolGiftSubscriptionFlow)
