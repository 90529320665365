import {
  Button,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  UseDisclosureReturn,
  VStack,
  useDisclosure
} from "@chakra-ui/react"
import { IconName } from "@pathwright/pathicons"
import { useEffect, useState } from "react"
import Pathicon from "../../pathicon/Pathicon"
import ChakraTooltip from "../ChakraTooltip"

export type AugmentationToolbarOption = {
  key: string
  icon: IconName
  label: string
  // Probably change to accept the toolbar option and be responsible for
  // rendering the IconButton
  RenderComponent?: (props: AugmentationToolbarOptionProps) => JSX.Element
} & Omit<IconButtonProps, "icon">

export type AugmentationToolbarHookReturn = {
  options: AugmentationToolbarOption[]
  selectedKey: AugmentationToolbarOption["key"] | null
  onSelect: (key: AugmentationToolbarOption["key"] | null) => void
}

export function useAugmentationToolbar(
  options: AugmentationToolbarOption[]
): AugmentationToolbarHookReturn {
  const [selectedKey, setSelectedKey] =
    useState<AugmentationToolbarHookReturn["selectedKey"]>(null)

  return {
    selectedKey,
    onSelect: setSelectedKey,
    options
  }
}

// Tweak the toolbar ot include the disclosure button props.
export function useAugmentationDisclosedToolbar(
  toolbar: AugmentationToolbarHookReturn,
  disclosure: UseDisclosureReturn
): AugmentationToolbarHookReturn {
  // Clear the selected toolbar option, when the disclosure is closed
  // allowing the toolbar to once again show inline.
  useEffect(() => {
    if (!disclosure.isOpen) toolbar.onSelect(null)
  }, [disclosure.isOpen])

  return {
    ...toolbar,
    options: toolbar.options.map((option) => ({
      ...option,
      ...disclosure.getButtonProps({
        onClick: () => toolbar.onSelect(option.key)
      })
    }))
  }
}

export function AugmentationToolbarDropdown({
  options,
  selectedKey,
  onSelect
}: AugmentationToolbarHookReturn) {
  const { isOpen, onClose, getButtonProps, getDisclosureProps } =
    useDisclosure()
  const selectedOption = options.find((option) => option.key === selectedKey)

  return selectedOption ? (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          size="sm"
          colorScheme="gray"
          variant="ghost"
          leftIcon={<Pathicon icon={selectedOption.icon} size="1.2em" />}
          cursor="pointer"
          p={2}
          {...getButtonProps()}
        >
          {selectedOption.label}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        whiteSpace="nowrap"
        {...getDisclosureProps()}
      >
        <PopoverBody>
          <VStack w="100%" alignItems="flex-start">
            {options.map(({ icon, key, label, ...rest }) => (
              <Button
                key={key}
                size="sm"
                colorScheme="gray"
                variant={selectedKey === "key" ? "outline" : "ghost"}
                leftIcon={<Pathicon icon={icon} size="1.2em" />}
                cursor="pointer"
                p={2}
                {...rest}
                onClick={() => {
                  onSelect(key)
                  onClose()
                }}
              >
                {label}
              </Button>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : null
}

type AugmentationToolbarOptionProps = {
  option: AugmentationToolbarOption
  isSelected: boolean
}

export function AugmentationToolbarOption({
  option,
  isSelected
}: AugmentationToolbarOptionProps) {
  const { icon, key, label, ...rest } = option
  return (
    <IconButton
      size="sm"
      colorScheme="gray"
      variant={isSelected ? "outline" : "ghost"}
      icon={<Pathicon icon={icon} size="1.2em" />}
      h={8}
      minW={8}
      isRound
      cursor="pointer"
      {...rest}
    />
  )
}

function AugmentationToolbar({
  options,
  selectedKey
}: AugmentationToolbarHookReturn) {
  return (
    <VStack
      className="AugmentationToolbar"
      spacing={1}
      position="relative"
      bg="rgba(255, 255, 255, 0.95)"
      px=".25em"
      py=".5em"
      borderRadius="20px"
    >
      {options.map(({ RenderComponent, ...option }) => {
        const isSelected = selectedKey === option.key
        const ToolbarOptionComponent =
          RenderComponent || AugmentationToolbarOption

        return (
          <ChakraTooltip key={option.key} title={option.label} fitContent>
            <ToolbarOptionComponent option={option} isSelected={isSelected} />
          </ChakraTooltip>
        )
      })}
    </VStack>
  )
}

export default AugmentationToolbar
