import { Button, Heading, Text, VStack } from "@chakra-ui/react"
import { captureException } from "@sentry/browser"
import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Pathicon from "../pathicon/Pathicon"
import LoadingChunkErrorBoundary from "./LoadingChunkErrorBoundary"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // log the error to our error reporting service
    if (this.props.logToSentry) {
      captureException(error)
    }
  }

  render() {
    if (this.state.error) {
      if (this.props.renderError) {
        return this.props.renderError(this.state.error, () => {
          this.setState({ error: null })
        })
      }

      // Hide the error details from end users in production.
      // Showing the actual error message is poor UX.
      const errorDetails =
        process.env.NODE_ENV === "production"
          ? null
          : this.state.error.toString()

      return (
        <VStack
          className={classnames("ErrorBoundary", this.props.className)}
          w="100%"
          minH="100%"
          maxH="100vh"
          // HACK ALERT: somewhere, the Stack styles could be overridden to display block... (they are).
          display="flex !important"
          justifyContent="center"
          alignItems="center"
          bg="white"
          p={4}
          spacing={4}
          borderRadius="20px"
        >
          <Pathicon icon="computer-frown" style={{ fontSize: "2em" }} />
          <Heading as="h1" size="h4" m={0}>
            An unexpected error occurred
          </Heading>
          {!!errorDetails && (
            <Text
              fontFamily="monospace"
              textAlign="left"
              m={0}
              p={4}
              borderRadius="md"
              color="black"
              bg="gray.200"
              maxW="min(600px, 100%)"
              overflow="auto"
            >
              {errorDetails}
            </Text>
          )}
          {window.school ? (
            <>
              <Button colorScheme="brand" variant="solid" cursor="pointer">
                Email {window.school.name}
              </Button>

              <Button
                variant="link"
                p={0}
                bg="none"
                cursor="pointer"
                onClick={() => (window.location = window.location)}
              >
                or refresh
              </Button>
            </>
          ) : (
            <Button
              colorScheme="brand"
              variant="solid"
              cursor="pointer"
              onClick={() => (window.location = window.location)}
            >
              Try refreshing
            </Button>
          )}
        </VStack>
      )
    }

    if (this.props.catchLoadingChunkFailures) {
      return (
        <LoadingChunkErrorBoundary className={this.props.className}>
          {this.props.children}
        </LoadingChunkErrorBoundary>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  logToSentry: true,
  catchLoadingChunkFailures: true
}

ErrorBoundary.propTypes = {
  logToSentry: PropTypes.bool,
  catchLoadingChunkFailures: PropTypes.bool,
  renderError: PropTypes.func
}

ErrorBoundary.displayName = "ErrorBoundary"

export default ErrorBoundary
