import { Spacer, Spinner, VStack } from "@chakra-ui/react"
import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import Text from "@pathwright/ui/src/components/ui/Text"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useUserPathReportCardQuery } from "../../api/generated"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { createPathFromItems } from "../utils"
import ReportPathList from "./ReportPathList"
import ReportPathStats from "./ReportPathStats"

const Header = styled.header`
  text-align: center;
  padding: 0 50px 10px;
`

const UserReportCard = ({ card, pathId }: { card: any; pathId: number }) => {
  const { me } = usePathwrightContext()

  const { data, error, loading } = useUserPathReportCardQuery({
    variables: {
      id: pathId
    },
    skip: !pathId || !me,
    fetchPolicy: "cache-and-network"
  })

  const pathItems = data?.path
    ? createPathFromItems(data.path!.items!.edges!.map((e) => e!.node!))
    : []

  // Possibly user is looking at a default Path that has no associated user.
  const user = data?.path?.user || me

  return (
    <Card card={card}>
      {(loading && !data) || !pathId ? (
        <VStack w="100%" h="200px !important" justifyContent="center">
          <Spinner />
        </VStack>
      ) : error ? (
        <BlankSlate
          icons={null}
          heading="An unexpected error occurred"
          body={(error && error!.message) || error}
        />
      ) : data?.path ? (
        <VStack w="100%" spacing={4}>
          <Header>
            <Avatar user={user} />
            <Text.H4>{user!.full_name}</Text.H4>
            <Text.Meta>{user!.email}</Text.Meta>
          </Header>
          <ReportPathStats performance={data!.path!.performance!} />
          <ReportPathList pathItems={pathItems} />
          <Spacer />
        </VStack>
      ) : (
        <BlankSlate icons={null} heading="Unable to load Path Performance." />
      )}
    </Card>
  )
}

UserReportCard.displayName = "UserReportCard"

UserReportCard.propTypes = {
  pathId: PropTypes.number.isRequired
}

export default UserReportCard
