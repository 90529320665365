import { Box, HStack, Text } from "@chakra-ui/react"
import Button from "@pathwright/ui/src/components/button/Button"
import DropdownItem from "@pathwright/ui/src/components/dropdown/DropdownItem"
import DropdownMenu from "@pathwright/ui/src/components/dropdown/DropdownMenu"
import DropdownTrigger from "@pathwright/ui/src/components/dropdown/DropdownTrigger"
import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import capitalize from "lodash/capitalize"
import { Component } from "react"
import { MEMBERSHIP_FILTERS } from "./constants"

class MembershipSearch extends Component {
  state = {
    value: ""
  }

  handleChange = (value) => {
    this.setState({ value }, () => {
      this.props.onSearch(value)
    })
  }

  handleClear = () => {
    this.handleChange("")
  }

  handleFilter = (membershipFilter) => () => {
    this.props.onFilter(membershipFilter)
  }

  render() {
    return (
      <Screensize.Consumer>
        {(screensize) => (
          <HStack p={2}>
            <Box flexGrow={1}>
              <SearchInput
                emphasis="tertiary"
                placeholder="Search..."
                onChange={this.handleChange}
                onClear={this.handleClear}
                value={this.state.value}
                autoFocus={screensize !== "sm"}
                searching={this.props.searching}
              />
            </Box>
            <DropdownTrigger
              alignment="left"
              renderDropdown={() => (
                <DropdownMenu closeOnClick>
                  {Object.keys(MEMBERSHIP_FILTERS).map((label, index) => (
                    <DropdownItem
                      key={index}
                      label={capitalize(label)}
                      onClick={this.handleFilter(label)}
                    />
                  ))}
                </DropdownMenu>
              )}
            >
              <Button styleType="secondary">
                <HStack spacing={1}>
                  {screensize !== "sm" && <Text as="span">Show</Text>}
                  <strong>{capitalize(this.props.membershipFilter)}</strong>
                  <Pathicon icon="chevron-down" />
                </HStack>
              </Button>
            </DropdownTrigger>
            <Button styleType="secondary" to="/community/members/invite/">
              {screensize === "sm" ? "Invite" : "Invite members"}
            </Button>
          </HStack>
        )}
      </Screensize.Consumer>
    )
  }
}

MembershipSearch.displayName = "MembershipSearch"

export default MembershipSearch
