import { Box, Flex, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import React from "react"
import PlayButton from "./PlayButton"

export const Playlist = ({ onSelectSource, current, sources }) => {
  const handleSelectSource = (index) => () => {
    onSelectSource(index)
  }

  const currentIndex = sources.indexOf(current)

  const mappedItems = sources.map((source, i) => (
    <Flex
      key={i}
      onClick={handleSelectSource(i)}
      justify="space-between"
      align="center"
      border="1px solid"
      borderColor="#e5e5e5"
      color="rgb(102, 102, 102)"
      borderRadius="5px"
      px="20px"
      py="10px"
      w="100%"
      cursor="pointer"
      _hover={{ bg: "gray.100" }}
      bg={i === currentIndex ? "gray.200" : "white"}
    >
      <Text>{source.name || "Current Track"}</Text>
      <Box className="Button" mb="4px">
        <PlayButton onTogglePlaying={handleSelectSource(i)} />
      </Box>
    </Flex>
  ))

  return (
    <Flex
      justify="space-between"
      mx="auto"
      w="100%"
      maxW="300px"
      flexDirection="column"
      gap="10px"
    >
      {mappedItems}
    </Flex>
  )
}

Playlist.propTypes = {
  onSelectSource: PropTypes.func,
  current: PropTypes.object,
  sources: PropTypes.arrayOf(PropTypes.object)
}

export default Playlist
