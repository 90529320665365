import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import withTranslate from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import CohortVisibilityPrompt from "@pathwright/web/src/modules/cohort/CohortVisibilityPrompt"
import { getCohortPlaceholder } from "@pathwright/web/src/modules/cohort/create/CohortNameInput"
import { TextInput } from "lib/core/components/forms"
import ActivationLockedFallback from "lib/core/components/pathwright/ActivationLockedFallback"
import PathwrightAccountPermissions from "lib/core/components/pathwright/PathwrightAccountPermissions"
import { ConnectStores } from "lib/core/store"
import { Field, Form, Submit } from "react-connect-form-forked"
import isEqual from "react-fast-compare"
import "./GroupDetailsForm.css"

const GroupDetailsForm = function (props) {
  const {
    group,
    onSubmit,
    isLicensed,
    isNew,
    initialValue,
    t,
    tc,
    group_loading,
    group_saving
  } = props
  const buttonLabel = isNew ? "Create" : "Save"

  return (
    <div className="GroupDetailsForm">
      {group_loading ? (
        <LoadingCircle />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValue={initialValue}
          render={({ onSubmit, ...formState }) => {
            return (
              <form onSubmit={onSubmit}>
                <Submit
                  styleType="primary"
                  component={SubmitButton}
                  disabled={
                    isEqual(initialValue, formState.value) && !group_saving
                  }
                >
                  {buttonLabel}
                </Submit>
                <Field
                  name="name"
                  type="text"
                  label={tc("cohort.forms.details.fields.name.label")}
                  component={TextInput}
                  placeholder={getCohortPlaceholder(t)}
                  required
                />
                <PathwrightAccountPermissions offering={group}>
                  {({
                    highest_offering_visibility,
                    can_edit_offering_visibility,
                    is_pathwright_activated
                  }) =>
                    !can_edit_offering_visibility &&
                    !is_pathwright_activated ? (
                      <ActivationLockedFallback />
                    ) : can_edit_offering_visibility ? (
                      <CohortVisibilityPrompt
                        formState={formState}
                        highestOfferingVisibility={highest_offering_visibility}
                      />
                    ) : null
                  }
                </PathwrightAccountPermissions>
              </form>
            )
          }}
        />
      )}
    </div>
  )
}

GroupDetailsForm.displayName = "GroupDetailsForm"

// SMART WRAPPING

const mapStateToProps = function (state, ownProps) {
  const { group, group_loading, group_saving } = state.groupAdminStore.state
  const { saveGroup } = state.groupAdminStore.action

  const isNew = !(group != null ? group.id : undefined)
  const isLicensed =
    (group != null ? group.resource_license : undefined) != null

  const initialValue = {
    name: group != null ? group.name : undefined,
    visibility: group != null ? group.visibility : undefined
  }

  const onSubmit = function (form, resetForm) {
    return saveGroup(form).promise
  }

  const props = _.assign(
    {},
    {
      group,
      isLicensed,
      initialValue,
      onSubmit,
      isNew,
      group_loading,
      group_saving
    },
    ownProps
  )
  return props
}

const stores = [{ store: "groupAdmin", watch: ["group", "group_saving"] }]

export default withTranslate(
  ConnectStores(GroupDetailsForm, stores, {}, mapStateToProps)
)
