import { useMemo } from "react"
import {
  HomeRegistrationFragment,
  HomeRegistrationsQueryResult,
  HomeRegistrationsQueryVariables,
  useHomeRegistrationsQuery
} from "../api/generated"

// Detect a registration that was removed from the home registrations query,
// scoped to the provided variables.
export function useRemovedRegistration(
  variables: HomeRegistrationsQueryVariables
): HomeRegistrationFragment | null {
  const homeRegistrationsQuery = useHomeRegistrationsQuery({
    variables: variables,
    fetchPolicy: "cache-only"
  })

  function mapRegistrationEdges(
    data:
      | HomeRegistrationsQueryResult["data"]
      | HomeRegistrationsQueryResult["previousData"]
  ) {
    return data?.me?.registrations?.edges?.map((edge) => edge!.node!) || []
  }

  const registrations = mapRegistrationEdges(homeRegistrationsQuery.data)
  const prevRegistrations = mapRegistrationEdges(
    homeRegistrationsQuery.previousData
  )
  const numHomeRegistrations = registrations.length
  const prevNumHomeRegistrations = prevRegistrations.length

  const registration = useMemo(() => {
    // Registration(s) has/have been removed?
    if (numHomeRegistrations < prevNumHomeRegistrations) {
      // Any previous registrations that are no longer found in the current list
      // of registrations are considered removed. Typically, there will just be one
      // registration, but it could be more than one if the user soft-archived a
      // Collection (which will soft-archive all child registrations).
      const removedRegistrations = prevRegistrations.filter(
        (prevRegistration) => {
          return !registrations.some(
            (registration) => registration.id === prevRegistration.id
          )
        }
      )
      const registration = removedRegistrations.find(
        (registration) => !registration.group?.parent_section_id
      )
      // Fallback to null, though there should be a registration.
      return registration || null
    }

    return null
  }, [numHomeRegistrations])

  return registration
}

export default useRemovedRegistration
