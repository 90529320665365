import IconButton from "@pathwright/ui/src/components/button/IconButton"
import Fullscreen, {
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import classnames from "classnames"
import { useEffect } from "react"
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook"
import styled from "styled-components"

const StyledFullscreen = styled(Fullscreen)`
  .Modal__close {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 1.5em;
    z-index: 10;
    line-height: 0.8em;
    padding: 5px;
  }

  .Modal__content {
    padding: 50px 5px 50px;
    height: 100%;
    position: relative;
    /* let pointer events land on fullscreen background */
    pointer-events: none;

    > *:not(.Portable),
    > .Portable > * {
      /* restore pointer events to modal content children */
      pointer-events: auto;
    }
  }
`

const Modal = ({ children, className, backTo, onClose, hideClose }) => {
  const screensize = useScreensizeContext()
  // The useHotkeys will attempt to call the callback even if it is undefined.
  // So, providing a noop in that case.
  const onCloseCallback = onClose || (() => {})

  // Scope the "esc" hotkey to the modal.
  const { toggleScope } = useHotkeysContext()
  useHotkeys("esc", onCloseCallback, { scopes: ["modal"] })
  useEffect(() => {
    // Only toggle the "modal" scop on when an onClose prop is provided.
    if (onClose) {
      toggleScope("modal")
    }
  }, [!!onClose])

  return (
    <StyledFullscreen
      className={classnames("Modal", className)}
      layer={FULLSCREEN_LAYER_OVERLAY}
      background={{
        overlay: screensize === "sm" ? null : "rgba(0, 0, 0, 0.6)"
      }}
      onClickBackground={() => {
        if (backTo) window.App && window.App.navigate(backTo)
        if (onClose) onClose()
      }}
    >
      {Boolean(backTo || onClose) && !hideClose && (
        <IconButton
          icon="x"
          to={backTo}
          onClick={onClose}
          className="Modal__close"
          inverted
        />
      )}
      <div className="Modal__content">{children}</div>
    </StyledFullscreen>
  )
}

Modal.displayName = "Modal"

export default Modal
