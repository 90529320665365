import Avatar from "@pathwright/ui/src/components/avatar/Avatar"
import Button from "@pathwright/ui/src/components/button/Button"
import IconButton from "@pathwright/ui/src/components/button/IconButton"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import { getOptions, pick } from "@pathwright/ui/src/components/filestack/utils"
import RichTextInput from "@pathwright/ui/src/components/form/form-richtext/RichTextInput"
import { FormikWithDiscardFormState } from "@pathwright/ui/src/components/form/form-state/FormikDiscardFormState"
import { getFormStateStorageKey } from "@pathwright/ui/src/components/form/form-state/useFormStateStorage"
import { validate } from "@pathwright/ui/src/components/form/utils"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingBlock from "@pathwright/ui/src/components/loading/LoadingBlock"
import Text from "@pathwright/ui/src/components/ui/Text"
import classnames from "classnames"
import { Form as FormikForm } from "formik"
import { useRef, useState } from "react"
import styled from "styled-components"
import KeyboardFormSubmit from "../components/formik/KeyboardFormSubmit"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { useDiscussionContext } from "./DiscussionProvider"
import DiscussionResponseAttachments from "./DiscussionResponseAttachments"
import { DISCUSSION_TYPE_FEEDBACK } from "./constants"

const Header = styled.div`
  border-width: 1px 0 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
`

const Form = styled(FormikForm)`
  border-radius: 20px;
  padding: 10px;

  &.DiscussionInput__editing {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.:not(.DiscussionInput__editing) .RichTextInput {
    max-height: max(300px, 40vh);
    overflow-y: auto;
  }

  .RichTextInput {
    width: 100%;
    max-width: 100%;
    padding: 4px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .UIFieldWrapper__input {
      width: 100%;
    }

    .ql-editor {
      max-width: 100%;
      border: 0px;
      border-radius: 12px;
      word-break: break-word;
    }

    .ql-editor.ql-blank::before {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

const Row = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 46px;
  display: flex;
  align-items: center;
  position: relative;

  .Avatar {
    position: absolute;
    left: -46px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &:empty {
    margin: 0;
  }
`

const UpdateResponseRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  > *:not(.Button.blank) {
    margin-left: 5px;
    margin-right: 5px;
  }

  > .Button.blank {
    margin-right: -5px;
  }
`

const parseResponseAttachments = (response) => {
  if (!response || !response.attachments) {
    return []
  }
  try {
    return JSON.parse(response.attachments)
  } catch (e) {
    return []
  }
}

const DiscussionInput = (props) => {
  const { t } = useTranslate()
  const discardFormStateRef = useRef(null)
  let { placeholder, title, response } = props

  placeholder = placeholder || t("discussion.input.placeholder")
  title = title || t("discussion.input.title")

  const { user } = usePathwrightContext()

  const [error, setError] = useState()
  const [submitting, setSubmitting] = useState(false)

  const {
    context,
    post,
    createResponse,
    updateResponse,
    createDiscussion,
    replyingTo,
    setReplyingTo,
    scrollToResponse,
    setEditingResponse
  } = useDiscussionContext()

  const handleSubmit = async (values, form) => {
    if (submitting) return

    const nextResponse = {
      body: values.richText,
      attachments: values.attachments.length
        ? JSON.stringify(values.attachments)
        : null
    }

    try {
      setSubmitting(true)
      discardFormStateRef.current?.discardFormState?.()
      // Immediately restore response view after updating response.
      setEditingResponse(null)

      if (post && post.id) {
        if (response) {
          await updateResponse({ ...response, ...nextResponse })
        } else {
          await createResponse({ ...nextResponse, user })
        }
      } else {
        await createDiscussion({
          title,
          body: "<br/>",
          type: DISCUSSION_TYPE_FEEDBACK,
          initialResponse: nextResponse
        })
      }
    } catch (err) {
      setError(err)
      form.resetForm({
        values
      })
    } finally {
      setSubmitting(false)
    }
  }

  const handlePick = async (form) => {
    try {
      const result = await pick(
        getOptions("ANY", {
          fromSources: [
            "local_file_system",
            "dropbox",
            "googledrive",
            "onedrive",
            "facebook",
            "url",
            "webcam",
            // "video",
            "audio"
          ]
        })
      )
      handleAddAttachments(form, result.filesUploaded)
    } catch (error) {
      setError(error)
    }
  }

  const handleRemoveAttachment = (form, attachment) => {
    const nextAttachments = form.values.attachments.filter(
      (a) => a.mediaId !== attachment.mediaId
    )
    form.setFieldValue("attachments", nextAttachments)
  }

  const handleAddAttachments = (form, attachments) => {
    const nextAttachments = [
      ...form.values.attachments,
      ...attachments.map((attachment) => ({
        filestackHandle: attachment.handle,
        name: attachment.filename,
        mediaId: attachment.uploadId,
        mimetype: attachment.mimetype,
        url: attachment.url
      }))
    ]
    form.setFieldValue("attachments", nextAttachments)
  }

  // Store form state based on context.
  const storageKey = getFormStateStorageKey([
    "review-discussion",
    ...Object.entries(context),
    post && post.id,
    response && response.id
  ])
  const initialValues = {
    richText: response ? response.body : "",
    attachments: parseResponseAttachments(response)
  }

  const validator = validate((key, value) => {
    if (key === "richText") {
      const el = document.createElement("div")
      el.innerHTML = value
      if (!el.innerText) {
        // Doesn't show in UI, just used for form validation.
        return "Required"
      }
    }
  })

  return (
    <FormikWithDiscardFormState
      initialValues={initialValues}
      storageKey={storageKey}
      validate={validator}
      onSubmit={handleSubmit}
      // Using escape hatch to hoist discard form state to be used in
      // submit handler for immediately discarding form state upon submission.
      onChangeDiscardFormState={(v) => (discardFormStateRef.current = v)}
    >
      {(form) => (
        <Form
          className={classnames("DiscussionInput", {
            DiscussionInput__editing: !!response
          })}
        >
          <KeyboardFormSubmit />
          {replyingTo && (
            <Header onClick={() => scrollToResponse(replyingTo)}>
              <Text.Meta>
                {t("discussion.input.replying_to")}{" "}
                {replyingTo.user.display_name}
              </Text.Meta>
              <IconButton icon="x" onClick={() => setReplyingTo(null)} />
            </Header>
          )}
          <Row>
            <Avatar user={user} size="36px" style={{ marginRight: 10 }} />
            <RichTextInput
              placeholder={placeholder}
              onChange={(value) => form.setFieldValue("richText", value)}
              value={form.values.richText}
              tabIndex={1}
              hideStatus
              hideLabel
              stretch={false}
              controlled={true}
              LoadingComponent={() => (
                <LoadingBlock style={{ height: "2em" }} />
              )}
            />
          </Row>
          <Row style={{ justifyContent: "flex-end" }}>
            {!response && (
              <Button
                style={{ fontSize: "1.2em" }}
                styleType="blank"
                onClick={() => handlePick(form)}
                icon="paper-clip"
              />
            )}
            {!response && (
              <SubmitButton
                disabled={submitting || !form.isValid || !form.dirty}
                styleType="primary"
                icon="paper-airplane"
                label="Post"
                brand={false}
              />
            )}
          </Row>
          <Row>
            <DiscussionResponseAttachments
              attachments={form.values.attachments}
              onRemoveAttachment={(attachment) =>
                handleRemoveAttachment(form, attachment)
              }
            />
          </Row>

          {!!response && (
            <UpdateResponseRow>
              <Button
                style={{ fontSize: "1.2em" }}
                styleType="blank"
                onClick={handlePick}
                icon="paper-clip"
              />
              <SubmitButton
                styleType="primary"
                disabled={submitting || !form.isValid || !form.dirty}
                brand={false}
                label={t("discussion.input.update_response")}
              />{" "}
              {t("discussion.input.or")}{" "}
              <Button
                styleType="text"
                onClick={() => {
                  form.discardFormState()
                  setEditingResponse(null)
                }}
              >
                {t("discussion.input.cancel")}
              </Button>
            </UpdateResponseRow>
          )}
        </Form>
      )}
    </FormikWithDiscardFormState>
  )
}

DiscussionInput.displayName = "DiscussionInput"

export default DiscussionInput
