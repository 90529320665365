import IconButton from "@pathwright/ui/src/components/button/IconButton"
import useOnClickOutside from "@pathwright/ui/src/components/hooks/useOnClickOutside"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { useCallback, useState } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import InboxNavigator from "./InboxNavigator"
import useInboxItems from "./useInboxItems"

const Container = styled.div`
  position: relative;
  z-index: 10000000;
  display: flex;
  align-items: center;
  /* need extra margin on the right for the badge */
  margin: 0 10px;
  ${media.max.phone`position: static;`}
`

const ItemsContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
`

const InboxNavigatorIndicator = ({ onSelect }) => {
  const { me } = usePathwrightContext()
  const { items, loading } = useInboxItems()
  const [showItems, setShowItems] = useState(false)

  const clickOutsideHandler = useCallback(() => setShowItems(false), [])
  const setNode = useOnClickOutside(clickOutsideHandler)
  // const count = items.length

  // Attempt to assess if user has necessary roles to access inbox.
  // User must be moderator or teacher of Cohort or mentor of Community Group.
  const hasNecessaryRoles = me?.group_role_stats?.some(
    (group) =>
      ((group?.type === "active_registration" ||
        group?.type === "registration") &&
        !!group?.role &&
        (group.role == 10 || group.role == 15)) ||
      (group?.type === "community_group" && !!group?.role && group.role === 10)
  )

  // Correct to only show InboxNavigatorIndicator when there are items?
  // TODO: Othwerwise we need a blank slate.
  if (!hasNecessaryRoles && (loading || !items.length)) return null
  if (!me) return null

  return (
    <Container ref={setNode}>
      <IconButton
        icon="inbox"
        onClick={() => setShowItems(!showItems)}
        inverted
        styleType={showItems ? "primary" : "tertiary"}
        rounded={false}
        // Likely unhelpful to show indicator simpley because the user has
        // staff access to cohorts or mentor groups. This will be helpful in the
        // future when each user has their own review objects.
        // indicator={!!count}
      />
      {showItems && (
        <ItemsContainer>
          <InboxNavigator onSelect={onSelect} />
        </ItemsContainer>
      )}
    </Container>
  )
}

InboxNavigatorIndicator.displayName = "InboxNavigatorIndicator"

export default InboxNavigatorIndicator
