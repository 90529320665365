import { useFirebaseValue } from "@pathwright/web/src/modules/firebase/utils"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { trackEvent } from "@pathwright/web/src/modules/plausible/events"
import { set } from "firebase/database"
import { useEffect, useMemo, useState } from "react"
import { CHANGELOG_PATH_API_URL } from "./constants"

function useChangelogUnreadIndicator() {
  const { school, me } = usePathwrightContext()
  const [pathData, setPathData] = useState()
  const [lastRead, lastReadRef] = useFirebaseValue(`/user/${me.id}/changelog/`)

  // For testing only! Removes user's last read date.
  // useEffect(() => {
  //   lastReadRef.set(null)
  // }, [])

  // Get latest path data from paths.app.
  useEffect(() => {
    async function fetchPathData() {
      const resp = await fetch(CHANGELOG_PATH_API_URL, {
        method: "GET"
      })
      const data = await resp.json()
      if (data) setPathData(data)
    }
    fetchPathData()
  }, [])

  // Set pathLastPublished from path data.
  const pathLastPublished = useMemo(() => {
    if (pathData?.length) {
      const node = pathData.sort((nodeA, nodeB) => {
        return (
          new Date(nodeB.created_dtime).getTime() -
          new Date(nodeA.created_dtime).getTime()
        )
      })[0]
      return node.created_dtime
    }
  }, [pathData])

  // Set hasUnread based on comparison of lastRead & pathLastPublished.
  const hasUnread = useMemo(() => {
    // Note: the lastRead value from Firebase will be undefined until loaded,
    // then eirther null or the user's last viewed date.
    const lastReadDate = lastRead
      ? new Date(lastRead)
      : lastRead === null
      ? new Date(0)
      : null

    const pathLastPublishedDate = pathLastPublished
      ? new Date(pathLastPublished)
      : null

    return (
      lastReadDate &&
      pathLastPublishedDate &&
      lastReadDate.getTime() <= pathLastPublishedDate.getTime()
    )
  }, [lastRead, pathLastPublished])

  // Handle updating the last time the user viewed the changelog.
  function handleUpdateLastRead() {
    set(lastReadRef, new Date().toISOString())
    trackEvent({
      event: "Viewed PW2 Changelog",
      props: {
        schoolId: school.id,
        user: me.id,
        changelogPublishDate: pathLastPublished
      }
    })
  }

  return [hasUnread, handleUpdateLastRead]
}

export default useChangelogUnreadIndicator
