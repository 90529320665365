import MuxEmbed, { useMuxEmbed } from "@pathwright/media-client/src/MuxEmbed"
import {
  embedIsReady,
  embedNeedsPolling
} from "@pathwright/media-client/src/utils"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useCallback, useEffect, useRef, useState } from "react"
import LazyVideoPlayer from "../LazyVideoPlayer"

const POLL_RATE = 5000

const RenderPollVideo = (props) => {
  const { embed, refetch, onReady } = props

  const pollRef = useRef(null)

  useEffect(() => {
    pollRef.current = setInterval(() => {
      refetch()
    }, POLL_RATE)

    return () => {
      clearInterval(pollRef.current)
      pollRef.current = null
    }
  }, [])

  useEffect(() => {
    if (embedIsReady(embed)) {
      clearInterval(pollRef.current)
      pollRef.current = null
      onReady(embed)
    }
  }, [embed])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        color: "#ccc",
        textAlign: "center",
        minHeight: "200px"
      }}
    >
      <LoadingCircle center={false} />
      <p style={{ marginTop: "0.5rem" }}>Preparing your video.</p>
    </div>
  )
}

const EmbedPlayer = (props) => {
  const { coverImage: savedCoverImage, controls, allowDownload, video } = props

  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)
  const [coverImage, setCoverImage] = useState(savedCoverImage)
  const { loading, error, embed, refetch } = useMuxEmbed()

  const needsPolling = embedNeedsPolling(embed)

  // Reset cover image if video changes
  useEffect(() => {
    if (video?.id) {
      setCoverImage(savedCoverImage)
      // We refetch here to ensure we get the latest embed data
      // This ensures the cover image is available if not supplied by props
      refetch()
    }
  }, [video?.id])

  // Cache the height, width, and cover image from the embed to avoid flicker
  useEffect(() => {
    if (embed?.status === "ready") {
      if (!height) {
        setHeight(embed?.stream?.size?.maxHeight)
      }
      if (!width) {
        setWidth(embed?.stream?.size?.maxWidth)
      }
      if (!coverImage) {
        setCoverImage(embed?.thumb?.src)
      }
    }
  }, [embed?.status])

  const subtitleTracks = Object.keys(embed?.subtitles || {})
    .filter((s) => embed?.subtitles[s]?.active)
    .map((s) => embed?.subtitles[s])

  // This never actually fires
  const handleVideoReady = useCallback(() => {
    refetch()
  })

  // Initial loading only
  if (!needsPolling && loading) {
    return <LoadingCircle />
  }

  if (error) return <p>{JSON.stringify(error, null, 2)}</p>

  if (needsPolling) {
    return (
      <RenderPollVideo
        embed={embed}
        refetch={refetch}
        onReady={handleVideoReady}
      />
    )
  }

  const downloadURL = embed?.download?.url || ""

  if (embed?.status === "ready")
    return (
      <LazyVideoPlayer
        src={embed.stream.url}
        showControls={controls}
        coverImage={coverImage}
        width={width}
        height={height}
        downloadURL={downloadURL}
        allowDownload={allowDownload}
        subtitleTracks={subtitleTracks}
        muxVideoID={video.id}
      />
    )

  return null
}

const PlayerWithEmbed = (props) => {
  const { video, allowDownload, coverImage, controls } = props

  return (
    <MuxEmbed id={video.id}>
      <EmbedPlayer
        video={video}
        allowDownload={allowDownload}
        coverImage={coverImage}
        controls={controls}
      />
    </MuxEmbed>
  )
}

export default PlayerWithEmbed
