import { Box, LightMode } from "@chakra-ui/react"
import Card from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import LazyPasswordChangeContainer from "./LazyPasswordChangeContainer"

const PasswordCard = ({ card }) => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()

  return (
    <Card
      // title={t("password_form.card_title")}
      card={card}
    >
      <LightMode>
        <Box className="chakra-ui-reset-css" p="30px" pt={0}>
          <LazyPasswordChangeContainer hasPassword={!me.no_password_set} />
        </Box>
      </LightMode>
    </Card>
  )
}

PasswordCard.displayName = "PasswordCard"

export default PasswordCard
