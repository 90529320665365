// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const { createCardRoutes } = require("lib/core/routing/card-routing")
import OrderSubscriptionCard from "@pathwright/web/src/modules/order/OrderSubscriptionCard"
import ManageNotificationSettingsCard from "@pathwright/web/src/modules/profile/manage/ManageNotificationSettingsCard"
import path from "path-browserify"
import styled from "styled-components"
import { getDefaultLaunchedFromHome } from "../../../lib/core/routing/utils"

const StyledOrderSubscriptionCard = styled(OrderSubscriptionCard)`
  & > .CardContainer {
    max-width: unset !important;
  }
`

const cardRoutes = {
  profile: {
    component: require("./profile/ProfileCard").default,
    getDefaultLaunchedFrom: getDefaultLaunchedFromHome,
    childCards: {
      billing: {
        component: require("./billing/BillingCard").default,
        middleware: (nextState, replace, callback) => {
          // Ensure user has manageable subscription.
          const hasSchoolPlans =
            App.getStore("school").request.hasSubscriptionPlans()
          const redirect = hasSchoolPlans ? null : replace("/manage/profile/")
          return callback(redirect)
        },
        childCards: {
          payment: {
            component: require("./update-payment/UpdatePayment").default
          },
          activate: {
            component: ({ card }) => (
              <StyledOrderSubscriptionCard
                card={card}
                redirectTo={path.join(window.location.pathname, "../")}
              />
            )
          },
          cancel: {
            component: require("./cancel-subscription/CancelSubscription")
              .default
          },
          history: {
            component: require("./payment-history/PaymentHistory").default
          }
        }
      },
      password: {
        component: require("./change-password/PasswordCard").default
      },
      history: {
        component: require("./payment-history/PaymentHistory").default
      },
      notifications: {
        component: ManageNotificationSettingsCard
      }
    }
  }
}

const routes = createCardRoutes(
  "/manage/",
  cardRoutes,
  require("home/components/HomeOrLibraryBrowse").default
)

export default routes
