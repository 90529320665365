import Button from "@pathwright/ui/src/components/button/Button"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CounterInput from "@pathwright/ui/src/components/form/form-counter-input/CounterInput"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import FieldHeader from "@pathwright/ui/src/components/form/form-utils/FieldHeader"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import View from "@pathwright/ui/src/components/ui/View"
import { PRIMARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import { useEffect, useState } from "react"
import styled from "styled-components"
import LazyAdaptedAuthContainer from "../auth/LazyAdaptedAuthContainer"
import OrderStripeCheckoutForm from "../order/OrderStripeCheckoutForm"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import StripePricePreview from "./StripePricePreview"
import formatStripeAmount from "./utils/formatStripeAmount"
import getStartingStripePriceAmount from "./utils/getStartingStripePriceAmount"
import getStripePriceActionLabel from "./utils/getStripePriceActionLabel"
import getStripePriceIntervalLabel from "./utils/getStripePriceIntervalLabel"
import getStripePriceTotalAmount from "./utils/getStripePriceTotalAmount"
import getStripeProductMaxQuantity from "./utils/getStripeProductMaxQuantity"
import getStripeProductMinQuantity from "./utils/getStripeProductMinQuantity"

const sampleImage =
  "https://stripe-camo.global.ssl.fastly.net/34909b72d225e6db5bea037fbcc29714153b44fa/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f666c5f746573745f41686e3463357868357a5663473342324c6c4e6d5661556f"

export default function StripeProductCard({
  stripeProduct,
  onAuthStep,
  redirectTo,
  inviteToken
}) {
  const { me } = usePathwrightContext()
  const { t, tc, tca } = useTranslate()

  const minQuantity = getStripeProductMinQuantity(stripeProduct)
  const maxQuantity = getStripeProductMaxQuantity(stripeProduct)
  const hasIndividualPricing = minQuantity <= 1
  const hasGroupPricing = maxQuantity > 1
  // We'll only show selection UI for the individual and group options
  // if the product allows for both, otherwise, the available option
  // will automatically be selected/displayed.
  const hasIndividualAndGroupPricingOptions =
    hasIndividualPricing && hasGroupPricing
  const [showAuthStep, setShowAuthStep] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState(stripeProduct.stripe_prices.length === 1 ? stripeProduct.stripe_prices[0] : null) // prettier-ignore
  const [groupPurchase, setGroupPurchase] = useState(!hasIndividualPricing)
  const [groupName, setGroupName] = useState("")
  const [unitsQuantity, setUnitsQuantity] = useState(minQuantity)

  useEffect(() => {
    onAuthStep(showAuthStep)
  }, [onAuthStep, showAuthStep])

  useEffect(() => {
    setShowAuthStep(false)
  }, [me])

  if (showAuthStep) {
    return (
      <CardBlock>
        <Button brand styleType="text" onClick={() => setShowAuthStep(false)}>
          {tca("Go Back")}
        </Button>
        <LazyAdaptedAuthContainer
          authWrapperProps={{ p: [0, 0], pt: [6, 8] }}
          inviteToken={inviteToken}
        />
      </CardBlock>
    )
  }

  return (
    <StyledCard>
      <StyledHeader>
        {stripeProduct.images && stripeProduct.images.length ? (
          <img src={stripeProduct.images[0]} />
        ) : null}
        <Text.H4>{stripeProduct.name}</Text.H4>
      </StyledHeader>
      <View style={{ padding: "10px 20px" }}>
        {stripeProduct.description ? (
          <Text.Body
            dangerouslySetInnerHTML={{ __html: stripeProduct.description }}
          />
        ) : null}
      </View>
      <View style={{ padding: "2px 20px 10px" }}>
        {stripeProduct.stripe_prices.map((price) => (
          <label key={price.id}>
            <StyledRadio>
              <input
                type="radio"
                value={price.id}
                required={true}
                checked={selectedPrice ? selectedPrice.id == price.id : false}
                onChange={() => setSelectedPrice(price)}
                hidden={stripeProduct.stripe_prices.length === 1}
              />
              <div style={{ flex: 1 }}>
                <StyledRow>
                  <Text.H5>{getStripePriceActionLabel(price, t, tc)}</Text.H5>
                  <Text.Meta style={{ textAlign: "right" }}>
                    {price.billing_scheme === "tiered"
                      ? `${t("starts at")} `
                      : ""}
                    <StyledEmphasis>
                      {formatStripeAmount(
                        getStartingStripePriceAmount(price),
                        price.currency
                      )}
                    </StyledEmphasis>
                    {price.recurring_interval ? (
                      <>
                        {" "}
                        {t("per")}&nbsp;
                        {getStripePriceIntervalLabel(price, false, t)}
                      </>
                    ) : (
                      ""
                    )}
                  </Text.Meta>
                </StyledRow>
                <Text.Meta>{price.nickname}</Text.Meta>
              </div>
            </StyledRadio>
          </label>
        ))}
      </View>
      {(() => {
        if (!selectedPrice) {
          return null
        }
        const startingAmount = getStripePriceTotalAmount(
          selectedPrice,
          stripeProduct.min_quantity || 1
        )
        const totalUnitsAmount = getStripePriceTotalAmount(selectedPrice, unitsQuantity) // prettier-ignore
        return (
          <View emphasis="secondary" style={{ padding: 20 }}>
            <StyledRow>
              {hasIndividualAndGroupPricingOptions && (
                <Text.Body>{tc("Purchase plan for")}:</Text.Body>
              )}
              {selectedPrice.billing_scheme === "tiered" ? (
                <Tooltip
                  title={<StripePricePreview stripePrice={selectedPrice} />}
                  style={{
                    marginLeft: "auto"
                  }}
                >
                  <StyledTooltipTrigger>
                    <Text.Body>
                      <Pathicon
                        icon="info-circle"
                        style={{ marginRight: "6px" }}
                      />{" "}
                      {tca("View Pricing")}
                    </Text.Body>
                  </StyledTooltipTrigger>
                </Tooltip>
              ) : null}
            </StyledRow>
            {hasIndividualAndGroupPricingOptions && (
              <>
                <label>
                  <StyledRadio>
                    <input
                      type="radio"
                      value={false}
                      checked={!groupPurchase}
                      onChange={() => {
                        setGroupPurchase(false)
                        setUnitsQuantity(1)
                      }}
                    />
                    <div>
                      <Text.H5>{tc("Just yourself")}</Text.H5>
                      <Text.Meta>
                        {tc("Get started with an individual subscription")}.
                      </Text.Meta>
                    </div>
                  </StyledRadio>
                </label>
                <label>
                  <StyledRadio>
                    <input
                      type="radio"
                      value={true}
                      checked={groupPurchase}
                      onChange={() => {
                        setGroupPurchase(true)
                        setUnitsQuantity(
                          getStripeProductMinQuantity(stripeProduct, 2)
                        )
                      }}
                    />
                    <div>
                      <Text.H5>{tc("Yourself and others")}</Text.H5>
                      <Text.Meta>
                        {tc("Learn with others on your subscription plan")}.
                      </Text.Meta>
                    </div>
                  </StyledRadio>
                </label>
              </>
            )}
            {groupPurchase ? (
              <>
                <FieldHeader>
                  <Text.Body>
                    {tc("Enter how many seats you want to start with")}:
                  </Text.Body>
                </FieldHeader>
                <StyledRow>
                  <CounterInput
                    min={getStripeProductMinQuantity(stripeProduct, 2)}
                    max={maxQuantity}
                    value={unitsQuantity}
                    onChange={setUnitsQuantity}
                    style={{ width: 80 }}
                  />
                  <Text.Meta>
                    <StyledEmphasis>
                      {formatStripeAmount(
                        totalUnitsAmount,
                        selectedPrice.currency
                      )}
                    </StyledEmphasis>
                    {` ${t("per")} `}
                    {getStripePriceIntervalLabel(selectedPrice, false, t)}
                  </Text.Meta>
                </StyledRow>
                <FieldHeader>
                  <Text.Body>
                    {tc(
                      `Add a name for your group that members you invite will recognize`
                    )}
                    :
                  </Text.Body>
                </FieldHeader>
                <TextInput
                  value={groupName}
                  type="text"
                  required
                  placeholder={tc("Your group name")}
                  onChange={(text) => setGroupName(text)}
                />
                <br />
              </>
            ) : null}
            {me ? (
              <OrderStripeCheckoutForm
                submitLabel={tc(`Subscribe for {{ price }} / {{ interval }}`, {
                  price: formatStripeAmount(
                    totalUnitsAmount,
                    selectedPrice.currency
                  ),
                  interval: getStripePriceIntervalLabel(selectedPrice, false, t)
                })}
                orderType="createGroupSubscriptionOrder"
                orderData={{
                  price_id: selectedPrice.id,
                  quantity: unitsQuantity || minQuantity,
                  group_name: groupName || tca("My Group"),
                  redirect_to: redirectTo
                }}
              />
            ) : (
              <CenteredContent>
                <Button
                  disabled={groupPurchase ? !groupName : false}
                  onClick={() => setShowAuthStep(true)}
                  styleType="primary"
                  brand
                >
                  {tc("Continue")}
                </Button>
              </CenteredContent>
            )}
            <br />
            <CenteredContent>
              <Text.Meta>
                {tc("Have a coupon or gift code? Enter it at checkout")}.
              </Text.Meta>
            </CenteredContent>
          </View>
        )
      })()}
    </StyledCard>
  )
}

const StyledCard = styled.div`
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 10px 0;
`

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 15px 20px 0;
  img {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    margin-right: 10px;
  }
`

const CenteredContent = styled.div`
  text-align: center;
`

const StyledRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const StyledRadio = styled.div`
  display: flex;
  align-items: baseline;
  padding: 8px 0;
  input {
    margin-right: 10px;
  }
`

const StyledEmphasis = styled.em`
  font-size: 1.2em;
  font-weight: bold;
  font-style: normal;
  color: ${PRIMARY_GRAY};
`

const StyledTooltipTrigger = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  p {
    display: flex;
    align-items: center;
  }
`
