import defaultsDeep from "lodash/defaultsDeep"
import { useReducer } from "react"
import { z } from "zod"
import { RegistrationRoleEnum } from "../../api/generated"
import { exhaustiveGuard } from "../../lib/typing"
import {
  HistoryProps,
  HistorySync,
  ItemEquivalence,
  useHistoryReducer
} from "./history"

// The items that can be created or selected.
export type ItemKey = "resource" | "cohort"

// The selected types represent those objects that already exist.
export type SelectedResource = z.infer<typeof selectedResourceSchema>
export type SelectedCohort = z.infer<typeof selectedCohortSchema>

// The new types represet those objects that don't exist.
export type NewResource = z.infer<typeof newResourceSchema>
export type NewCohort = z.infer<typeof newCohortSchema>

// Role can either be a registration role or null.
export type SelectedResourceRole = z.infer<typeof resourceRoleSchema> | null
export type SelectedCohortRole = z.infer<typeof cohortRoleSchema> | null

// The items (Resource and Cohort) will either by form values for
// to-be-created objects, or the existing (selected) objects themselevs.
export type Resource = z.infer<typeof resourceSchema>
export type Cohort = z.infer<typeof cohortSchema>
// Unsure if "Path" should be used for "Resource", but for consistency
// at least with the Resource and Cohort types above, mapping the role
// terms to those makes sense.
export type ResourceRole = SelectedResourceRole
export type CohortRole = SelectedCohortRole

export type PathCreatorState = z.infer<typeof pathCreatorStateSchema>

// The status the flow may be in at any give point.
export type StatusKey = PathCreatorState["status"]

// The actions the user may take.
export type PathCreatorAction = z.infer<typeof pathCreatorActionSchema>

export type PathCreatorDispatch = ReturnType<typeof usePathCreatorReducer>[1]

export type PathCreatorProps = {
  pathCreatorState: PathCreatorState
  pathCreatorDispatch: PathCreatorDispatch
}

export type PathCreatorHistoryProps = HistoryProps<PathCreatorState>

// We want to conform an enum to our own RegistrationRoleEnum and
// using z.nativeEnum is how to do that.
const registrationRoleSchema = z.nativeEnum(RegistrationRoleEnum)

const resourceRoleEnum = z.enum([registrationRoleSchema.enum.Editor])

const resourceRoleSchema = z.union([resourceRoleEnum, z.null()])

const newResourceSchema = z.object({
  name: z.string().min(1),
  image: z.string().url(),
  role: resourceRoleSchema.optional()
})

// The selected resource differs in that it has an id field + sourceCohort
// that includes an optional role.
const selectedResourceSchema = newResourceSchema.merge(
  z.object({
    id: z.number(),
    background_image: z.union([z.string(), z.null()]).optional(),
    sourceCohort: z.object({
      id: z.number(),
      role: resourceRoleSchema.optional()
    })
  })
)

// Order here is important, from greatest constraint to least.
const resourceSchema = z.union([selectedResourceSchema, newResourceSchema])

const cohortRoleEnum = z.enum([
  registrationRoleSchema.enum.Teacher,
  registrationRoleSchema.enum.Moderator,
  registrationRoleSchema.enum.Student
])

const cohortRoleSchema = z.union([cohortRoleEnum, z.null()])

const newCohortSchema = z.object({
  name: z.string().min(1),
  role: cohortRoleSchema.optional()
})

// The selected cohort differs only in that it has an id field.
const selectedCohortSchema = newCohortSchema.merge(
  z.object({
    id: z.number(),
    // NOTE: having to include the registrations list expected by <GroupAvatars />
    registrations: z.any().optional()
  })
)

// Order here is important, from greatest constraint to least.
const cohortSchema = z.union([selectedCohortSchema, newCohortSchema])

export const statusKeySchema = z.enum([
  "initial",
  "create_or_select_resource",
  "create_resource",
  "select_resource",
  "create_cohort",
  "create_or_select_cohort",
  "select_cohort",
  "select_role",
  // NOTE: these "submit_*" states are somewhat of an either/or OR a both/and.
  // The user can submit with either roles, or both.
  "submit_with_resource_role",
  "submit_with_cohort_role",
  "complete"
])

// The various states the flow may be in.
// The status keys are grouped based on resource, cohort, and role values.
export const pathCreatorStateSchema = z.discriminatedUnion("status", [
  z.object({
    status: statusKeySchema.extract([
      "initial",
      "create_or_select_resource",
      "create_resource",
      "select_resource"
    ]),
    resource: z.null().optional(),
    cohort: z.null().optional()
  }),
  z.object({
    status: statusKeySchema.extract(["create_cohort"]),
    resource: resourceSchema,
    cohort: z.null().optional()
  }),
  z.object({
    status: statusKeySchema.extract(["create_or_select_cohort"]),
    resource: selectedResourceSchema,
    cohort: z.null().optional()
  }),
  z.object({
    status: statusKeySchema.extract(["select_cohort"]),
    resource: selectedResourceSchema,
    cohort: z.null().optional()
  }),
  z.object({
    status: statusKeySchema.extract(["select_role"]),
    resource: resourceSchema,
    cohort: cohortSchema
  }),
  // User must submit wth either a resource role or cohort role selected.
  // The unfortunate limitation with zod discriminated unions is that they can
  // only accept zod objects at the root, not zod unions.
  z.object({
    status: statusKeySchema.extract(["submit_with_resource_role"]),
    resource: resourceSchema.and(
      z.object({
        role: resourceRoleEnum
      })
    ),
    cohort: cohortSchema
  }),
  z.object({
    status: statusKeySchema.extract(["submit_with_cohort_role"]),
    resource: resourceSchema,
    cohort: cohortSchema.and(
      z.object({
        role: cohortRoleEnum
      })
    )
  }),
  z.object({
    status: statusKeySchema.extract(["complete"]),
    resource: selectedResourceSchema,
    cohort: selectedCohortSchema
  })
])

const pathCreatorActionSchema = z.discriminatedUnion("type", [
  // Change both status and resource + cohort values.
  z.object({
    type: z.literal("set"),
    value: z.union(pathCreatorStateSchema.options)
  }),
  // Change status when the resource + cohort values should remain unchanged.
  z.object({
    type: z.literal("set_status"),
    status: statusKeySchema
  })
])

function reducer(
  state: PathCreatorState,
  action: PathCreatorAction
): PathCreatorState {
  // Note: for exhastive guard check to work, have to pull out
  // the action.type and use in both the switch and exhastive guard check.
  const { type } = action

  switch (type) {
    case "set":
      // If applying a transform to the zod discriminated union was an option, that might
      // be a simpler approach, but either way, we need to change to a "create_cohort" status
      // when selecting a cohort with -1 id.
      if (
        action.value.status === "select_role" &&
        "id" in action.value.cohort &&
        action.value.cohort.id === -1
      ) {
        return reducer(state, {
          type: action.type,
          value: {
            resource: action.value.resource,
            cohort: null,
            status: "create_cohort"
          }
        })
      }
      // Just set the state to exactly what's specified in the action's value.
      // This is helpful for updating state after history change.
      return action.value
    // Handle the case where we change status, ensuring the correct
    // state is used. This can be helpful for if we track status history
    // and allow user to go back (which we will).
    case "set_status": {
      return pathCreatorStateSchema.parse({
        ...state,
        status: action.status
      })
    }
    default: {
      exhaustiveGuard(type)
    }
  }
}

export function usePathCreatorReducer() {
  return useReducer(reducer, {
    status: "initial",
    resource: null,
    cohort: null
  })
}

// Sync the path creator reducer's state with succeeding items in history.
// This handles the case where the user goes backward, and makes a compatible edit.
// We want all succeeding history items to share the compatible state with that item.
export const pathCreatorStateHistoryRevisor: HistorySync<PathCreatorState> = (
  item,
  items
) => {
  const revisedItems = items.reduce((acc, historyItem, i) => {
    // If we've had to pop an invalid item, the length of the accumulated list
    // will be less than the iterator. This means from this point on we'll drop all
    // remaining history items.
    if (acc.length === i) {
      // Use the previous item as the base item, falling back to the provided item
      // initially (since acc[0 - 1] will be undefined).
      const baseItem = acc[i - 1]

      // Let's only attempt to revise if we have a base item.
      if (baseItem) {
        // Clone the items to ensure we don't overwrite them.
        const clonedBaseItem = structuredClone(baseItem)
        const clonedHistoryItem = structuredClone(historyItem)

        // Compare baseItem with history, using the historyItem as the defaults for
        // both the resource and cohort when the items are equivalent.
        const nextItem = {
          // Safely use the historyItem.resource as defaults when resources are equvialent.
          // Otherwise overwrite with baseItem.resource.
          resource: resourceEquivalence(
            clonedHistoryItem.resource,
            clonedBaseItem.resource
          )
            ? defaultsDeep(clonedBaseItem.resource, clonedHistoryItem.resource)
            : clonedBaseItem.resource || clonedHistoryItem.resource,
          // Safely use the historyItem.cohort as defaults when either the
          // cohorts are equvialent or the historyItem.cohort is a created one.
          // Otherwise overwrite with baseItem.cohort.
          cohort:
            resourceEquivalence(
              clonedHistoryItem.resource,
              clonedBaseItem.resource
            ) ||
            (clonedHistoryItem.cohort && !("id" in clonedHistoryItem.cohort))
              ? defaultsDeep(clonedBaseItem.cohort, clonedHistoryItem.cohort)
              : clonedBaseItem.cohort || clonedHistoryItem.cohort,
          // Always use the historyItem's status, since the baseItem's status will be "outdated".
          status: clonedHistoryItem.status
        }

        acc.push(nextItem)
      } else {
        acc.push(item)
      }

      // Remove this latest state if it isn't valid after the revise.
      if (!pathCreatorStateSchema.safeParse(acc.slice(-1)[0]).success) {
        acc.pop()
      }
    }

    return acc
  }, [] as PathCreatorState[])

  return revisedItems
}

// Resources differing by id are different.
const resourceEquivalence: ItemEquivalence<PathCreatorState["resource"]> = (
  itemA,
  itemB
) => {
  if (
    (!!itemA && "id" in itemA && itemA.id) !==
    (!!itemB && "id" in itemB && itemB.id)
  ) {
    return false
  }

  return true
}

// Cohorts differing by id are different.
const cohortEquivalence: ItemEquivalence<PathCreatorState["cohort"]> = (
  itemA,
  itemB
) => {
  if (
    (!!itemA && "id" in itemA && itemA.id) !==
    (!!itemB && "id" in itemB && itemB.id)
  ) {
    return false
  }

  return true
}

// We only find items to be unequivalent when their inherent identites differ.
export const itemEquivalence: ItemEquivalence<PathCreatorState> = (
  itemA,
  itemB
) => {
  // Items differing by cohort id are different.
  if (!cohortEquivalence(itemA.cohort, itemB.cohort)) {
    return false
  }

  // Items differing by resource id are different.
  if (!resourceEquivalence(itemA.resource, itemB.resource)) {
    return false
  }

  // Items differing by role are different.
  if (
    itemA.resource?.role != itemB.resource?.role ||
    itemA.cohort?.role != itemB.cohort?.role
  ) {
    return false
  }

  // When item statuses are equal, items differing by existance for
  // resource, cohort, and role keys are differnt.
  return (
    itemA.status === itemB.status &&
    !!itemA.resource === !!itemB.resource &&
    !!itemA.cohort === !!itemB.cohort
  )
}

export function usePathCreatorHistoryReducer({
  pathCreatorState,
  pathCreatorDispatch
}: PathCreatorProps) {
  return useHistoryReducer({
    item: pathCreatorState,
    // Sync the history item with the path creator reducer.
    sourceSync: (item) =>
      pathCreatorDispatch({
        type: "set",
        value: item
      }),
    succeedingHistorySync: pathCreatorStateHistoryRevisor,
    itemEquivalence
  })
}
