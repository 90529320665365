import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import TextArea from "@pathwright/ui/src/components/form/form-textarea/TextArea"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import { validateAsync } from "lib/core/components/forms"
import { ImageUpload } from "lib/core/components/forms/new"
import { Field, Form, Submit, validators } from "react-connect-form-forked"
import styled from "styled-components"
const { isEmail, isMinLength, isMaxLength } = validators
const { isEmailUnique } = validateAsync

const Container = styled.div`
  .styled-field {
    margin-left: 100px;
    overflow: visible;

    > span {
      margin-left: 0px;
    }

    .Tooltip {
      min-width: 200px;
      white-space: normal;
      text-align: left;
    }
  }
`

const tPrefix = "profile.form"

const getVisibilityIndicator = (field, i18next) => {
  const { t, Trans } = i18next

  switch (field) {
    case "firstname":
      return {
        icon: "eye",
        label: t("Visible to all members"),
        tooltip: t("All members in courses with you can see your first name.")
      }
    case "lastname":
      return {
        icon: "eye-lock",
        label: t("Visible to staff only"),
        tooltip: (
          <Trans
            i18nKey="Only administrators, teachers, and other staff can see your full last name.<br /><br />Everyone else only sees your last initial."
            components={{ br: <br /> }}
          />
        )
      }
    case "email":
      return {
        icon: "eye-lock",
        label: t("Visible to staff only"),
        tooltip: t(
          "Only administrators, teachers, and other staff can see your email address."
        )
      }
    case "location":
      return {
        icon: "eye",
        label: t("Visible to all members"),
        tooltip: t("All members in courses with you can see your location.")
      }
    case "bio":
      return {
        icon: "eye",
        label: t("Visible to all members"),
        tooltip: t(
          "All members in courses with you can see anything you'd like to share about yourself."
        )
      }
  }
}

const VisibilityIndicator = ({ field }) => {
  const i18next = useTranslate()
  const { icon, label, tooltip } = getVisibilityIndicator(field, i18next)

  return (
    <Tooltip title={tooltip}>
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <Pathicon icon={icon} style={{ marginRight: 4 }} /> {label}
      </div>
    </Tooltip>
  )
}

// Just to be sure, validating that the user's email is unchanged if we are preventing them from updating their email
const emailUnchanged = (shouldNotChange, initialValue) => {
  return (value, values) => {
    if (shouldNotChange) {
      if (value !== initialValue) {
        return "You may not change your email."
      }
    }
  }
}

const ProfileForm = ({ user, saveUser, pathwright_login_enabled }) => {
  const { t } = useTranslate()

  return (
    <Container className="ProfileForm">
      <Form onSubmit={(values) => saveUser(values).promise}>
        <Submit styleType="primary" component={SubmitButton}>
          {t(`${tPrefix}.save`)}
        </Submit>
        <Field
          name="avatar"
          type="image"
          initialValue={{
            image: user.profile.image,
            background_image: user.profile.background_image
          }}
          component={ImageUpload}
          styleType="avatar"
          showAtmosphere={true}
        />
        <CardBlock>
          <Field
            name="firstname"
            label={t(`${tPrefix}.first_name`)}
            type="text"
            placeholder={t(`${tPrefix}.first_name_ph`)}
            initialValue={user.first_name}
            validators={[isMinLength(2)]}
            component={TextInput}
            required={true}
            helperText={<VisibilityIndicator field="firstname" />}
          />
          <Field
            name="lastname"
            label={t(`${tPrefix}.last_name`)}
            type="text"
            placeholder={t(`${tPrefix}.last_name_ph`)}
            initialValue={user.last_name}
            validators={[isMinLength(2)]}
            component={TextInput}
            required={true}
            helperText={<VisibilityIndicator field="lastname" />}
          />
          <Field
            name="email"
            label={t(`${tPrefix}.email`)}
            type="email"
            placeholder={t(`${tPrefix}.email_ph`)}
            initialValue={user.email}
            validators={[
              isEmail,
              isEmailUnique,
              emailUnchanged(!pathwright_login_enabled, user.email)
            ]}
            component={TextInput}
            disabled={!pathwright_login_enabled}
            required={true}
            helperText={<VisibilityIndicator field="email" />}
          />
          <Field
            name="location"
            label={t(`${tPrefix}.location`)}
            type="text"
            placeholder={t(`${tPrefix}.location_ph`)}
            initialValue={user.profile.location}
            component={TextInput}
            validators={[isMaxLength(80)]}
            showLimit={80}
            helperText={<VisibilityIndicator field="location" />}
          />
          <Field
            name="bio"
            label={t(`${tPrefix}.about_you`)}
            type="text"
            placeholder={t(`${tPrefix}.about_you_ph`)}
            initialValue={user.profile.bio || ""}
            validators={[isMaxLength(140)]}
            component={TextArea}
            showLimit={140}
            maxRows={3}
            helperText={<VisibilityIndicator field="bio" />}
          />
        </CardBlock>
      </Form>
    </Container>
  )
}

ProfileForm.displayName = "ProfileForm"

export default ProfileForm
