import { useApolloClient } from "@apollo/client"
import {
  HomeRegistrationFragment,
  HomeRegistrationsDocument,
  HomeRegistrationsQueryResult,
  HomeRegistrationsQueryVariables,
  SoftArchiveRegistrationMutationFn,
  SoftArchiveRegistrationMutationHookResult,
  useSoftArchiveRegistrationMutation
} from "../api/generated"
import {
  useHomeRegistrationVars,
  useHomeSoftArchivedRegistrationVars
} from "./HomeRegistrationQueryVars"

// Toggles the soft-archived state of a registration based on whether the registration
// is currently soft-archived or not.
function useSoftArchiveRegistration(
  registration: HomeRegistrationFragment,
  shouldUpdateCache: boolean = true
): SoftArchiveRegistrationMutationHookResult {
  const shouldArchive = !registration.softArchivedTime
  const unifiedHomeRegistrationVars = useHomeRegistrationVars()
  const unifiedHomeSoftArchivedRegistrationVars =
    useHomeSoftArchivedRegistrationVars()
  const client = useApolloClient()

  // Update the appropriate registration queries to either add or remove the registration
  // based on whether it is being soft archived or not.
  function updateCache(shouldArchive: boolean) {
    let refetchQueries: string[] = []

    if (shouldUpdateCache) {
      const query = HomeRegistrationsDocument
      const queryVars = [
        unifiedHomeRegistrationVars,
        unifiedHomeSoftArchivedRegistrationVars
      ]

      for (const variables of queryVars) {
        const shouldInsert = shouldArchive
          ? variables.softArchived?.neq === null
          : variables.softArchived?.eq === null

        // if (shouldArchive && variables.softArchived?.eq === null)

        const data = client.readQuery<
          HomeRegistrationsQueryResult["data"],
          HomeRegistrationsQueryVariables
        >({
          query,
          variables
        })
        if (data) {
          const nextData: HomeRegistrationsQueryResult["data"] = {
            me: {
              ...data?.me,
              registrations: {
                ...data?.me?.registrations,
                // If we should archive, then we should remove the registration from the
                // user's home screen, otherwise, add back.
                edges: shouldInsert
                  ? [
                      ...(data?.me?.registrations?.edges || []),
                      {
                        node: registration
                      }
                    ]
                  : data?.me?.registrations?.edges?.filter(
                      (edge) => edge?.node?.id !== registration.id
                    )
              }
            }
          }

          try {
            client.writeQuery({
              query,
              variables,
              data: nextData
            })
          } catch (error) {
            // It's possible we were not provided a registration of the type HomeRegistrationFragment
            // so let's just silently ignore.
            refetchQueries = ["HomeRegistrations"]
          }
        }
      }
    } else {
      refetchQueries = ["HomeRegistrations"]
    }

    return refetchQueries

    // TODO: we should also update the query the lists the user's soft-archived registrations,
    // performing the opposite action.
  }

  const [mutate, state] = useSoftArchiveRegistrationMutation({
    variables: shouldArchive
      ? {
          id: registration.id,
          softArchivedTime: new Date(),
          // Archiving a registration should turn off the user's notifications for that registration.
          sendDiscussionNotifications: false,
          sendDueNotifications: false
        }
      : {
          id: registration.id,
          softArchivedTime: null,
          // Archiving a registration should turn on the user's notifications for that registration.
          sendDiscussionNotifications: true,
          sendDueNotifications: true
        },
    optimisticResponse: {
      updateRegistration: shouldArchive
        ? {
            ...registration,
            softArchivedTime: new Date(),
            send_discussion_notifications: false,
            send_due_notifications: false,
            __typename: "Registration"
          }
        : {
            ...registration,
            softArchivedTime: null,
            send_discussion_notifications: true,
            send_due_notifications: true,
            __typename: "Registration"
          }
    },
    onError() {
      // Restore cache when mutation fails.
      const refetchQueries = updateCache(!shouldArchive)
      client.refetchQueries({ include: refetchQueries })
    }
  })

  const handleMutate: SoftArchiveRegistrationMutationFn = async (options) => {
    const refetchQueries = updateCache(shouldArchive)
    const result = await mutate(options)
    client.refetchQueries({ include: refetchQueries })
    return result
  }

  return [handleMutate, state]
}

export default useSoftArchiveRegistration
