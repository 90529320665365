import { HStack, IconButton, UseDisclosureReturn } from "@chakra-ui/react"
import {
  AugmentationToolbarDropdown,
  AugmentationToolbarHookReturn
} from "../../components/augmentation/AugmentationToolbar"
import Pathicon from "../../pathicon/Pathicon"

type HomeAugmentationHeaderProps = {
  toolbar: AugmentationToolbarHookReturn
  disclosure?: UseDisclosureReturn
}

const HomeAugmentationHeader = ({
  toolbar,
  disclosure
}: HomeAugmentationHeaderProps) => {
  return (
    <HStack w="100%" justifyContent="space-between">
      <AugmentationToolbarDropdown {...toolbar} />
      {!!disclosure && (
        <IconButton
          {...disclosure.getButtonProps()}
          icon={<Pathicon icon="x" />}
          h={8}
          minW={8}
          isRound
          cursor="pointer"
        />
      )}
    </HStack>
  )
}

export default HomeAugmentationHeader
