export const embedIsReady = (embed) => !!embed && embed.status === "ready"

export const embedNeedsPolling = (embed) =>
  !embed || embed.status === "preparing"

export const downloadIsReady = (embed) =>
  !!embed && embed.download?.status === "ready"

export const downloadNeedsPolling = (embed) =>
  !embed || embed.download?.status === "preparing"
