import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import CardLink from "@pathwright/ui/src/components/card/CardLink"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import ConnectStores from "lib/core/store/components/ConnectStores"
import { modalRoute } from "lib/core/utils/urls"
import { useEffect } from "react"
import SubscriptionInfo from "./SubscriptionInfo"

const linkData = {
  viewcard: {
    icon: "credit-card",
    label: "Update Card",
    to: "/manage/profile/billing/payment/"
  },
  subscription: {
    icon: "recur-pause",
    label: "Manage Subscription",
    to: "/manage/profile/billing/subscription/"
  },
  cancelsub: {
    icon: "recur-x",
    label: "Cancel Subscription",
    to: "/manage/profile/billing/cancel/"
  },
  history: {
    icon: "credit-card",
    label: "View Payment History",
    to: "/manage/profile/billing/history/"
  },
  activate: {
    icon: "recur",
    label: "Activate Subscription",
    to: "/manage/profile/billing/activate/"
  }
}

function BillingCard({
  card,
  subscription,
  subscription_plans,
  loadUserSubscription
}) {
  const { school } = usePathwrightContext()

  // Special hardcoded exception for Codely who process subscription payment
  // outside of Pathwrihgt but provides access to their Paths via subscription.
  // These users should be able to cancel their subscription (which cancels
  // there 3rd party subscription).
  const canCancelTrialSub =
    school.id === 13172 &&
    !!subscription &&
    subscription.is_trial &&
    !subscription.is_canceled

  useEffect(() => {
    loadUserSubscription()
  }, [])

  return (
    <Card title="Billing" card={card}>
      <SubscriptionInfo
        {...subscription}
        starting_plan={subscription_plans.starting_plan}
      />
      {!subscription || !subscription.subscription ? (
        <div>
          <Button styleType="card" to={modalRoute("/subscribe/")}>
            Subscribe
          </Button>
          <CardLink {...linkData.history} />
        </div>
      ) : (
        <div>
          {!subscription.is_active && <CardLink {...linkData.activate} />}{" "}
          {
            // can cancel if subscription isn't set to cancel
            ((subscription.is_active &&
              !subscription.subscription.is_canceled_at_cycle_end) ||
              canCancelTrialSub) && <CardLink {...linkData.cancelsub} />
          }
          {subscription.subscription.card_last_4 &&
            !subscription.subscription.is_canceled_at_cycle_end && (
              <CardLink
                {...linkData.viewcard}
                preview={`Card ending in ${subscription.subscription.card_last_4}`}
              />
            )}
          <CardLink {...linkData.history} />
        </div>
      )}
    </Card>
  )
}

const mapStateToProps = function (state, ownProps) {
  const { subscription } = state.subscribeStore.state
  const { loadUserSubscription } = state.subscribeStore.action
  const { subscription_plans } = state.schoolStore.state

  subscription_plans.starting_plan = _.first(subscription_plans)
  for (let plan of Array.from(subscription_plans)) {
    if (plan.amount < subscription_plans.starting_plan.amount) {
      subscription_plans.starting_plan = plan
    }
  }

  const props = _.assign(
    {},
    {
      subscription,
      subscription_plans,
      loadUserSubscription
    },
    ownProps
  )
  return props
}

const stores = [
  { store: "subscribe", watch: ["subscription_loading"] },
  { store: "school", watch: false }
]

export default ConnectStores(BillingCard, stores, {}, mapStateToProps)
