import { Box } from "@chakra-ui/react"

import { BORDER_RADIUS } from "../../constants"

type ProgressBarProps = {
  progress: number
}

const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <Box w="full" h="full" borderRadius={BORDER_RADIUS} bg="whiteAlpha.600">
      <Box
        h="16px"
        w={`${progress * 100}%`}
        borderRadius={BORDER_RADIUS}
        bg={progress === 1 ? "green.400" : "blue.500"}
      />
    </Box>
  )
}

export default ProgressBar
