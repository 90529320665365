import { ReactNode, createContext, useContext, useState } from "react"
import { PathCreatorState } from "../path/create/state"

type PathCreatorCompletion = (PathCreatorState & { status: "complete" }) | null

type HomeContextValue = {
  setPathCreatorCompletion: (completion: PathCreatorCompletion) => void
  pathCreatorCompletion: PathCreatorCompletion
}

export const HomeContext = createContext<HomeContextValue>(
  {} as HomeContextValue
)

export const useHomeContext = () => useContext(HomeContext)

export const HomeContextProvider = ({ children }: { children: ReactNode }) => {
  const [pathCreatorCompletion, setPathCreatorCompletion] =
    useState<PathCreatorCompletion>(null)

  return (
    <HomeContext.Provider
      value={{
        setPathCreatorCompletion,
        pathCreatorCompletion
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}
