import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import compose from "lodash/flowRight"
// import { DataValue } from "@apollo/client"
import { useMutation, useQuery } from "@apollo/client"

export const MUX_VIDEOS_QUERY = gql`
  query Videos {
    muxVideos {
      id
      name
      asset {
        id
        status
      }
      upload {
        id
        status
        url
      }
    }
  }
`

export const MUX_VIDEO_EMBED_QUERY = gql`
  query Embed($id: ID!) {
    muxVideoEmbed(id: $id) {
      status
      duration
      download {
        status
        url
      }
      thumb {
        src
        gifSrc
      }
      stream {
        url
        status
        size {
          maxWidth
          maxHeight
          aspectRatio
        }
      }
      audio {
        duration
        status
        maxChannelLayout
        maxChannels
      }
      subtitles
    }
  }
`

export const CREATE_VIDEO_MUTATION = gql`
  mutation CreateVideo($name: String) {
    createMuxVideo(name: $name) {
      id
      name
      upload {
        url
        id
        status
      }
    }
  }
`

export const useCreateVideo = () => {
  const [createVideo] = useMutation(CREATE_VIDEO_MUTATION)
  return createVideo
}

export const SET_MP4_SUPPORT_MUTATION = gql`
  mutation SetMP4Support($id: ID!, $mp4Support: MuxMP4Support!) {
    setMuxVideoMP4Support(id: $id, support: $mp4Support) {
      id
      accountID
      createdByID
      createdDate
      name
      asset {
        id
        status
        mp4_support
        static_renditions {
          status
        }
      }
    }
  }
`

export const useMuxEmbedQuery = ({ id }) => {
  const { data, loading, error, refetch } = useQuery(MUX_VIDEO_EMBED_QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: !id
  })

  return {
    embed: data?.muxVideoEmbed,
    loading,
    error,
    refetch
  }
}

// Turn on ("standard") or off ("none") MP4 support for a Mux video
export const useUpdateVideoMP4 = () => {
  const [setMuxVideoMP4Support] = useMutation(SET_MP4_SUPPORT_MUTATION)
  return (id: string, toggle: boolean) => {
    setMuxVideoMP4Support({
      variables: { id, mp4Support: toggle ? "standard" : "none" }
    })
  }
}

export const DELETE_VIDEO_MUTATION = gql`
  mutation DeleteVideo($id: ID!) {
    deleteMuxVideo(id: $id)
  }
`

export const CHECK_AND_UPDATE_VIDEO_STATUS_MUTATION = gql`
  mutation VideoStatus($assetID: ID!) {
    checkAndUpdateVideoStatus(assetID: $assetID) {
      encoding {
        status
      }
    }
  }
`

export const withVideos = graphql(MUX_VIDEOS_QUERY, { name: "videos" })

export const withDeleteVideo = graphql(DELETE_VIDEO_MUTATION, {
  name: "deleteVideo"
})

export const withCheckAndUpdateStatus = graphql(
  CHECK_AND_UPDATE_VIDEO_STATUS_MUTATION,
  {
    name: "checkAndUpdateStatus"
  }
)

export const SUBTITLE_FRAGMENT = gql`
  fragment subtitleFields on MuxSubtitle {
    src
    closed_captions
    name
    id
    language_code
    format
    active
  }
`

const upsertSubtitleMutation = gql`
  mutation UpsertMuxSubtitle($muxVideoID: ID!, $subtitle: MuxSubtitleInput!) {
    upsertMuxSubtitle(muxVideoID: $muxVideoID, subtitle: $subtitle) {
      ...subtitleFields
    }
  }
  ${SUBTITLE_FRAGMENT}
`

export const withUpsertSubtitle = graphql(upsertSubtitleMutation, {
  name: "upsertSubtitle"
})

const deleteSubtitleMutation = gql`
  mutation DeleteMuxSubtitle($muxVideoID: ID!, $id: ID!) {
    deleteMuxSubtitle(muxVideoID: $muxVideoID, id: $id)
  }
`

export const withDeleteSubtitle = graphql(deleteSubtitleMutation, {
  name: "deleteSubtitle"
})

const subtitlesQuery = gql`
  query SubtitlesQuery($muxVideoID: ID!) {
    muxSubtitles(muxVideoID: $muxVideoID)
  }
`

type SubtitleData = {
  id: string
  name: string
  language_code: string
  format: string
  src: string
  closed_captions: string
  active: boolean
}

type SubtitleProps = {
  subtitles?: SubtitleData[]
  muxVideoID?: string
  data?: {
    muxSubtitles?: Record<string, SubtitleData>
  }
}

export const withSubtitlesQuery = graphql(subtitlesQuery, {
  props: (props: SubtitleProps) => {
    if (!props.data || !props.data.muxSubtitles) return props

    return {
      ...props,
      subtitles: Object.keys(props.data.muxSubtitles).map(
        (id) => props.data?.muxSubtitles?.[id]
      )
    }
  },
  options: ({ muxVideoID }: SubtitleProps) => {
    return {
      variables: { muxVideoID },
      fetchPolicy: "cache-and-network"
    }
  },
  skip: (props: SubtitleProps) =>
    !props.muxVideoID || !!(props.subtitles && props.subtitles.length)
})

export const withSubtitles = compose(
  withSubtitlesQuery,
  withUpsertSubtitle,
  withDeleteSubtitle
)
