import type { TFunction } from "i18next"
import capitalize from "lodash/capitalize"
import get from "lodash/get"
import merge from "lodash/merge"
import uniq from "lodash/uniq"
import isEqual from "react-fast-compare"
import {
  GroupContextFragment,
  PathSyncPlanForceReasonType,
  usePathPublishQuery
} from "../../api/generated"
import useCohortsQuery from "../../cohort/switcher/useCohortsQuery"
import { hasEditorLevelAccess } from "../../group/permissions"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { flattenEdges } from "../../utils/apollo"
import { STEP_RE, getCohortSectionUrl, getUrlValues } from "../../utils/urls"
import {
  useCohortSyncPlanContext,
  useCohortSyncPlanQuery
} from "./SyncPlanContext"
import type {
  Change,
  ChangeSet,
  FromCohort,
  GroupedChanges,
  Path,
  PathItem,
  SyncPlan
} from "./types"

type ChangeKey = keyof Change["change_details"]["changed"]["changed_attributes"]

// get tooltip for change
export const getChangeLabel = (
  change: Change,
  // changes: Change[],
  translator: TFunction
) => {
  const getUpdateLabel = (change: Change) => {
    const getChangedLabels = (change: Change) => {
      const getLabel = (changeKey: ChangeKey) => {
        switch (changeKey) {
          case "name":
            return translator("title")
          case "description":
            return translator("description")
          case "is_required":
            return translator("required setting")
          case "allow_resets":
            return translator("retry setting")
          case "show_grading_feedback":
            return translator("answer display setting")
          case "minimum_passing_score":
          case "userpoints_value":
            return translator("points")
          case "grading_type":
            return translator("completion settings")
          case "is_previewable":
            return translator("preview setting")
          case "lock_password":
            return translator("password setting")
          case "blocks":
            return translator("blocks")
          default:
            return ""
        }
      }

      if (change.change_details?.changed) {
        return Object.keys(change.change_details.changed.changed_attributes)
          .map((key) => getLabel(key))
          .filter((label) => label)
      }

      return []
    }

    const getChangedGradingTypeLabels = (change: Change) => {
      if (change.change_details?.changed_attributes_grading_type) {
        return [translator("completion settings")]
      }
      return []
    }

    const getChangedPointsLabels = (change: Change) => {
      if (change.change_details?.changed_points) {
        return [translator("points")]
      }
      return []
    }

    // Prepend "Edited"
    let changesListLabel = translator("edited")

    // make sure we have no duplicates by using uniq
    const changeItemLabels = uniq([
      ...getChangedLabels(change),
      ...getChangedGradingTypeLabels(change),
      ...getChangedPointsLabels(change)
    ]).join(", ")

    // add space if list is not empty
    changesListLabel += changeItemLabels ? ` ${changeItemLabels}` : ""
    // capitalize first letter
    changesListLabel = capitalize(changesListLabel)

    return changesListLabel
  }

  // const childItemCounts = changes.filter(
  //   (c) => c.parent_source_id === change.item_source_id
  // ).length

  if (change.change_details?.deleted) {
    if (change.item_type === "lesson") {
      // if (childItemCounts) {
      //   return translator("Deleted Lesson with {{ count }} step", {
      //     defaultValue_plural: "Deleted Lesson with {{ count }} steps",
      //     count: childItemCounts
      //   })
      // }
      // catch edge case where no steps exist on lesson (pre simple-sync)
      return translator("Removed Lesson")
    }

    if (change.item_type === "step") {
      return translator("Removed Step")
    }

    if (change.item_type === "divider") {
      return translator("Removed Divider")
    }

    if (change.item_type === "resource") {
      return translator("Removed Path")
    }
  }

  if (change.change_details?.created) {
    if (change.item_type === "lesson") {
      // if (childItemCounts) {
      //   return translator("Created Lesson with {{ count }} step", {
      //     defaultValue_plural: "Created Lesson with {{ count }} steps",
      //     count: childItemCounts
      //   })
      // }
      // catch edge case where no steps exist on lesson (pre simple-sync)
      return translator("Added Lesson")
    }

    if (change.item_type === "step") {
      return translator("Added Step")
    }

    if (change.item_type === "divider") {
      return translator("Added Divider")
    }

    if (change.item_type === "resource") {
      return translator("Added Path")
    }
  }

  return getUpdateLabel(change)
}

// change is syncable if the change is not forced or forced by user request
export const isSyncableChange = (change: Change) =>
  !change.forced ||
  change.forced === "include" ||
  change?.forced_reasons?.includes(
    PathSyncPlanForceReasonType.IncludeUserRequest
  ) ||
  change?.forced_reasons?.includes(
    PathSyncPlanForceReasonType.ExcludeUserRequest
  )

// Considers whether the change *will* be synced.
export const getSyncableChangesCount = (syncPlan?: SyncPlan | null) => {
  const syncable = syncPlan?.groupedChanges?.syncable || []
  // only the included syncable changes count towards the total number of sync changes
  const syncableCount = syncable.filter(
    (change) =>
      isSyncableChange(change) &&
      // (!change.forced || change.forced === "include") &&
      !syncPlan?.excludedChanges?.find(
        (c) => c.item_source_id === change.item_source_id
      )
  ).length

  // Count a single plan-wide change for reordering if it will occur
  if (syncPlan?.willReorder) {
    return syncableCount + 1
  }

  return syncableCount
}

// Considers whether the change *can* be synced (considers user opted out/in changes).
export const getPotentiallySyncableChangesCount = (
  syncPlan?: SyncPlan | null
) => {
  const syncable = syncPlan?.groupedChanges?.syncable || []
  // only the included syncable changes count towards the total number of sync changes
  const syncableCount = syncable.filter(isSyncableChange).length

  // Count a single plan-wide change for reordering if it will occur
  if (syncPlan?.willReorder) {
    return syncableCount + 1
  }

  return syncableCount
}

export const getUnsyncableChangesCount = (syncPlan?: SyncPlan | null) => {
  const changes = get(syncPlan, "groupedChanges", {
    syncable: [],
    ignored: [],
    stepsNoContent: [],
    stepsDraftBlocks: [],
    stepsUnpublishedBlocks: [],
    lessonsNoSteps: [],
    lessonsNoValidSteps: []
  })
  const unsyncableChanges = [
    ...changes.stepsNoContent,
    ...changes.stepsDraftBlocks,
    ...changes.stepsUnpublishedBlocks,
    ...changes.lessonsNoSteps,
    ...changes.lessonsNoValidSteps
  ]

  return unsyncableChanges.length
}

// massage items data
export const groupSyncChanges = (
  syncPlanChanges: Change[],
  path: Path | null,
  isSourcePathEditor: boolean
) => {
  const pathItems = flattenEdges(path?.items) || []

  const userCanEditItemBlocks = (item: PathItem) => {
    return isSourcePathEditor || item.source_id === item.id
  }

  // Blocks changes are not currently included in PathChanges
  // so we generate a client-side Change as needed
  const getChangeFromItem = (item: PathItem): Change => {
    const parentItem = pathItems.find(
      (i: any) => i.source_id === item.parent_source_id
    )

    return {
      item_name: item.name,
      item_source_id: item.source_id!,
      item_type: item.type,
      last_changed: item.blocks?.content_last_modified || undefined,
      parent_name: parentItem?.name || null,
      parent_source_id: item.parent_source_id || null,
      assignment_type: item.assignment_type || null,
      change_details: {}
    }
  }

  const getChildItems = (change: Change) =>
    syncPlanChanges.filter((i) => i.parent_source_id === change.item_source_id)

  const getStepNoContent = (item: PathItem, change?: Change) =>
    userCanEditItemBlocks(item) &&
    item.type === "step" &&
    (!item.content_id ||
      (change?.forced === "exclude" &&
        change?.forced_reasons?.includes(
          PathSyncPlanForceReasonType.ExcludeStepNoContent
        ) &&
        !change.change_details?.deleted))

  const getIgnoredItem = (change: Change) => {
    // ignoring changes on change if change only has one of these changes
    const ignoredItemChanges = ["reorder"]

    const ignoreOne = ignoredItemChanges.find(
      (ignoredChangeType) =>
        change.change_details[ignoredChangeType] &&
        Object.keys(change.change_details).length === 1
    )

    // This check currently exists because of some pre-existing staging Steps
    // that were appearing in the Sync Plan as deletions, but could not be synced.
    const hasNoMatchingItem = !pathItems.find(
      (i: any) => i.source_id === change.item_source_id
    )

    const ignoreDeletedStepWOContent =
      change.forced === "exclude" &&
      change?.forced_reasons?.includes(
        PathSyncPlanForceReasonType.ExcludeStepNoContent
      ) &&
      change.change_details.deleted

    return hasNoMatchingItem || ignoreOne || ignoreDeletedStepWOContent
  }

  const getLessonNoSteps = (change: Change) => {
    const children = pathItems.filter(
      (i: PathItem) => i.parent_source_id === change.item_source_id
    )

    return (
      change.item_type === "lesson" &&
      change.forced === "exclude" &&
      change?.forced_reasons?.includes(
        PathSyncPlanForceReasonType.ExcludeLessonEmpty
      ) &&
      !children.length
    )
  }

  const getStepUnpublishedBlocks = (item: PathItem) => {
    if (!item?.blocks || item.type !== "step" || !userCanEditItemBlocks(item))
      return false

    const neverBeenPublished =
      item.blocks.content_last_modified && !item.blocks.last_published
    return neverBeenPublished

    // TODO: implement this once the backend has the ability to exclude Blocks changes
    // return (
    //   change.forced === "exclude" &&
    //   change?.forced_reasons?.includes("exclude_blocks_unpublished_changes")
    // )
  }

  const getStepDraftBlocks = (item: PathItem) => {
    if (!item?.blocks || item.type !== "step" || !userCanEditItemBlocks(item))
      return false

    const hasDraftChanges =
      item.blocks?.last_published &&
      item.blocks?.content_last_modified &&
      new Date(item.blocks?.content_last_modified) >
        new Date(item.blocks?.last_published)

    return hasDraftChanges

    // TODO: implement this once the backend has the ability to exclude Blocks changes
    // return (
    //   change.forced === "exclude" &&
    //   change?.forced_reasons?.includes("exclude_blocks_draft_changes")
    // )
  }

  // a lesson has no valid steps if the lesson is "empty" and has steps that lack content
  // Thought: should the simple fact that a step exists for an "empty" lesson be proof of the step beinb invalid?
  const getLessonNoValidSteps = (change: Change) => {
    if (change.item_type !== "lesson") return false

    const children = getChildItems(change)

    return (
      children?.length &&
      children.every((change: Change) => {
        const item = pathItems.find(
          (i: any) => i.source_id === change.item_source_id
        )

        const isInvalidStep =
          !!item &&
          (getStepNoContent(item, change) ||
            getStepDraftBlocks(item) ||
            getStepUnpublishedBlocks(item))

        return isInvalidStep
      })
    )
  }

  const deletionChanges = syncPlanChanges.filter(
    (change) => change.change_details.deleted
  )

  // We need to check all path items (not just PathChanges),
  //  since the backend doesn't create PathChanges (yet) for Blocks changes
  //  note: the backend Sync Plan generator ensures that there is never more than one PathChange per PathItem
  const pathItemChanges = pathItems.reduce(
    (map: GroupedChanges, item: PathItem) => {
      let change = syncPlanChanges.find(
        (c) => c.item_source_id === item.source_id
      )

      const getParentItem = (item: PathItem) =>
        pathItems.find((i: any) => i.source_id === item.parent_source_id)

      const mergeChange = (key: keyof ChangeSet, reason: Change["reason"]) => ({
        ...map,
        // reason key is added for client-only purposes
        [key]: [
          ...map[key],
          {
            ...change,
            reason,
            item_order: item.order,
            // Since item_order represents a Step's order within a Lesson,
            // we must also include the parent_item_order to allow for sorting Steps in the ChangeList
            parent_item_order: getParentItem(item)?.order
          }
        ] as Partial<ChangeSet>
      })

      // group all steps needing content
      if (getStepNoContent(item, change)) {
        if (!change) {
          change = getChangeFromItem(item)!
        }
        change = merge(change, {
          change_details: {
            changed: {
              changed_attributes: {
                blocks: "no_content"
              }
            }
          },
          forced: "exclude",
          forced_reasons: [PathSyncPlanForceReasonType.ExcludeStepNoContent]
        })
        return mergeChange("stepsNoContent", "step_no_content")
      }

      // group all steps with blocks that have never been published
      if (getStepUnpublishedBlocks(item)) {
        if (!change) {
          change = getChangeFromItem(item)
        }
        change = merge(change, {
          change_details: {
            changed: {
              changed_attributes: {
                blocks: "unpublished"
              }
            }
          },
          // The client currently handles Blocks changes as forced excluded
          // until the backend has the ability to exclude them.
          forced: "exclude",
          forced_reasons: ["exclude_blocks_unpublished_changes"]
        })
        return mergeChange("stepsUnpublishedBlocks", "step_unpublished_blocks")
      }

      // group all steps with draft blocks
      if (getStepDraftBlocks(item)) {
        if (!change) {
          change = getChangeFromItem(item)
        }
        change = merge(change, {
          change_details: {
            changed: {
              changed_attributes: {
                blocks: "draft"
              }
            }
          },
          // The client currently handles Blocks changes as forced excluded
          // until the backend has the ability to exclude them.
          forced: "exclude",
          forced_reasons: ["exclude_blocks_draft_changes"]
        })
        return mergeChange("stepsDraftBlocks", "step_draft_blocks")
      }

      // group all ignored items
      if (change && getIgnoredItem(change)) {
        return mergeChange("ignored", "ignored")
      }

      // group all lessons lacking steps
      if (change && getLessonNoSteps(change)) {
        return mergeChange("lessonsNoSteps", "lesson_no_steps")
      }

      // group all lessons lacking valid steps
      if (change && getLessonNoValidSteps(change)) {
        return mergeChange("lessonsNoValidSteps", "lesson_no_valid_steps")
      }

      return change ? mergeChange("syncable", "syncable") : map
    },
    {
      // NOTE: syncable not quite clear as there could potentially be
      // forced excluded changes in this list, though it seems the current
      // pattern is to handle forced excluded separately (i.e. stepsLackingContent and lessonsLackingSteps)
      syncable: [],
      ignored: [],
      stepsNoContent: [],
      stepsDraftBlocks: [],
      stepsUnpublishedBlocks: [],
      lessonsNoSteps: [],
      lessonsNoValidSteps: []
    } as GroupedChanges
  )

  // We have to merge deletions into the pathItemChanges
  // Why? Because deletion changes obviously don't have a corresponding PathItem in the Path
  const changes = {
    ...pathItemChanges,
    // assume all deletions are syncable
    syncable: [
      ...pathItemChanges.syncable,
      ...deletionChanges.map((change) => ({
        ...change,
        reason: "deleted"
      }))
    ]
  }

  // Path items also reference the syncable list, so we must included
  // all the reordered changes in order to display certain publish states
  // on path items. We must be sure to reduce the total count of reordered
  // changes to 1 when aggregating the total change count.
  // syncPlanChanges.forEach((change) => {
  //   if (
  //     change.change_details.reorder &&
  //     // Only include if not already included (don't duplicate).
  //     !hasChange(changes.syncable, change)
  //   ) {
  //     changes.syncable.push(change)
  //   }
  // })

  return changes
}

// Filter out syncable changes that already exist in the changes list.
export function hasChange(changes: Change[], change: Change) {
  return changes.some((targetChange) => {
    return (
      targetChange.item_source_id === change.item_source_id &&
      // NOTE: we can't simply compare referential equality due to client
      // mutating the changes data (adding "reason" and "item_order" keys).
      isEqual(targetChange.change_details, change.change_details)
    )
  })
}

// Prevent syncing from the source cohort when there are no cohorts
// AND no license offerings (allowing syncing even when there are no
// cohorts yet the resource is licened – either via school licensing
// or group licensing).
export const syncFromSourceCohortPrevented = (cohort?: FromCohort | null) =>
  Boolean(
    cohort &&
      cohort.is_master &&
      cohort.resource?.cohorts?.total === 0 &&
      cohort.resource?.license_offerings?.total === 0
  )

// This and the following function allow the Source Path Blank Slate states to be displayed
export const isBlankSourceWith1Cohort = ({
  isEmptyPath,
  numCohorts,
  singleCohortPath
}: {
  isEmptyPath?: boolean
  numCohorts?: number
  singleCohortPath?: Path
}): boolean | undefined => {
  return (
    isEmptyPath &&
    numCohorts === 1 &&
    !!flattenEdges(singleCohortPath?.items)?.find(
      (item: any) => item.type === "step"
    )
  )
}

export const isBlankSourceWithNoCohorts = ({
  isEmptySource,
  numCohorts,
  singleCohortPath
}: {
  isEmptySource?: boolean
  numCohorts?: number
  singleCohortPath?: Path
}): boolean | undefined => {
  return (
    isEmptySource &&
    (numCohorts === 0 ||
      !flattenEdges(singleCohortPath?.items)?.find(
        (item: any) => item.type === "step"
      ))
  )
}

export const needsSourcePathSetup = ({
  numCohorts,
  sourcePathItemsLength
}: {
  numCohorts?: number
  sourcePathItemsLength?: number
}): boolean | undefined => {
  return numCohorts === 1 && sourcePathItemsLength === 0
}

export const getSyncUrl = () => {
  const { stepSourceId } = getUrlValues(STEP_RE) as { stepSourceId: string }
  if (stepSourceId) return getCohortSectionUrl(`/step/${stepSourceId}/sync/`)
  return getCohortSectionUrl("/sync/")
}

// User can design the source path.
export const useCanDesignSourcePath = ({ cohortId }: { cohortId?: number }) => {
  const { fromCohort } = useCohortSyncPlanQuery({
    cohortId
  })
  const pwContext = usePathwrightContext()
  return hasEditorLevelAccess(pwContext, fromCohort as GroupContextFragment)
}

// Determine whether a given path is published.
// The source path is the path from which the provided path syncs.
// Note: we can't rely on a Cohort path's sync.last_synced_date to
// accurately reflect whether the Source Path has been published.
export const usePathHasBeenPublished = ({
  cohortId
}: {
  cohortId?: number
}) => {
  const pathPublishQuery = usePathPublishQuery({
    variables: { cohort_id: cohortId },
    skip: !cohortId
  })

  const sourcePathIsPublished =
    !!pathPublishQuery?.data?.path?.publish?.last_published_date

  return sourcePathIsPublished
}

// Rules for showing Back Sync options (ex: back sync icons on Step indicators, sync direction toggle on Sync Card)
// 1. User must have Design access
// 2. There are 2+ cohorts OR Licensing is enabled OR Source Path has been published
export const useShowSyncOption = ({ cohortId }: { cohortId: number }) => {
  const { fromCohort, toCohort } = useCohortSyncPlanQuery({
    cohortId
  })

  const isSourcePath = fromCohort?.is_master

  const { fromPath } = useCohortSyncPlanContext({
    cohortId
  })

  // toCohort is only defined when on a Cohort, syncing to Source
  const sourcePathIsPublished = usePathHasBeenPublished({
    cohortId: toCohort?.id
  })

  const canDesign = useCanDesignSourcePath({
    cohortId
  })

  if (!fromCohort || !fromPath || isSourcePath) return false

  return (
    canDesign &&
    ((fromCohort.resource?.cohorts?.total || 0) > 1 ||
      fromCohort.resource?.licensing_enabled ||
      sourcePathIsPublished)
  )
}

// We show (expand) Sync card tips ("More about publishing", etc.) when:
// 1. There is only one cohort AND
// 2. The current path has not been published
export const useShouldShowTips = ({
  resourceId,
  cohortId,
  sourceCohortId
}: {
  resourceId?: number
  cohortId?: number
  sourceCohortId?: number
}): boolean => {
  const { cohorts } = useCohortsQuery({
    resourceId
  } as any)

  const { data: cohortPathPublishData } = usePathPublishQuery({
    variables: { cohort_id: cohortId },
    skip: !cohortId
  })

  const isCohortPathPublished =
    !!cohortPathPublishData?.path?.publish?.last_published_date

  const { data: sourcePathPublishData } = usePathPublishQuery({
    variables: { cohort_id: sourceCohortId },
    skip: !sourceCohortId || sourceCohortId === cohortId
  })

  const isSourcePathPublished =
    !!sourcePathPublishData?.path?.publish?.last_published_date

  return (
    cohorts?.length === 1 && (!isCohortPathPublished || !isSourcePathPublished)
  )
}
