import { Box, HStack, StackProps, UseDisclosureReturn } from "@chakra-ui/react"
import classnames from "classnames"
import { ReactNode, useMemo } from "react"
import { InPortal, OutPortal, createHtmlPortalNode } from "react-reverse-portal"
import { useMeasure } from "react-use"
import AugmentationPanel from "../augmentation/AugmentationPanel"

// TODO: considering conditionally rendering the Toolbar as children
// just as the primary and augmentation components are.

type AugmentationLayoutProps = {
  // Either provide [Primary, Augmentation] or [Primary, Toolbar, Augmentation]
  children: [ReactNode, ReactNode] | [ReactNode, ReactNode, ReactNode]
  // Allow the augmentation panel to completely overlay the primary view at smaller screen sizes.
  allowOverlay?: boolean
  allowClose?: boolean
  innerGutter?: number
  outerGutter?: number
  disclosure: UseDisclosureReturn
} & StackProps

// Handle min width for primary view, default to 0, but if positive than don't allow
// primary view to shrink below that width. Also, restrict augmentation to a max width in that case,
// that is, don't allow to overlay whole screen.

// To assess if Augmentation can fit in inline, we must calculate
// the 1) Container size minus the 2) Primary size (min of Primary size and minimum size).
// If the remaining width (+ gutters) is <= the minimum Augmentation size, then can inline.

const AugmentationLayout = ({
  children,
  allowOverlay,
  allowClose,
  innerGutter,
  outerGutter,
  disclosure,
  ...stackProps
}: AugmentationLayoutProps) => {
  const [layoutRef, layoutSize] = useMeasure<HTMLDivElement>()
  const [primaryRef, primarySize] = useMeasure<HTMLDivElement>()
  const [augmentationRef, augmentationSize] = useMeasure<HTMLDivElement>()
  const [panelRef, panelSize] = useMeasure<HTMLDivElement>()
  const [toolbarRef, toolbarSize] = useMeasure<HTMLDivElement>()

  // Default the innter and outer gutter sizes when not provided.
  innerGutter ??= 10
  outerGutter ??= 10

  // Assess what (if any) remaining width there is for inlining the augmentation.
  const remainingWidth =
    layoutSize.width -
    primarySize.width -
    outerGutter * 2 -
    innerGutter * 2 -
    toolbarSize.width

  const augmentationWidth = panelSize.width ?? augmentationSize.width

  // We can inline the augmentation if there is enough remaining width to afford the
  // min augmentation width.
  const canInlineAugmentation = remainingWidth >= augmentationWidth

  //
  // TODO: If we cannot inline the panel, than we handle the floating panel layout which depends on
  // a icon panel to launch the panel, which needs to be positioned absolutely adjacent to the primary view.

  const augmentationPortalNode = useMemo(
    () =>
      createHtmlPortalNode({
        attributes: {
          class: classnames("AugmentationLayout__Augmentation", {
            ["AugmentationLayout__Augmentation-inline"]: canInlineAugmentation
          }),
          style: "height: 100%;"
        }
      }),
    [canInlineAugmentation]
  )

  const augmentationInPortal = (
    <InPortal node={augmentationPortalNode}>
      <Box
        ref={augmentationRef}
        className="AugmentationLayout__Augmentation-background"
        h="100%"
        bg="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(10px)"
        borderRadius="20px"
        overflow="auto"
      >
        {children.slice(-1)[0]}
      </Box>
    </InPortal>
  )
  const augmentationOutPortal = <OutPortal node={augmentationPortalNode} />

  const augmentation = canInlineAugmentation ? (
    //   <Collapse in={disclosure.isOpen && !!toolbar.selectedKey}>
    //     {augmentationOutPortal}
    //   </Collapse>
    augmentationOutPortal
  ) : (
    <AugmentationPanel
      ref={panelRef}
      disclosure={disclosure}
      allowOverlay={allowOverlay}
      allowClose={allowClose}
    >
      {augmentationOutPortal}
    </AugmentationPanel>
  )

  const canInlineToolbar = children.length === 3 && !disclosure.isOpen

  return (
    <>
      {augmentationInPortal}
      <HStack
        ref={layoutRef}
        className="AugmentationLayout"
        position="relative"
        justifyContent="center"
        alignItems="flex-start"
        maxW="100%"
        spacing={`${innerGutter}px`}
        {...stackProps}
      >
        <Box ref={primaryRef} className="AugmentationLayout__Primary">
          {children[0]}
        </Box>
        {canInlineToolbar && (
          <Box ref={toolbarRef} className="AugmentationLayout__Toolbar">
            {children[1]}
          </Box>
        )}
        {!!disclosure.isOpen && augmentation}
      </HStack>
    </>
  )
}

export default AugmentationLayout
