import {
  Box,
  BoxProps,
  ButtonProps,
  DarkMode,
  InputProps,
  SpinnerProps,
  TextProps
} from "@chakra-ui/react"
import classNames from "classnames"

type BGModeProps = {
  exclusions?: string[]
  text?: TextProps
  input?: InputProps
  button?: ButtonProps
  spinner?: SpinnerProps
} & BoxProps

function BGMode({
  exclusions,
  text,
  input,
  button,
  spinner,
  ...props
}: BGModeProps) {
  const textStyles = {
    color: "white",
    ...text
  }

  const inputStyles: InputProps = {
    bg: "white !important",
    _placeholder: { color: "gray.500" },
    ...input
  }

  const inputPlaceholderStyles = {
    _placeholder: { color: "gray.500" },
    ...input?._placeholder
  }

  const buttonStyles: ButtonProps = {
    ...button,
    _disabled: {
      color: "whiteAlpha.500",
      opacity: 0.6,
      cursor: "not-allowed",
      ...button?._disabled
    }
  }

  const spinnerStyles: SpinnerProps = {
    borderTopColor: "white",
    borderRightColor: "white",
    ...spinner
  }

  let sx: NonNullable<BoxProps["sx"]> = {
    ...props.sx,
    // NOTE: using classnames instead of tag names since, at least with "p",
    // when using tag names it seems to affect other styling.

    // textStyles
    [`[data-theme="dark"].chakra-heading`]: textStyles,
    [`[data-theme="dark"].chakra-text`]: textStyles,
    [`[data-theme="dark"].chakra-form__label`]: textStyles,
    [`[data-theme="dark"].chakra-form__error-message`]: textStyles,
    [`[data-theme="dark"].chakra-input__right-addon`]: textStyles,
    [`[data-theme="dark"].chakra-checkbox__label`]: textStyles,
    [`[data-theme="dark"].chakra-icon`]: textStyles,

    // inputStyles
    [`[data-theme="dark"].chakra-input`]: inputStyles,
    [`input[data-theme="dark"][id^="field-:r"]`]: inputStyles,
    [`input[data-theme="dark"]:-internal-autofill-previewed`]: inputStyles,
    [`input[data-theme="dark"]:-internal-autofill-selected`]: inputStyles,

    // inputPlaceholderStyles
    // Found in chakra-react-select.
    [`[data-theme="dark"][id$="-placeholder"]`]: inputPlaceholderStyles,

    // buttonStyles
    [`button[data-theme="dark"].chakra-button`]: buttonStyles,

    // Enabled button's icon styles.
    [`button[data-theme="dark"].chakra-button:not(:disabled) [data-theme="dark"].chakra-button__icon,
      button[data-theme="dark"].chakra-button:not(:disabled) [data-theme="dark"].chakra-button__icon [data-theme="dark"].chakra-icon`]:
      {
        color: "gray.800"
      },

    // Disabled button's icon styles.
    [`button[data-theme="dark"].chakra-button:disabled [data-theme="dark"].chakra-button__icon,
        button[data-theme="dark"].chakra-button:disabled [data-theme="dark"].chakra-button__icon [data-theme="dark"].chakra-icon`]:
      {
        color: buttonStyles?._disabled?.color || "whiteAlpha.500"
      },
    [`[data-theme="dark"].chakra-spinner`]: spinnerStyles
  }

  // Exclude the override styles from these selectors and their descendents.
  if (exclusions) {
    sx = {
      [`*${exclusions.map((selector) => `:not(${selector} *)`).join("")}`]: sx
    }
  }

  return (
    <DarkMode>
      <Box
        {...props}
        className={classNames("chakra-ui-reset-css", props.className)}
        sx={sx}
      />
    </DarkMode>
  )
}

export default BGMode
