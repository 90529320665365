import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import View from "@pathwright/ui/src/components/ui/View"
import ProductCheckboxes, {
  useProducts
} from "@pathwright/web/src/modules/stripe/product/ProductCheckboxes"
import { ConnectForm } from "lib/core/components/forms"
import { ConnectStores } from "lib/core/store"

const fieldsData = {
  include_with_products: {
    // label: "Products"
  }
}

const ManageLicenseProductsForm = (props) => {
  const { fieldsData, isValid, handleSubmit } = props
  const { products } = useProducts({ includeInactive: true })

  return (
    <form className="ManageLicenseProductsForm" onSubmit={handleSubmit}>
      <SubmitButton
        {...props}
        disabled={!isValid}
        styleType="primary"
        type="submit"
      >
        Save
      </SubmitButton>
      {products && products.length > 0 && (
        <View paddingRight paddingLeft>
          <ProductCheckboxes
            {...fieldsData.include_with_products}
            selectedProductIds={fieldsData.include_with_products.value}
            includeInactive
          />
        </View>
      )}
    </form>
  )
}

ManageLicenseProductsForm.displayName = "ManageLicenseProductsForm"

const mapStateToProps = (state, ownProps) => {
  const { saveLicenseOffering } = state.resourceLicenseAdminStore.action
  const { license_offering } = state.resourceLicenseAdminStore.state

  const fieldValues = {
    include_with_products:
      license_offering && _.compact(license_offering.include_with_products)
  }

  const onSubmit = (fieldsData, resetForm) => {
    const licenseData = {
      include_with_products: fieldsData.include_with_products.value
    }
    return saveLicenseOffering(licenseData)
  }

  return _.assign(
    {},
    {
      onSubmit,
      fieldValues
    },
    ownProps
  )
}

const stores = [
  { store: "resourceLicenseAdmin", watch: ["license_offering"] },
  { store: "resourceAdmin", watch: ["resource_saving"] }
]

export default ConnectStores(
  ConnectForm(ManageLicenseProductsForm, fieldsData),
  stores,
  {},
  mapStateToProps
)
