// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
import { insertTryCatch } from "@pathwright/ui/src/components/embed/utils"
import { getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import { getSchoolUrl } from "@pathwright/web/src/modules/utils/urls"
import { PAGE_TYPE_HOME } from "./constants"
const School = require("./models/school").default
const SchoolSubscriptionPlanCollection =
  require("account/subscription/models/school-subscription-plan-collection").default
const { injectVariable } = require("lib/core/utils/variables")

const { bootstrapSchool } = require("lib/core/boot/bootstrap")

class SchoolStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    // email, phone, etc. . .

    this.prototype.storeTriggers = {
      "bootstrapped:school"(data) {
        return data
      }
    }

    this.prototype.actions = {
      bootstrapSchool(callback) {
        return bootstrapSchool(getSchoolUrl()).then((bootstrappedData) => {
          this.bootstrapStore(bootstrappedData)
          if (callback) {
            callback(bootstrappedData)
          }
          return this.storeTrigger("bootstrapped:school", bootstrappedData)
        })
      }
    }

    this.prototype.requests = {
      truncatedName() {
        const { school } = this.getState()
        let words = school.name.split(/  */)
        let spaces = school.name.match(/  */g) || []
        let name = ""
        for (let i = 0; i < words.length; i++) {
          name = `${name}${spaces[i - 1] || ""}${words[i]}`

          if (name.length >= 30 && name != school.name) {
            name = `${name}...`
            break
          }
        }
        return name
      },

      integration(key) {
        return this.get("integrations")[key]
      },

      hasSubscriptionPlans() {
        return this.get("subscription_plans")?.length
      },

      checkConnectedAccounts() {
        const { social } = this.getState()
        return Object.values(social).filter((v) => v)
      },

      // adding support url as a page
      getPages() {
        const pages = this.get("media")
          .pages.slice()
          .filter((p) => p.is_live)
        if (this.get("support").has_faqs) {
          pages.push({
            url: this.get("support").support_link || `/support/`,
            name: "FAQs",
            show_in_nav: true
          })
        }
        return pages
      },

      getPageForURL(url) {
        const isRegExp = typeof url == "object"
        return this.get("media").pages.find(
          (p) =>
            (isRegExp ? url.test(p.url) : p.url === url) ||
            ((isRegExp ? url.test("/") : url == "/") &&
              p.page_type == PAGE_TYPE_HOME)
        )
      }
    }
  }

  defaults() {
    return {
      old_school: null,
      school: null, // the current school
      subscription_plans: null, // set if the school offers subscriptions
      integrations: null, // school integrations (google_analytics, etc. . .)
      security: null, // terms, policy, csrf_token
      social: null, // social app usernames
      support: null,
      page: null,
      features: {
        auth__platform: false
      }
    }
  }

  start() {
    // legacy
    return window.App.school != null
      ? window.App.school
      : (App.school = this.get("school"))
  }

  initializeStore() {
    // backend is responsible for inject head and script markup,
    // so need to do this on the client for local host
    if (getIsLocalHost()) this.boostrapSchoolHeadAndScriptMarkup()
  }

  boostrapSchoolHeadAndScriptMarkup() {
    const { head_markup, script_markup } = bootstrappedData.customizations
    // We are including the head markup in the source html file
    $("head").append(injectVariable(insertTryCatch(head_markup.join(""))))
    $("body").append(injectVariable(insertTryCatch(script_markup.join(""))))
  }

  bootstrapStore(d) {
    let school
    if ((d != null ? d.school : undefined) != null) {
      school = new School(d.school)
    } else if (App.school != null) {
      ;({ school } = App)
    } else {
      throw new Error("School not found")
    }
    const subscription_plans = new SchoolSubscriptionPlanCollection(
      d.commerce.subscription_plans
    )
    const {
      integrations,
      security,
      social,
      media,
      support,
      permissions,
      commerce
    } = bootstrappedData
    this._mutate({
      school,
      subscription_plans,
      integrations,
      security,
      social,
      media,
      support,
      permissions,
      commerce
    })
    // Forcing support and analytics stores to initialize!
    return _.defer(() => {
      // defer enables analytics store to properly listen to school store
      return this.getStore("analytics")
    })
  }
}
SchoolStore.initClass()

export default window.App.stores.registerStore("school", SchoolStore)
