import {
  Box,
  Button,
  Collapse,
  VStack,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { useMeasure } from "react-use"
import { HomeRegistrationFragment } from "../../../api/generated"
import Pathicon from "../../../pathicon/Pathicon"
import { getResourceLink } from "../../utils"
import PathCard from "./PathCard"

type PathCardStackProps = {
  registration: HomeRegistrationFragment
  nestedRegistrations: HomeRegistrationFragment[]
}

function PathCardStack({
  registration,
  nestedRegistrations
}: PathCardStackProps) {
  // NOTE: currently constraining the height of the scrollable stack to
  // the first 2 elements results in some jank in relation to the Collapse
  // component which only opens part way then to the max height, taking
  // some time to do so. Likely causing too much degraded UX to leave as is.
  const [sizeRef, size] = useMeasure<HTMLDivElement>()

  // Setting scrollable container to be max height of first two items.
  const maxH = size.height ? `${size.height}px` : 0

  return (
    <VStack w="100%">
      <VStack w="100%" maxH={maxH} overflow="auto">
        <VStack ref={sizeRef} w="100%">
          {nestedRegistrations.slice(0, 2).map((registration, i) => (
            <PathCard key={registration.id} registration={registration} />
          ))}
        </VStack>
        {nestedRegistrations.slice(2).map((registration, i) => (
          <PathCard key={registration.id} registration={registration} />
        ))}
      </VStack>
      <Button
        as={ReactRouterLink}
        to={getResourceLink(registration)}
        colorScheme="whiteAlpha"
        rightIcon={<Pathicon icon="chevron-right" />}
        cursor="pointer"
        w="100%"
      >
        View all
      </Button>
    </VStack>
  )
}

type PathCardContainerProps = {
  registration: HomeRegistrationFragment
  nestedRegistrations: HomeRegistrationFragment[]
}

function ExpandablePathCard({
  registration,
  nestedRegistrations
}: PathCardContainerProps) {
  const ref = useRef<HTMLDivElement>(null)
  const { isOpen, onOpen, onClose, getButtonProps, getDisclosureProps } =
    useDisclosure()

  // Determine if discolosure was opened via hover, and if so,
  // we can allow for closing the disclosure on mouse leave.
  const [openedViaHover, setOpenedViaHover] = useState(false)
  useEffect(() => {
    if (!isOpen) setOpenedViaHover(false)
  }, [isOpen])

  // Close expanded PathCardStack when clicking outside.
  useOutsideClick({
    ref,
    handler: onClose
  })

  return (
    <Box pos="relative" w="100%" p={isOpen ? 2 : 0}>
      {isOpen && (
        <Box pos="absolute" inset={0} bg="blackAlpha.400" borderRadius={25} />
      )}
      <VStack
        ref={ref}
        w="100%"
        pos="relative"
        zIndex={1}
        {...getButtonProps()}
        onClick={(e) => {
          if (!isOpen) {
            // Prevent navigation, open top Cohort.
            // e.preventDefault()
            e.stopPropagation()
            onOpen()
          }
        }}
        onMouseOver={() => {
          if (!isOpen) {
            onOpen()
            setOpenedViaHover(true)
          }
        }}
        onMouseLeave={() => {
          if (openedViaHover) {
            onClose()
          }
        }}
      >
        {/* Alternate approach to showing nested registrations when collapsed,
      which is not working, but will polish if we go this route. */}
        {/* {!isOpen && (
        <VStack w="100%" spacing={0} pos="absolute" inset={0}>
          {nestedRegistrations.map((registration, i) => (
            <Box
              key={registration.id}
              transform={`translateY(calc(${-5 * (i + 1)}px))`}
              w="100%"
              opacity={0.8}
            >
              <PathCard registration={registration} />
            </Box>
          ))}
        </VStack>
      )} */}
        <PathCard
          registration={registration}
          nestedRegistrations={nestedRegistrations}
        />
        <Collapse
          in={isOpen}
          // NOTE: using unmountOnExit to prevent some inconsistency. If you collapse
          // and reopen to quickly, the Collapse component may not open again.
          unmountOnExit
          {...getDisclosureProps()}
          // Seems Collapse doesn't support standard style props...
          style={{
            width: "100%"
          }}
        >
          <PathCardStack
            registration={registration}
            nestedRegistrations={nestedRegistrations}
          />
        </Collapse>
      </VStack>
    </Box>
  )
}

function PathCardContainer({
  registration,
  nestedRegistrations
}: PathCardContainerProps) {
  return nestedRegistrations.length ? (
    <ExpandablePathCard
      registration={registration}
      nestedRegistrations={nestedRegistrations}
    />
  ) : (
    <PathCard registration={registration} />
  )
}

export default PathCardContainer
