import LazyAdaptedAuthContainer from "../auth/LazyAdaptedAuthContainer"

const SignupAccountForm = ({ onNext }) => {
  return (
    <LazyAdaptedAuthContainer
      onComplete={onNext}
      authWrapperProps={{ bg: "background", maxW: "500px", m: "auto" }}
      createMembership={false}
    />
  )
}

SignupAccountForm.displayName = "SignupAccountForm"

export default SignupAccountForm
