import Card from "@pathwright/ui/src/components/card/Card"
import GroupAccessFormContainer from "./GroupAccessFormContainer"

const GroupAccessCard = ({ card }) => (
  <Card card={card} title="Availability">
    <GroupAccessFormContainer
      onPristine={() => card.setCardDirty(false)}
      onDirty={() => card.setCardDirty(true)}
    />
  </Card>
)

GroupAccessCard.displayName = "GroupAccessCard"

export default GroupAccessCard
