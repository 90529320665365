import { Box } from "@chakra-ui/react"
import React from "react"
import RangedControl from "./RangedControl"

const VolumeUI = ({ vertical, children }) => (
  <Box
    padding={"12px 2px"}
    width={vertical ? "auto" : "75px"}
    height={vertical ? "75px" : "auto"}
    margin={"0px auto"}
  >
    {children}
  </Box>
)

const Volume = ({ currentVolume, onSetVolume, vertical = false }) => (
  <VolumeUI vertical={vertical}>
    <RangedControl
      aria-label="Volume"
      vertical={vertical}
      value={currentVolume * 100}
      onSeek={onSetVolume}
      updateOnScrub={true}
    />
  </VolumeUI>
)

export default Volume
