//! Mutations based on modules/home/teach/item/GroupNotificationsForm.js
import { useMutation } from "@apollo/client"
import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Switch,
  Text,
  VStack,
  useDisclosure
} from "@chakra-ui/react"
import { IconName } from "@pathwright/pathicons"
import { Field, Form, Formik } from "formik"
import { HomeRegistrationFragment } from "modules/api/generated"
import { ChangeEvent, useEffect } from "react"
import Pathicon from "../../pathicon/Pathicon"
import UPDATE_REGISTRATION_MUTATION from "../../registration/graphql/update-registration-mutation"

const GroupNotificationsFormOption = ({
  registration
}: {
  registration: HomeRegistrationFragment
}) => {
  const discussion = !!registration.send_discussion_notifications
  const dates = !!registration.send_due_notifications

  const [mutation] = useMutation(UPDATE_REGISTRATION_MUTATION)
  // Primary switch is on when all secondary switches are on.
  const primarySwitchOn = discussion && dates
  // Sencondary switches are shown by force when they aren't
  // all set to the same value.
  const forceShowSecondarySwithces = discussion !== dates

  const { isOpen, onOpen, getDisclosureProps, getButtonProps } = useDisclosure()

  // Force the collapsed disclosure open when we must show the secondary switches.
  useEffect(() => {
    if (forceShowSecondarySwithces && !isOpen) onOpen()
  }, [forceShowSecondarySwithces])

  // Options for the switches.
  const switchOptions: Record<string, { icon: IconName; label: string }> = {
    discussion: {
      icon: "chat-bubbles",
      label: "Discussions"
    },
    dates: {
      icon: "calendar",
      label: "Date reminders"
    }
  }

  async function handleSubmit(values: { discussion: boolean; dates: boolean }) {
    const changes = {
      id: registration.id,
      send_discussion_notifications: !!values.discussion,
      send_due_notifications: !!values.dates
    }

    await mutation({
      variables: changes,
      optimisticResponse: {
        updateRegistration: {
          ...changes,
          __typename: "Registration"
        }
      }
    })
  }

  return (
    <>
      <HStack
        align="center"
        justify="space-between"
        w="100%"
        pointerEvents="auto"
        minW={200}
        py={1.5}
        px={2}
        color="gray.500"
      >
        <Flex align="center" gap={2}>
          <Pathicon icon="bell" />
          <Text fontSize="xs" my={0} fontWeight="normal">
            Get Notifications
          </Text>
        </Flex>
        {!forceShowSecondarySwithces && (
          <Flex align="center" h={18}>
            {!isOpen && (
              <Switch
                colorScheme="brand"
                id="notifications"
                isChecked={primarySwitchOn}
                onChange={() =>
                  handleSubmit({
                    discussion: !primarySwitchOn,
                    dates: !primarySwitchOn
                  })
                }
              />
            )}
            <IconButton
              {...getButtonProps()}
              variant="ghost"
              textDecor="none"
              cursor="pointer"
              ml={2}
              h={8}
              minW={8}
              isRound
            >
              <Pathicon
                color="gray.500"
                icon={isOpen ? "chevron-down" : "chevron-right"}
              />
            </IconButton>
          </Flex>
        )}
      </HStack>
      <Box w="100%">
        <Collapse in={isOpen} animateOpacity>
          <Formik
            initialValues={{
              discussion,
              dates
            }}
            onSubmit={handleSubmit}
          >
            {(form) => (
              <Form>
                <VStack align="start" gap={0} w="100%" bg="blackAlpha.100">
                  {(
                    Object.keys(form.values) as (keyof typeof form.values)[]
                  ).map((key) => (
                    <FormLabel
                      key={key}
                      htmlFor={key}
                      m={0}
                      w="100%"
                      cursor="pointer"
                      _hover={{
                        textDecoration: "none",
                        bg: "blackAlpha.200"
                      }}
                    >
                      <FormControl
                        as={HStack}
                        align="center"
                        justify="space-between"
                        w="100%"
                        py={1.5}
                        px={2}
                        pl={5}
                        color="gray.500"
                      >
                        <HStack align="center">
                          <Pathicon icon={switchOptions[key].icon} />
                          <Text fontSize="xs" my={0} fontWeight="normal">
                            {switchOptions[key].label}
                          </Text>
                        </HStack>
                        <Field
                          as={Switch}
                          name={key}
                          id={key}
                          colorScheme="brand"
                          isChecked={form.values[key]}
                          onChange={async (
                            e: ChangeEvent<HTMLInputElement>
                          ) => {
                            await form.setFieldValue(key, e.target.checked)
                            await form.submitForm()
                          }}
                        />
                      </FormControl>
                    </FormLabel>
                  ))}
                </VStack>
              </Form>
            )}
          </Formik>
        </Collapse>
      </Box>
    </>
  )
}

export default GroupNotificationsFormOption
