import { Box } from "@chakra-ui/react"
import { Togglei18nKeys } from "@pathwright/ui/src/components"
import AlertBanner from "@pathwright/ui/src/components/alert/AlertBanner"
import PopupAlert from "@pathwright/ui/src/components/alert/PopupAlert"
import ErrorBoundary from "@pathwright/ui/src/components/error/ErrorBoundary"
import SentryError from "@pathwright/ui/src/components/error/SentryError"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ObserveSizeContext, {
  ObserveSizeProvider
} from "@pathwright/ui/src/components/observers/ObserveSizeContext"
import DockPanel from "@pathwright/ui/src/components/panel/DockPanel"
import ScrollView from "@pathwright/ui/src/components/scroll/ScrollView"
import PathwrightUI from "@pathwright/ui/src/components/ui/PathwrightUI"
import Screensize from "@pathwright/ui/src/components/ui/Screensize"
import useRefreshToken from "@pathwright/web/src/modules/auth/useRefreshToken"
import { BackgroundTaskContextProvider } from "@pathwright/web/src/modules/bg-task/BackgroundTaskContext"
import StripeAlert from "@pathwright/web/src/modules/commerce/manage/stripe/StripeAlert"
import CurrencyFormatterProvider from "@pathwright/web/src/modules/currency/CurrencyFormatterProvider"
import { FirebaseProvider } from "@pathwright/web/src/modules/firebase/utils"
import IntercomProvider, {
  useIntercomContext
} from "@pathwright/web/src/modules/intercom/IntercomProvider"
import ErrorParamAlert from "@pathwright/web/src/modules/lib/ErrorParamAlert"
import useHeartbeat from "@pathwright/web/src/modules/lib/useHeartbeat"
import I18nextProvider from "@pathwright/web/src/modules/lng/I18nextProvider"
import {
  ModalContextProvider,
  ModalPortal
} from "@pathwright/web/src/modules/modal"
import { NotificationsProvider } from "@pathwright/web/src/modules/notifications/state/notification-state"
import GlobalSchoolThemeStyles from "@pathwright/web/src/modules/pathwright/GlobalSchoolThemeStyles"
import {
  PathwrightClientContextProvider,
  usePathwrightContext
} from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import StagingPreviewAlert from "@pathwright/web/src/modules/pathwright/StagingPreviewAlert"
import { useLocalAuth } from "@pathwright/web/src/modules/pathwright/dev"
import useReferral from "@pathwright/web/src/modules/referral/useReferral"
import SubscriptionsNeedingAction from "@pathwright/web/src/modules/subscription/SubscriptionsNeedingAction"
import ChakraThemeProvider from "@pathwright/web/src/modules/theme/ChakraThemeProvider"
import TipsProvider from "@pathwright/web/src/modules/tips/TipsProvider"
import UserMembershipAccessExpired from "@pathwright/web/src/modules/user/UserMembershipAccessExpired"
import assign from "lodash/assign"
import PropTypes from "prop-types"
import React, { Suspense, useEffect } from "react"
import { HotkeysProvider } from "react-hotkeys-hook"
import { useAuthSync } from "./ClientRoutes"
import { AppNavbar, AppPanel } from "./lib/core/components/nav"
import { useHistoryEvent } from "./lib/core/routing/router"
import { ConnectStores } from "./lib/core/store"
import "./lib/core/styles/App.css"
import "./lib/core/styles/OverrideStyles.css"

const WrappedSentryError = (props) => {
  const { me } = usePathwrightContext()
  return <SentryError {...props} user={me} />
}

// Toggles Intercom UI visibility based on DockPanel layout.
const WrappedDockPanel = (props) => {
  const { hideIntercomUI, showIntercomUI } = useIntercomContext()

  return (
    <DockPanel
      {...props}
      onChangeLayout={({ open, docked }) => {
        // Whenever DockPanel is open and not docked, hide the Intercom launcher, etc.
        if (open && !docked) hideIntercomUI()
        // Whenever DockPanel is closed or docked, show the Intercom launcher, etc.
        if (!open || docked) showIntercomUI()
      }}
    />
  )
}

// houses legacy childContext
class AppLecacyContext extends React.Component {
  static contextTypes = {
    school: PropTypes.object
  }

  static initClass() {
    this.displayName = "AppLecacyContext"

    this.childContextTypes = {
      school: PropTypes.object,
      integrations: PropTypes.object,
      media: PropTypes.object,
      theme: PropTypes.object,
      user: PropTypes.object,
      me: PropTypes.object,
      user_permissions: PropTypes.object
    }
  }

  getChildContext() {
    let context = {
      school: {
        ...this.props.appContext.school,
        ...this.props.school
      },
      integrations: this.props.integrations,
      media: this.props.media,
      theme: this.props.media.theme,
      user: this.props.appContext.user,
      me: this.props.appContext.user,
      user_permissions: this.props.permissions
    }
    return context
  }

  render() {
    return this.props.children
  }
}

AppLecacyContext.initClass()

class PrimaryComponentWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    // prevent updating primary component with new route props
    // if showing some secondary component (like a modal or card stack)
    return !nextProps.components
  }

  render() {
    return this.props.children
  }
}

PrimaryComponentWrapper.displayName = "PrimaryComponentWrapper"

const App = (props) => {
  const {
    children,
    authSync,
    schoolId,
    panel,
    primary,
    secondary,
    modal,
    cardStack,
    popupAlert,
    aside
  } = props

  const pwContext = usePathwrightContext()
  const { me } = pwContext
  useHistoryEvent()
  useHeartbeat()
  useRefreshToken()
  useReferral()

  // Sync school's features in Pathwright context to legacy school store.
  useEffect(() => {
    window.App.getStore("school")._mutate("features", pwContext.school.features)
  }, [pwContext.school])

  const panelProps = React.useMemo(
    () =>
      panel
        ? {
            ...(panel.config || {}),
            children: panel.Component ? (
              <panel.Component {...(panel.props || {})} />
            ) : null
          }
        : {},
    [panel]
  )

  // Throwing this local auth handling in here
  const { refetchingToken } = useLocalAuth()
  if (refetchingToken) {
    return null
  }

  return (
    <>
      {typeof children === "function" ? children({ authSync }) : children}
      <AppLecacyContext {...props} appContext={pwContext}>
        <ModalContextProvider>
          <ObserveSizeContext.Consumer>
            {({ getRectValue }) => (
              <>
                {/* {inPortal} */}
                {/* Optionally portal something like a ThemeBackground */}
                <div id="theme-portal" />
                <div
                  className="App"
                  style={{
                    top: getRectValue("alert.height"),
                    right: getRectValue("cardstack.width")
                  }}
                >
                  <ScrollView fill className="App__primary">
                    <AppPanel user={me} />
                    <AppNavbar user={me} />
                    <div className="App__primary__component">{primary}</div>
                  </ScrollView>
                  {/* TODO: merge assid into card stack? */}
                  {!!aside && (
                    <aside.Component className="App__aside" {...aside.props} />
                  )}
                </div>
                <WrappedDockPanel {...panelProps} />
                {secondary}
                {modal}
                {!!popupAlert && <PopupAlert {...popupAlert.props} />}
                <Togglei18nKeys />
                <GlobalSchoolThemeStyles />
                <StripeAlert.Reconnect />
                <AlertBanner.Container />
                <StagingPreviewAlert />
                <ErrorParamAlert />
                <SubscriptionsNeedingAction />
                <UserMembershipAccessExpired />
                <ModalPortal />
                <div id="fullscreen-portal">
                  <div id="fullscreen-theme-portal" />
                </div>
                {/* Putting cards on top of everything */}
                <Box className="App__cards">{cardStack}</Box>
              </>
            )}
          </ObserveSizeContext.Consumer>
        </ModalContextProvider>
      </AppLecacyContext>
    </>
  )
}

App.displayName = "App"

// Connect AppLecacyContext to various stores
let connectedStores = [
  { store: "navigation", watch: ["panel"] },
  { store: "school", watch: ["integrations", "listen_hack"] },
  { store: "layout", watch: ["modal", "panel", "popupAlert", "aside"] }
]

const AppMapStateToProps = function (state, ownProps) {
  const { school, integrations, media, permissions } = state.schoolStore.state
  const { panel, popupAlert, aside } = state.layoutStore.state

  return assign({}, ownProps, {
    school,
    media,
    panel,
    popupAlert,
    aside,
    permissions,
    integrations
  })
}

const AppConnected = ConnectStores(App, connectedStores, {}, AppMapStateToProps)

// The provided uiTheme is dependent on bootstrapped data, but we want the school
// theme to be dependent on the pathwright context data, hence this extra merge layer.
export const SchoolThemeProvider = ({ uiTheme: uiThemeProp, children }) => {
  const { school } = usePathwrightContext()

  let uiTheme = uiThemeProp

  if (school.theme) {
    uiTheme = {
      ...uiThemeProp,
      primaryBrandColor:
        school.theme.primary_color || uiThemeProp.primaryBrandColor,
      backgroundImage:
        school.theme.background_image || uiThemeProp.backgroundImage,
      backgroundOverlay:
        school.theme.background_overlay || uiThemeProp.backgroundOverlay,
      headingFont: school.theme.heading_font
    }
  }

  return (
    <PathwrightUI.Provider theme={uiTheme}>{children}</PathwrightUI.Provider>
  )
}

// Connect PathwrightWeb client, PathwrightUI theme
const PathwrightApp = (props) => {
  const { schoolId, is_authenticated, theme, logo, icon } = props

  const uiTheme = {
    ...PathwrightUI.Provider.defaultTheme,
    primaryBrandColor:
      theme.primary_color ||
      PathwrightUI.Provider.defaultTheme.primaryBrandColor,
    backgroundImage:
      theme.background_image ||
      PathwrightUI.Provider.defaultTheme.backgroundImage,
    backgroundOverlay:
      theme.background_overlay ||
      PathwrightUI.Provider.defaultTheme.backgroundOverlay,
    headingFont: theme.heading_font,
    logo,
    icon
  }

  const authSync = useAuthSync()

  return (
    <Suspense fallback={<LoadingCircle />}>
      <PathwrightClientContextProvider
        authenticated={is_authenticated}
        schoolId={schoolId}
        onAuthChange={authSync}
      >
        <I18nextProvider useLocalTranslation>
          <SchoolThemeProvider uiTheme={uiTheme}>
            <ChakraThemeProvider>
              <WrappedSentryError>
                <ErrorBoundary className="AppErrorBoundary">
                  <FirebaseProvider>
                    <Screensize.Provider>
                      <CurrencyFormatterProvider>
                        <IntercomProvider>
                          <NotificationsProvider>
                            <ObserveSizeProvider>
                              <BackgroundTaskContextProvider>
                                <TipsProvider>
                                  <HotkeysProvider>
                                    <AppConnected
                                      {...props}
                                      authSync={authSync}
                                    />
                                  </HotkeysProvider>
                                </TipsProvider>
                              </BackgroundTaskContextProvider>
                            </ObserveSizeProvider>
                          </NotificationsProvider>
                        </IntercomProvider>
                      </CurrencyFormatterProvider>
                    </Screensize.Provider>
                  </FirebaseProvider>
                </ErrorBoundary>
              </WrappedSentryError>
            </ChakraThemeProvider>
          </SchoolThemeProvider>
        </I18nextProvider>
      </PathwrightClientContextProvider>
    </Suspense>
  )
}

PathwrightApp.displayName = "PathwrightApp"

const PathwrightAppMapStateToProps = function (state, ownProps) {
  let { is_authenticated } = state.authStore.state
  let { school, media, integrations } = state.schoolStore.state

  return assign(
    {},
    {
      integrations,
      is_authenticated,
      schoolId: school.id,
      theme: media.theme,
      logo: media.logo,
      icon: media.image
    },
    ownProps
  )
}

const PathwrightAppConnected = ConnectStores(
  PathwrightApp,
  [
    { store: "auth", watch: ["is_authenticated"] },
    {
      store: "school",
      watch: ["school", "media", "integrations", "listen_hack"]
    }
  ],
  {},
  PathwrightAppMapStateToProps
)

export default PathwrightAppConnected
