import { HStack } from "@chakra-ui/react"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import { alphanumericRe } from "@pathwright/ui/src/components/form/constants"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import TextInput from "@pathwright/ui/src/components/form/form-text-input/TextInput"
import {
  useInitialValues,
  validate
} from "@pathwright/ui/src/components/form/utils"
import Text from "@pathwright/ui/src/components/ui/Text"
import { Formik } from "formik"
import PropTypes from "prop-types"
import { z } from "zod"
import ChakraTooltip from "../../../components/ChakraTooltip"
import Pathicon from "../../../pathicon/Pathicon"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { hasPathwrightStaffAccess } from "../../../user/permissions"

// For now, just using zod schema for validating numbers.
const numCodesSchema = z.coerce.number().int().gte(2).lte(100)
const pathwrightAdminnNumCodesSchema = z.coerce.number().int().gte(2).lte(10000)
const discountPercentageSchema = z.coerce.number().gt(0).lte(100)

const ManageDiscountCodeForm = ({ setValues, showDiscount }) => {
  const pwContext = usePathwrightContext()

  return (
    <Formik
      initialValues={useInitialValues(ManageDiscountCodeForm.initialValues)}
      validate={(values) => {
        setValues(values)
        // return the errors
        return ManageDiscountCodeForm.validate(pwContext)(values)
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <CardBlock paddingTop={false} paddingBottom={false}>
          <CardBlock paddingLeft={false} paddingRight={false}>
            <RadioGroup topLabel label="Create one or a set?" hideStatus>
              <RadioButton
                name="codeGenerationType"
                type="radio"
                value="single"
                checked={values.codeGenerationType === "single"}
                required
                errors={
                  errors.codeGenerationType && touched.codeGenerationType
                    ? [errors.codeGenerationType]
                    : null
                }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Text.Body>Create a one-off code</Text.Body>
              </RadioButton>
              <RadioButton
                name="codeGenerationType"
                type="radio"
                value="set"
                checked={values.codeGenerationType === "set"}
                required
                errors={
                  errors.codeGenerationType && touched.codeGenerationType
                    ? [errors.codeGenerationType]
                    : null
                }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Text.Body>Create a set of codes</Text.Body>
              </RadioButton>
            </RadioGroup>
          </CardBlock>
          {values.codeGenerationType === "single" ? (
            <CardBlock
              borderTop={false}
              paddingLeft={false}
              paddingRight={false}
            >
              <TextInput
                name="code"
                value={values.code}
                type="text"
                required
                label="Code"
                labelWidth={105}
                helperText={`Entered to apply discount (e.g. "HalfOffApril").`}
                errors={errors.code && touched.code ? [errors.code] : null}
                onChange={(value, e) => handleChange(e)}
                onBlur={(value, e) => handleBlur(e)}
              />
            </CardBlock>
          ) : (
            <>
              <CardBlock
                borderTop={false}
                paddingLeft={false}
                paddingRight={false}
              >
                <TextInput
                  name="numCodes"
                  value={values.numCodes}
                  type="number"
                  required
                  label="# of codes"
                  labelWidth={105}
                  step="1"
                  helperText={
                    hasPathwrightStaffAccess(pwContext) ? (
                      <HStack
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Text.Meta>
                          The number of codes to generate between 2 and 10,000.
                        </Text.Meta>
                        <ChakraTooltip
                          title="Max 10,000 only available to superusers"
                          fitContent
                        >
                          <Pathicon icon="sparkles" />
                        </ChakraTooltip>
                      </HStack>
                    ) : (
                      "The number of codes to generate between 2 and 100."
                    )
                  }
                  errors={
                    errors.numCodes && touched.numCodes
                      ? [errors.numCodes]
                      : null
                  }
                  onChange={(value, e) => handleChange(e)}
                  onBlur={(value, e) => handleBlur(e)}
                />
              </CardBlock>
              <CardBlock paddingLeft={false} paddingRight={false}>
                <TextInput
                  name="codePrefix"
                  value={values.codePrefix}
                  type="text"
                  required
                  label="Code prefix"
                  labelWidth={105}
                  helperText="The first part of all codes in the set (up to 15 char)."
                  errors={
                    errors.codePrefix && touched.codePrefix
                      ? [errors.codePrefix]
                      : null
                  }
                  onChange={(value, e) => handleChange(e)}
                  onBlur={(value, e) => handleBlur(e)}
                />
              </CardBlock>
            </>
          )}
          {showDiscount && (
            <CardBlock paddingLeft={false} paddingRight={false}>
              <TextInput
                name="discountPercentage"
                value={values.discountPercentage}
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                required
                label="Discount"
                labelWidth={105}
                helperText="The amount deducted from the standard price, from 0%-100%."
                autoSize
                minWidth={50}
                custom=" %"
                stretch={false}
                errors={
                  errors.discountPercentage && touched.discountPercentage
                    ? [errors.discountPercentage]
                    : null
                }
                onChange={(value, e) => handleChange(e)}
                onBlur={(value, e) => handleBlur(e)}
              />
            </CardBlock>
          )}
        </CardBlock>
      )}
    </Formik>
  )
}

ManageDiscountCodeForm.displayName = "ManageDiscountCodeForm"

ManageDiscountCodeForm.propTypes = {
  showDiscount: PropTypes.bool
}

ManageDiscountCodeForm.defaultProps = {
  showDiscount: false
}

ManageDiscountCodeForm.initialValues = {
  codeGenerationType: "single",
  code: "",
  codePrefix: "",
  numCodes: null,
  discountPercentage: null
}

ManageDiscountCodeForm.validate = (pwContext) =>
  validate((key, value, values) => {
    switch (key) {
      case "code":
        if (values.codeGenerationType !== "single") break
        if (!value) return "Please enter a discount code."
        if (value.length > 255) return "Please enter a discount code that's no more than 255 characters." // prettier-ignore
        if (!alphanumericRe.test(value)) return "Please enter an alphanumeric discount code." // prettier-ignore
        break
      case "codePrefix":
        if (values.codeGenerationType !== "set") break
        if (!value) return "Please enter a discount code prefix."
        if (value.length > 15) return "Please enter a discount code no more than 15 characters." // prettier-ignore
        if (!alphanumericRe.test(value))  return "Please enter an alphanumeric discount code prefix." // prettier-ignore
        break
      case "numCodes":
        if (values.codeGenerationType !== "set") break
        if (hasPathwrightStaffAccess(pwContext)) {
          if (!pathwrightAdminnNumCodesSchema.safeParse(value).success)
            return "Please enter a number of codes to generate between 2 and 1000"
        } else {
          if (!numCodesSchema.safeParse(value).success)
            return "Please enter a number of codes to generate between 2 and 100"
        }
        break
      case "discountPercentage":
        if (!discountPercentageSchema.safeParse(value).success)
          return "Please enter the discount amount between 0% and 100%."
        break
    }
  })

export default ManageDiscountCodeForm
