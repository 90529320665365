import { Button, Heading, Text, VStack } from "@chakra-ui/react"
import classnames from "classnames"
import React from "react"
import withTranslate from "../lng/withTranslate"
import Pathicon from "../pathicon/Pathicon"

// client deploys can lead to the loading chunk failures
// if user has stale manifest and triggers a lazy-loaded
// chunk to be fetched
class LoadingChunkErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, showErrorDetails: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    const res = [
      /Loading chunk [0-9a-zA-Z-_]+ failed\./,
      /Loading CSS chunk [0-9a-zA-Z-_]+ failed\./
    ]
    if (res.find((re) => re.test(error.message))) {
      return { error }
    }

    // When not a loading chunk error, propagate the error to a parent
    // ErrorBoundary component as otherwise React will attempt to rerender this tree
    // and the error will be thrown again and again (at least in local dev).
    throw error
  }

  render() {
    if (this.state.error) {
      const { t } = this.props

      return (
        <VStack
          className={classnames(
            "LoadingChunkErrorBoundary",
            this.props.className
          )}
          w="100%"
          minH="100%"
          maxH="100vh"
          justifyContent="center"
          alignItems="center"
          bg="white"
          p={2}
          spacing={4}
        >
          <Pathicon icon="rocket" style={{ fontSize: "2em" }} />
          <Heading as="h1" size="h4" m={0}>
            {t("Updates available")}
          </Heading>
          <Text m={0}>
            {t("A newer version is available. Please refresh.")}
          </Text>
          {this.state.showErrorDetails && (
            <Text
              fontFamily="monospace"
              textAlign="left"
              m={0}
              p={4}
              borderRadius="md"
              color="black"
              bg="gray.200"
              maxW="min(600px, 100%)"
              overflow="auto"
            >
              {JSON.stringify(
                {
                  message: this.state.error.message,
                  name: this.state.error.name,
                  type: this.state.error.type,
                  request: this.state.error.request
                },
                null,
                2
              )}
            </Text>
          )}
          <Button
            colorScheme="brand"
            variant="solid"
            cursor="pointer"
            onClick={() => {
              // Add a query param to tell the browser to load the page from the server
              // not from the cache. It seems sometimes the page is cached and users still
              // see this message, hence this workaround. We remove the query param on page load.
              const url = new URL(window.location)
              url.searchParams.append("forceHardRefresh", new Date().getTime())
              window.location = url
            }}
          >
            {t("Refresh")}
          </Button>
          {!this.state.showErrorDetails && (
            <Button
              variant="link"
              p={0}
              bg="none"
              cursor="pointer"
              onClick={() =>
                this.setState((state) => ({
                  ...state,
                  showErrorDetails: true
                }))
              }
            >
              {t("Expand details")}
            </Button>
          )}
        </VStack>
      )
    }

    return this.props.children
  }
}

LoadingChunkErrorBoundary.displayName = "LoadingChunkErrorBoundary"

export default withTranslate(LoadingChunkErrorBoundary)
