import get from "lodash/get"
import {
  ComponentType,
  ReactNode,
  createContext,
  useContext,
  useMemo
} from "react"
import {
  CohortSyncPathQueryResult,
  ExecuteSyncPlanMutationHookResult,
  PathSyncPlanChange,
  PathSyncPlanQueryHookResult,
  useCohortSyncPathQuery,
  useExecuteSyncPlanMutation,
  usePathQuery,
  usePathSyncPlanQuery,
  GroupContextFragment
} from "../../api/generated"
import {
  hasEditorLevelAccess,
  hasTeacherLevelAccess
} from "../../group/permissions"
import { usePathwrightClient } from "../../pathwright/PathwrightClient"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import usePath from "../hooks/usePath"
import { SYNC_DIR_BACKWARD, SYNC_DIR_FORWARD } from "./constants"
import {
  Change,
  FromCohort,
  GroupedChanges,
  Path,
  SelectedSourceIDs,
  SyncDirection,
  SyncPlan,
  SyncPlanOptions,
  ToCohort
} from "./types"
import { getSyncableChangesCount, groupSyncChanges } from "./utils"

// Cannot pass a new client instance to refetchQueries so using
// a hack to "refetch" path queries but with the _consistentReadClient client.
const useRefetchPathQueries = (
  fromPathId: number,
  fromCohortId: number,
  toCohortId?: number
) => {
  const client = usePathwrightClient()

  /**
   * refetching both possible path queries that could be in cache
   * really don't like having the fromCohortId dependency, but seems to be the only way
   * simply calling refetchQueries: ["Path"] would refetch all cached path queries, so not ideal
   *
   * NOTE: in the case of a back sync this is imperative whenever
   * new path items are back synced as the items in the cohort will now
   * have new ids and source ids! This may be the only case–in which a
   * back sync requires refetching the path–but currently refetching in all cases.
   *
   * NOTE: in the case of a forward sync, it is at least necessary to refetch
   * the path query for those path items that have been published for the first time
   * in order to update the published_dtime on those path items
   */

  const pathQuery = usePathQuery({
    variables: {
      id: fromPathId || null,
      cohort_id: fromCohortId
    },
    skip: true,
    fetchPolicy: "network-only",
    client: client._consistentReadClient
  })

  const toPathQuery = usePathQuery({
    variables: {
      id: null,
      cohort_id: toCohortId
    },
    skip: true,
    fetchPolicy: "network-only",
    client: client._consistentReadClient
  })

  const cohortPathQuery = usePathQuery({
    variables: {
      cohort_id: fromCohortId
    },
    skip: true,
    fetchPolicy: "network-only",
    client: client._consistentReadClient
  })

  const refetch = () => {
    const promises = []

    if (fromCohortId) {
      promises.push(
        pathQuery.refetch({
          id: fromPathId,
          cohort_id: fromCohortId
        })
      )
      promises.push(
        cohortPathQuery.refetch({
          cohort_id: fromCohortId
        })
      )
    }

    if (toCohortId) {
      promises.push(
        toPathQuery.refetch({
          id: null,
          cohort_id: toCohortId
        })
      )
    }

    return Promise.all(promises)
  }

  return refetch
}

type UseSyncPlanQueryMutationOptions = {
  [key: string]: {
    includedSourceIds: number[]
    excludedSourceIds: number[]
  }
}

export const useSyncPlan = ({
  fromPathId,
  toPathId,
  fromCohortId,
  toCohortId,
  selectedItemSourceIds = {
    [SYNC_DIR_FORWARD]: null,
    [SYNC_DIR_BACKWARD]: null
  } as SelectedSourceIDs,
  queryOptions = {},
  path: propsPath
}: SyncPlanOptions): {
  hasSyncableChanges: boolean
  forward: SyncPlan | null
  backward: SyncPlan | null
  refetchSyncPlanQueries: () => Promise<any>
  fromPath?: Path | null
  toPath?: Path
  fromCohort?: FromCohort
  toCohort?: ToCohort
} => {
  const pwContext = usePathwrightContext()

  const { fromCohort } = useCohortSyncPlanQuery({
    cohortId: fromCohortId
  })

  const isSourcePathEditor = hasEditorLevelAccess(
    pwContext,
    fromCohort as GroupContextFragment
  )

  // If no Path provided via props, we query it here
  const pathQuery = usePath({
    pathId: null,
    cohortId: fromCohortId,
    skip: !!propsPath
  } as any)

  const path = propsPath || pathQuery?.path

  const refetchPathQueries = useRefetchPathQueries(
    fromPathId!,
    fromCohortId!,
    toCohortId
  )

  const useSyncPlanQueryMutation = ({
    [SYNC_DIR_FORWARD]: forwardSyncOptions,
    [SYNC_DIR_BACKWARD]: backwardSyncOptions
  }: UseSyncPlanQueryMutationOptions) => {
    // apollo cache sees a differently ordered list of the same values and treats as a different query
    const sortSourceIds = (sourceIds: number[]) =>
      sourceIds.sort((a, b) => a - b)

    // base variables for forward sync
    const forwardSyncPlanVariables = {
      from_path_id: fromPathId!,
      include_source_ids: sortSourceIds(forwardSyncOptions.includedSourceIds),
      exclude_source_ids: sortSourceIds(forwardSyncOptions.excludedSourceIds)
    }

    // base variables for backward sync
    const backwardSyncPlanVariables = {
      from_path_id: fromPathId!,
      to_path_id: toPathId,
      include_source_ids: sortSourceIds(backwardSyncOptions.includedSourceIds),
      exclude_source_ids: sortSourceIds(backwardSyncOptions.excludedSourceIds)
    }

    const forwardSyncPlanQuery = usePathSyncPlanQuery({
      ...queryOptions,
      variables: {
        sync_plan: forwardSyncPlanVariables
      },
      notifyOnNetworkStatusChange: true,
      skip: !fromPathId || !!queryOptions.skip
    })

    const backwardSyncPlanQuery = usePathSyncPlanQuery({
      ...queryOptions,
      variables: {
        sync_plan: backwardSyncPlanVariables
      },
      notifyOnNetworkStatusChange: true,
      skip: !toPathId || !!queryOptions.skip
    })

    const forwardSyncPlanMutation = useExecuteSyncPlanMutation({
      variables: {
        sync_plan: {
          ...forwardSyncPlanVariables,
          // backend uses the hash to verify nothing has changed between the generation of the sync plan
          // and the execution of it
          hash: get(forwardSyncPlanQuery, "data.pathSyncPlan.hash")
        }
      },
      onCompleted: () => refetchPathQueries()
    })

    const backwardSyncPlanMutation = useExecuteSyncPlanMutation({
      variables: {
        sync_plan: {
          ...backwardSyncPlanVariables,
          // backend uses the hash to verify nothing has changed between the generation of the sync plan
          // and the execution of it
          hash: get(backwardSyncPlanQuery, "data.pathSyncPlan.hash")
        }
      },
      onCompleted: () => refetchPathQueries()
    })

    return {
      [SYNC_DIR_FORWARD]: {
        query: forwardSyncPlanQuery,
        mutation: forwardSyncPlanMutation
      },
      [SYNC_DIR_BACKWARD]: {
        query: backwardSyncPlanQuery,
        mutation: backwardSyncPlanMutation
      }
    }
  }

  // by default, the base sync plan does not include/exclude any item source IDs
  // based on user request
  const baseSyncOptions = {
    [SYNC_DIR_FORWARD]: {
      includedSourceIds: [],
      excludedSourceIds: []
    },
    [SYNC_DIR_BACKWARD]: {
      includedSourceIds: [],
      excludedSourceIds: []
    }
  }

  // base sync plan with no user requested inclusions/exclustions
  const baseSyncPlans = useSyncPlanQueryMutation(baseSyncOptions)

  // ensuring we only send included/excluded item source IDs when the IDs are not
  // already included/excluded by default
  const getUserRequestedItemSourceIds = (syncDir: SyncDirection) => {
    let includedSourceIds: number[] = []
    let excludedSourceIds: number[] = []

    const query = baseSyncPlans[syncDir].query

    // When loading or query.data isn't available yet, use query.previousData for smoother transitions
    const data = query.data && !query.loading ? query.data : query.previousData

    if (data) {
      const itemSourceIds = selectedItemSourceIds[syncDir]

      if (itemSourceIds) {
        includedSourceIds =
          data.pathSyncPlan?.excluded_changes
            ?.map((change) => change?.item_source_id!)
            .filter((itemSourceId) => itemSourceIds.includes(itemSourceId!)) ||
          []
        excludedSourceIds =
          data.pathSyncPlan?.included_changes
            ?.map((change) => change!.item_source_id!)
            .filter((itemSourceId) => !itemSourceIds.includes(itemSourceId)) ||
          []
      }
    }

    return {
      includedSourceIds,
      excludedSourceIds
    }
  }

  // construct the requestedSyncOptions based on the currently included/excluded item source
  // in a way that only includes the item source ID when it would otherwise be excluded and
  // only excludes the item source ID when it would otherwise be included
  const requestedSyncOptions = {
    [SYNC_DIR_FORWARD]: getUserRequestedItemSourceIds(SYNC_DIR_FORWARD),
    [SYNC_DIR_BACKWARD]: getUserRequestedItemSourceIds(SYNC_DIR_BACKWARD)
  }

  const requestedSyncPlans = useSyncPlanQueryMutation(requestedSyncOptions)

  return useMemo(() => {
    let hasSyncableChanges = false
    let forwardSync = null
    let backwardSync = null

    const getWillReorder = (syncPlanIncludedChanges: PathSyncPlanChange[]) =>
      !!syncPlanIncludedChanges.find((item) => item.change_details.reorder)

    const getSyncData = (
      {
        query,
        mutation
      }: {
        query: PathSyncPlanQueryHookResult
        mutation: ExecuteSyncPlanMutationHookResult
      },
      syncDir: SyncDirection
    ) => {
      let syncData = null

      // When loading or query.data isn't available yet, use query.previousData for smoother transitions
      const data =
        query.data && !query.loading ? query.data : query.previousData

      if (data && path) {
        // NOTE: having to use `as Change[]` type assertion to please TS
        // due to the ChangeType being an extension of PathSyncPlanChange.
        // TODO: We preferably would remove all extensions of the
        // PathSyncPlanChange type in Change.
        const { included_changes, excluded_changes } = data.pathSyncPlan!

        syncData = {
          query,
          mutation: {
            mutate: mutation[0],
            ...mutation[1]
          },
          includedChanges: included_changes as Change[],
          excludedChanges: excluded_changes as Change[],
          groupedChanges: groupSyncChanges(
            [
              ...(included_changes as Change[]),
              ...(excluded_changes as Change[])
            ],
            path,
            isSourcePathEditor
          ) as GroupedChanges,
          // We treat a reorder as a single change
          willReorder: getWillReorder(included_changes as Change[]),
          syncDir
        }
      } else if (query.error) {
        syncData = {
          query,
          mutation: null,
          includedChanges: [] as Change[],
          excludedChanges: [] as Change[],
          groupedChanges: groupSyncChanges([], null, false) as GroupedChanges,
          willReorder: false,
          syncDir
        }
      }

      return syncData
    }

    forwardSync = getSyncData(
      requestedSyncPlans[SYNC_DIR_FORWARD],
      SYNC_DIR_FORWARD
    )
    backwardSync = getSyncData(
      requestedSyncPlans[SYNC_DIR_BACKWARD],
      SYNC_DIR_BACKWARD
    )

    const syncPlans = {
      [SYNC_DIR_FORWARD as SyncDirection]: forwardSync,
      [SYNC_DIR_BACKWARD as SyncDirection]: backwardSync
    }

    // check if any syncable changes exist
    hasSyncableChanges =
      !!getSyncableChangesCount(forwardSync as SyncPlan) ||
      !!getSyncableChangesCount(backwardSync as SyncPlan)

    // handles refetching both sync plans
    const refetchSyncPlanQueries = () => {
      const promises = [SYNC_DIR_FORWARD, SYNC_DIR_BACKWARD].reduce(
        (promises, syncDir: SyncDirection) => {
          const query = syncPlans[syncDir] ? syncPlans[syncDir]?.query : null

          if (query) {
            promises.push(query.refetch())
          }

          return promises
        },
        [] as Promise<any>[]
      )
      return Promise.all(promises)
    }

    return {
      forward: forwardSync,
      backward: backwardSync,
      hasSyncableChanges,
      refetchSyncPlanQueries,
      fromPath: path
    }
  }, [
    requestedSyncPlans[SYNC_DIR_FORWARD].query,
    requestedSyncPlans[SYNC_DIR_BACKWARD].query,
    ...requestedSyncPlans[SYNC_DIR_FORWARD].mutation,
    ...requestedSyncPlans[SYNC_DIR_BACKWARD].mutation,
    path
  ])
}

export const useCohortSyncPlanQuery = ({
  cohortId
}: {
  cohortId?: number
}): {
  query: CohortSyncPathQueryResult
  fromCohort?: FromCohort | null
  toCohort?: ToCohort | null
} => {
  // require Teacher or higher level access to avoid unnecessary queries
  const { me } = usePathwrightContext()
  const canEditLibrary = me?.permissions?.can_edit_library
  // NOTE: this is not a great check as we still will end up running the
  // CohortSyncPath query for users who don't need it (say they are a Learner
  // on *this* Path but a Teacher on some other Path).
  // TODO: use a better check for Teacher+ perms here.
  const canEditAPath = me?.group_role_stats?.some(
    (group) =>
      (group?.type === "active_registration" ||
        group?.type === "registration") &&
      !!group?.role &&
      group.role >= 15
  )

  const cohortSyncPlanQuery = useCohortSyncPathQuery({
    variables: {
      cohortId: cohortId!
    },
    skip: !cohortId || (!canEditLibrary && !canEditAPath)
  })

  const fromCohort = cohortSyncPlanQuery.data?.cohort
  // note that the source cohort should only exist when cohortId is the ID for a non-source cohort
  const toCohort = cohortSyncPlanQuery.data?.cohort?.source

  return {
    query: cohortSyncPlanQuery,
    fromCohort,
    toCohort
  }
}

type CohortSyncPlanProps = {
  cohortId?: number
  cohort_id?: number
  allowBackSync?: boolean
} & SyncPlanOptions

// accepts either cohortId or cohort_id for convenience
export const useCohortSyncPlanContext = ({
  cohortId,
  cohort_id,
  allowBackSync,
  ...rest
}: CohortSyncPlanProps) => {
  const pwContext = usePathwrightContext()

  const { fromCohort, toCohort } = useCohortSyncPlanQuery({
    cohortId: cohortId || cohort_id
  })

  const fromCohortId = get(fromCohort, "id")
  const toCohortId = get(toCohort, "id")

  let fromPathId: null | number = null
  let toPathId: null | number = null

  if (fromCohort) {
    const isSourcePath = fromCohort.is_master

    if (isSourcePath) {
      // only generate forward sync plan if user can edit the fromCohort
      if (hasEditorLevelAccess(pwContext, fromCohort as GroupContextFragment)) {
        fromPathId = fromCohort.path.id
      }
    } else {
      // only generate forward sync plan if user can teach the fromCohort
      if (
        hasTeacherLevelAccess(pwContext, fromCohort as GroupContextFragment)
      ) {
        fromPathId = fromCohort.path.id
      }
    }
  }

  if (toCohort) {
    // only generate back sync plan if user can edit the toCohort
    if (hasEditorLevelAccess(pwContext, toCohort) && allowBackSync) {
      toPathId = toCohort.path.id
    }
  }

  return useSyncPlan({
    fromPathId,
    toPathId,
    // not desireable, but necessary for requering the PATH_QUERY
    fromCohortId,
    toCohortId,
    ...rest
  })
}

type CohortSyncPlanContextType = {
  hasSyncableChanges: boolean
  fromPath?: Path | null
  toPath?: Path | null
  fromCohort?: FromCohort
  toCohort?: ToCohort
  refetchSyncPlanQueries?: () => Promise<any>
  forward?: SyncPlan | null
  backward?: SyncPlan | null
}

export const SyncPlanContext = createContext<CohortSyncPlanContextType>({
  fromPath: undefined,
  toPath: undefined,
  fromCohort: undefined,
  toCohort: undefined,
  hasSyncableChanges: false,
  forward: null,
  backward: null
})

export const SyncPlanContextProvider = ({
  children,
  ...syncPlanOptions
}: SyncPlanOptions & {
  children: ReactNode
}) => {
  return (
    <SyncPlanContext.Provider
      value={{
        ...useSyncPlan(syncPlanOptions),
        fromPath: syncPlanOptions.path
      }}
    >
      {children}
    </SyncPlanContext.Provider>
  )
}

export const useSyncPlanContext = () => useContext(SyncPlanContext)

// Not currently in use
export const withCohortSyncPlanContext =
  <P extends CohortSyncPlanContextType>(Component: ComponentType<P>) =>
  (props: Omit<P, keyof CohortSyncPlanContextType> & CohortSyncPlanProps) => {
    const cohortSyncPlanContext = useCohortSyncPlanContext(props)

    // Merge context and other props
    const combinedProps = {
      ...cohortSyncPlanContext,
      ...props
    } as unknown as P

    return <Component {...combinedProps} />
  }
