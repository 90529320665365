import {} from "@apollo/client"
import { COHORT_STATUS_COMPLETED } from "@pathwright/web/src/modules/group/constants"
import {
  ROLES,
  ROLE_TYPE_OFFERING
} from "@pathwright/web/src/modules/invitation/constants"
import { filterAvailableRoles } from "@pathwright/web/src/modules/invitation/utils"
import STORE_LINKS_QUERY from "@pathwright/web/src/modules/store/graphql/store-links-query"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { ConnectStores } from "lib/core/store"
import get from "lodash/get"
import ActivationLockedFallback from "./ActivationLockedFallback"

const getPathwrightAccountPermissions = ({
  school,
  resource,
  offering,
  curriculumOffering,
  hasActivePathwrightSubscription,
  hasStoreLink,
  ignoreActivated = false
}) => {
  const is_pathwright_activated =
    !!hasActivePathwrightSubscription || school.is_activated_via_override

  const permissions = {
    can_invite_to_resource: false,
    can_invite_to_offering: false,
    can_invite_to_school: false,
    can_edit_offering_visibility: false,
    highest_offering_visibility: 10,
    can_edit_offering_commerce: false,
    is_pathwright_activated,
    is_activated: school.is_activated
  }

  let can_invite_to_offering = false

  if (offering) {
    const availableRoles = ROLES[ROLE_TYPE_OFFERING].filter((role) =>
      filterAvailableRoles(ROLE_TYPE_OFFERING, role, offering)
    )
    if (availableRoles.length) {
      can_invite_to_offering = true
    }
  }

  // currently and activated school is one that has an active Pathwright subscription
  // or active curriculum subscription
  if (school.is_activated || ignoreActivated) {
    permissions.can_invite_to_school = true
    if (offering) {
      if (!offering.resource_license) {
        if (is_pathwright_activated || ignoreActivated) {
          permissions.can_invite_to_offering = can_invite_to_offering

          if (curriculumOffering && school.is_curriculum_enabled) {
            permissions.can_invite_to_resource = true
          }

          if (!offering.parent_offering_id) {
            permissions.can_edit_offering_visibility = true
            permissions.highest_offering_visibility = 15
            permissions.can_edit_offering_commerce = true
          }
        }
      } else if (resource) {
        // member created group
        const licensed_from_same_school = !resource.licensed_from_resource
        const licensed_from_another_school = !!resource.licensed_from_resource
        if (licensed_from_same_school && is_pathwright_activated) {
          permissions.can_invite_to_offering = can_invite_to_offering

          if (school.is_curriculum_enabled) {
            permissions.can_invite_to_resource = true
          }
        } else if (licensed_from_another_school) {
          permissions.can_invite_to_offering = can_invite_to_offering
          permissions.can_edit_offering_visibility = true
        }
      }
    }
  }

  // currently allowing inviting to a cohort licensed via a store without requiring
  // the school to be activated on a Pathwright plan
  if (hasStoreLink) {
    permissions.can_invite_to_offering = can_invite_to_offering
  }

  // completed cohorts can only be private
  if (offering && offering.status === COHORT_STATUS_COMPLETED) {
    permissions.highest_offering_visibility = 5
  }

  return permissions
}

const PathwrightAccountPermissions = ({
  children,
  loading,
  school,
  resource,
  offering,
  curriculumOffering,
  hasActivePathwrightSubscription,
  ignoreActivated
}) => {
  // find if this resource has been licensed from another school via store link
  // (assuming store link was used, though that is not necessarily true)
  const licensedFromSchoolId = get(resource, "licensed_from_school")
  const storeLinksQuery = useQuery(STORE_LINKS_QUERY, {
    variables: {
      licensor_id: { eq: licensedFromSchoolId }
    },
    skip: !licensedFromSchoolId
  })
  const hasStoreLink = get(storeLinksQuery, "data.storeLinks.total") >= 1

  const permissions = React.useMemo(
    () =>
      getPathwrightAccountPermissions({
        school,
        resource,
        offering,
        curriculumOffering,
        hasActivePathwrightSubscription,
        hasStoreLink,
        ignoreActivated
      }),
    [
      school,
      resource,
      offering,
      curriculumOffering,
      hasActivePathwrightSubscription,
      hasStoreLink
    ]
  )

  return loading ? null : children({ ...permissions, ActivationLockedFallback })
}

PathwrightAccountPermissions.displayName = "PathwrightAccountPermissions"

const mapStateToProps = (state, ownProps) => {
  const { school } = state.schoolStore.state
  const offerings =
    state.resourceStore.state.offerings ||
    state.resourceAdminStore.state.offerings
  const curriculumOffering = (offerings || []).find((o) => o.is_curriculum)
  const offering =
    state.resourceStore.state.offering ||
    state.groupAdminStore.state.group ||
    curriculumOffering
  const resource =
    state.resourceStore.state.resource ||
    state.resourceAdminStore.state.resource
  const resource_loading =
    (!resource && state.resourceStore.state.resource_loading) ||
    state.resourceAdminStore.state.resource_loading
  const offering_loading =
    !offering &&
    (state.resourceStore.state.offering_loading ||
      state.groupAdminStore.state.group_loading ||
      state.resourceStore.state.offerings_loading ||
      state.resourceAdminStore.state.offerings_loading)

  const hasActivePathwrightSubscription =
    state.pathwrightAccountStore.request.hasActivePathwrightSubscription()

  let loading = resource_loading || offering_loading

  // Load resource if necessary for checking permisisons
  if (offering && offering.resource_license && !resource && !resource_loading) {
    state.resourceAdminStore.action.selectResource(offering.resource_id)
  }

  return _.assign(
    {},
    {
      loading,
      school,
      resource,
      offering,
      curriculumOffering,
      hasActivePathwrightSubscription
    },
    ownProps
  )
}

const stores = [
  { store: "pathwrightAccount", watch: "pathwright_account" },
  { store: "school", watch: false },
  {
    store: "resource",
    watch: [
      "resource",
      "offering",
      "offerings",
      "resource_loading",
      "offering_loading",
      "offerings_loading"
    ]
  },
  {
    store: "resourceAdmin",
    watch: ["resource", "resource_loading", "offerings", "offerings_loading"]
  },
  { store: "groupAdmin", watch: ["group", "group_loading"] }
]

export default ConnectStores(
  PathwrightAccountPermissions,
  stores,
  {},
  mapStateToProps
)
