import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  IconButtonProps,
  UseDisclosureReturn,
  useBreakpointValue
} from "@chakra-ui/react"
import React, { useMemo } from "react"
import { usePreviousDistinct } from "react-use"
import { useMergedDisclosure } from "./utils"

type UsePanelSizeProps = {
  allowOverlay?: boolean
}

// Use these drawer sizes based on media queries.
const usePanelSize = (options?: UsePanelSizeProps) =>
  useBreakpointValue({
    // When allowing the panel to overlay, we set the size to full on
    // <= sm sized screens.
    base: options?.allowOverlay ? "full" : "xs",
    md: "xs",
    "2xl": "sm"
  })

type useMotionPropsProps = {
  isOpen: boolean
  preventAnimation: boolean
}

// Initially the drawer should not animate in, but always
// animate in/out afterwards, unless drawer is offset.
const useMotionProps = ({ isOpen, preventAnimation }: useMotionPropsProps) => {
  const prevIsOpen = usePreviousDistinct(isOpen)
  return useMemo(() => {
    if (preventAnimation || typeof prevIsOpen !== "boolean") {
      return {
        initial: false
      }
    }
    return {}
  }, [prevIsOpen])
}

type ToolbarOption = {
  key: string
  icon: string
  selected: boolean
  toggleSelected: () => void
} & Omit<IconButtonProps, "icon">

type Toolbar = {
  // Hide the toolbar when an option is selected?
  hideWhenSelected?: boolean
  options: ToolbarOption[]
}

export type AugmentationComponentProps = {} & UseDisclosureReturn

export type AugmentationFnComponent<T = {}> = (
  props: AugmentationComponentProps & T
) => JSX.Element

export type AugmentationComponent = JSX.Element

export type AugmentationComponents = {
  children: AugmentationComponent
}

export type AugmentationWrapperComponent = AugmentationFnComponent<{
  Panel: (props: Partial<AugmentationComponents>) => JSX.Element
}>

export type AugmentationPanelOptions = {
  toolbar?: Toolbar
  offset?: {
    top?: number
    right?: number
  }
  allowOverlay?: boolean
  allowClose?: boolean
  disclosure?: UseDisclosureReturn
}

export type AugmentationPanelProps = AugmentationPanelOptions &
  AugmentationComponents

const AugmentationPanel = React.forwardRef<HTMLElement, AugmentationPanelProps>(
  (props, ref) => {
    const { offset, allowClose, allowOverlay, children, toolbar } = props
    const size = usePanelSize({ allowOverlay })

    const { isOpen, onClose } = useMergedDisclosure(props.disclosure, {
      isOpen: props.disclosure?.isOpen
    })

    const motionProps = useMotionProps({
      isOpen,
      preventAnimation: !!offset
    })

    // Offset from all sides of screen.
    const gutter = 10

    // Styling the drawer content conditionally based on drawer size.
    const contentSizeProps =
      // Use overlay styles for when drawer is "full" size.
      size === "full"
        ? {
            inset: "10px !important",
            maxW: `calc(100% - ${gutter * 2}px)`,
            maxH: `calc(100% - ${gutter * 2}px)`
          }
        : {}

    return (
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        variant="aside"
        size={size}
        trapFocus={false}
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
      >
        <DrawerContent
          ref={ref}
          className="AugmentationPanel"
          motionProps={motionProps}
          bg="transparent"
          bottom={`${gutter}px !important`}
          top={`${(offset?.top || 0) + gutter}px !important`}
          marginRight={offset?.right ? `${offset.right}px` : undefined}
          boxShadow="none"
          {...contentSizeProps}
        >
          {allowClose && <DrawerCloseButton zIndex={1000} />}
          <DrawerBody p={0} w="100%">
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  }
)

AugmentationPanel.displayName = "AugmentationPanel"

export default AugmentationPanel
