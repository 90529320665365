import { Spinner } from "@chakra-ui/react"
import React, { Suspense } from "react"

const PasswordChangeContainer = React.lazy(
  () =>
    import(
      "./PasswordChangeContainer" /* webpackChunkName: "PasswordChangeContainer" */
    )
)

function LazyPasswordChangeContainer(props: any) {
  return (
    <Suspense fallback={<Spinner />}>
      <PasswordChangeContainer {...props} />
    </Suspense>
  )
}

export default LazyPasswordChangeContainer
