// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
// Creating a new Resource model here that is just for save, delete
const Resource = require("./resource-admin-model").default
const Offering = require("library/models/offering").default
const OfferingCollection = require("library/models/offering-collection").default

class ResourceAdminStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.defaults = {
      resource: null, // the resource being created/updated/selected
      offerings: null, // the offerings on the resource
      offerings_loading: false,
      offerings_error: null
    }

    this.prototype.resources = {
      resource: {
        model: Resource
      },
      offering: {
        model: Offering,
        collection: OfferingCollection
      }
    }

    this.prototype.storeTriggerHandlers = {
      // NOTE: this event no longer fires since we don't create new cohorts
      // via the groupAdmin store,but we may want to consider porting this
      // handler over.
      groupAdmin: {
        "group:created"(offering) {
          let resource_id
          let { resource } = this.getState()
          if (!resource) {
            ;({ resource } = this.getStore("resource").getState())
          }
          if (resource) {
            resource_id = resource.id
          }
          return this.action.loadOfferings(resource_id, true)
        }
      }
    }

    this.prototype.actions = {
      saveResource(resourceData) {
        return this.resource.resource.save(resourceData)
      },

      deleteResource() {
        return this.resource.resource.destroy()
      },

      loadResource(id, force = false) {
        return this.resource.resource.load({ id }, force)
      },

      selectResource(id) {
        return this.resource.resource.load({ id })
      },

      loadOfferings(resource_id, force) {
        if (force == null) {
          force = false
        }
        if (resource_id == null) {
          resource_id = this.get("resource").id
        }
        resource_id = parseInt(resource_id)
        return this.resource.offering
          .list({ resource: resource_id, manage: 1 }, force)
          .catch((error) => console.log("error", error))
          .then((offerings) =>
            // filter out curriculum offerings
            offerings.toJSON().filter((g) => !g.is_curriculum)
          )
      },

      loadMoreOfferings() {
        const offerings = this.get("offerings")
        const loading = this.get("offerings_loading")

        if (!loading) {
          return new Promise(
            function (resolve, reject) {
              this._mutate({ offerings_loading: true })
              return offerings.next().then(() => {
                this._mutate({ offerings_loading: false })
                this.triggerChange("change:offerings")
                return resolve()
              })
            }.bind(this)
          )
        }
      }
    }

    this.prototype.requests = {
      getCurriculumOffering() {
        const { offerings } = this.getState()
        return _.find(offerings, (o) => {
          return o.is_curriculum
        })
      }
    }
  }
}
ResourceAdminStore.initClass()

export default window.App.stores.registerStore(
  "resourceAdmin",
  ResourceAdminStore
)
