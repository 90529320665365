import { SkipBack2Icon, SkipForward2Icon } from "@pathwright/pathicons"
import PropTypes from "prop-types"
import React from "react"
import { AudioButtonUI } from "./ui"

export const SkipButton = ({ onSkip, type }) => {
  return (
    <AudioButtonUI
      icon={
        type === "next" ? (
          <SkipForward2Icon size="24px" />
        ) : (
          <SkipBack2Icon size="24px" />
        )
      }
      aria-label={`Skip ${type === "next" ? "forward" : "back"}`}
      onClick={() => onSkip(type)}
      m="0px 15px"
      _after={{
        content: "'15'",
        color: "gray",
        display: "flex",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        top: "2px",
        lineHeight: "30px",
        fontSize: "10px",
        fontWeight: "600"
      }}
    />
  )
}

SkipButton.propTypes = {
  type: PropTypes.string.isRequired, // prev or next
  onSkip: PropTypes.func.isRequired //
}

export default SkipButton
