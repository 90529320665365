import { useMutation } from "@apollo/client"
import { getIsLocalHost } from "@pathwright/ui/src/components/utils/env"
import { handleAuthToken } from "@pathwright/web-new/src/lib/utils/auth-token"
import { useEffect } from "react"
import REFRESH_TOKEN_MUTATION from "../auth/graphql/refresh-token-mutation"
import { usePathwrightContext } from "./PathwrightContext"

// auth handling for local dev (also handles current school config URL)
// TODO: choose better name
export const useLocalAuth = () => {
  const { school, me } = usePathwrightContext()

  const [refreshToken, { loading: refetchingToken }] = useMutation(
    REFRESH_TOKEN_MUTATION,
    {
      variables: {
        school_id: school.id
      }
    }
  )

  const handleAuth = async () => {
    if (process.env.NODE_ENV === "development" && getIsLocalHost()) {
      const school_config_url = localStorage.getItem("school_config_url")
      // We'll also compare against the stored space ID to ensure we don't
      // overrite a valid school url (think one with a custom domain).
      const spaceId = parseInt(localStorage.getItem("PW_SPACE"))
      // Ensure school_config_url is current in localStorage
      if (school.url !== school_config_url && spaceId !== school.id) {
        // Refresh token for current user for school
        if (!me && localStorage.getItem("auth_token") && !refetchingToken) {
          try {
            const result = await refreshToken()
            handleAuthToken(result.data.refreshToken)
          } catch (err) {
            console.log("Failed to refresh token for school", err)
            handleAuthToken(null)
          }
        }
      }
    }
  }

  useEffect(() => {
    handleAuth()
  }, [])

  return { refetchingToken }
}
