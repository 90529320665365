// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const pluralize = require("pluralize")
const capitalize = require("lodash/capitalize")

const getKeys = function (resourceKey) {
  const keyPlural = pluralize(resourceKey)
  const keyCapital = capitalize(resourceKey)
  const keyCapitalPlural = pluralize(keyCapital)
  return {
    capital: keyCapital,
    plural: keyPlural,
    capitalPlural: keyCapitalPlural,
    key: resourceKey
  }
}

const getResources = function (resourceKey, Collection, Model, options) {
  if (options == null) {
    options = {}
  }
  return {
    [resourceKey]: {
      collection: Collection,
      model: Model
    }
  }
}

const getStoreTriggers = function (resourceKey, Collection, Model, options) {
  if (options == null) {
    options = {}
  }
  const keys = getKeys(resourceKey)
  return {
    [`${keys.plural}:updated`]() {
      return this.get(resourceKey)
    },
    [`${keys.key}:created`]() {
      return this.get(resourceKey)
    },
    [`${keys.key}:deleted`](resource) {
      return resource
    }
  }
}

const getActions = function (resourceKey, Collection, Model, options) {
  if (options == null) {
    options = {}
  }
  const keys = getKeys(resourceKey)
  options = _.defaults(options, {
    read: true,
    write: true
  })

  const readActions = {
    [`load${keys.capitalPlural}`](forceRefresh) {
      if (forceRefresh == null) {
        forceRefresh = false
      }
      return this.resource[keys.key].list(options.list_params, forceRefresh)
    },

    [`load${keys.capital}`](id, forceRefresh) {
      if (forceRefresh == null) {
        forceRefresh = false
      }
      const params = _.assign({}, options.load_params, { id })
      return this.resource[keys.key].load(params, forceRefresh)
    },

    [`get${keys.capital}`](id, forceRefresh) {
      // Tries to load the cached version of the resource
      if (forceRefresh == null) {
        forceRefresh = false
      }
      id = parseInt(id)
      const list = this.get(keys.plural)
      if ((list != null ? list.get(id) : undefined) && !forceRefresh) {
        return Promise.resolve(list.get(id))
      } else if (this.get(keys.key)?.id === id) {
        return Promise.resolve(this.get(keys.key))
      } else {
        return this.action[`load${keys.capital}`](id, forceRefresh)
      }
    }
  }

  const writeActions = {
    [`save${keys.capital}`](data) {
      // Save the resource and add it to the colleciton if it's new
      data = _.assign({}, this.get(keys.key).toJSON(), data)
      const isNew = !data.id
      return this.resource[keys.key].save(data).then(() => {
        if (!this.get(`${keys.key}_error`)) {
          return this.action[`on${keys.capital}Saved`](isNew)
        }
      })
    },

    [`on${keys.capital}Saved`](isNew) {
      const resource = this.get(keys.key)
      const resources = this.get(keys.plural)

      if (resources) {
        resources.add(resource, {
          at: resource.get("order") || 0, // add resource at index
          merge: true
        }) // update model if already exists
      }

      this.triggerChange(`change:${keys.plural}`)
      this.storeTrigger(`${keys.plural}:updated`)
      if (isNew) {
        return this.storeTrigger(`${keys.key}:created`)
      }
    },

    [`update${keys.capital}Order`](resources) {
      // Creates a change list for order in a list
      // based on the index of each item
      const changes = []
      for (let index = 0; index < resources.length; index++) {
        const resource = resources[index]
        const order = index + 1
        const model = this.get(keys.plural).get(resource.id)
        if (model.get("order") !== order) {
          model.set("order", order)
          changes.push({
            id: resource.id,
            order
          })
        }
      }
      return this.action[`save${keys.capital}Order`](changes)
    },

    [`save${keys.capital}Order`](changes) {
      const OrderModel = getOrderModel(resourceKey, Model)
      return new Promise(
        function (resolve, reject) {
          const resourceOrder = new OrderModel({ [keys.plural]: changes })
          return resourceOrder
            .save()
            .then(() => {
              this.storeTrigger(`${keys.plural}:updated`)
              return resolve()
            })
            .fail(reject)
        }.bind(this)
      )
    },

    [`select${keys.capital}`](id) {
      if (id) {
        return this.action[`get${keys.capital}`](id).promise.then(
          (resource) => {
            if (!this.get(keys.key)?.id !== resource.id) {
              return this._mutate({ [keys.key]: resource })
            }
          }
        )
      } else {
        const resource = new Model()
        this._mutate({ [keys.key]: resource })
        return Promise.resolve(resource)
      }
    },

    [`unselect${keys.capital}`]() {
      return this._mutate({
        [keys.key]: null,
        [`${keys.key}_selected_for_deletion`]: false
      })
    },

    [`delete${keys.capital}`](id, finalize, cancel) {
      if (finalize == null) {
        finalize = false
      }
      if (cancel == null) {
        cancel = false
      }
      if (finalize) {
        // Actually destroy the resource
        return this.action[`get${keys.capital}`](id).promise.then(
          (resource) => {
            return this.resource[keys.key]
              .destroy(resource.toJSON())
              .then(() => {
                return this.action[`on${keys.capital}Deleted`](resource)
              })
          }
        )
      } else {
        // Select the resource for deletion ("shows a UI prompt")
        return this._mutate({ [`${keys.key}_selected_for_deletion`]: !cancel })
      }
    },

    [`on${keys.capital}Deleted`](resource) {
      this._mutate({ [`${keys.key}_selected_for_deletion`]: false })
      const resources = this.get(keys.plural)
      if (resources) {
        resources.remove(resource)
      }

      // TODO: reset order here?
      this.triggerChange(`change:${keys.plural}`)
      this.storeTrigger(`${keys.plural}:updated`)
      return this.storeTrigger(`${keys.key}:deleted`, resource)
    }
  }

  let actions = {}
  if (options.read) {
    actions = _.assign(actions, readActions)
  }
  if (options.write) {
    actions = _.assign(actions, writeActions)
  }
  return actions
}

var getOrderModel = function (resourceKey, Model) {
  let OrderModel
  const keys = getKeys(resourceKey)
  return (OrderModel = (function () {
    OrderModel = class OrderModel extends (
      require("lib/static-shim").default(require("lib/utils/model").default)
    ) {
      static initClass() {
        this.prototype.defaults = { [keys.plural]: [] }
      }
      isNew() {
        return false
      }
      url() {
        const baseURL = new Model().url()
        return `${baseURL}orders/`
      }
    }
    OrderModel.initClass()
    return OrderModel
  })())
}

const createResourceStore = function (resourceKey, Collection, Model, options) {
  if (options == null) {
    options = {}
  }
  options = _.defaults(options, {
    list_params: {},
    load_params: {}
  })

  class ResourceStore extends require("lib/static-shim").default(
    require("lib/core/store/backbone-store").default
  ) {
    static initClass() {
      this.prototype.resources = getResources(
        resourceKey,
        Collection,
        Model,
        options
      )
      this.prototype.storeTriggers = getStoreTriggers(
        resourceKey,
        Collection,
        Model,
        options
      )
      this.prototype.actions = getActions(
        resourceKey,
        Collection,
        Model,
        options
      )
    }
  }
  ResourceStore.initClass()
  return ResourceStore
}

export {
  createResourceStore,
  getActions,
  getOrderModel,
  getResources,
  getStoreTriggers
}
