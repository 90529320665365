const {
  DISCUSSION_ASK_TO_ANSWER,
  RESPONSE_ASK_TO_REPLY
} = require("./constants")

const NotificationAction = require("./models/notification-action").default

class NotificationStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.resources = {
      notification_action: {
        model: NotificationAction
      }
    }

    // TODO: convert to GraphQL mutation.
    this.prototype.actions = {
      sendAskToAnswerNotification(
        discussionItem,
        toUser,
        discussionContextKey
      ) {
        let target = `discussion_${discussionItem.id}`
        let action = DISCUSSION_ASK_TO_ANSWER
        let discussion_context = discussionItem?.context?.id
        if (discussionItem.discussion != null) {
          // this is a response!
          target = `response_${discussionItem.id}`
          action = RESPONSE_ASK_TO_REPLY
          discussion_context =
            this.getStore("discussion").getState().discussion?.context?.id
        }
        return this.action.sendActionNotification(
          action,
          target,
          [toUser.id],
          discussionContextKey,
          discussion_context
        )
      },

      sendActionNotification(
        action,
        target,
        to_users,
        context,
        discussion_context,
        send_email
      ) {
        if (send_email == null) {
          send_email = true
        }
        const payload = {
          action,
          target,
          context,
          to_users: to_users.join(","),
          discussion_context,
          send_email
        }
        return this.resource.notification_action.save(payload)
      }
    }
  }
}
NotificationStore.initClass()

export default window.App.stores.registerStore(
  "notification",
  NotificationStore
)
