import { Component } from "react"

export function printContent(querySelector, debug = false) {
  let $iframe = document.querySelector("#print")
  let $content = document.querySelector(querySelector)
  if (!$content) {
    // Content is not rendered for some reason.
    return window.alert("Unable to print this content.")
  }
  if ($iframe) {
    // Clean up previous printing.
    // Ideally we'd do this immediately after print,
    // but to do so w/ cross-browser support is difficult.
    document.body.removeChild($iframe)
  }
  // Build new iframe as print target
  $iframe = document.createElement("iframe")
  $iframe.id = "print"

  if (debug) {
    // ✅ Make the iframe visible for debugging
    $iframe.style.position = "fixed"
    $iframe.style.top = "10px"
    $iframe.style.left = "10px"
    $iframe.style.width = "90vw"
    $iframe.style.height = "90vh"
    $iframe.style.background = "white"
    $iframe.style.border = "2px solid black"
    $iframe.style.zIndex = "999999999"
    console.log("Debug mode: iframe is visible for inspection.")
  } else {
    // Hide the iframe normally
    $iframe.style.position = "absolute"
    $iframe.style.height = 0
    $iframe.style.width = 0
  }
  document.body.appendChild($iframe)

  $iframe.contentWindow.document.open()
  $iframe.contentWindow.document.write(`
    <html>
      <head>
        ${
          ""
          // HACK ALERT
          // UI fonts (-apple-system etc) don't print correctly,
          // and for whatever reason @media print isn't working either,
          // so we inject font styles on the fly.
        }
        <style>
          body {
            font-family:'ProximaNova',sans-serif !important;
          }
        </style>
      </head>
      <body>${$content.outerHTML}</body>
    </html>
  `)

  // Insert link and style elements from parent document.
  // Using documentFragment to trigger only one reflow and render.
  const documentFragment = document.createDocumentFragment()
  document
    .querySelectorAll("link")
    .forEach((element) => documentFragment.appendChild(element.cloneNode(true)))
  document
    .querySelectorAll("style")
    .forEach((element) => documentFragment.appendChild(element.cloneNode(true)))

  const styleSheetsStyle = document.createElement("style")
  // Get all the stylesheet styles for every <style /> tag that has empty textContent.
  styleSheetsStyle.textContent = Array.from(document.querySelectorAll("style"))
    .filter((style) => !style.textContent && style.sheet)
    .map(({ sheet }) =>
      Object.keys(sheet.cssRules)
        .map((key) => sheet.cssRules[key].cssText)
        .join(" ")
    )
    .join(" ")

  // Insert styleSheetsStyle
  documentFragment.appendChild(styleSheetsStyle)

  // Append document fragment with links and styles to the iframe document head
  $iframe.contentWindow.document.head.appendChild(documentFragment)

  $iframe.contentWindow.document.close()

  if (!debug) {
    // ✅ Only print if NOT in debug mode
    $iframe.contentWindow.onload = function () {
      $iframe.contentWindow.focus()
      $iframe.contentWindow.print()
    }

    $iframe.contentWindow.onafterprint = function () {
      document.body.removeChild($iframe)
    }
  } else {
    console.log("Debug mode: iframe is visible for inspection.")
  }
}

const handlePrintShortcut = (queryString) => (e) => {
  // e.ctrlKey for windows
  if (e && (e.metaKey || e.ctrlKey) && e.keyCode == 80) {
    printContent(queryString)
    // prevent default print behavior
    e.preventDefault()
    return false
  }
}

// setup listening
export function listenToPrintShortcut(queryString) {
  const printHandler = handlePrintShortcut(queryString)
  window.addEventListener("keydown", printHandler)
  return function unlistenToPrintShortcut() {
    window.removeEventListener("keydown", printHandler)
  }
}

export const withPrintContent = (queryString) => (PassedComponent) =>
  class extends Component {
    componentDidMount() {
      this.unlistenToPrintShortcut = listenToPrintShortcut(queryString)
    }

    componentWillUnmount() {
      if (this.unlistenToPrintShortcut) {
        this.unlistenToPrintShortcut()
        this.unlistenToPrintShortcut = null
      }
    }

    render() {
      return (
        <PassedComponent
          {...this.props}
          printContent={() => printContent(queryString)}
        />
      )
    }
  }
