import { ReactNode } from "react"
import BlocksConfigProvider, {
  BlocksConfigContextType
} from "../config/BlocksConfigProvider"
import GraphQLSyncer from "../syncer/GraphQLSyncer"
import { SyncerContextType, useSyncer } from "../syncer/SyncerContext"
import EditorStateProvider, {
  ESPTypes,
  MergedEditorContextValueType
} from "./EditorStateProvider"

const EditorStateWithSyncer = ({
  children
}: {
  children: ReactNode | ((props: MergedEditorContextValueType) => ReactNode)
}) => {
  const syncerState: SyncerContextType = useSyncer()

  return (
    <EditorStateProvider {...(syncerState as Omit<ESPTypes.Props, "children">)}>
      {children}
    </EditorStateProvider>
  )
}

type BlocksEditContainerProps = BlocksConfigContextType & {
  children: ReactNode | ((props: MergedEditorContextValueType) => ReactNode)
  Syncer?: any
}

export const BlocksEditContainer = ({
  children,
  Syncer,
  ...config
}: BlocksEditContainerProps) => {
  const SyncProvider = Syncer || GraphQLSyncer

  const syncerProps: Record<string, any> = {}

  if (!Syncer) {
    syncerProps.graphQLEndpoint = config.graphQLEndpoint
    syncerProps.accountID = config.accountID
  }

  return (
    <BlocksConfigProvider {...config}>
      <SyncProvider {...syncerProps}>
        <EditorStateWithSyncer>{children}</EditorStateWithSyncer>
      </SyncProvider>
    </BlocksConfigProvider>
  )
}

export default BlocksEditContainer
