import { Box, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import moment from "moment"
import pluralize from "pluralize"

type SubscriptionInfoProps = {
  subscription: any
  plan: any
  school_plan: any
  starting_plan: any
  is_lifetime: boolean
  is_trial: boolean
  is_paused: boolean
  is_canceled: boolean
  is_expired: boolean
  is_active: boolean
}

function SubscriptionInfo({
  subscription,
  plan,
  school_plan,
  starting_plan,
  is_lifetime,
  is_trial,
  is_paused,
  is_canceled,
  is_expired,
  is_active
}: SubscriptionInfoProps) {
  const { school } = usePathwrightContext()

  const getTerm = (interval: number) => {
    if (interval < 10) {
      return "monthly"
    } else if (interval === 10) {
      return "yearly"
    } else if (interval === 20) {
      return "lifetime"
    }
  }

  const getBillingDate = () => {
    const format = (() => {
      switch (getTerm(plan.interval)) {
        case "monthly":
          return "Do"
        case "yearly":
          return "Do [of] MMMM"
      }
    })()
    return moment(subscription.cycle_start_dtime).format(format)
  }

  const getStatus = () => {
    if (is_lifetime) {
      return "lifetime"
    }
    if (is_trial && moment(subscription.trial_end_dtime) > moment()) {
      if (subscription.trial_type === 10) {
        return "trialcard"
      } else {
        return "trial"
      }
    }
    if (is_paused) {
      return "paused"
    }
    if (is_canceled || subscription.is_canceled_at_cycle_end) {
      return "canceled"
    }
    if (is_expired) {
      return "expired"
    }
    if (is_active) {
      return "active"
    }
  }

  const getLifetimeMessage = () => {
    return `You have a lifetime subscription to ${school.name}.`
  }

  const getActiveMessage = () => {
    return `You're subscribed for ${school_plan.currency_display}/${getTerm(
      plan.interval
    )}. Your card ending in ${
      subscription.card_last_4
    } will be billed on the ${getBillingDate()} ${getTerm(plan.interval)}.`
  }

  const getExpiredMessage = () => {
    return `You're subscription has expired.`
  }

  const getCanceledMessage = () => {
    return `Your subscription has been cancelled, but you'll have access to all content until ${moment(
      subscription.cycle_end_dtime
    ).format("MMMM Do YYYY")}.`
  }

  const getPausedMessage = () => {
    return `Your subscription to ${school.name} is paused until ${moment(
      subscription.paused_until_dtime
    ).format("MMMM Do YYYY")}.`
  }

  const getTrialCardMessage = () => {
    if (!(is_canceled || subscription.is_canceled_at_cycle_end)) {
      return `You have free access until ${moment(
        subscription.trial_end_dtime
      ).format("MMMM Do YYYY")} after which your card will be charged ${
        school_plan.currency_display
      }/${getTerm(plan.interval)}.`
    } else {
      return `Your free access ends on ${moment(
        subscription.trial_end_dtime
      ).format("MMMM Do YYYY")}.`
    }
  }

  const getTrialMessage = () => {
    return `You have free access until ${moment(
      subscription.trial_end_dtime
    ).format("MMMM Do YYYY")}.`
  }

  const getNoneMessage = () => {
    const plan = starting_plan
    return `You are not currently subscribed. Subscribe to access ${pluralize(
      "resource",
      plan.resources_count,
      true
    )} for as little as ${plan.currency_display}/${getTerm(
      plan.plan.interval
    )}.`
  }

  const statusMessageFnMap = {
    lifetime: getLifetimeMessage,
    active: getActiveMessage,
    canceled: getCanceledMessage,
    paused: getPausedMessage,
    trialcard: getTrialCardMessage,
    trial: getTrialMessage,
    expired: getExpiredMessage
  }

  return (
    <Box className="SubscriptionInfo" p={4}>
      {subscription != null ? (
        <HStack
          className="SubscriptionInfo__status"
          w="100%"
          justifyContent="flex-start"
          spacing={4}
        >
          <Image src={school_plan.image} h="60px" />
          <VStack alignItems="flex-start" spacing={1}>
            <Heading size="h4" m={0}>
              {school_plan.name}
            </Heading>
            <Text m={0} color="#888">
              {statusMessageFnMap[getStatus()!]?.()}
            </Text>
          </VStack>
        </HStack>
      ) : (
        <p>{getNoneMessage()}</p>
      )}
    </Box>
  )
}

export default SubscriptionInfo
