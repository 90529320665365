import { Spinner } from "@chakra-ui/react"
import { Suspense } from "react"
import lazy from "react-lazy-with-preload"

export const PreloadableAdaptedSignedInContainer = lazy(
  () =>
    import(
      "./AdaptedSignedInContainer" /* webpackChunkName: "AdaptedSignedInContainer" */
    )
)

function LazyAdaptedSignedInContainer(props: any) {
  return (
    <Suspense fallback={<Spinner />}>
      <PreloadableAdaptedSignedInContainer {...props} />
    </Suspense>
  )
}

export default LazyAdaptedSignedInContainer
