import { Box, BoxProps, useOutsideClick } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { useIntersection } from "react-use"
import { useHomeRegistrationsLazyQuery } from "../../api/generated"
import PathCreatorFlow, {
  usePathCreatorFlow
} from "../../path/create/PathCreatorFlow"
import { OnFlowComplete } from "../../path/create/PathCreatorForm"
import { PathCreatorAction } from "../../path/create/state"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { useHomeContext } from "../HomeContext"
import { useHomeRegistrationVars } from "../HomeRegistrationQueryVars"

// Reload related data after user completes Path Creator Flow.
function useReloadData(): OnFlowComplete {
  // Just get it working stop-gap for reloading user registrations
  // after successfully completing PathCreatorFlow.
  // NOTE: we could possibly(?) use the onComplete state prop to update the cache instead.
  const [reloadRegistrations] = useHomeRegistrationsLazyQuery({
    variables: useHomeRegistrationVars(),
    fetchPolicy: "network-only"
  })

  return async () => {
    // Once the flow is complete, let's reload the user's registrations.
    await reloadRegistrations()
  }
}

const AddPathButton = () => {
  const { me } = usePathwrightContext()
  const { setPathCreatorCompletion } = useHomeContext()
  const flowRef = useRef<HTMLDivElement>(null)
  const intersectionRef = useRef<HTMLDivElement>(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1
  })
  const [deferredAction, setDeferredAction] =
    useState<PathCreatorAction | null>(null)

  const reloadData = useReloadData()

  const flowProps = usePathCreatorFlow({
    onComplete: async (completion) => {
      await reloadData(completion)
      setPathCreatorCompletion(completion)
    }
  })

  // Here we augment the pathCreatorDispatch in order to defer the initial action
  // while we wait for the flow to scroll into view. Once scrolled into view, we
  // apply the deferred action.
  const augmentedFlowProps = {
    ...flowProps,
    pathCreatorDispatch(action: PathCreatorAction) {
      if (
        (action.type === "set" &&
          action.value.status === "create_or_select_resource") ||
        (action.type === "set_status" &&
          action.status === "create_or_select_resource")
      ) {
        setDeferredAction(action)
      } else {
        flowProps.pathCreatorDispatch(action)
      }
    }
  }

  const { pathCreatorState, historyDispatch } = augmentedFlowProps

  // If user hasn't progressed in any meaningful way through the flow
  // and they click outside then close out the flow.
  useOutsideClick({
    ref: flowRef,
    handler: () => {
      if (pathCreatorState.status === "create_or_select_resource") {
        historyDispatch({
          type: "reset"
        })
      }
    }
  })

  // In order to transition the width of the flow, we must use a specific value (not "auto").
  // So, when the intersection ref node is intersecting, we use the width of the flow itself
  // as the start width to transition from, transitioning to 100% width.
  const stickyWidth = intersection?.isIntersecting
    ? (flowRef?.current?.firstChild as HTMLElement)?.getBoundingClientRect?.()
        ?.width
    : "auto"

  // When PathCreatorFlow is in "initial" state, we position
  // it sticky to the bottom.
  const stickyProps: BoxProps =
    pathCreatorState.status === "initial"
      ? {
          position: "sticky",
          bottom: "40px",
          backdropFilter: "blur(20px)",
          // Contain the filter to the wrapped button (matching the button's border radius).
          borderRadius: "50px",
          zIndex: 10,
          w: `${stickyWidth}px`
        }
      : {}

  // When we have a deferred action, we scroll the flow into view.
  useEffect(() => {
    if (deferredAction && !intersection?.isIntersecting) {
      intersectionRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [deferredAction])

  // Once flow comes into view, apply the deferred action.
  useEffect(() => {
    if (deferredAction && intersection?.isIntersecting) {
      flowProps.pathCreatorDispatch(deferredAction)
      setDeferredAction(null)
    }
  }, [intersection, deferredAction])

  return (
    <>
      <Box
        ref={flowRef}
        w="100%"
        transition="width 100ms ease"
        // TODO: ensure we include enough height for the select menus
        // (calculate in some way)?
        mb="400px"
        {...stickyProps}
      >
        <PathCreatorFlow targetUserId={me.id!} {...augmentedFlowProps} />
      </Box>
      <Box ref={intersectionRef}></Box>
    </>
  )
}

export default AddPathButton
