export default {
  type: "Button",
  icon: "text-cursor", // TODO: pathicon text => text-cursor
  helpLink: "http://help.pathwright.com/en/articles/4263727-meeting-block-new",
  menu: {
    category: "interactive",
    order: 1
  },
  layouts: {
    button: {
      key: "button",
      label: "Button",
      data: {
        type: null,
        link: "",
        isActive: true,
        buttonLabel: "View Link"
      }
    }
  },
  schema: `
    type Button implements Block {
      id: ID!
      description: String
      link: String
      buttonLabel: String
    }
  `
}
