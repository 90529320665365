import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LazyAdaptedAuthContainer from "../../auth/LazyAdaptedAuthContainer"
import LazyAdaptedSignedInContainer from "../../auth/LazyAdaptedSignedInContainer"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

const RedeemSchoolGiftSubscriptionAuthGate = (props) => {
  const { me, school } = usePathwrightContext()
  const { t } = useTranslate()

  return me ? <LazyAdaptedSignedInContainer /> : <LazyAdaptedAuthContainer />
}

RedeemSchoolGiftSubscriptionAuthGate.displayName =
  "RedeemSchoolGiftSubscriptionAuthGate"

export default RedeemSchoolGiftSubscriptionAuthGate
