import { Flex, LightMode } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValueRemoveProps,
  chakraComponents
} from "chakra-react-select"
import { snakeCase } from "change-case"

type WithCustomSelectProps<T extends any, CustomSelectProps> = T & {
  selectProps: CustomSelectProps
}

export function getSelectCustomComponents<
  Option,
  IsMulti extends boolean,
  CustomSelectProps extends any = any
>() {
  type Group = GroupBase<Option>

  const ClearIndicator = (
    props: WithCustomSelectProps<
      ClearIndicatorProps<Option, IsMulti, Group>,
      CustomSelectProps
    >
  ) => (
    <chakraComponents.ClearIndicator {...props}>
      <Pathicon
        icon="x"
        size="1.4em"
        data-testid={snakeCase(
          `${props.selectProps["aria-label"] || ""} clear all`
        )}
      />
    </chakraComponents.ClearIndicator>
  )

  const DropdownIndicator = (
    props: WithCustomSelectProps<
      DropdownIndicatorProps<Option, IsMulti, Group>,
      CustomSelectProps
    >
  ) => (
    <chakraComponents.DropdownIndicator {...props}>
      <Pathicon icon="chevron-down" />
    </chakraComponents.DropdownIndicator>
  )

  const MultiValueRemove = (
    props: WithCustomSelectProps<
      MultiValueRemoveProps<Option, IsMulti, Group>,
      CustomSelectProps
    >
  ) => (
    <chakraComponents.MultiValueRemove {...props}>
      <Flex cursor="pointer">
        <Pathicon icon="x" size=".85em" />
      </Flex>
    </chakraComponents.MultiValueRemove>
  )

  // Ensure we render the menu in light mode.
  const Menu = (
    props: WithCustomSelectProps<
      MenuProps<Option, IsMulti, Group>,
      CustomSelectProps
    >
  ) => (
    <LightMode>
      <chakraComponents.Menu {...props} />
    </LightMode>
  )

  return {
    Menu,
    ClearIndicator,
    DropdownIndicator,
    MultiValueRemove
  }
}
