import Card from "@pathwright/ui/src/components/card/Card"
import StripeElementsForm from "@pathwright/web/src/modules/lib/StripeElementsForm"
import ConnectStores from "lib/core/store/components/ConnectStores"

const UpdatePaymentCard = ({
  card,
  publishable_key,
  payment_requires_zip_code,
  card_last_4,
  updatePayment
}) => {
  return (
    <Card title={`Update Card • ${card_last_4}`} card={card}>
      <div style={{ padding: "20px" }}>
        <StripeElementsForm
          apiKey={publishable_key}
          hidePostalCode={!payment_requires_zip_code}
          onToken={(token) => updatePayment(token).promise}
        />
      </div>
    </Card>
  )
}

UpdatePaymentCard.displayName = "UpdatePaymentCard"

const mapStateToProps = function (state, ownProps) {
  const { updatePayment } = state.profileStore.action
  const { publishable_key } = state.schoolStore.state.integrations.stripe
  const { payment_requires_zip_code } = state.schoolStore.state.commerce
  const { subscription } = state.subscribeStore.state

  let card_last_4 = ""
  if (subscription != null ? subscription.subscription : undefined) {
    ;({ card_last_4 } = subscription.subscription)
  }

  const props = _.assign(
    {},
    {
      publishable_key,
      payment_requires_zip_code,
      card_last_4,
      updatePayment
    },
    ownProps
  )
  return props
}

const stores = [
  { store: "profile", watch: false },
  { store: "school", watch: false },
  { store: "subscribe", watch: ["subscription"] }
]

export default ConnectStores(UpdatePaymentCard, stores, {}, mapStateToProps)
