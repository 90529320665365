import { HStack, LightMode, Link, Text, VStack } from "@chakra-ui/react"
import {
  FormatOptionLabelMeta,
  GroupBase,
  MultiValue,
  OptionBase,
  OptionProps,
  Props,
  Select,
  SingleValue,
  chakraComponents,
  useChakraSelectProps
} from "chakra-react-select"
import { useEffect, useMemo, useRef, useState } from "react"
import { usePreviousDistinct } from "react-use"
import Pathicon from "../../pathicon/Pathicon"
import RoleBadge, {
  RoleOption,
  getRoleOptions,
  registrationRoleEnumMap,
  serializeRoleBadgesToRegistrationRoles
} from "./RoleBadge"
import { getSelectCustomComponents } from "./components/select"
import { CohortRole, ResourceRole } from "./state"

interface SelectRoleOption extends RoleOption, OptionBase {}

const selectComponents = getSelectCustomComponents<
  SelectRoleOption,
  boolean,
  SelectCustomProps
>()

const MultiValueRemove = (
  props: Parameters<typeof selectComponents.MultiValueRemove>[0]
) =>
  // Only allowing option to be cleared if not found in initially selected options.
  isOptionClearable({
    option: props.data,
    initiallySelectedOptions: props.selectProps._initiallySelectedOptions
  }) ? (
    <selectComponents.MultiValueRemove {...props} />
  ) : null

// Add learn more link to menu.
const Menu = (props: Parameters<typeof selectComponents.Menu>[0]) => {
  const selectedOptions = props.selectProps
    .value as MultiValue<SelectRoleOption>

  return (
    <LightMode>
      <selectComponents.Menu {...props}>
        {props.children}
        <VStack spacing={0} py={2}>
          {selectedOptions.map((option, i) => (
            <HStack key={option.value}>
              {i > 0 && <Text as="span">+</Text>}
              {/* @ts-expect-error: icon is string, no IconName */}
              {!!option.badge.icon && <Pathicon icon={option.badge.icon} />}
              <Text as="span">{option.description}</Text>
            </HStack>
          ))}
        </VStack>
        <Link
          href="https://help.pathwright.com/en/articles/2083448-pathwright-member-roles"
          target="_blank"
          textDecor="underline"
          cursor="pointer"
        >
          <HStack p={2} justifyContent="center">
            <Pathicon icon="lightbulb" />
            <Text as="span">Learn more about roles...</Text>
          </HStack>
        </Link>
      </selectComponents.Menu>
    </LightMode>
  )
}

// Ensure we render the menu in light mode.
const Option = (
  props: OptionProps<SelectRoleOption, boolean, GroupBase<SelectRoleOption>> & {
    selectProps: SelectCustomProps
  }
) => {
  function getIsOptionClearable(option: SelectRoleOption) {
    return isOptionClearable({
      option,
      initiallySelectedOptions: props.selectProps._initiallySelectedOptions
    })
  }

  return (
    <chakraComponents.Option
      {...props}
      isDisabled={!getIsOptionClearable(props.data)}
    />
  )
}

// Pass any custom props to components
type SelectCustomProps = {
  _initiallySelectedOptions?: SelectRoleOption[]
}

// Note: Typescript is not able to infer types for chakra-react-select some reason.
// Have to explicitly type the generics: https://react-select.com/typescript
type SelectProps = Props<
  SelectRoleOption,
  boolean,
  GroupBase<SelectRoleOption>
> &
  SelectCustomProps

function useCustomChakraSelectProps(): SelectProps {
  return useChakraSelectProps({
    chakraStyles: {
      container: (provided, state) => ({
        ...provided,
        w: "100%"
      }),
      option: (provided, state) => ({
        ...provided,
        color: "gray.900",
        w: "initial",
        paddingInline: ".4rem",
        bg: "transparent",
        _hover: {
          bg: "transparent"
        },
        opacity: state.isSelected ? 1 : state.isFocused ? 0.6 : 0.45,
        _disabled: {
          cursor: "not-allowed",
          opacity: state.isSelected ? 1 : 0.4
        }
      }),
      menu: (provided, state) => ({
        ...provided,
        bg: "white",
        borderRadius: "xl",
        // Hide overflowing bg of focused/selected items at start/end of menu.
        overflow: "hidden"
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: 0,
        border: "none",
        bg: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }),
      placeholder: (provided) => ({
        ...provided,
        paddingLeft: "2px",
        fontSize: { base: "sm", md: undefined },
        noOfLines: 1
      }),
      control: (provided, state) => ({
        ...provided,
        padding: "0 .4em",
        border: "1px solid",
        borderColor: "gray.100",
        color: "gray.900",
        borderRadius: "xl"
      }),
      clearIndicator: (provided) => ({
        ...provided,
        m: 0,
        mr: 1
      }),
      valueContainer: (provided) => ({
        ...provided,
        pl: 0
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        ml: 0
      })
    }
  })
}

function sortRoleOptionsByRole(roleOptions: SelectRoleOption[]) {
  // Sort higest roles to front for consistent ordering.
  const sortedRoleOptions = roleOptions.sort((optionA, optionB) => {
    function getIndex(option: SelectRoleOption) {
      return ["design", "teach", "learn"].findIndex(
        (role) => option.value === role
      )
    }
    // Sort null values to the end.
    const valueA = getIndex(optionA)
    const valueB = getIndex(optionB)
    return valueA - valueB
  })

  return sortedRoleOptions
}

function isOptionClearable({
  option,
  initiallySelectedOptions
}: {
  option: SelectRoleOption
  initiallySelectedOptions?: SelectRoleOption[]
}) {
  // User can clear option as long as it isn't found in the initial values.
  const isClearable = initiallySelectedOptions?.every(
    (selectedOption) => option.value !== selectedOption.value
  )

  return Boolean(isClearable)
}

type Roles = {
  resourceRole: ResourceRole
  cohortRole: CohortRole
}

export type RoleSelectorOnChange = (value: Roles) => void

type RoleSelectorProps = {
  value: Roles
  onChange: RoleSelectorOnChange
}

function RoleSelector({ value, onChange }: RoleSelectorProps) {
  const [selectedOptions, setSelectedOptions] = useState<SelectRoleOption[]>([])
  const chakraSelectProps = useCustomChakraSelectProps()
  const options = getRoleOptions()

  function formatOptionLabel(
    option: SelectRoleOption,
    meta: FormatOptionLabelMeta<SelectRoleOption>
  ) {
    // // Contextualize the option label, more verbose version used in menu.
    // return meta.context === "menu" ? null : (
    //   <RoleBadge badge={option.badge} />
    // )
    return <RoleBadge badge={option.badge} />
  }

  // Ensure the selected options contain the non-clearable options.
  function handleChange(
    selected: SingleValue<SelectRoleOption> | MultiValue<SelectRoleOption>
  ) {
    // Type as selected options, forcing array.
    let nextSelectedOptions = selected as SelectRoleOption[]

    // Add back any previously selected option that is not clearable,
    // preventing multi-remove from removing non-clearable options.
    const nonClearableOptions = selectedOptions
      // Keep options that aren't clearable.
      .filter(
        (option) =>
          !isOptionClearable({
            option,
            initiallySelectedOptions: initiallySelectedOptionsRef.current
          })
      )
      // Keep options that aren't already selected.
      .filter(
        (option) =>
          !nextSelectedOptions.some(
            (selectedOption) => selectedOption.value === option.value
          )
      )

    // Merge the two lists.
    const mergedSelectedOptions = [
      ...nextSelectedOptions,
      ...nonClearableOptions
    ]

    // Finally, set the selected options.
    setSelectedOptions(sortRoleOptionsByRole(mergedSelectedOptions))
  }

  const preivousSelected = usePreviousDistinct(selectedOptions)

  // Whenever the selected options change, fire an onChange callback with the roles.
  // We must serialize the selected options back into registration roles.
  useEffect(() => {
    // Only call onChange when local state has changed from initial.
    if (preivousSelected) {
      onChange(serializeRoleBadgesToRegistrationRoles(selectedOptions))
    }
  }, [selectedOptions])

  // Get the selected options
  function getSelectedOptions(roles: Roles) {
    const { resourceRole, cohortRole } = roles

    const selectedOptions = new Set<RoleOption>([])

    if (resourceRole) {
      for (const roleOption of registrationRoleEnumMap.get(resourceRole)!
        .options) {
        selectedOptions.add(roleOption)
      }
    }
    if (cohortRole) {
      for (const roleOption of registrationRoleEnumMap.get(cohortRole)!
        .options) {
        selectedOptions.add(roleOption)
      }
    }

    return sortRoleOptionsByRole([...selectedOptions])
  }

  // Sync local state with parent.
  useEffect(() => {
    setSelectedOptions(getSelectedOptions(value))
  }, [value.resourceRole, value.cohortRole])

  // Store the initially selected options so that we can ensure
  // they are not removable. This means that a user can not unselect
  // a role they already have in a path/cohort.
  const initiallySelectedOptionsRef = useRef(
    useMemo(() => getSelectedOptions(value), [])
  )

  // Determine if user can clear the select input based on if there are any
  // currently selected options that are clearable.
  const isClearable = useMemo(() => {
    return selectedOptions.some((option) =>
      isOptionClearable({
        option,
        initiallySelectedOptions: initiallySelectedOptionsRef.current
      })
    )
  }, [value])

  // User can only select 2 options. We know that a cohort role is required,
  // but we can't pass a fn to Select to assess if the immediately selected
  // item should permit closing the menu.
  // We can assume the user will select a second item when there is only 1
  // selected item, and replace a selected item when there are 2 selected items.
  const closeMenuOnSelect = selectedOptions.length >= 1

  return (
    <Select
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      aria-label="Select roles"
      placeholder="Your roles..."
      variant="unstyled"
      isClearable={isClearable}
      escapeClearsValue
      hideSelectedOptions={false}
      isMulti={true}
      isSearchable={false}
      autoFocus
      openMenuOnFocus
      closeMenuOnSelect={closeMenuOnSelect}
      _initiallySelectedOptions={initiallySelectedOptionsRef.current}
      {...{
        ...chakraSelectProps,
        components: {
          ...chakraSelectProps,
          ...selectComponents,
          // Locally custom components.
          MultiValueRemove,
          Menu,
          Option
        }
      }}
    />
  )
}

export default RoleSelector
