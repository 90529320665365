import { Box, Flex } from "@chakra-ui/react"
import { isMobile } from "@pathwright/media-utils"
import throttle from "lodash/throttle"
import React, { useEffect, useReducer, useRef } from "react"
import AudioProgress from "./AudioProgress"
import DownloadButton from "./DownloadButton"
import PlayButton from "./PlayButton"
import Playlist from "./Playlist"
import SkipButton from "./SkipButton"
import SpeedSelector from "./SpeedSelector"
import TrackTime from "./TrackTime"
import Volume from "./Volume"
import {
  ControlsCenter,
  ControlsLeft,
  ControlsRight,
  ControlsRow,
  SimpleSkin
} from "./ui"

// Define the structure for playlist items
interface AudioSource {
  src: string
  name?: string
}

// Props for the CustomAudioPlayer component
interface CustomAudioPlayerProps {
  src?: string
  playlist?: AudioSource[]
  playing?: boolean
  enablePlaylist?: boolean
  onPlay?: () => void
  showControls?: boolean
  autoPlay?: boolean
  renderPlayButton?: (props: {
    playing: boolean
    onTogglePlaying: () => void
  }) => React.ReactNode
  trackTitle?: string
  customStyles?: React.CSSProperties
  allowDownload?: boolean
}

// State for the audio player
interface AudioPlayerState {
  playing: boolean
  scrubbingProgress: boolean
  currentProgress: number
  currentTime: number
  duration: number
  audioElement: HTMLAudioElement | null
  volume: number
  playbackRate: number
  currentSource: string
  metadataLoaded: boolean
}

// Action type for the reducer
type Action = Partial<AudioPlayerState>

const initialState = ({
  src,
  playlist,
  playing
}: Pick<
  CustomAudioPlayerProps,
  "src" | "playlist" | "playing"
>): AudioPlayerState => ({
  playing: playing || false,
  scrubbingProgress: false,
  currentProgress: 0,
  currentTime: 0,
  duration: 0,
  audioElement: null,
  volume: 0.5,
  playbackRate: 1,
  currentSource: src || (playlist && playlist[0]?.src) || "",
  metadataLoaded: false
})

const reducer = (
  state: AudioPlayerState,
  action: Action
): AudioPlayerState => ({
  ...state,
  ...action
})

const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = (props) => {
  const {
    src,
    playlist,
    playing,
    enablePlaylist,
    onPlay,
    showControls = true,
    autoPlay = false,
    renderPlayButton,
    trackTitle,
    customStyles = {},
    allowDownload = true,
    ...audioProps
  } = props

  const [state, setState] = useReducer(reducer, props, initialState)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  if (!src && (!playlist || playlist.length === 0)) {
    throw new Error("Please supply an audio source or non-empty playlist")
  }

  useEffect(() => {
    const audioElement = audioRef.current
    if (!audioElement) return

    const onAudioLoad = () => {
      setState({ duration: audioElement.duration, metadataLoaded: true })
    }

    audioElement.addEventListener("loadedmetadata", onAudioLoad)
    return () => {
      audioElement.removeEventListener("loadedmetadata", onAudioLoad)
    }
  }, [])

  useEffect(() => {
    if (src) {
      setState({ currentSource: src })
      resetAudio()
    }
  }, [src])

  useEffect(() => {
    if (playing !== undefined && playing !== state.playing) {
      handleTogglePlaying({ restart: true })
    }
  }, [playing])

  const initializeAudio = (audioElement: HTMLAudioElement) => {
    if (!audioElement || audioRef.current) return
    audioRef.current = audioElement

    setState({
      audioElement,
      volume: audioElement.volume,
      playbackRate: audioElement.playbackRate
    })

    audioElement.addEventListener(
      "timeupdate",
      throttle(onTimeUpdate, 250, { leading: true, trailing: false })
    )
  }

  const handleTogglePlaying = ({
    restart = false
  }: { restart?: boolean } = {}) => {
    if (!audioRef.current || !state.metadataLoaded) return

    setState({ playing: !state.playing, duration: audioRef.current.duration })
    if (restart) audioRef.current.currentTime = 0

    state.playing ? audioRef.current.pause() : audioRef.current.play()
  }

  const onTimeUpdate = () => {
    if (!audioRef.current) return

    const { currentTime, duration } = audioRef.current

    // Safeguard to ensure duration is a valid number
    if (!duration || isNaN(duration) || duration <= 0) return

    setState({
      currentTime,
      currentProgress: (currentTime / duration) * 100 || 0
    })
  }

  const handleSeekProgress = (progress: number) => {
    if (!audioRef.current || progress < 0 || progress > 100) return

    const duration = audioRef.current.duration

    // Safeguard to ensure duration is a valid number
    if (!duration || isNaN(duration) || duration <= 0) {
      console.warn("Duration is not valid or metadata not loaded yet.")
      return
    }

    const newTime = (duration * progress) / 100

    // Ensure newTime is a finite value
    if (isNaN(newTime) || !isFinite(newTime)) {
      console.warn("Computed new time is invalid:", newTime)
      return
    }

    audioRef.current.currentTime = newTime

    setState({
      currentTime: newTime,
      currentProgress: progress
    })
  }

  const handleSetVolume = (volumePercentage: number) => {
    if (!audioRef.current) return

    const volume = volumePercentage / 100
    audioRef.current.volume = volume

    setState({ volume })
  }

  const handleSetSpeed = (speed: number) => {
    if (!audioRef.current) return

    audioRef.current.playbackRate = speed
    setState({ playbackRate: speed })
  }

  const resetAudio = () => {
    if (!audioRef.current) return

    audioRef.current.pause()
    audioRef.current.currentTime = 0
    setState({ playing: false, volume: 0.5, playbackRate: 1 })
  }

  const renderControls = () => (
    <Flex
      direction="column"
      align="center"
      w="100%"
      maxW="500px"
      style={customStyles}
    >
      {enablePlaylist && playlist && (
        <ControlsRow>
          <Playlist
            sources={playlist}
            current={{ src: state.currentSource }}
            onSelectSource={(index) =>
              setState({ currentSource: playlist[index].src })
            }
          />
        </ControlsRow>
      )}
      <ControlsRow>
        <Box
          fontWeight="bold"
          textAlign="center"
          mt="15px"
          color="#282e32"
          aria-label={`track: ${trackTitle || "Current Audio"}`}
        >
          {trackTitle || "Current Audio"}
        </Box>
      </ControlsRow>
      <ControlsRow rowOnMobile>
        <TrackTime type="start" {...state} />
        <AudioProgress
          progress={state.currentProgress}
          onSeek={handleSeekProgress}
        />
        <TrackTime type="end" {...state} />
      </ControlsRow>
      <ControlsRow rowOnMobile={false}>
        <ControlsLeft>
          {!isMobile() && (
            <Volume
              currentVolume={state.volume}
              onSetVolume={handleSetVolume}
            />
          )}
        </ControlsLeft>
        <ControlsCenter>
          <SkipButton
            onSkip={() => handleSeekProgress(state.currentProgress - 15)}
            type="prev"
          />
          <PlayButton
            playing={state.playing}
            onTogglePlaying={handleTogglePlaying}
          />
          <SkipButton
            onSkip={() => handleSeekProgress(state.currentProgress + 15)}
            type="next"
          />
        </ControlsCenter>
        <ControlsRight>
          <SpeedSelector
            currentSpeed={state.playbackRate}
            onSelectSpeed={handleSetSpeed}
          />
          {allowDownload && <DownloadButton source={state.currentSource} />}
        </ControlsRight>
      </ControlsRow>
    </Flex>
  )

  return (
    <SimpleSkin customStyles={customStyles} className="BlocksAudioPlayer">
      <audio
        {...audioProps}
        ref={(el) => el && initializeAudio(el)}
        src={state.currentSource}
        onPlay={onPlay}
        autoPlay={autoPlay}
        preload="metadata"
      />
      {showControls
        ? renderControls()
        : renderPlayButton?.({
            playing: state.playing,
            onTogglePlaying: handleTogglePlaying
          })}
    </SimpleSkin>
  )
}

export default CustomAudioPlayer
