import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react"
import React from "react"

export const AudioButtonUI = (props) => {
  return (
    <IconButton
      tabIndex={0}
      width="30px"
      height="30px"
      m={["0", "0", "0 15px"]}
      size="sm"
      variant="ghost"
      colorScheme="gray"
      cursor="pointer"
      aria-label={props["aria-label"] || "Audio Button"}
      _hover={{
        bg: "transparent"
      }}
      sx={{
        i: {
          color: "rgb(41, 46, 53)"
        }
      }}
      {...props}
    />
  )
}

export const TrackTimeUI = (props) => (
  <Text color="#747474" fontSize="0.9em" {...props} />
)

export const SimpleSkin = (props) => (
  <Flex
    justify="center"
    maxW="100%"
    mx="auto"
    sx={{
      "audio:not([controls])": {
        display: "none !important"
      },
      "*": {
        color: props.customStyles?.styleAttributes?.color || "rgb(41, 46, 53)"
      }
      // ${TrackTitle},
      // ${TrackTimeUI},
      // ${SpeedSelectorUI} button,
      // ${AudioButtonUI} i.Pathicon,
      // ${SkipButtonUI} .Button:after {
      //   color: ${(p) =>
      //     p.customStyles ? p.customStyles.styleAttributes.color : ""};
      // }
    }}
    {...props}
  />
)

export const SpeedSelectorUI = (props) => <Box {...props} />

export const SpeedButton = (props) => (
  <Button
    color="rgb(102, 102, 102)"
    bg="none"
    border="none"
    outline="none"
    w="50px"
    _hover={{ cursor: "pointer" }}
    fontSize="16px"
    lineHeight={1}
    {...props}
  />
)

export const ControlsRow = ({ rowOnMobile = false, ...props }) => (
  <Flex
    align="center"
    justify="center"
    letterSpacing="1px"
    w="100%"
    mb="20px"
    maxW={["100%", "100%", "500px"]}
    flexDirection={[
      rowOnMobile ? "row" : "column",
      rowOnMobile ? "row" : "column",
      "row"
    ]}
    {...props}
  />
)

const ControlsSection = (props) => (
  <Flex
    flex="1 1 33%"
    justify="center"
    align="center"
    flexBasis={["100%", "100%", "33%"]}
    mx={["0", "0", "15px"]}
    {...props}
  />
)

export const ControlsLeft = (props) => <ControlsSection {...props} />
export const ControlsRight = (props) => <ControlsSection {...props} />
export const ControlsCenter = (props) => (
  <ControlsSection mx="15px" {...props} />
)
