import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ChakraTooltip from "@pathwright/ui/src/components/tooltip/ChakraTooltip"
import classnames from "classnames"
import get from "lodash/get"
import moment from "moment"
import PropTypes from "prop-types"
import { useMemo } from "react"
import styled from "styled-components"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

// Util for getting the most recent date between a the last_seen and
// last_active dates on a user's school membership.
export const getMaxLastSeen = (user, membership) => {
  const dates = [
    get(user, "membership.last_seen"),
    get(user, "membership.created"),
    get(membership, "last_seen"),
    get(membership, "created")
  ]
    .filter(Boolean)
    .filter((date) => moment(date).year() > 1970) // ensure time isn't epoch

  if (dates.length) {
    // Convert dates to moments.
    const moments = dates.filter(Boolean).map((date) => moment(date))
    // Then get the most recent moment of those.
    const maxMoment = moment.max(moments)
    return maxMoment
  }
}

export const useMaxLastSeen = (user, membership) => {
  const pwContext = usePathwrightContext()
  user = user || pwContext.me

  return useMemo(() => {
    if (user || membership) return getMaxLastSeen(user, membership)
  }, [user])
}

const Container = styled.div`
  display: flex;
  align-items: center;

  label {
    font-weight: normal;
    margin-left: 0.2em;
  }
`

const MaxLastSeen = ({ className, user, membership, children }) => {
  const { t } = useTranslate()
  const maxLastSeen = useMaxLastSeen(user, membership)
  const tooltip = t("Last active {{ date }}", {
    date: maxLastSeen ? maxLastSeen.format("MMM Do, YYYY") : ""
  })

  if (!maxLastSeen) return null

  const node = (
    <Container>
      <Pathicon icon="clock" />
      <label>{moment(maxLastSeen).fromNow()}</label>
    </Container>
  )

  return (
    <ChakraTooltip
      className={classnames("MaxLastSeen", className)}
      title={tooltip}
    >
      {children ? children(node) : node}
    </ChakraTooltip>
  )
}

MaxLastSeen.displayName = "MaxLastSeen"

MaxLastSeen.propTypes = {
  user: PropTypes.object,
  membership: PropTypes.object
}

export default MaxLastSeen
