import {
  Box,
  Button,
  HStack,
  IconProps,
  Text,
  UseRadioGroupReturn,
  useRadio,
  useRadioGroup
} from "@chakra-ui/react"
import { IconName } from "@pathwright/pathicons"
import Pathicon from "../../pathicon/Pathicon"

function HomeAugmentationRadio(
  props: ReturnType<UseRadioGroupReturn["getRadioProps"]>
) {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        as={Button}
        {...checkbox}
        cursor="pointer"
        borderRadius="20px"
        variant={input.checked ? "outline" : "ghost"}
        size="sm"
      >
        {props.children}
      </Box>
    </Box>
  )
}

export type HomeAugmentationFilter = {
  key: string
  icon: IconName
  iconProps: IconProps
  label: string
}

type HomeAugmentationFiltersProps = {
  value: HomeAugmentationFilter["key"]
  onChange: (key: HomeAugmentationFilter["key"]) => void
  filters: HomeAugmentationFilter[]
}

function HomeAugmentationFilters({
  value,
  onChange,
  filters
}: HomeAugmentationFiltersProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "HomeAugmentationFilters",
    value,
    onChange
  })

  return (
    <HStack {...getRootProps()} w="100%" justifyContent="flex-start">
      {filters.map((option) => (
        <HomeAugmentationRadio
          key={option.key}
          {...getRadioProps({ value: option.key })}
        >
          <HStack>
            <Pathicon icon={option.icon} {...option.iconProps} />
            <Text as="span">{option.label}</Text>
          </HStack>
        </HomeAugmentationRadio>
      ))}
    </HStack>
  )
}

export default HomeAugmentationFilters
