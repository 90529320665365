import { withTranslate } from "@pathwright/ui/src/components/"
import Button from "@pathwright/ui/src/components/button/Button"
import Card from "@pathwright/ui/src/components/card/Card"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import ConnectStores from "lib/core/store/components/ConnectStores"
import React from "react"
import "./PaymentHistory.css"

const tPrefix = "payment_history"

class PaymentHistory extends React.Component {
  static initClass() {
    this.displayName = "PaymentHistory"
  }

  componentDidMount() {
    return this.props.loadPaymentHistory()
  }

  render() {
    const {
      card,
      payment_history,
      payment_history_loading,
      payment_history_download_url,
      t
    } = this.props

    return (
      <Card title={t(`${tPrefix}.title`)} card={card}>
        <div className="PaymentHistory">
          <div className="PaymentHistoryHeader">
            <span className="PaymentHistory__item">
              <h6>{t(`${tPrefix}.item`)}</h6>
            </span>
            <span className="PaymentHistory__total">
              <h6>{t(`${tPrefix}.total`)}</h6>
            </span>
            <span className="PaymentHistory__date">
              <h6>{t(`${tPrefix}.date`)}</h6>
            </span>
          </div>
          <div className="PaymentHistoryTable">
            <table>
              <tbody>
                {(() => {
                  if (
                    payment_history != null ? payment_history.length : undefined
                  ) {
                    return Array.from(payment_history).map((payment, index) => (
                      <tr key={`PaymentHistoryEntry${index}`}>
                        <td className="PaymentHistory__item">
                          <span>{payment.item}</span>
                        </td>
                        <td className="PaymentHistory__total">
                          <span>{payment.currency_display}</span>
                        </td>
                        <td className="PaymentHistory__date">
                          <span>{moment(payment.date).format("L")}</span>
                        </td>
                      </tr>
                    ))
                  } else if (!payment_history_loading) {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          {t(`${tPrefix}.no_payments`)}
                        </td>
                      </tr>
                    )
                  }
                })()}
              </tbody>
            </table>
          </div>
        </div>
        {(payment_history != null ? payment_history.length : undefined) ? (
          <Button
            className="PaymentHistory__download"
            styleType="card"
            href={payment_history_download_url}
            download={true}
          >
            <Pathicon icon="download" />
            {` ${t(`${tPrefix}.download_history`)}\
`}
          </Button>
        ) : undefined}
      </Card>
    )
  }
}
PaymentHistory.initClass()

const mapStateToProps = function (state, ownProps) {
  const {
    payment_history,
    payment_history_loading,
    payment_history_download_url
  } = state.profileStore.state
  const { loadPaymentHistory } = state.profileStore.action

  const { t } = ownProps

  const props = _.assign(
    {},
    {
      payment_history,
      payment_history_download_url,
      loadPaymentHistory,
      payment_history_loading
    },
    ownProps
  )
  return props
}

const stores = [
  {
    store: "profile",
    watch: ["payment_history_loading", "payment_history_download_url"]
  },
  { store: "school", watch: false }
]

export default withTranslate(
  ConnectStores(PaymentHistory, stores, {}, mapStateToProps)
)
