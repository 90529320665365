import Card from "@pathwright/ui/src/components/card/Card"
import { ConnectStores } from "lib/core/store"
import ResourceThemeFormContainer from "./ResourceThemeFormContainer"

const ResourceThemeCard = ({ card, title }) => (
  <Card title={title} card={card}>
    <ResourceThemeFormContainer
      card={card}
      onDirty={() => card.setCardDirty(true)}
      onPristine={() => card.setCardDirty(false)}
    />
  </Card>
)

ResourceThemeCard.displayName = "ResourceThemeCard"

const mapStateToProps = function (state, ownProps) {
  const { resource } = state.resourceAdminStore.state

  const title = (resource && resource.name) || "Course"

  return _.assign(
    {},
    {
      resource,
      title
    },
    ownProps
  )
}

const stores = [
  {
    store: "resourceAdmin",
    watch: ["resource"]
  }
]

export default ConnectStores(ResourceThemeCard, stores, {}, mapStateToProps)
