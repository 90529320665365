;`\
Middleware functions take the next location state from react router onEnter handlers
and do stuff with it. 

If a middleware function returns a new location (object with pathname set) then
the router state will be replaced with the returned state with and no further middleware will not be executed.\
`

import { canGoHome } from "belltower/utils"
import path from "path-browserify"

// NOTE: leaveMiddleware is not supplied with the replace function from react routes!
export const composeMiddleware =
  (middlewares, ...rest) =>
  async (nextState, replace, callback) => {
    // Call all middlewares until one returns a redirect or all have been called.
    let redirect = null
    const allMiddlewares = [].concat(middlewares, ...rest).filter(Boolean)
    while (allMiddlewares.length && !redirect) {
      const middleware = allMiddlewares.shift()
      let retVal = middleware(nextState, replace, callback)
      if (retVal) {
        // NOTE: awaiting the returned promise would be safe and good to do but
        // this breaks direct links that depend on loading the path, as the `loadPath`
        // middleware requires that the route Component be rendered as the Component
        // is expected to load the path. Awaiting the promise could lead to an indefinite
        // period of awaiting the path to load.

        // Await potential promise, expecting possible redirect as result.
        // if (retVal instanceof Promise) {
        //   retVal = await retVal
        // }

        if (retVal?.pathname) {
          redirect = retVal
        }
      }
    }

    return redirect ? replace(redirect) : callback?.()
  }

export const authConditional = (nextState) => {
  if (!App.getStore("auth").request.isAuthenticated()) {
    return {
      pathname: `/auth/sign-in/`,
      search: `next=${encodeURIComponent(
        `${nextState.location.pathname}${nextState.location.search}`
      )}`
    }
  }
}

const belltowerHomeConditional = (nextState) => {
  if (nextState.location.pathname.indexOf("/home/") > -1 && !canGoHome()) {
    return { pathname: "/store/" }
  }
}

const adminAccessConditional = (nextState) => {
  const { user } = window.App.getStore("auth").getState()
  if (!user || !user.membership || !user.membership.can_manage_school) {
    return {
      pathname: "/home/"
    }
  }
}

const pathwrightAdminAccessConditional = (nextState) => {
  const { user } = window.App.getStore("auth").getState()
  if (!user || !user.is_superuser) {
    return {
      pathname: path.join(nextState.location.pathname, "../")
    }
  }
}

export const authRedirect = composeMiddleware(authConditional)
export const belltowerHomeRedirect = composeMiddleware(
  authConditional,
  belltowerHomeConditional
)
export const adminAccessRedirect = composeMiddleware(
  authConditional,
  adminAccessConditional
)
export const pathwrightAdminAccessRedirect = composeMiddleware(
  authConditional,
  pathwrightAdminAccessConditional
)
