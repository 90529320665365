import {
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Spinner
} from "@chakra-ui/react"
import classnames from "classnames"
import PropTypes from "prop-types"
import { PureComponent } from "react"
import Pathicon from "../../pathicon/Pathicon"
import { PortableContext } from "../../portable/Portable"
import PathwrightUI from "../../ui/PathwrightUI"

class SearchInput extends PureComponent {
  state = {
    value: ""
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.input.focus()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange = (e) => {
    if (this.props.value == null) {
      this.setState({ value: e.target.value })
    }
    if (this.props.onChange) {
      this.props.onChange(e.target.value, e)
    }
  }

  handleKeyUp = (e) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e.target.value, e)
    }
    if (this.props.onEnter && e.keyCode === 13) {
      this.props.onEnter(e.target.value, e)
    }

    // clear on escape key
    if (e.keyCode === 27) {
      this.handleClear()
    }
  }

  handleClear = (e) => {
    this.setState({ value: "" })
    if (this.props.onClear) {
      this.props.onClear()
    }
  }

  get value() {
    // defaulting to empty string to ensure the input value is controlled
    return this.props.value || this.state.value || ""
  }

  render() {
    const {
      typography,
      inverted,
      onEnter,
      onClear,
      className,
      style,
      icon,
      searching,
      allowFullscreen,
      ...rest
    } = this.props

    const { setPorting, porting } = this.context || {}

    return (
      <PathwrightUI.Consumer contrast={1}>
        {({ backgroundColor, emphasis }) => {
          const iconProps = {
            size: 16
          }

          const borderRadius = "50px"

          return (
            <InputGroup
              className="SearchInput"
              size="sm"
              borderRadius={borderRadius}
              bg={backgroundColor}
            >
              <InputLeftAddon
                borderLeftRadius={borderRadius}
                bg="none"
                border="none"
              >
                <Pathicon icon="search" />
              </InputLeftAddon>
              <Input
                {...rest}
                ref={(ref) => (this.input = ref)}
                type="text"
                className={classnames(className)}
                value={this.value}
                onKeyUp={this.handleKeyUp}
                onChange={this.handleChange}
                borderRadius={borderRadius}
                bg="transparent"
                border="none"
                outline="none"
                background="none"
                fontWeight="normal"
                p={0}
                sx={{
                  ["&:has(+ :empty)"]: {
                    borderRightRadius: borderRadius
                  }
                }}
              />
              <InputRightAddon
                borderRightRadius={borderRadius}
                bg="none"
                border="none"
                _empty={{ display: "none" }}
              >
                {searching ? (
                  <Spinner size="sm" m={2} />
                ) : onClear && this.value ? (
                  <IconButton
                    variant="ghost"
                    size="sm"
                    icon={<Pathicon icon="x" />}
                    onClick={this.handleClear}
                    cursor="pointer"
                    _hover={{
                      bg: "transparent"
                    }}
                  />
                ) : setPorting && allowFullscreen ? (
                  porting ? (
                    <IconButton
                      variant="ghost"
                      size="sm"
                      className="Fullscreen__minimize"
                      icon={<Pathicon icon="minimize" />}
                      onClick={() => setPorting(false)}
                      cursor="pointer"
                      _hover={{
                        bg: "transparent"
                      }}
                    />
                  ) : (
                    <IconButton
                      variant="ghost"
                      size="sm"
                      className="Fullscreen__maximize"
                      icon={<Pathicon icon="maximize" />}
                      onClick={() => setPorting(true)}
                      cursor="pointer"
                      _hover={{
                        bg: "transparent"
                      }}
                    />
                  )
                ) : null}
              </InputRightAddon>
            </InputGroup>
          )
        }}
      </PathwrightUI.Consumer>
    )
  }
}

SearchInput.displayName = "SearchInput"

SearchInput.contextType = PortableContext

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  autoFocus: PropTypes.bool,
  searching: PropTypes.bool
}

SearchInput.defaultProps = {
  placeholder: "Search...",
  icon: "search",
  autoFocus: true,
  searching: false,
  allowFullscreen: false
}

export default SearchInput
