import { gql } from "@apollo/client"
import { getApolloClient } from "@pathwright/web/src/modules/pathwright/PathwrightClient"

// Sanity-check the conversion and remove this comment.
const Offering = require("library/models/offering").default

// TODO: update all "group" language to "offering"

// Sync the cohort name change to all child cohorts.
function syncCollectionCohortNameChange({ id, name }) {
  // We may not be scoped to any store with the respective resource
  // but let's attempt to get the resource name for this cohort.
  const resourceName =
    App.getStore("resource").getState().resource?.name ||
    App.getStore("resourceAdmin").getState().resource?.name
  if (resourceName) {
    // Derive the new child cohort name following the pattern: "<Collection Resource Name>: <Collection Cohort Name>".
    const newChildCohortName = `${resourceName}: ${name}`
    const client = getApolloClient()
    // We must extract the cache data in order to find cached child cohorts.
    const cacheData = client.cache.extract()
    // Find child cohort of this cohort.
    const matchingEntries = Object.entries(cacheData).filter(([key, data]) => {
      return data.__typename === "Group" && data.parent_section_id === id
    })
    // For each child cohort, update the name in the cache.
    matchingEntries.forEach(([key, data]) => {
      client.writeFragment({
        // Scope a Group fragment to the keys we need.
        fragment: gql`
          fragment GroupName on Group {
            id
            name
          }
        `,
        fragmentName: "GroupName",
        id: key,
        data: {
          ...data,
          name: newChildCohortName
        }
      })
    })
  }
}

class GroupAdminStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.defaults = {
      group: null
    }

    this.prototype.resources = {
      group: {
        model: Offering
      }
    }

    this.prototype.actions = {
      saveGroup(groupData) {
        // Cache previous name to see if name changed.
        const prevName = this.get("group")?.get("name")

        return this.resource.group.save(groupData).then((group) => {
          // refetch group for Apollo Client cache
          window.apolloClientStoreBridge.refetchGroup({
            id: group.id
          })

          // If we updated the cohort name, let's attempt to update the names of
          // all child cohorts (we don't know if this is a collection cohort, just by
          // checking the 'group' data).
          if (group.get("name") !== prevName) {
            // Update all child cohorts with name change.
            syncCollectionCohortNameChange({
              id: group.get("id"),
              name: group.get("name")
            })
          }
        })
      },

      deleteGroup(id) {
        return this.resource.group.destroy()
      },

      loadGroup(resource_id, group_id) {
        return this.resource.group.load({
          resource: resource_id,
          id: group_id
        })
      }
    }
  }
}
GroupAdminStore.initClass()

export default window.App.stores.registerStore("groupAdmin", GroupAdminStore)
