import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useSubscriptoinsNeedingActionQuery } from "@pathwright/web/src/modules/subscription/SubscriptionsNeedingAction"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import React from "react"
import LazyAdaptedAuthContainer from "../auth/LazyAdaptedAuthContainer"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import SUBSCRIPTION_QUERY from "../subscription/graphql/subscription-query"

const ConfirmStripePayment = ({ card, subscriptionID }) => {
  const [cardActionError, setCardActionError] = React.useState(null)
  const [cardActionSuccess, setCardActionSuccess] = React.useState(null)
  const [pollInterval, setPollInterval] = React.useState(0)

  const { me } = usePathwrightContext()

  const subscriptionQuery = useQuery(SUBSCRIPTION_QUERY, {
    variables: {
      id: subscriptionID
    },
    skip: !me,
    // refetch subscriptionQuery to clear payment_intent_needing_action_id
    pollInterval
  })

  const subscription = get(subscriptionQuery, "data.subscription")

  // forcing refetch of subcsriptions needing action
  const subscriptionsQuery = useSubscriptoinsNeedingActionQuery({
    pollInterval
  })

  // after successful card payment confirmation, begin polling to clear the payment_intent_needing_action_id
  React.useEffect(() => {
    if (cardActionSuccess) setPollInterval(500)
  }, [cardActionSuccess])

  // end polling once payment_intent_needing_action_id has been cleared
  React.useEffect(() => {
    if (pollInterval) setPollInterval(0)
  }, [get(subscription, "payment_intent_needing_action_id")])

  return (
    <Card
      className="ConfirmStripePayment"
      card={card}
      title={`Confirm your payment information`}
      noaction
    >
      {subscriptionQuery.error && <Alert error={subscriptionQuery.error} />}
      {cardActionError && <Alert error={cardActionError} />}
      {cardActionSuccess && <Alert success={cardActionSuccess} />}
      {subscriptionQuery.loading ? (
        <LoadingCircle />
      ) : !me ? (
        <LazyAdaptedAuthContainer />
      ) : !subscription ? (
        <BlankSlate
          icons={null}
          heading="Not found"
          body={`A subscription with the ID ${subscriptionID} could not be found. The subscription may not exist, or is no longer active.`}
        />
      ) : // NOTE: the subscription.payment_intent_needing_action_id is not cleared immediately on the backend,
      // possibly due to webhook delay, so must rely on cardActionSuccess
      !subscription.payment_intent_needing_action_id || cardActionSuccess ? (
        <BlankSlate
          icons={null}
          heading="No action required"
          body={`The subscription with the ID ${subscriptionID} does not require any confirmation at this time.`}
        />
      ) : (
        <BlankSlate
          icons={null}
          heading={"Confirmation required"}
          body={`Your card issuer has requested that you reconfirm your subscription payment information.`}
          primaryAction={{
            onClick: async () => {
              const stripe = new window.Stripe(
                subscription.stripe_publishable_api_key
              )
              try {
                const result = await stripe.confirmCardPayment(
                  subscription.payment_intent_client_secret
                )
                // TODO: better success message?
                setCardActionSuccess("Success!")
              } catch (error) {
                setCardActionError(error)
              }
            },
            children: "Confirm Payment"
          }}
        />
      )}
    </Card>
  )
}

export default ConfirmStripePayment
