import get from "lodash/get"
import { withMutationProps } from "../../utils/apollo"
import {
  SUBSCRIPTION_CREATED_EVENT,
  TRANSACTION_EVENT,
  dispatchGlobalEvent
} from "../../utils/dispatcher"
import REDEEM_SCHOOL_GIFT_SUBSCRIPTION from "./redeem-school-gift-subscription-mutation"

const withConfiguredMutationProps = withMutationProps({
  name: "redeemSchoolGiftSubscription",
  key: "redeem_school_subscription_mutation"
})

export default (C) =>
  withConfiguredMutationProps(REDEEM_SCHOOL_GIFT_SUBSCRIPTION, {
    props: ({ mutate }) => ({
      redeemSchoolGiftSubscription: async ({
        payment_token,
        school_subscription_plan_id,
        gift_subscription,
        coupon
      }) => {
        const result = await mutate({
          variables: {
            school_subscription_plan_id,
            payment_token,
            gift_code: gift_subscription ? gift_subscription.gift_code : null,
            coupon_code: coupon ? coupon.code : null
          },
          refetchQueries: ["UserSchoolSubscriptionQuery"],
          onCompleted: () => {
            // Reload legacy backbone subscription.
            window.App?.getStore("subscribe")?.action?.loadUserSubscription?.(
              true
            )
          }
        })

        const subscription = get(
          result,
          "data.redeemSchoolGiftSubscription.subscription"
        )

        if (subscription) {
          const amount = gift_subscription
            ? 0
            : coupon
            ? coupon.discounted_total
            : subscription.subscription_plan.amount
          const data = {
            transaction: {
              id: null,
              method: "subscription",
              amount,
              discount: gift_subscription
                ? {
                    code: gift_subscription.gift_code,
                    amount:
                      gift_subscription.gifted_subscription_cycles *
                      subscription.subscription_plan.amount,
                    percent: 1,
                    cycles: gift_subscription.gifted_subscription_cycles
                  }
                : coupon
                ? {
                    code: coupon.code,
                    amount:
                      coupon.discounted_subscription_cycles *
                      coupon.discount_percent *
                      subscription.subscription_plan.amount,
                    percent: coupon.discount_percent,
                    cycles: coupon.discounted_subscription_cycles
                  }
                : null
            },
            user: {
              id: subscription.customer.id,
              email: subscription.customer.email,
              firstName: subscription.customer.first_name,
              lastName: subscription.customer.last_name,
              fullName: subscription.customer.full_name
            },
            product: {
              plan: {
                id: subscription.subscription_plan.id,
                name: subscription.subscription_plan.name,
                amount: subscription.subscription_plan.amount,
                type: subscription.subscription_plan.plan_type
              }
            }
          }

          if (amount > 0) {
            dispatchGlobalEvent(TRANSACTION_EVENT, {
              ...data.transaction,
              user: data.user,
              product: data.product
            })
          }
          dispatchGlobalEvent(SUBSCRIPTION_CREATED_EVENT, {
            id: subscription.id,
            isCanceledAtCycleEnd: subscription.is_canceled_at_cycle_end,
            cycleEndDate: subscription.cycle_end_dtime,
            cycleStartDate: subscription.cycle_start_dtime,
            transaction: data.transaction,
            user: data.user,
            ...data.product
          })
        }
      }
    })
  })(C)
