const env = process.env.NODE_ENV || "development"
const moment = require("moment")
const { getAPIUrl, createSchoolUrl } = require("lib/core/api/urls")
const Storage = require("lib/utils/store").default
require("whatwg-fetch")
import { getEnv } from "@pathwright/ui/src/components/utils/env"

const locStorage = new Storage()

const getAuthToken = () => locStorage.getItem("auth_token")

const parseErrorMessage = function (response, error) {
  // pull out first error if array
  let errorMessage
  if (_.isArray(error)) {
    error = error[0]
  }
  // parse error
  if (_.isObject(error)) {
    if (error.error) {
      errorMessage = error.error
    }
    if (error.detail) {
      errorMessage = error.detail
    }
    if (error.message) {
      errorMessage = error.message
    }
    // server side validation errors
    if (!error.error && !error.detail && !error.message) {
      errorMessage = JSON.stringify(error)
    }
  } else if (_.isString(error)) {
    errorMessage = error
  } else {
    errorMessage = response.statusText
  }
  return errorMessage
}

const handleResponse = (response) =>
  new Promise(function (resolve, reject) {
    // delete (NO CONTENT)
    if (response.status === 204) {
      return resolve()
    } else if (
      response.status >= 200 &&
      response.status <= 300 &&
      !response.redirected
    ) {
      return resolve(response.json())
    } else {
      return response
        .json()
        .then(function (data) {
          const message = parseErrorMessage(response, data)
          const error = new Error(message)
          error.status = response.status
          error.response = response
          return reject(error)
        })
        .catch(function () {
          // couldn't parse any JSON
          let error = new Error(response.statusText)

          // Reset the error when the request was redirected.
          if (response.redirected) {
            error = new Error(
              `Request responsded with a redirect: ${response.url}`
            )
          }

          error.status = response.status
          error.response = response
          return reject(error)
        })
    }
  })

export const get = function (url, options, setHeaders) {
  if (options == null) {
    options = {}
  }
  if (setHeaders == null) {
    setHeaders = true
  }
  options = _.defaults(
    {
      method: "get",
      headers: setHeaders ? getPathwrightRequestHeaders(url) : {}
    },
    options
  )
  return fetch(url, options).then(handleResponse)
}

export const deleteFn = function (url, options, setHeaders) {
  if (options == null) {
    options = {}
  }
  if (setHeaders == null) {
    setHeaders = true
  }
  options = _.defaults(
    {
      method: "delete",
      headers: setHeaders ? getPathwrightRequestHeaders(url) : {}
    },
    options
  )
  return fetch(url, options).then(handleResponse)
}

const writeMethod = (method) =>
  function (url, data, options) {
    if (options == null) {
      options = {}
    }
    const headers = getPathwrightRequestHeaders(url)
    _.extend(headers, {
      "Content-Type": "application/json"
    })
    options = _.defaults(
      {
        method,
        headers,
        body: JSON.stringify(data)
      },
      options
    )

    return fetch(url, options).then(handleResponse)
  }

export const post = writeMethod("POST")
export const put = writeMethod("PUT")
export const patch = writeMethod("PATCH")

export const getPathwrightRequestHeaders = function (url) {
  // Note this function is assuming we're calling a
  // Pathwright API url
  const headers = {}

  // Authorization
  const authToken = getAuthToken()
  if (authToken) {
    headers["Authorization"] = `Token ${authToken}`
  }

  if (!getEnv("production")) {
    // Only need to set these headers if we're hitting a non-dev API endpoint
    if (url.indexOf("pathwrightdev") === -1) {
      headers["X-Pathwright-Application-Version"] = "1.0"
    }
    headers["X-Pathwright-API-Version"] = moment()
      .locale("en")
      .format("YYYY-MM-DD")
  } else {
    // TODO: get date from ENV
    headers["X-Pathwright-API-Version"] = "2016-06-15"
  }

  return headers
}

export const API = {
  get(url, options) {
    if (options == null) {
      options = {}
    }
    return get(getAPIUrl(url), options)
  },
  put(url, data, options) {
    if (options == null) {
      options = {}
    }
    return put(getAPIUrl(url), data, options)
  },
  post(url, data, options) {
    if (options == null) {
      options = {}
    }
    return post(getAPIUrl(url), data, options)
  },
  delete(url, options) {
    if (options == null) {
      options = {}
    }
    return deleteFn(getAPIUrl(url), options)
  },
  legacy: {
    get(url, options) {
      if (options == null) {
        options = {}
      }
      return get(createSchoolUrl(url), options)
    },
    put(url, data, options) {
      if (options == null) {
        options = {}
      }
      return put(createSchoolUrl(url), data, options)
    },
    post(url, data, options) {
      if (options == null) {
        options = {}
      }
      return post(createSchoolUrl(url), data, options)
    },
    delete(url, options) {
      if (options == null) {
        options = {}
      }
      return deleteFn(createSchoolUrl(url), options)
    }
  }
}

// const utils = {
//   get,
//   post,
//   put,
//   patch,
//   getPathwrightRequestHeaders,
//   API,
//   delete: deleteFunc
// }

// export default utils
