import { VStack, useDisclosure } from "@chakra-ui/react"
import AugmentationLayout from "../components/augmentation/AugmentationLayout"
import AugmentationToolbar, {
  useAugmentationDisclosedToolbar
} from "../components/augmentation/AugmentationToolbar"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { HomeContextProvider } from "./HomeContext"
import { useHomeRegistrationVars } from "./HomeRegistrationQueryVars"
import HomeAugmentation, {
  useHomeAugmentationToolbar
} from "./augmentations/HomeAugmentation"
import AddPathButton from "./components/AddPathButton"
import HomeBackground from "./components/ui/HomeBackground"
import RegistrationsContainer from "./containers/RegistrationsContainer"
import TopContainer from "./containers/TopContainer"

function HomePrimary() {
  const registrationVariables = useHomeRegistrationVars()
  return (
    <VStack w="full" minH="100vh" h="full">
      <HomeBackground />
      <TopContainer />
      <VStack pos="relative" w="100%" spacing={4}>
        <RegistrationsContainer variables={registrationVariables} />
        <AddPathButton />
      </VStack>
    </VStack>
  )
}

const Home = () => {
  const { me } = usePathwrightContext()
  const toolbar = useHomeAugmentationToolbar()
  const disclosure = useDisclosure()
  const disclosedToolbar = useAugmentationDisclosedToolbar(toolbar, disclosure)

  return me ? (
    <HomeContextProvider>
      <AugmentationLayout
        allowOverlay={true}
        // Handling close in augmentation.
        allowClose={false}
        outerGutter={10}
        innerGutter={50}
        disclosure={disclosure}
        // toolbar={toolbar}
        py="calc(50px + 10px)"
        px="20px"
        pos="relative"
        sx={{
          [".App__primary:has(&)"]: {
            overflow: "visible"
          },
          [".AugmentationLayout__Primary"]: {
            maxW: "min(500px, calc(100% - 20px))"
          },
          [`.AugmentationLayout__Augmentation-inline .AugmentationLayout__Augmentation-background,
            .AugmentationLayout__Toolbar`]: {
            pos: "sticky",
            top: "10px"
          },
          [".AugmentationLayout__Augmentation-inline .AugmentationLayout__Augmentation-background"]:
            {
              h: "calc(100vh - 10px - (50px + 10px))"
            }
        }}
      >
        <HomePrimary />
        <AugmentationToolbar {...disclosedToolbar} />
        <HomeAugmentation toolbar={toolbar} disclosure={disclosure} />
      </AugmentationLayout>
    </HomeContextProvider>
  ) : null
}

export default Home
