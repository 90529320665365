import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app"
import { getAuth, signInWithCustomToken } from "firebase/auth"
import { getDatabase, onValue, ref } from "firebase/database"
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { ZodSchema, z } from "zod"
import { ContextFragment } from "../api/generated"
import { usePathwrightContext } from "../pathwright/PathwrightContext"

type FirebaseIntegration = NonNullable<
  NonNullable<ContextFragment["integrations"]>["firebase"]
>

const firebaseConfigSchema = z.object({
  url: z.string().optional(),
  client_api_key: z.string().optional()
}) satisfies ZodSchema<FirebaseIntegration>

let app: FirebaseApp

const firebaseConfigSchemaTransform = firebaseConfigSchema.transform(
  (val) =>
    ({
      apiKey: val.client_api_key,
      databaseURL: val.url
    } satisfies FirebaseOptions)
)

export const initializeFirebase = (firebaseConfig: FirebaseIntegration) => {
  if (!firebaseConfigSchema.safeParse(firebaseConfig)) {
    if (process.env.NODE_ENV !== "test") {
      console.warn("Could not initialize Firebase: no Firebase config provided")
    }
    return
  }

  if (!app) {
    app = initializeApp(firebaseConfigSchemaTransform.parse(firebaseConfig))
  }

  return app
}

export function getFirebaseDb() {
  return getDatabase(app)
}

type FirebaseContextValue = {
  app: FirebaseApp
  isAuthenticated: boolean
}

const FirebaseContext = createContext<FirebaseContextValue>(
  {} as FirebaseContextValue
)

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
  const { app, isAuthenticated } = _useFB()
  return (
    <FirebaseContext.Provider value={{ app, isAuthenticated }}>
      {children}
    </FirebaseContext.Provider>
  )
}

const _useFB = () => {
  const { integrations } = usePathwrightContext()
  // Necessary to trigger state update after auth for useFirebaseValue to
  // recheck authentication state for getting ref.
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const firebaseAuthToken = integrations.firebase!.auth_token
  const app = useMemo(
    () => initializeFirebase(integrations.firebase!),
    [integrations.firebase]
  )!

  useEffect(() => {
    const handleAuth = async () => {
      if (app) {
        const auth = getAuth(app)
        const { currentUser } = auth

        if (!currentUser && firebaseAuthToken) {
          await signInWithCustomToken(auth, firebaseAuthToken)
        } else if (!firebaseAuthToken && currentUser) {
          await auth.signOut()
        }
        setIsAuthenticated(!!auth.currentUser)
      }
    }
    handleAuth()
  }, [app, firebaseAuthToken])

  return { app, isAuthenticated }
}

export const useFirebaseContext = () => useContext(FirebaseContext)

export const useFirebaseValue = (path: string) => {
  const [value, setValue] = useState()
  const { app, isAuthenticated } = useFirebaseContext()

  const dbRef = useMemo(() => {
    if (
      !app ||
      // Currently all refs require authentication. Wait for auth.
      !isAuthenticated ||
      !path
    ) {
      return null
    }

    const db = getFirebaseDb()
    return ref(db, path)
  }, [app, path, isAuthenticated])

  useEffect(() => {
    if (dbRef) {
      // Return unsubsribe fn to clear listener when ref changes.
      return onValue(
        dbRef,
        (snap) => {
          let value = snap.val()
          setValue(value)
        },
        (error) => {
          console.log("got error: ", error)
          console.log(error)
        }
      )
    } else {
      // Reset value to initial state.
      setValue(undefined)
    }
  }, [dbRef])

  return [value, dbRef]
}
