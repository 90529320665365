// Taken from: https://steve.dignam.xyz/2020/03/31/practical-ordering/

const START_CHAR_CODE = 32
const END_CHAR_CODE = 126
export const FIRST_POSITION = String.fromCharCode(START_CHAR_CODE + 1)

function assertDev(expr: boolean) {
  if (!expr) {
    throw Error("Assertion Error")
  }
}

export function comparePositions(firstPos: string, secondPos: string) {
  return +(firstPos < secondPos) - +(firstPos > secondPos)
}

export function isValidPosition(pos: string) {
  if (pos === "" || pos.charCodeAt(pos.length - 1) == START_CHAR_CODE) {
    return false
  }
  for (let i = 0; i < pos.length; i++) {
    const t = pos.charCodeAt(i)
    if (t < START_CHAR_CODE || t > END_CHAR_CODE) {
      return false
    }
  }
  return true
}

export function positionBefore(pos: string) {
  assertDev(0 !== pos.length)

  for (let i = pos.length - 1; i >= 0; i--) {
    let curCharCode = pos.charCodeAt(i)
    if (curCharCode > START_CHAR_CODE + 1) {
      let position = pos.substr(0, i) + String.fromCharCode(curCharCode - 1)
      assertDev(isValidPosition(position))
      return position
    }
  }
  let position =
    pos.substr(0, pos.length - 1) +
    String.fromCharCode(START_CHAR_CODE) +
    String.fromCharCode(END_CHAR_CODE)
  assertDev(isValidPosition(position))
  return position
}

export function positionAfter(pos: string) {
  assertDev(0 !== pos.length)

  for (let i = pos.length - 1; i >= 0; i--) {
    let curCharCode = pos.charCodeAt(i)
    if (curCharCode < END_CHAR_CODE) {
      let position = pos.substr(0, i) + String.fromCharCode(curCharCode + 1)
      assertDev(isValidPosition(position))
      return position
    }
  }
  let position = pos + String.fromCharCode(START_CHAR_CODE + 1)
  assertDev(isValidPosition(position))
  return position
}

function avg(a: number, b: number) {
  // @ts-ignore
  return Math.trunc((a + b) / 2)
}

export function positionBetween(firstPos: string, secondPos: string) {
  assertDev(firstPos < secondPos)
  let flag = false
  let position = ""
  const maxLength = Math.max(firstPos.length, secondPos.length)
  for (let i = 0; i < maxLength; i++) {
    const lower = i < firstPos.length ? firstPos.charCodeAt(i) : START_CHAR_CODE
    const upper =
      i < secondPos.length && !flag ? secondPos.charCodeAt(i) : END_CHAR_CODE
    if (lower === upper) {
      position += String.fromCharCode(lower)
    } else if (upper - lower > 1) {
      position += String.fromCharCode(avg(lower, upper))
      flag = false
      break
    } else {
      position += String.fromCharCode(lower)
      flag = true
    }
  }

  if (flag) {
    position += String.fromCharCode(avg(START_CHAR_CODE, END_CHAR_CODE))
  }
  assertDev(firstPos < position)
  assertDev(position < secondPos)
  assertDev(isValidPosition(position))
  return position
}

// An item's position can either string or null.
export type Position = string | null

export type PositionedObj = Record<string, any>

// We want to constrain an item's "position" key to valid Positions.
type StringOrNullKeys<T> = {
  [K in keyof T]: T[K] extends Position ? K : never
}[keyof T]

export type PositionOptions<T extends PositionedObj> = {
  list: T[]
  item: T
  index: number
  positionKey?: StringOrNullKeys<T>
} & (T extends { position: Position }
  ? { positionKey?: StringOrNullKeys<T> } // If item has a "position" key, positionKey is optional
  : { positionKey: StringOrNullKeys<T> }) // If item lacks a "position" key, positionKey is required

// Assumes list already contains item at index with position (likely in local state)
// and we merely want to determine the item's updated position (possibly for database mutation).
export function getPosition<T extends PositionedObj>(
  options: PositionOptions<T>
): Position {
  const { list, item, index, positionKey } = withDefaultPositionKey(options)

  const targetItem = list[index]
  // The index of the item being moved which can be compared to the target index
  // which can be used to determine previous and next positions when moving item
  // in between items.
  const itemIndex = list.findIndex(
    (listItem) =>
      // We cannot compare item positions because there may be multiple items with
      // a null position (which is valid). Let's just assume referential equality.
      listItem === item
  )

  // Inserted at beginning of list.
  if (index === 0) {
    if (targetItem?.position) return positionBefore(targetItem[positionKey])
    return FIRST_POSITION
  }
  // Inserted at end of list.
  if (index === list.length - 1) {
    if (targetItem?.position) return positionAfter(targetItem[positionKey])
    return FIRST_POSITION
  }
  // Inserted in between items in list.
  if (index < itemIndex) {
    // Going upstream in list, need new position in between upstream index and target index.
    const prevPosition = list[index - 1]?.[positionKey] || ""
    const nextPosition = list[index]?.[positionKey] || ""
    // Only position between when we have a next position (assuming we always haeve a prev position).
    return nextPosition
      ? positionBetween(prevPosition, nextPosition)
      : positionAfter(prevPosition)
  }
  if (index > itemIndex) {
    // Going downstream in list, need new position in between target index and downstream index.
    const prevPosition = list[index]?.[positionKey] || ""
    const nextPosition = list[index + 1]?.[positionKey] || ""
    // Only position between when we have a next position (assuming we always haeve a prev position).
    return nextPosition
      ? positionBetween(prevPosition, nextPosition)
      : positionAfter(prevPosition)
  }
  // Possibly false alarm, not moving in list, but we may be setting a position.
  if (index === itemIndex) {
    if (item) {
      // When already positioned, return current position.
      if (item[positionKey]) {
        return item[positionKey]
      } else {
        // Otherwise, attempt to position the item after the previous one
        const prevPosition = list[index - 1]?.[positionKey] || ""
        return prevPosition ? positionAfter(prevPosition) : null
      }
    }
    return FIRST_POSITION
  }

  // Unable to position item.
  return null
}

// Sometimes the user may be moving an item to a position in the list
// where the items that come before it are not yet positioned.
// This could occur in a list that is a mix of positioned items and
// unpositioned items (the positioned items should always be sorted)
// to the front of the list).

// The `getPositions` util ensures that all items preceding the provided
// item are positoned (either already or will be assigned a position).
export function getPositions<T extends PositionedObj>(
  options: PositionOptions<T>
): string[] {
  const { list, index, positionKey } = withDefaultPositionKey(options)
  // NOTE: shallow copying the list since we'll postentially modify the item's
  // position when generates list of positions.
  const copiedList = list.map((item) => ({ ...item }))

  // Get the index of the last positioned item in the list (likely upstream from provied index).
  // We'll traverse from the upstreamIndex to the provided index generating positions along the way.
  const upstreamIndex =
    copiedList.findLastIndex((listItem) => listItem[positionKey]) +
    // Pad by 1, as we want to start positioning the index after the first item
    // that has a position.
    1

  // Ensure we're starting at the furthest upstream index.
  // Since we're padding by 1 above, this may have pushed the upstreamIndex past the index.
  // We basically only need to derive multiple positions if we are truly dealying with
  // upstream items that lack a position
  if (upstreamIndex < index) {
    // Start with the upstreamIndex working down the list till we reach the provided index.
    const items = copiedList.slice(upstreamIndex, index + 1)

    // Now reduce the items into an array of positions.
    const positions = items.reduce((acc, listItem, i) => {
      const position = getPosition({
        list: copiedList,
        item: listItem,
        index: upstreamIndex + i,
        positionKey
      } as PositionOptions<T>)

      // Side-effect: update the position of the listItem so we can compare
      // against it in next iteration.
      // @ts-ignore
      listItem[positionKey] = position

      acc.push(position!)
      return acc
    }, [] as string[])

    return positions
  } else {
    return [getPosition(options)!]
  }
}

// Sort objs by position, taking into account null positions, sorting them last.
// This can be used to order the objs in state or writing the list into the cache.
// Its too complex to automatiacally update the cache for queries, so not providing
// that out of the box.

// Optionally require the positionKey when the list items' positionKey
// is "position".
export function sortByPosition<
  T extends PositionedObj & { position: Position }
>(
  list: PositionOptions<T>["list"],
  positionKey?: PositionOptions<T>["positionKey"]
): PositionOptions<T>["list"]
// Require the positionKey otherwise.
export function sortByPosition<T extends PositionedObj>(
  list: PositionOptions<T>["list"],
  positionKey: PositionOptions<T>["positionKey"]
): PositionOptions<T>["list"]
export function sortByPosition<T extends PositionedObj>(
  list: PositionOptions<T>["list"],
  positionKey: PositionOptions<T>["positionKey"]
): PositionOptions<T>["list"] {
  // Cannot sort empty list.
  if (!list.length) {
    return list
  }

  // Safely set default positionKey.
  if (typeof positionKey === "undefined" && "position" in list[0]) {
    // @ts-ignore: we are confident the item contains the positionKey "position"
    positionKey = "position"
  }
  return list.sort((objA, objB) => {
    // When both items have a position, we can order lexicographically by them.
    return objA[positionKey] && objB[positionKey]
      ? objA[positionKey] < objB[positionKey]
        ? -1
        : objA[positionKey] > objB[positionKey]
        ? 1
        : 0
      : // When only one item has a position, prefer that item.
      objA[positionKey]
      ? -1
      : objB[positionKey]
      ? 1
      : // When neither items have a position, leave as is.
        0
  })
}

// Assign the generated positions to the provided list (returning a new list, no side-effects).
export function assignPositions<T extends PositionedObj>(
  options: PositionOptions<T>
): T[] {
  const { list, item, index, positionKey } = withDefaultPositionKey(options)

  // Get the positions to asign.
  const positions = getPositions(options)

  return list.reduce((acc, listItem, i) => {
    // Starting with the first item that matches the index of the first
    // item receiving a new position, assign the position, shifting the
    // first position. When only 1 position remains, wait to assign it to
    // the item matching the current listItem, this listItem being the
    // base item we repositioned.
    if (
      positions.length > 1
        ? index + 1 - positions.length === i
        : listItem === item
    ) {
      acc.push({
        ...listItem,
        [positionKey]: positions.shift()
      })
    } else {
      acc.push(listItem)
    }

    return acc
  }, [] as T[])
}

// We need to "safely" provide a default positionKey when none is provied.
// Having trouble making TS happy with the assignment, so ignoring ts error for now.
function withDefaultPositionKey<T extends PositionedObj>(
  options: PositionOptions<T>
): PositionOptions<T> {
  let { item, positionKey } = options

  // Safely set default positionKey.
  if (typeof positionKey === "undefined" && "position" in item) {
    // @ts-ignore: we are confident the item contains the positionKey "position"
    positionKey = "position"
  }

  return {
    ...options,
    positionKey
  }
}
