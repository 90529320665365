import useHomeActiveEntries from "@pathwright/web/src/modules/home/useHomeActiveEntries"
import Home from "@pathwright/web/src/modules/unified-home/Home"

const Homescreen = () => {
  // Here we explicitly select the "paths.unified" entry.
  useHomeActiveEntries("paths.unified")

  return (
    // <div>
    //   <Home />
    // </div>
    <Home />
  )
}

Homescreen.displayName = "Homescreen"

export default Homescreen
