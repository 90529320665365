import { Spinner } from "@chakra-ui/react"
import React, { Suspense } from "react"

const VideoPlayer = React.lazy(() =>
  import("./VideoPlayer" /* webpackChunkName: "VideoPlayer" */)
)

function LazyVideoPlayer(props) {
  return (
    <Suspense fallback={<Spinner />}>
      <VideoPlayer {...props} />
    </Suspense>
  )
}

export default LazyVideoPlayer
