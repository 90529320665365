import { i18n } from "@pathwright/ui/src/components/lng/I18nextProvider"

let ResponseListItemView
require("./styles/response-list-item.css")

const User = require("auth/models/user").default
const DiscussionResponseForm = require("./discussion-response-form").default
const DiscussionResponseDelete = require("./discussion-response-delete").default
const ResponseListItemArchived =
  require("./response-list-item-archived").default

const reactUtils = require("lib/core/react/react-utils")
const ActionMenu = require("discussion/components/ActionMenu").default

export default ResponseListItemView = (function () {
  ResponseListItemView = class ResponseListItemView extends (
    require("lib/static-shim").default(require("lib/core/item-view").default)
  ) {
    static initClass() {
      this.prototype.template = require("./templates/response-list-item.html")
      this.prototype.className = "response-list-item-view"

      this.prototype.behaviors = {
        ConnectStore: {
          store: "discussion"
        },
        MathJax: {},
        Bind: {
          getBindEl() {
            return this.$(".response-meta")
          }
        },

        Media: {},
        Highlight: {},
        DiscussionVote: {
          getVotePrompt() {
            if (this.model.get("user_has_voted")) {
              return i18n.t("discussion.list_item.remove_vote")
            } else {
              return i18n.t("discussion.list_item.upvote", {
                response_noun: this._getResponseNoun()
              })
            }
          }
        },
        ViewStates: {
          force_state_change: true,
          states: {
            archived() {
              this.view.$el.attr("class", "")
              this.view.model.set("archived_time", new Date().toISOString())
              return this.replaceView(
                new ResponseListItemArchived({ model: this.view.model })
              )
            },
            view() {
              this.renderView()
              return this.triggerMethod("show")
            },
            edit() {
              this.view._closeMenu()
              const form = new DiscussionResponseForm({
                model: this.view.model,
                discussion: this.view.discussion,
                reply_parent: this.view.model.reply_parent,
                onResponseSaved: this.view.options.onResponseSaved
              })
              this.listenTo(form, "response:canceled", function () {
                this.view.triggerMethod("response:canceled")
                if (!this.view.model.isNew()) {
                  return this.switchState("view")
                } else {
                  this.view.model.destroy()
                  return this.destroy()
                }
              })

              this.listenTo(form, "response:saved", function () {
                this.view.triggerMethod("response:added")
                if (this.view.model.get("reply_parent")) {
                  return this.switchState("view")
                }
              })
              return this.replaceView(form)
            },

            delete() {
              this.view._closeMenu()
              const deletePrompt = new DiscussionResponseDelete({
                model: this.view.model
              })
              this.appendView(deletePrompt)
              this.listenTo(
                deletePrompt,
                "cancel:delete:response",
                function () {
                  return this.switchState("view")
                }
              )
              return this.listenTo(
                deletePrompt,
                "delete:response",
                function () {
                  const m = this.view.model
                  const { collection } = m
                  const index = collection.indexOf(m)
                  return m.destroy({ wait: true }).then(() => {
                    this.options.onResponseArchived &&
                      this.options.onResponseArchived(m)
                    return collection.trigger("response:archived", m, index)
                  })
                }
              )
            }
          }
        }
      }

      this.prototype.ui = {
        responseBody: ".response-body",
        readMore: ".response-read-more",
        reply: ".response-reply",
        responseLink: ".response-link",
        discussionAdminActions: ".discussion-admin-menu-container"
      }

      this.prototype.triggers = {
        "click @ui.readMore": "read:more",
        "click @ui.reply": "reply",
        "click @ui.responseLink": "link:response"
      }
    }

    initialize(options) {
      if (options == null) {
        options = {}
      }
      return ({
        context: this.context,
        discussion: this.discussion,
        baseDepth: this.baseDepth
      } = options)
    }

    unMountDiscussionAdminActions() {
      // Require that the element exist in order to unmount react children.
      const el = this.ui.discussionAdminActions?.[0]
      if (el) {
        // React may be in the middle or rendering in which case we should not
        // unmount the root. Trying to run this in the onBeforeDestroy callback
        // doesn't avoid the following warning:
        // Warning: Attempted to synchronously unmount a root while React was already rendering. React cannot finish unmounting the root until the current render has completed, which may lead to a race condition.
        // By unmounting in a timeout, we ensure React is done rendering.
        setTimeout(() => {
          reactUtils.unmountComponent(el)
        }, 0)
      }
    }

    onRender() {
      if (!this.model.isNew()) {
        this.$el.attr("id", `response-${this.model.id}`)
      }

      const relativeDepth = this.model.get("depth") - this.baseDepth + 1
      this.$el
        .addClass(`response-depth-${relativeDepth}`)
        .data("depth", relativeDepth)

      if (this.model.get("reply_parent") && this.model.isNew()) {
        // This is a reply
        this.triggerMethod("switch:state", "edit")
      }

      if (!this.model.isNew() && this.model.get("user_permissions").can_edit) {
        const item = this.model.serialize()
        // make sure we add the model.id to the ui.discussionAdminActions before mounting
        // the component
        this.ui.discussionAdminActions.attr(
          "id",
          `discussion-admin-menu-${this.model.id}`
        )

        reactUtils.mountComponent(
          this.ui.discussionAdminActions,
          ActionMenu,
          { item },
          [{ store: "discussion" }]
        )
      }

      if (
        !this.model.isNew() &&
        !this.model.get("user_permissions").can_respond
      ) {
        this.ui.reply.hide()
      }
    }

    onDestroy() {
      this.unMountDiscussionAdminActions()
    }

    onLinkResponse() {
      const discussion = this.discussionStore.get("discussion_id")
      return this.discussionStore.action
        .selectDiscussionResponse(discussion, this.model.id)
        .setURL()
    }

    onReadMore() {
      return this.$el.removeClass("truncated").addClass("expanded")
    }

    _shouldShowAskToAnswer() {
      // disable for now
      return false
    }

    _getResponseNoun() {
      let response_noun = i18n.t("discussion.list_item.answer")
      if (this.model.get("depth") > 1) {
        response_noun = i18n.t("discussion.list_item.reply")
      }
      return response_noun
    }

    _closeMenu() {
      this.$(".discussion-admin-menu").removeClass("open")
      // Unmount these controls now.
      this.unMountDiscussionAdminActions()
    }

    serializeData() {
      const data = super.serializeData()
      data.is_authenticated = window.App.session.get("is_authenticated")
      data.author = new User(data.author).toJSON()
      data.response_noun = this._getResponseNoun()
      const schoolPermissions =
        window.App.getStore("school").getState().permissions
      const resourcePermissions =
        window.App.getStore("resource").request.permissions &&
        window.App.getStore("resource").request.permissions()
      data.shouldShowFullName = !!(
        (resourcePermissions && resourcePermissions.can_facilitate) ||
        (schoolPermissions &&
          (schoolPermissions.user_is_school_editor ||
            schoolPermissions.user_is_school_admin))
      )
      return data
    }
  }
  ResponseListItemView.initClass()
  return ResponseListItemView
})()
