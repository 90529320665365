import {
  DocumentNode,
  ErrorPolicy,
  InMemoryCache,
  MutationTuple,
  QueryResult,
  WatchQueryFetchPolicy,
  useMutation,
  useQuery
} from "@apollo/client"
import { ServerBlockTypeType } from "@pathwright/blocks-core"
import { BLOCKS_MODE_TYPE, BlockType, BlocksContentType } from "../types"
import {
  BlockTypeMutationWithNameType,
  CONTENT_BLOCK_FIELDS_FRAGMENT,
  blocksContentListQuery,
  blocksContentOnlyQuery,
  blocksContentQuery,
  blocksContextQuery,
  contentPublishMutation,
  discardDraftContentMutation,
  getBlockMutationsForType,
  getRootBlockMutations
} from "./schema"

type MutationUpdaterFnOptionsType = {
  contentID: string
  dataKey?: string
}

const handleUpdateAfterDeletingBlock =
  ({ contentID }: MutationUpdaterFnOptionsType) =>
  (
    cache: InMemoryCache,
    { data }: { data: { content: BlocksContentType } }
  ): void | undefined => {
    if (!data?.content?.blocks || !cache) return

    cache.modify({
      id: cache.identify({
        __typename: "Content",
        id: contentID
      }),
      fields: {
        // Content updates don't update the cached lastModifiedDateTime field, so we need to
        lastModifiedDateTime(): number {
          return new Date().getTime()
        },
        // @ts-ignore
        blocks(existingBlocks: any[] = [], { readField }): any[] {
          const blocks: BlockType[] = data?.content?.blocks

          if (!blocks || !blocks.length) return existingBlocks

          const nextBlocksRefs: any[] = existingBlocks.filter((ref: any) => {
            const id: any = readField<string>("id", ref)

            return blocks.map((b: BlockType) => b.id).includes(`${id}`)
          })

          return nextBlocksRefs
        }
      }
    })
  }

const handleUpdateAfterBlockMutation =
  ({ contentID, dataKey = "block" }: MutationUpdaterFnOptionsType) =>
  (
    cache: InMemoryCache,
    { data }: { data: { [key: string]: BlockType } }
  ): void | undefined => {
    if (!data || !data[dataKey]) return

    const block = data[dataKey]

    cache.modify({
      id: cache.identify({
        __typename: "Content",
        id: contentID
      }),
      fields: {
        // Block updates don't update the cached lastModifiedDateTime field, so we need to
        lastModifiedDateTime() {
          return new Date().getTime()
        },
        blocks(existingBlocks = [], { readField }) {
          const newBlockRef = cache.writeFragment({
            data: data[dataKey],
            fragment: CONTENT_BLOCK_FIELDS_FRAGMENT
          })

          // If the block already exists, we don't need to do anything (Apollo should handle this)
          if (existingBlocks.some((ref) => readField("id", ref) === block.id)) {
            return existingBlocks
          }

          // We need to insert the new block into the correct position
          const insertionIndex = +block.order - 1

          const nextBlocksRefs = [
            ...existingBlocks.slice(0, insertionIndex),
            newBlockRef,
            ...existingBlocks.slice(insertionIndex)
          ]

          // Now we have to update the order field of each block
          nextBlocksRefs.forEach((ref, index) => {
            cache.modify({
              id: cache.identify(ref),
              fields: {
                order() {
                  return index + 1
                }
              }
            })
          })

          return nextBlocksRefs
        }
      }
    })
  }

const handleUpdateAfterMoveBlock =
  ({ contentID }: MutationUpdaterFnOptionsType) =>
  (
    cache: InMemoryCache,
    { data }: { data: { content: BlocksContentType } }
  ): void | undefined => {
    if (!data?.content?.blocks) return

    cache.modify({
      id: cache.identify({
        __typename: "Content",
        id: contentID
      }),
      fields: {
        // Content updates don't update the cached lastModifiedDateTime field, so we need to
        lastModifiedDateTime() {
          return new Date().getTime()
        },
        blocks(existingBlocks = [], { readField }) {
          const nextBlocksRefs = existingBlocks.map((ref) => {
            const block = data.content.blocks.find(
              (b) => b.id === readField("id", ref)
            )
            if (block) {
              return cache.writeFragment({
                data: block,
                fragment: CONTENT_BLOCK_FIELDS_FRAGMENT
              })
            }
            return ref
          })

          return nextBlocksRefs
        }
      }
    })
  }

const handleUpdateAfterContentMutation =
  ({ contentID }: MutationUpdaterFnOptionsType) =>
  (
    cache: InMemoryCache,
    { data }: { data?: { content: BlocksContentType } | null }
  ): void | undefined => {
    if (!data?.content) return

    cache.modify({
      id: cache.identify({
        __typename: "Content",
        id: contentID
      }),
      fields: {
        blocks(existingBlocks = [], { readField }) {
          const nextBlocksRefs = data.content.blocks.map((block) => {
            return cache.writeFragment({
              data: block,
              fragment: CONTENT_BLOCK_FIELDS_FRAGMENT
            })
          })

          return nextBlocksRefs
        }
      }
    })
  }

type ContentMutationVariablesType = {
  contentID?: string
  contextKey?: string
  id?: string
}

export const useDiscardDraft = ({
  variables,
  onCompleted
}: {
  variables: ContentMutationVariablesType
  onCompleted?: () => void
}): MutationTuple<any, any> => {
  return useMutation(discardDraftContentMutation, {
    variables,
    update: handleUpdateAfterContentMutation({
      contentID: variables.contentID!
    }),
    onCompleted
  })
}

export const usePublish = ({
  variables,
  onCompleted
}: {
  variables: ContentMutationVariablesType
  onCompleted?: () => void
}): MutationTuple<any, any> => {
  return useMutation(contentPublishMutation, {
    variables,
    update: handleUpdateAfterContentMutation({ contentID: variables.id! }),
    onCompleted
  })
}

type BlockQueryVariablesType = {
  mode?: BLOCKS_MODE_TYPE
  contextKey: string
  template?: string
  userID?: string | number
  upsert: boolean
  draft: boolean
}

type BlockQueryOptionsType = {
  variables: BlockQueryVariablesType
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  errorPolicy?: ErrorPolicy
}

type BlockContentListVariablesType = {
  contextKeys: string[]
  draft: boolean
  mode?: BLOCKS_MODE_TYPE
  userID?: string | number
  limit?: number // Limit the number of Firebase Content documents returned
  offset?: number // Offset the Firebase Content documents returned
}

type BlockContentListQueryOptionsType = {
  variables: BlockContentListVariablesType
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  errorPolicy?: ErrorPolicy
}

export const useBlocksContent = ({
  variables
}: BlockQueryOptionsType): Partial<QueryResult> => {
  const { loading, error, data } = useQuery(blocksContentQuery, {
    variables
  })
  return { loading, error, data }
}

export const useBlocksContentOnly = ({
  variables,
  ...options
}: BlockQueryOptionsType): Partial<QueryResult> => {
  const blocksContentOnlyQueryResult = useQuery(blocksContentOnlyQuery, {
    variables,
    ...options
  })
  return blocksContentOnlyQueryResult
}

export const useBlocksContentList = ({
  variables,
  ...options
}: BlockContentListQueryOptionsType): Partial<QueryResult> => {
  const blocksContentListQueryResult = useQuery(blocksContentListQuery, {
    variables,
    skip: !variables.contextKeys.length || options.skip,
    ...options
  })
  return blocksContentListQueryResult
}

export const useBlocksContext = (options: {
  skip?: boolean
}): Partial<QueryResult> => {
  const { loading, error, data } = useQuery(blocksContextQuery, options)
  return { loading, error, data }
}

export const useBlocksMutations = ({
  blockTypes,
  contentID,
  onCompleted
}: {
  blockTypes: ServerBlockTypeType[]
  contentID: string
  onCompleted?: () => void
}): { [key: string]: MutationTuple<any, any> } => {
  const allBlockMutations: { [key: string]: MutationTuple<any, any> } = {}

  // Auto-generated mutations for each block type (ex: saveQuestionBlock or saveQuestionBlockUserData)
  const blockMutations = blockTypes.reduce(
    (mutations, blockType): BlockTypeMutationWithNameType[] => {
      const typeMutations: BlockTypeMutationWithNameType[] =
        getBlockMutationsForType(blockType)
      return mutations.concat(typeMutations)
    },
    [] as BlockTypeMutationWithNameType[]
  )

  blockMutations.forEach(({ name, gql }: BlockTypeMutationWithNameType) => {
    const options: Record<string, any> = {}

    options.onCompleted = onCompleted

    // User blocks don't need any cache updates
    if (!name.includes("UserData")) {
      options.update = handleUpdateAfterBlockMutation({
        contentID
      })
    }
    allBlockMutations[name] = useMutation(gql, options)
  })

  // Add root block mutations (moveBlock, deleteBlock, pasteBlock)
  const rootBlockMutations: {
    moveBlock: DocumentNode
    deleteBlock: DocumentNode
    pasteBlock: DocumentNode
  } = getRootBlockMutations()

  Object.keys(rootBlockMutations).forEach((mutationName) => {
    const mutationGQL = rootBlockMutations[mutationName]

    const options: Record<string, any> = {}

    options.onCompleted = onCompleted

    if (mutationName === "deleteBlock") {
      options.update = handleUpdateAfterDeletingBlock({
        contentID
      })
    }

    if (mutationName === "moveBlock") {
      options.update = handleUpdateAfterMoveBlock({
        contentID
      })
    }

    if (mutationName === "pasteBlock") {
      options.update = handleUpdateAfterBlockMutation({
        contentID,
        dataKey: "pasteBlock"
      })
    }

    allBlockMutations[mutationName] = useMutation(mutationGQL, options)
  })

  return allBlockMutations
}
