import { Box, Flex } from "@chakra-ui/react"
import PropTypes from "prop-types"
import React from "react"

// RangeWrapper
export const RangeWrapper = ({ vertical, rangeValue, children, ...props }) => {
  return (
    <Flex
      flexDirection={vertical ? "column" : "row"}
      align="stretch"
      justify="flex-start"
      width={vertical ? "auto" : "100%"}
      height={vertical ? "100%" : "auto"}
      padding="8px"
      margin="0"
      cursor="pointer"
      {...props}
    >
      <Flex
        className="RangedControl__progress-wrapper"
        flexDirection={vertical ? "column" : "row"}
        align="center"
        transform={vertical ? "rotateZ(180deg)" : "none"}
        overflow="visible"
        width={vertical ? "4px" : "100%"}
        height={vertical ? "100%" : "4px"}
        bg="#e5e5e5"
      >
        <Box
          className="RangedControl__current-progress"
          display="flex"
          bg="rgb(102, 102, 102)"
          width={vertical ? "100%" : `${rangeValue}%`}
          height={vertical ? `${100 - rangeValue}%` : "100%"}
        />
        {children}
      </Flex>
    </Flex>
  )
}

// RangeMarker
export const RangeMarker = ({ vertical, ...props }) => {
  return (
    <Box
      width="8px"
      height="8px"
      borderRadius="50%"
      bg="rgb(102, 102, 102)"
      transition="transform 0.1s ease-out"
      transform={vertical ? "translateY(-1px)" : "translateX(-1px)"}
      transformOrigin="center center"
      overflow="visible"
      _hover={{
        transform: `scale(1.5) ${
          vertical ? "translateY(-1px)" : "translateX(-1px)"
        }`
      }}
      {...props}
    />
  )
}
const RangedControl = (props) => {
  const {
    rangeValue: propsRangeValue,
    vertical,
    value,
    onStartScrub,
    updateOnScrub,
    onStopScrub,
    onSeek
  } = props

  const [mouseDown, setMouseDown] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [rangeValue, setRangeValue] = React.useState(propsRangeValue)

  React.useEffect(() => {
    if (dragging) return

    setRangeValue(value)
  }, [value])

  const handleMouseDown = (e) => {
    setMouseDown(true)
    if (onStartScrub) onStartScrub()
  }

  const handleMouseMove = (e) => {
    if (!mouseDown) return

    const nextValue = calcRangeValue(e)

    setDragging(true)
    setRangeValue(nextValue)

    if (updateOnScrub) {
      onSeek(nextValue)
    }
  }

  const handleMouseEnter = (event) => {
    if (!dragging) return

    setMouseDown(false)
    setDragging(false)
  }

  const handleMouseUp = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const nextValue = calcRangeValue(event)

    setMouseDown(false)
    setDragging(false)

    onSeek(nextValue)
    if (onStopScrub) {
      onStopScrub()
    }
  }

  const handleKeyDown = (event) => {
    let step = 1 // Increment step
    let newValue = rangeValue

    // Require the Shift key for arrow navigation
    switch (event.key) {
      case "ArrowRight":
        newValue = delimitValue(rangeValue + step)
        break
      case "ArrowLeft":
        newValue = delimitValue(rangeValue - step)
        break
      case "PageUp":
        newValue = delimitValue(rangeValue + 10)
        break
      case "PageDown":
        newValue = delimitValue(rangeValue - 10)
        break
      case "Home":
        newValue = 0 // Jump to minimum
        break
      case "End":
        newValue = 100 // Jump to maximum
        break
      default:
        break
    }

    if (newValue !== rangeValue) {
      setRangeValue(newValue)
      onSeek(newValue)
    }
  }

  const handleFocus = () => {
    const instructions =
      "Use Arrow right and left keys to adjust the slider. Home jumps to the start, End jumps to the end."
    const liveRegion = document.createElement("div")
    liveRegion.setAttribute("aria-live", "polite")
    liveRegion.style.position = "absolute"
    liveRegion.style.clip = "rect(1px, 1px, 1px, 1px)"
    liveRegion.style.height = "1px"
    liveRegion.style.width = "1px"
    liveRegion.style.overflow = "hidden"

    liveRegion.textContent = instructions

    document.body.appendChild(liveRegion)
    setTimeout(() => document.body.removeChild(liveRegion), 3000)
  }

  const delimitValue = (valueAsPercentage) => {
    let delimited = valueAsPercentage
    if (valueAsPercentage > 100) delimited = 100
    if (valueAsPercentage < 0) delimited = 0

    return delimited
  }

  const calcRangeValue = (event) => {
    const { type, clientX, changedTouches } = event

    const x = type.includes("touch") ? changedTouches[0].clientX : clientX

    const rect = event.currentTarget.getClientRects()[0]
    const nextX = x - rect.left
    const nextValue = (nextX / rect.width) * 100

    return delimitValue(nextValue)
  }

  return (
    <RangeWrapper
      className="RangedControl"
      tabIndex={0}
      role="slider"
      aria-label={props["aria-label"] || "Range control"}
      aria-valuenow={rangeValue}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-orientation={vertical ? "vertical" : "horizontal"}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onClick={handleMouseUp}
      vertical={vertical}
      rangeValue={rangeValue}
    >
      <div className="RangedControl__progress-wrapper">
        <div className="RangedControl__current-progress" />
        <RangeMarker vertical={vertical} />
      </div>
      <div
        aria-live="polite"
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          overflow: "hidden",
          clip: "rect(1px, 1px, 1px, 1px)"
        }}
      >
        {`Value: ${rangeValue}`}
      </div>
    </RangeWrapper>
  )
}

RangedControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSeek: PropTypes.func.isRequired,
  onStartScrub: PropTypes.func,
  onStopScrub: PropTypes.func,
  updateOnScrub: PropTypes.bool,
  vertical: PropTypes.bool
}

export default RangedControl
