import { useApolloClient, useQuery } from "@apollo/client"
import { ExecutionResult, OperationDefinitionNode } from "graphql"
import React, { useCallback, useEffect, useState } from "react"
import isEqual from "react-fast-compare"
import { useLocalStorage } from "react-use"
import { BackgroundTaskPoll } from "./BackgroundTask"

type BackgroundTaskResult = ExecutionResult<{
  [key: string]: {
    id: string
    status: string
    result?: object
  }
}>

type BackgroundTaskRefetchQuery = {
  type: "apollo" | "backbone"
  queryName: string
  variables?: object
}

type BackgroundTaskValue = {
  refetchQueries?: BackgroundTaskRefetchQuery[]
  // task?: BackgroundTask
  data?: any
}

type BackgroundTaskValues = BackgroundTaskValue | BackgroundTaskValue[]

type BackgroundTasks = Record<string, BackgroundTaskValue>

type UseBackgroundTask = (
  result: BackgroundTaskResult,
  bgTaskValue?: BackgroundTaskValues
) => void

type UseScopedBackgroundTask = (bgTaskValue?: BackgroundTaskValues) => void

type AddBackgroundTask = (
  id: string,
  bgTaskValue?: BackgroundTaskValues
) => void

type BackgroundTaskContextValue = {
  useBackgroundTask: UseBackgroundTask
  useScopedBackgroundTask: UseScopedBackgroundTask
  addBackgroundTask: AddBackgroundTask
}

export const BackgroundTaskContext =
  React.createContext<BackgroundTaskContextValue>(
    null as unknown as BackgroundTaskContextValue
  )

export const useBackgroundTaskContext = () =>
  React.useContext(BackgroundTaskContext)

type BackgroundTaskContextProviderProps = {
  children: JSX.Element | JSX.Element[]
  // TODO: support a scopedBackgroundTaskRefetchQuery prop that can be used
  // in place of a child ScopedBackgroundTaskRefetchQuery.
}

export const BackgroundTaskContextProvider = ({
  children
}: BackgroundTaskContextProviderProps) => {
  const client = useApolloClient()
  // The parent context, if any.
  const bgTaskContext = useBackgroundTaskContext()
  // Storing tasks separately from state since it seems the current value
  // of bg tasks in the setState function is stale.
  const [storedBgTasks, setStoredBgTasks] = useLocalStorage<BackgroundTasks>(
    "bgTasks",
    {}
  )
  const [bgTasks, setBgTasks] = useState<BackgroundTasks>(storedBgTasks!)
  const [scopedBgTaskId, setScopedBgTaskId] = useState<string>()

  const addBackgroundTask: AddBackgroundTask = (id, bgTaskValues) => {
    // Scope the current bg task context when not in the parent context.
    if (bgTaskContext) {
      setScopedBgTaskId(id)
      // Also, we must go up the chain till we reach the top parent context
      // so we can actually set the bg task refetch query in top parent context.
      bgTaskContext.addBackgroundTask(id, bgTaskValues)
    } else {
      setBgTasks((bgTasks) => {
        const bgTaskValue = bgTasks![id]
        const bgTaskRefetchQueries = [...(bgTasks![id]?.refetchQueries || [])]

        // Merge provided refetchQueries from the bgTaskValues onto the existing refetchQueries
        // for this bg task.
        if (bgTaskValues) {
          if (Array.isArray(bgTaskValues)) {
            bgTaskRefetchQueries.push(
              ...bgTaskValues?.reduce<BackgroundTaskRefetchQuery[]>(
                (acc, bgTaskValues) => {
                  if (bgTaskValues.refetchQueries) {
                    acc.push(...bgTaskValues.refetchQueries)
                  }
                  return acc
                },
                []
              )
            )
          } else if (bgTaskValues?.refetchQueries) {
            bgTaskRefetchQueries.push(...bgTaskValues?.refetchQueries)
          }
        }

        // Set the merged refetchQueries on the bg task.
        const nextBgTasks: BackgroundTasks = {
          ...bgTasks,
          [id]: {
            ...bgTaskValue,
            refetchQueries: bgTaskRefetchQueries
          }
        }

        setStoredBgTasks(nextBgTasks)
        return nextBgTasks
      })
    }
  }

  function removeBackgroundTask(id: string) {
    const nextBgTasks = Object.entries(bgTasks!).reduce((acc, [key, value]) => {
      if (id !== key) acc[key] = value
      return acc
    }, {} as BackgroundTasks)

    setBgTasks(nextBgTasks)
    setStoredBgTasks(nextBgTasks)
  }

  const useBgTask = useCallback(
    (taskId: string, bgTaskValue?: BackgroundTaskValues) => {
      useEffect(() => {
        if (taskId) {
          addBackgroundTask(taskId, bgTaskValue)
        }
      }, [taskId])
    },
    []
  )

  const useScopedBackgroundTask: UseScopedBackgroundTask = useCallback(
    (bgTaskValue) => {
      useBgTask(scopedBgTaskId!, bgTaskValue)
      if (!bgTaskContext) {
        console.warn(
          "Attempting to scope background task refetch query(s) in top background task context. Hint: wrap with <BackgroundTaskContextProvider />.",
          JSON.stringify(bgTaskValue, null, 2)
        )
      }
    },
    [scopedBgTaskId]
  )

  const useBackgroundTask: UseBackgroundTask = useCallback(
    (result, bgTaskValue) => {
      const taskId = Object.values(result.data || {})[0]?.id
      useBgTask(taskId!, bgTaskValue)
    },
    []
  )

  function handleBackgroundTaskSuccess(id: string) {
    // Note: only refetching queries when in top context.
    const bgTaskValue = bgTasks![id]

    // Refetch "apollo" type bg task refetch queries.
    client.refetchQueries({
      // First, only include query names.
      include: bgTaskValue?.refetchQueries
        ?.filter((q) => q.type === "apollo")
        ?.map((q) => q.queryName),
      // Then, only refetch the query when it matches one of the "apollo" bg task refetch queries.
      onQueryUpdated: (obsQuery) => {
        const shouldRefetch = bgTaskValue?.refetchQueries?.some(
          (bgQuery) =>
            bgQuery.type === "apollo" &&
            bgQuery.queryName === obsQuery.queryName &&
            isEqual(bgQuery.variables, obsQuery.variables)
        )

        return shouldRefetch
      }
    })

    // Refetch "backbone" type bg task refetch queries.
    bgTaskValue?.refetchQueries?.forEach((bgTaskValue) => {
      if (bgTaskValue.type === "backbone") {
        const [backboneStoreKey, resourceName] =
          bgTaskValue.queryName.split(":")
        // @ts-ignore
        const store = window.App.getStore(backboneStoreKey)
        const backboneResource = store.get(resourceName)
        const resource = store.resource[resourceName]

        if (store.get(`${resourceName}_loaded`)) {
          const method =
            // @ts-ignore
            backboneResource instanceof window.Backbone.Collection
              ? "list"
              : "load"
          resource[method](bgTaskValue.variables, {
            forceReload: false,
            ignoreCache: true
          })
        }
      }
    })

    removeBackgroundTask(id)
  }

  return (
    <BackgroundTaskContext.Provider
      value={{ useBackgroundTask, useScopedBackgroundTask, addBackgroundTask }}
    >
      {!bgTaskContext &&
        Object.keys(bgTasks!).map((id) => (
          <BackgroundTaskPoll
            key={id}
            id={id}
            onBackgroundTaskSuccess={handleBackgroundTaskSuccess}
          />
        ))}
      {children}
    </BackgroundTaskContext.Provider>
  )
}

type ScopedBackgroundTaskRefetchQuery =
  | BackgroundTaskRefetchQuery
  | Omit<BackgroundTaskRefetchQuery, "type">

export const ScopedBackgroundTaskRefetchQuery = (
  props:
    | ScopedBackgroundTaskRefetchQuery
    | { backbroundTaskRefetchQueries: ScopedBackgroundTaskRefetchQuery[] }
) => {
  const { useScopedBackgroundTask } = useBackgroundTaskContext()
  // A single query or list of queries.
  const backbroundTaskRefetchQueries =
    "backbroundTaskRefetchQueries" in props
      ? props.backbroundTaskRefetchQueries
      : props

  // Add a default "apollo" type.
  const defaultBackbroundTaskRefetchQueries = (
    [] as ScopedBackgroundTaskRefetchQuery[]
  )
    .concat(backbroundTaskRefetchQueries)
    .reduce((acc, scopedBackgroundTaskRefetchQuery) => {
      if (!("type" in scopedBackgroundTaskRefetchQuery)) {
        acc.push({ ...scopedBackgroundTaskRefetchQuery, type: "apollo" })
      } else {
        acc.push(scopedBackgroundTaskRefetchQuery)
      }
      return acc
    }, [] as BackgroundTaskRefetchQuery[])

  useScopedBackgroundTask({
    refetchQueries: defaultBackbroundTaskRefetchQueries
  })
  return null
}

// Attempt at using a useQuery wrapper to automatically populated the scoped
// bg task refetch queries.
export const useScopedBackgroundTaskQuery: typeof useQuery = (
  documentNode,
  options
) => {
  const query = useQuery(documentNode, options)
  const queryName = (
    documentNode.definitions.find(
      (def) => def.kind === "OperationDefinition"
    ) as OperationDefinitionNode
  ).name!.value
  useBackgroundTaskContext().useScopedBackgroundTask({
    refetchQueries: [
      {
        type: "apollo",
        queryName,
        variables: options?.variables
      }
    ]
  })
  return query
}
