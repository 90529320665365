import SearchInput from "@pathwright/ui/src/components/form/form-text-input/SearchInput"
import styled from "styled-components"
import { useTranslate } from "../lng/withTranslate"

const StyledContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
`

const PickerSearch = ({
  onSearch,
  onClear,
  onFocus,
  icon,
  placeholder,
  value,
  searching
}) => {
  const { tc } = useTranslate()
  return (
    <StyledContainer className="PickerSearch">
      <StyledSearchInput
        icon={icon}
        value={value}
        searching={searching}
        placeholder={placeholder || tc("search your library...")}
        onEnter={onSearch}
        onChange={onSearch}
        onClear={onClear}
        onFocus={onFocus}
        allowFullscreen
      />
    </StyledContainer>
  )
}

PickerSearch.displayName = "PickerSearch"

PickerSearch.defaultProps = {
  icon: "search"
}

export default PickerSearch
