import classnames from "classnames"
import debounce from "lodash/debounce"
import isFunction from "lodash/isFunction"
import PropTypes from "prop-types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { useSessionStorage } from "../hooks/useLocalStorage"
import { useObserveSize } from "../observers/ObserveSizeContext"
import { WithCSS } from "../utils/styles"

export const ScrollContext = React.createContext()

export const useScrollContext = () => useContext(ScrollContext)

// Simple util for scrolling a node to a top/left position.
const scrollNodeToPosition = (scrollNode, top, left) => {
  if (scrollNode) {
    if (!isNaN(top)) scrollNode.scrollTop = top
    if (!isNaN(left)) scrollNode.scrollLeft = left
  }
}

// Determine if a node can scroll. If the node's overall height
// (the "clientHeight") is > then the node's contsrained height
// (the "scrollHeight") then the node can scroll.
// NOTE: it would seem this does not solve for horizontal scroll.
const canNodeScroll = (node) =>
  node ? node.scrollHeight !== node.clientHeight : false

// Simple hook that takes a node and returns a boolean indicating
// whether the node can currently scroll. Observes size changes
// of the node and updates state accordingly.
const useCanNodeScroll = (node) => {
  const [canScroll, setCanScroll] = useState(null)

  // determine if node can scroll
  const handleCanScroll = () => {
    const nextCanScroll = canNodeScroll(node)
    if (nextCanScroll !== canScroll) {
      setCanScroll(nextCanScroll)
    }
  }

  // Check if node can scroll after scroll event fires.
  // But would the current canScroll state actually change
  // as a result of a scroll event?
  useEffect(() => {
    if (node) {
      node.addEventListener("scroll", handleCanScroll)
      return () => node.removeEventListener("scroll", handleCanScroll)
    }
  }, [node])

  // For responding to node resize.
  const [rectValue, __, setNodeRef] = useObserveSize()
  useEffect(() => {
    node && setNodeRef(node)
  }, [node])

  // Check if node can scroll after resize.
  useEffect(() => {
    handleCanScroll()
  }, [rectValue ? rectValue.height : null])

  return canScroll
}

// Stores a scroll position based on a key and scrolls a node to that
// position when the node first becomes scrollable.
const useStoredScrollPosition = (scrollKeyProp, node) => {
  // Scope the scrollKey to "scrollPositionAt".
  const scrollKey = scrollKeyProp ? `scrollPositionAt:${scrollKeyProp}` : null

  // Retrieve and store the last scroll position of the node.
  const [lastScrollPosition, setLastScrollPosition] =
    useSessionStorage(scrollKey)

  // Handle the current scrollable state of the node. The node may mount in an
  // unscrollable state and only become scrollable once it renders its children.
  const canScroll = useCanNodeScroll(node)

  // Scroll to lastScrollPosition once node becomes scrollable and when scrollKey changes.
  useEffect(() => {
    if (lastScrollPosition && canScroll) {
      scrollNodeToPosition(
        node,
        lastScrollPosition.top,
        lastScrollPosition.left
      )
    }
  }, [scrollKey, canScroll])

  // Update the last scrolled position when scroll position changes.
  useEffect(() => {
    if (node) {
      // Debouncing for efficiency.
      const debouncedSetLastScrollPosition = debounce(
        setLastScrollPosition,
        200
      )

      const handleScrollKey = (e) => {
        if (scrollKey && canNodeScroll(node)) {
          const { scrollTop, scrollLeft } = e.currentTarget
          debouncedSetLastScrollPosition({
            top: scrollTop,
            left: scrollLeft
          })
        }
      }

      node.addEventListener("scroll", handleScrollKey)
      return () => node.removeEventListener("scroll", handleScrollKey)
    }
  }, [node, setLastScrollPosition])
}

const ScrollView = React.forwardRef(function ScrollView(
  {
    className,
    style,
    as,
    fill,
    lockBodyScroll,
    horizontal,
    vertical,
    offset,
    children,
    scrollKey,
    onScroll,
    onScrollBottom
  },
  forwardedRef
) {
  const lockingBodyScrollRef = useRef(false)
  // for current ref inside wrapper functions
  const scrollNodeRef = useRef(null)
  // for current node passed down through context (will be updated after mounting)
  const [scrollNode, setScrollNode] = useState(null)

  const canScroll = useCanNodeScroll(scrollNode)

  useStoredScrollPosition(scrollKey, scrollNode)

  const setRef = useCallback((node) => {
    if (isFunction(forwardedRef)) {
      forwardedRef(node)
    } else if (forwardedRef) {
      forwardedRef.current = node
    }
    if (scrollNodeRef.current !== node) {
      scrollNodeRef.current = node
      setScrollNode(node)
    }
  }, [])

  const useHandleScroll = ({ onScroll, onScrollBottom }) => {
    const scrolledToBottomRef = useRef(false)

    return (e) => {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } =
        e.currentTarget
      if (isFunction(onScroll)) {
        onScroll(scrollTop, scrollLeft)
      }
      // if scrolled within 100px of bottom
      if (scrollHeight - scrollTop < clientHeight + 100) {
        if (!scrolledToBottomRef.current) {
          if (isFunction(onScrollBottom)) {
            onScrollBottom()
          }
          scrolledToBottomRef.current = true
        }
      } else if (scrolledToBottomRef.current) {
        scrolledToBottomRef.current = false
      }
    }
  }

  const useScroll = ({ onScroll, onScrollBottom }) => {
    const handleScrollRef = useRef(null)
    const handleScroll = useHandleScroll({
      onScroll,
      onScrollBottom
    })

    useEffect(() => {
      if (scrollNodeRef.current) {
        if (handleScrollRef.current) {
          scrollNodeRef.current.removeEventListener(
            "scroll",
            handleScrollRef.current
          )
        }
        handleScrollRef.current = handleScroll
        scrollNodeRef.current.addEventListener(
          "scroll",
          handleScrollRef.current
        )
        return () => {
          scrollNodeRef.current &&
            handleScrollRef.current &&
            scrollNodeRef.current.removeEventListener(
              "scroll",
              handleScrollRef.current
            )
        }
      }
    }, [scrollNodeRef.current, onScroll, onScrollBottom])
  }

  const smoothScrollTo = (targetPosition, left = 0, duration = 500) => {
    if (!scrollNode) return

    const startPosition = scrollNode.scrollTop
    const distance = targetPosition - startPosition
    const startTime = performance.now()

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)

      // Ease-in-out cubic function for smooth animation
      const easeInOut = progress < 0.5
        ? 4 * progress * progress * progress
        : 1 - Math.pow(-2 * progress + 2, 3) / 2

      const newPosition = startPosition + distance * easeInOut

      scrollNodeToPosition(scrollNode, newPosition, left)

      if (progress < 1) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }

  const handleOnScroll = useHandleScroll({
    onScroll,
    onScrollBottom
  })

  return (
    <ScrollContext.Provider
      value={{
        useScroll,
        scrollNode,
        scrollTo: (...args) => scrollNodeToPosition(scrollNode, ...args),
        smoothScrollTo,
        canScroll
      }}
    >
      <WithCSS
        ref={setRef}
        as={as}
        className={classnames("ScrollView", className)}
        css={`
          overflow-x: ${horizontal ? "auto" : "hidden"};
          overflow-y: ${vertical ? "auto" : "hidden"};

          ${fill
            ? `
            width: 100%;
            height: calc(100% - ${offset.top || 0}px);
            min-height: calc(100% - ${offset.top || 0}px);
            max-height: calc(100% - ${offset.top || 0}px);
            /* assures scrollbar visibility on windows */
            position: relative;
          `
            : ""}
        `}
        style={style}
        onScroll={handleOnScroll}
      >
        {children}
      </WithCSS>
    </ScrollContext.Provider>
  )
})

ScrollView.displayName = "ScrollView"

ScrollView.propTypes = {
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  fill: PropTypes.bool,
  lockBodyScroll: PropTypes.bool,
  scrollKey: PropTypes.string,
  offset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number
  }),
  as: PropTypes.string
}

ScrollView.defaultProps = {
  vertical: true,
  horizontal: false,
  fill: false,
  lockBodyScroll: true,
  offset: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  as: "div"
}

export default ScrollView
