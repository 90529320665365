import { Box, Button, Link } from "@chakra-ui/react"
import { IconName } from "@pathwright/pathicons"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { Link as ReactRouterLink } from "react-router-dom"

type SuggestedActionProps = {
  label: string | JSX.Element
  icon?: IconName
  url?: string
  onClick?: () => void
}

const SuggestedAction = ({
  icon,
  label,
  url,
  onClick
}: SuggestedActionProps) => {
  const button = (
    <Box as="label" className="SuggestedAction">
      <Button
        variant="outline"
        size="sm"
        px={5}
        py={3}
        bg="transparent"
        _hover={{
          bgColor: "whiteAlpha.500",
          color: "white"
        }}
        leftIcon={icon ? <Pathicon icon={icon} /> : undefined}
        cursor="pointer"
        onClick={(event) => {
          if (!url) event.preventDefault()
          if (onClick) onClick()
        }}
        borderColor="whiteAlpha.500"
        color="whiteAlpha.900"
      >
        {label}
      </Button>
    </Box>
  )

  if (url) {
    return (
      <Link as={ReactRouterLink} to={url} cursor="pointer">
        {button}
      </Link>
    )
  }

  return button
}

export default SuggestedAction
