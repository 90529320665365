import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Collapse,
  HStack,
  Input,
  VStack,
  useDisclosure
} from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { Field, Form, Formik, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { StringParam, useQueryParam } from "use-query-params"
import { z } from "zod"
import { toFormikValidate } from "zod-formik-adapter"
import Pathicon from "../pathicon/Pathicon"

const schema = z.object({
  code: z.string().min(1)
})
const validate = toFormikValidate(schema)

function FormSubmitter() {
  const { initialValues, handleSubmit } = useFormikContext<{ code: string }>()

  useEffect(() => {
    // on initial mount, let's query for the discount if there is one pulled from the URL search params
    if (initialValues.code) {
      handleSubmit()
    }
  }, [])

  return null
}

type DiscountResult = {
  discount_percent: number
  discount_perc: number
  currency_display_discount_price: string
  discounted_total_currency_display: string
  discountMessage: string
}

export type OrderDiscountFormProps = {
  applyDiscountCode: (code: string) => Promise<DiscountResult>
  clearDiscountCode?: () => void
  searchParamKey: string
}

function OrderDiscountForm({
  applyDiscountCode,
  searchParamKey,
  clearDiscountCode
}: OrderDiscountFormProps) {
  const { t } = useTranslate()
  const [discountCode] = useQueryParam(searchParamKey, StringParam)
  const [successfulDiscountCode, setSuccessfulDiscountCode] = useState<
    string | null
  >(null)
  const [discountResult, setDiscountResult] = useState<DiscountResult | null>(
    null
  )
  const [discountError, setDiscountError] = useState<string | null>(null)

  const { isOpen, onClose, getButtonProps, getDisclosureProps } = useDisclosure(
    {
      defaultIsOpen: true
    }
  )

  useEffect(() => {
    if (isOpen) {
      setDiscountError(null)
      setDiscountResult(null)
      clearDiscountCode?.()
    }
  }, [isOpen])

  async function handleDiscount(discountCode: string) {
    setDiscountError(null)
    setSuccessfulDiscountCode(null)
    try {
      const discountResult = await applyDiscountCode(discountCode)
      if (discountResult) {
        setDiscountResult(discountResult)
        setSuccessfulDiscountCode(discountCode)
        onClose()
      } else {
        throw new Error(`The code "${discountCode}" is not valid.`)
      }
    } catch (error) {
      setDiscountError((error as unknown as Error).message)
    }
  }

  const discountSuccess = discountResult
    ? typeof discountResult === "string"
      ? discountResult
      : discountResult.discount_percent &&
        discountResult.currency_display_discount_price
      ? `${t("payments.elements_form.discount_applied", {
          code: successfulDiscountCode,
          perc: discountResult.discount_percent * 100,
          newTotal: discountResult.currency_display_discount_price
        })}.`
      : discountResult.discount_perc &&
        discountResult.discounted_total_currency_display
      ? `${t("payments.elements_form.discount_applied", {
          code: successfulDiscountCode,
          perc: discountResult.discount_perc * 100,
          newTotal: discountResult.discounted_total_currency_display
        })}.`
      : discountResult.discountMessage
      ? discountResult.discountMessage
      : null
    : null

  return (
    <VStack w="100%">
      <Collapse
        in={isOpen}
        animateOpacity
        style={{ width: "100%" }}
        {...getDisclosureProps()}
      >
        <Formik
          initialValues={{ code: discountCode || "" }}
          onSubmit={({ code }) => handleDiscount(code)}
          validate={validate}
          validateOnMount
          enableReinitialize
        >
          {(form) => (
            <Form style={{ width: "100%" }}>
              <FormSubmitter />
              <HStack w="100%">
                <Field
                  as={Input}
                  name="code"
                  placeholder="Enter your discount code"
                  value={discountCode}
                  flexGrow={1}
                  autoFocus
                />

                <Button
                  colorScheme="brand"
                  type="submit"
                  isDisabled={!form.isValid || form.isSubmitting}
                  isLoading={form.isSubmitting}
                  cursor="pointer"
                >
                  {t("Apply Code")}
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      </Collapse>
      {!!discountError && (
        <Collapse in animateOpacity style={{ width: "100%" }}>
          <Alert status="error" variant="subtle" wordBreak="break-word">
            <Pathicon icon="caution-octagon" mr={4} />
            <Box>
              <AlertTitle>{discountError}</AlertTitle>
            </Box>
          </Alert>
        </Collapse>
      )}
      {discountSuccess ? (
        <Collapse in animateOpacity style={{ width: "100%" }}>
          <Alert status="success" variant="subtle" wordBreak="break-word">
            <Pathicon icon="check-square" mr={4} />
            <Box>
              <AlertTitle>{discountSuccess}</AlertTitle>
              <AlertDescription>
                <Button
                  colorScheme="brand"
                  variant="link"
                  mr="auto"
                  cursor="pointer"
                  bg="transparent"
                  {...getButtonProps()}
                >
                  {t("Try a different code?")}
                </Button>
              </AlertDescription>
            </Box>
          </Alert>
        </Collapse>
      ) : null}
    </VStack>
  )
}

OrderDiscountForm.displayName = "OrderDiscountForm"

OrderDiscountForm.defaultProps = {
  searchParamKey: "coupon"
}

export default OrderDiscountForm
