import { Box } from "@chakra-ui/react"
import Button from "@pathwright/ui/src/components/button/Button"
import {
  getPreviewUrl
} from "@pathwright/ui/src/components/filestack/utils"
import {
  FULLSCREEN_LAYER_BASE,
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import styled from "styled-components"
import Modal from "../Modal"

const PREVIEW_ID = "filestack-preview"

const PreviewWrapper = styled.div`
  height: calc(100% - 60px);
  width: 100%;
`

const FILESTACK_STYLES_URL =
  "https://duointeractive.github.io/filestack-styles.css"

const ModalButton = styled(Button)`
  position: relative;
  text-align: left;
  color: ${(p) => p.themeColor || "gray"};
  max-height: 30px;
  width: auto;
  height: 100%;
  text-decoration: underline;

  .Pathicon {
    color: gray;
    text-decoration: none;
    margin-right: 5px;
  }
`

const FilestackPreviewModal = ({
  previewHandle,
  overlayStyles = {},
  contentStyles = {},
  downloadURL,
  theme,
  onCloseModal
}) => {
  const [previewUrl, setPreviewUrl] = useState(null)

  useEffect(() => {
    getPreviewUrl(previewHandle).then(setPreviewUrl)
  }, [previewHandle])

  // const handleOpenModal = () => {
  //   preview(previewHandle, PREVIEW_ID, FILESTACK_STYLES_URL)
  // }

  return (
    <Modal
      isOpen={true}
      // onAfterOpen={handleOpenModal}
      contentLabel="Filestack Preview Modal"
      domID="filestack-preview-modal"
      overlayStyles={{
        backgroundColor: "rgba(255,255,255,0.7)",
        zIndex: FULLSCREEN_LAYER_BASE + FULLSCREEN_LAYER_OVERLAY + 10,
        ...overlayStyles
      }}
      contentStyles={{ width: "100%", overflow: "hidden",...contentStyles }}
      closeText="Close"
      renderCloseButton={() => (
        <ModalButton
          className="exit-button"
          styleType="blank"
          aria-label="Close modal"
          onClick={onCloseModal}
          themeColor={theme ? theme.primaryBrandColor : null}
          tabIndex={0}
        >
          <Pathicon icon="x" />
          Close
        </ModalButton>
      )}
      renderTopRightButton={() => (
        <ModalButton
          className="exit-button"
          styleType="blank"
          aria-label="Download file"
          download={downloadURL}
          href={downloadURL}
          themeColor={theme ? theme.primaryBrandColor : null}
          tabIndex={0}
        >
          <Pathicon icon="download" />
          Download
        </ModalButton>
      )}
    >
      {/* <PreviewWrapper id={PREVIEW_ID} /> */}
      {!!previewUrl && (
        <Box
          id={PREVIEW_ID}
          as="iframe"
          // Allow only those scripts from same origin (which is likely no scripts).
          // This will prevent odd UX like UX like when a PDF attempts to call window.print
          // and would otherwise open the print dialogue.
          sandbox="allow-scripts allow-same-origin"
          src={previewUrl}
          w="100%"
          h="calc(100% - 60px)"
          minH="250px"
          marginLeft="0"
        />
      )}
    </Modal>
  )
}

FilestackPreviewModal.propTypes = {
  // previewHandles: PropTypes.arrayOf(PropTypes.string).isRequired
  previewHandle: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  downloadURL: PropTypes.string.isRequired,
  theme: PropTypes.object,
  overlayStyles: PropTypes.object,
  contentStyles: PropTypes.object
}

export default FilestackPreviewModal
