import {
  Box,
  Button,
  ButtonProps,
  Center,
  CloseButton,
  Collapse,
  Divider,
  HStack,
  Link,
  List,
  ListItem,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import moment from "moment"
import { useMemo } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  HomeRegistrationFragment,
  useHomeResourceGroupsQuery
} from "../../../api/generated"
import Pathicon from "../../../pathicon/Pathicon"
import { BORDER_RADIUS } from "../../constants"
import GroupAvatars from "../GroupAvatars"
import ProgressBar from "../ui/ProgressBar"

type SimpleButtonProps = {
  to?: string
  icon?: string
  label?: string
} & ButtonProps

const SimpleButton = ({ to, icon, label, ...rest }: SimpleButtonProps) => {
  return (
    <Button
      as={to ? ReactRouterLink : "button"}
      to={to}
      colorScheme="whiteAlpha"
      size="sm"
      textDecor="none"
      // @ts-expect-error
      rightIcon={icon ? <Pathicon icon={icon} /> : undefined}
      {...rest}
    >
      {label || rest.children}
    </Button>
  )
}

type SimpleButtonWrapperProps = Omit<SimpleButtonProps, "icon" | "label">

const StartButton = (props: SimpleButtonWrapperProps) => {
  return <SimpleButton label="Start" icon="chevron-right" {...props} />
}
const CompleteButton = (props: SimpleButtonWrapperProps) => {
  return <SimpleButton label="Complete" icon="check" {...props} />
}
const ObserverButton = (props: SimpleButtonWrapperProps) => {
  return <SimpleButton label="Guided Tour" icon="group-2" {...props} />
}

const LearnerButton = ({
  progress,
  href
}: {
  progress: number
  href: string
}) => {
  const complete: number = 1

  return (
    <Box pos="relative" maxH="32px" borderRadius={BORDER_RADIUS}>
      {progress && progress > 0 && progress < complete ? (
        <ProgressBar progress={progress} />
      ) : progress === complete ? (
        <CompleteButton to={href} />
      ) : (
        <StartButton to={href} />
      )}
    </Box>
  )
}

const ModeratorButton = ({
  progress,
  href
}: {
  progress: number
  href: string
}) => {
  return (
    <Box pos="relative" maxH="32px" borderRadius={BORDER_RADIUS}>
      {progress === 0 && <StartButton to={href} />}
      {progress > 0 ? (
        <ProgressBar progress={progress} />
      ) : (
        progress === 1 && <CompleteButton to={href} />
      )}
    </Box>
  )
}

const DesignSourceButton = (props: SimpleButtonWrapperProps) => {
  return (
    <SimpleButton
      label="Source Path"
      leftIcon={<Pathicon icon="cloud-arrows-fill" />}
      {...props}
    />
  )
}
type DesignWithCohortsButtonProps = {
  registration: HomeRegistrationFragment
  href: string
}

const DesignWithCohortsButton = ({
  registration,
  href
}: DesignWithCohortsButtonProps) => {
  const { isOpen, onToggle, getDisclosureProps, getButtonProps } =
    useDisclosure()

  const resource = registration.resource!

  // TODO: paginate or load all!
  const { data, loading } = useHomeResourceGroupsQuery({
    variables: {
      resourceId: resource.id
    },
    skip: !isOpen
  })

  const groups = useMemo(() => {
    return (
      data?.resource?.groups?.edges?.map((resource) => ({
        id: resource?.group?.id,
        name: resource?.group?.name,
        //!revisit this logic
        isExpired:
          resource?.group?.resource_license &&
          resource?.group?.resource_license.expiration_dtime &&
          moment().isAfter(resource?.group.resource_license.expiration_dtime) &&
          resource?.group.resource_license
      })) || []
    )
  }, [data])

  if ((resource?.groups?.total || 0) < 2) {
    return null
  }

  return (
    <Box
      h="100%"
      w="100%"
      position="relative"
      overflow="hidden"
      borderRadius={16}
      p={isOpen ? 2 : 0}
      backgroundColor={isOpen ? "whiteAlpha.600" : undefined}
    >
      <SimpleButton
        as={isOpen ? "div" : "button"}
        w={isOpen ? "100%" : "auto"}
        leftIcon={<Pathicon icon="group-2" />}
        {...getButtonProps()}
      >
        <HStack py={1} w="100%">
          <Text as="span" color="inherit" fontSize="sm" fontWeight="bold" m={0}>
            {resource.groups?.total} Cohorts
          </Text>
          {!isOpen && <Pathicon icon="chevron-down" />}
          {isOpen && !loading && (
            <CloseButton
              color="inherit"
              onClick={(e) => {
                onToggle()
                // Prevent propagating the click event to parent.
                e.stopPropagation()
              }}
              borderRadius="full"
              cursor="pointer"
              ml="auto"
            />
          )}
        </HStack>
      </SimpleButton>

      {isOpen && loading && (
        <Center w="100%">
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="blackAlpha.200"
            color="blackAlpha.900"
            size="md"
          />
        </Center>
      )}
      <Collapse in={isOpen} animateOpacity {...getDisclosureProps()}>
        {groups && (
          <List w="100%" maxH="172px" overflowY="auto" my={0} pl={0} py={1.5}>
            {groups.map((group) => {
              const pathURL = `/library/${resource?.slug}/${group.id}/path/`
              return (
                <ListItem
                  key={group.id}
                  _hover={{
                    backgroundColor: "whiteAlpha.600",
                    textDecoration: "none"
                  }}
                  h="100%"
                  w="100%"
                  p={1}
                  cursor="pointer"
                  borderRadius="lg"
                >
                  <Link
                    as={ReactRouterLink}
                    to={pathURL}
                    _hover={{ textDecoration: "none" }}
                    w="100%"
                  >
                    <GroupAvatars
                      groupId={group.id as number}
                      showGroupName
                      fontColor="blackAlpha.900"
                    />
                  </Link>
                </ListItem>
              )
            })}
          </List>
        )}

        <Divider />
        <DesignSourceButton to={href} w="100%" />
      </Collapse>
    </Box>
  )
}

interface PathCardButtonProps {
  registration: HomeRegistrationFragment
  href: string
  onClick?: () => void
  role:
    | "observer"
    | "copy"
    | "learner"
    | "moderator"
    | "teacher"
    | "designer_teacher"
    | "design_source"
}

// const PathCardButton: React.FC<PathCardButtonProps> = ({
//   progress = 0,
//   registration,
//   role,
//   href,
//   onClick
// }) => {
//   return (
//     <Box
//       w="100%"
//       borderRadius={BORDER_RADIUS}
//       cursor="pointer"
//       onClick={onClick}
//       zIndex={10}
//       backdropFilter="auto"
//       backdropBlur="8px"
//       pos="relative"
//       _hover={{ shadow: "sm" }}
//     >
//       {/* Conditional rendering based on the role */}
//       {role === "design_source" && <DesignSourceButton to={href} />}
//       {role === "observer" && <ObserverButton to={href} />}
//       {role === "learner" && <LearnerButton href={href} progress={progress} />}
//       {role === "moderator" && (
//         <ModeratorButton href={href} progress={progress} />
//       )}
//       {role === "designer_teacher" && (
//         <DesignWithCohortsButton href={href} registration={registration} />
//       )}
//     </Box>
//   )
// }

const PathCardButton: React.FC<PathCardButtonProps> = ({
  registration,
  role,
  href,
  onClick
}) => {
  const progress = registration.path?.progress || 0
  const isSource: boolean = role === "design_source"
  const isDesignerTeacher: boolean = role === "designer_teacher"
  // Determine if the width should be "full" based on progress
  const isWidthFull: boolean = progress > 0 && progress < 1

  const container = {
    animate: {
      width: isWidthFull ? "100%" : isDesignerTeacher ? "auto" : "max-content"
      // backdropFilter: "blur(20px)",
      // backgroundColor:
      //   role === "designer_teacher" ? "rgba(255, 255, 255, 0.85)" : undefined
    }
    // hover: {
    //   backgroundColor:
    //     role === "designer_teacher" ? "rgba(255, 255, 255, 0.85)" : undefined,
    //   // role === "designer_teacher"
    //   //   ? "rgba(255, 255, 255, 0.60)"
    //   //   : "rgba(255, 255, 255, 0.85)",
    //   transition: {
    //     duration: 0.2
    //   }
    // }
  }

  return (
    <Box
      as={motion.div}
      variants={container}
      animate="animate"
      whileHover="hover"
      borderRadius={BORDER_RADIUS}
      cursor={"pointer"}
      onClick={onClick}
      zIndex={10}
      backdropFilter={"auto"}
      backdropBlur={"8px"}
      _hover={{ shadow: "sm" }}
    >
      {/* Conditional rendering based on the role */}
      {role === "design_source" && <DesignSourceButton to={href} />}
      {role === "observer" && <ObserverButton to={href} />}
      {role === "learner" && <LearnerButton href={href} progress={progress} />}
      {role === "moderator" && (
        <ModeratorButton href={href} progress={progress} />
      )}
      {role === "designer_teacher" && (
        <DesignWithCohortsButton href={href} registration={registration} />
      )}
    </Box>
  )
}

export default PathCardButton
