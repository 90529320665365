import createReactClass from "create-react-class"
import React from "react"
import ReactDOM from "react-dom"
import { createRoot } from "react-dom/client"
const ConnectStores = require("lib/core/store/components/ConnectStores").default

const Region = require("lib/core/region").default

let roots = {}
export const getRootForEl = (el) => {
  if (!el.id) {
    console.error(
      "elements that are used for mounting roots must have an id!",
      el
    )
    // generate a random unique id

    const id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)

    el.id = id
  }

  let root = roots[el.id]
  if (!root) {
    roots[el.id] = createRoot(el)
  }
  return roots[el.id]
}

export const umountRootForEl = (el) => {
  if (el?.id) {
    const root = roots[el.id]
    if (root) {
      root.unmount()
      delete roots[el.id]
    }
  } else if (process.env.NODE_ENV === "development") {
    console.warn(
      "Attempted to unmount an React tree from a non-existing root element."
    )
  }
}

export function mountComponent(uiElement, Component, props, connectStores) {
  if (props == null) {
    props = {}
  }
  if (connectStores == null) {
    connectStores = null
  }
  ;`\

Params:
- uiElement: The element to mount the Component in
- Component: The React Component
- props (optional): Optional list of props to pass the Component.
- connectStores (optional): Will wrap the Component with <ConnectStores />

Example:
  reactUtils.mountComponent(
    ".widget-container",
    MyCoolWidget,
    {id: 1},
    connectStores=[{store: "widget"}]
  )\
`
  if (connectStores) {
    Component = ConnectStores(Component, connectStores)
  }
  const el = $(uiElement)[0]
  const root = getRootForEl(el)
  return root.render(React.createElement(Component, props))
  // return ReactDOM.render(React.createElement(Component, props), $(uiElement)[0])
}

export function unmountComponent(uiElement) {
  const el = $(uiElement)[0]
  umountRootForEl(el)
}

export function wrapMarionetteView(View, options, displayName) {
  if (options == null) {
    options = {}
  }
  if (displayName == null) {
    displayName = "WrappedMarionetteView"
  }

  return createReactClass({
    displayName,

    shouldComponentUpdate(nextProps, nextState) {
      if (nextProps.forceUpdate === true) {
        return true
      }
      return false
    },

    componentDidMount() {
      return this.renderView()
    },

    componentDidUpdate() {
      return this.renderView()
    },

    componentWillUnmount() {
      return this.region != null ? this.region.reset() : undefined
    },

    render() {
      return <div className="---marionette-is-rendering-this---" />
    },

    renderView() {
      options = _.assign({}, options, this.props)
      const el = ReactDOM.findDOMNode(this)
      if (this.region == null) {
        this.region = new Region({ el })
      }
      this.view = new View(options)
      window.region = this.region
      return this.region.show(this.view)
    }
  })
}
