import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import ExecuteScripts from "@pathwright/ui/src/components/embed/ExecuteScripts"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import ACCOUNT_QUERY from "@pathwright/web/src/modules/account/graphql/account-query"
import LazyAdaptedAuthContainer from "@pathwright/web/src/modules/auth/LazyAdaptedAuthContainer"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useEffect } from "react"
import styled from "styled-components"

const StyledCard = styled(Card)`
  .CardContainer {
    overflow: hidden;
  }
`

// shows a Typeform used to send Pathwright info about the upgrade/downgrade request
const PricingPlanUpgrade = ({ card, planId }) => {
  const { me, school } = usePathwrightContext()

  const accountQuery = useQuery(ACCOUNT_QUERY, {
    variables: {
      default_billing_plan_set: false
    }
  })

  const { billing_subscription, billing_plan_set } =
    get(accountQuery, "data.account") || {}

  // get the plan the school is upgrading/downgrading to
  const toPlan = (get(billing_plan_set, "plans") || []).find(
    (plan) => plan.id === planId
  )
  const toPlanName = get(toPlan, "name")
  const currentPlanName = get(billing_subscription, "plan.name")

  // get the Typeform hidden variables
  const typeformHiddenVariables = {
    to_plan: toPlanName,
    current_plan: currentPlanName,
    first_name: get(me, "first_name"),
    last_name: get(me, "last_name"),
    email: get(me, "email"),
    school_url: school.url
  }

  // massage into URL params
  const dataUrlParams = new URLSearchParams(typeformHiddenVariables)

  const typeformEmbedCode = `
    <div class="typeform-widget" data-url="https://form.typeform.com/to/p5KEj023?typeform-medium=embed-snippet?${dataUrlParams.toString()}" style="width: 100%; height: 500px;"></div> <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>`

  // hack for enabling typeform to reload
  useEffect(() => {
    return () => {
      const typeformNode = document.getElementById("typef_orm")
      if (typeformNode) {
        window.typeformEmbedIsloaded = false
        typeformNode.remove()
      }
    }
  }, [])

  return (
    <StyledCard card={card} noaction>
      {accountQuery.loading ? (
        <LoadingCircle center />
      ) : !me ? (
        <LazyAdaptedAuthContainer />
      ) : !currentPlanName ? (
        <BlankSlate
          icon="x-circle"
          heading="No Active Plan"
          body="Your school is not currently activated on any Pathwright plan."
          primaryAction={{
            children: "View Plans",
            to: "/pricing/plan/"
          }}
        />
      ) : !toPlanName ? (
        <BlankSlate
          icon="x-circle"
          heading="No Plan Found"
          body={`The Pathwright plan of ID ${planId} could not be found.`}
          primaryAction={{
            children: "View Plans",
            to: "/pricing/plan/"
          }}
        />
      ) : (
        <ExecuteScripts html={typeformEmbedCode} />
      )}
    </StyledCard>
  )
}

PricingPlanUpgrade.displayName = "PricingPlanUpgrade"

PricingPlanUpgrade.propTypes = {
  planId: PropTypes.number.isRequred
}

export default PricingPlanUpgrade
