import { isMobile } from "@pathwright/media-utils"
import Button from "@pathwright/ui/src/components/button/Button"
import {
  FULLSCREEN_LAYER_BASE,
  FULLSCREEN_LAYER_OVERLAY
} from "@pathwright/ui/src/components/fullscreen/Fullscreen"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import PropTypes from "prop-types"
import React from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`

// Styles as defined by react-modal's API: https://github.com/reactjs/react-modal
const defaultStyles = {
  overlay: {
    zIndex: FULLSCREEN_LAYER_BASE + FULLSCREEN_LAYER_OVERLAY, // need to match or exceed the Blocks header (and Fullscreen overlay layer)
    backgroundColor: "rgba(0,0,0,0.7)"
    // overflowY: "auto"
  },
  content: {
    top: "0",
    left: "10px",
    bottom: "0",
    right: "10px",
    padding: "0",
    overflow: "hidden !important",
    borderRadius: "10px",
    maxWidth: "1150px",
    border: "none",
    margin: isMobile() ? "10px auto" : "30px auto 30px",
    boxShadow: "0 -5px 5px 0 rgba(0,0,0,.1)",
    maxHeight: "1000px"
  }
}

// if (process.env.NODE_ENV !== "test") {
// }
// react-modal needs a base html element, but ignore this when testing
const domEl =
  document.getElementById("root") ||
  document.getElementById("react") ||
  document.getElementById("testing")
ReactModal.setAppElement(domEl)

const Modal = (props) => {
  React.useEffect(() => {
    document.querySelector("html").style.overflow = "hidden"

    return () => {
      document.querySelector("html").style.overflow = "unset"
    }
  }, [])

  const {
    isOpen,
    onClose,
    onAfterOpen,
    contentLabel,
    overlayStyles,
    contentStyles,
    domID,
    children,
    closeText,
    headerStyles,
    renderTopRightButton,
    renderCloseButton
  } = props

  return (
    <ReactModal
      id={domID}
      isOpen={isOpen}
      tabIndex={isOpen ? 0 : -1}
      onRequestClose={null} // Unused currently
      onAfterOpen={onAfterOpen}
      contentLabel={contentLabel}
      shouldReturnFocusAfterClose
      style={{
        overlay: {
          ...defaultStyles.overlay,
          ...overlayStyles
        },
        content: {
          ...defaultStyles.content,
          ...contentStyles
        }
      }}
    >
      {onClose || renderCloseButton || renderTopRightButton ? (
        <ModalHeader style={{ ...(headerStyles || {}) }}>
          {renderCloseButton ? (
            renderCloseButton()
          ) : onClose ? (
            <Button
              className="exit-button"
              styleType="blank"
              onClick={onClose}
              style={{
                position: "relative",
                textAlign: "left",
                color: "gray",
                maxHeight: "30px",
                width: "auto",
                height: "100%",
                textDecoration: "underline"
              }}
            >
              <Pathicon icon="x" />
              {closeText ? ` ${closeText}` : ""}
            </Button>
          ) : null}
          {renderTopRightButton ? renderTopRightButton() : null}
        </ModalHeader>
      ) : null}
      {children}
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  renderTopRightButton: PropTypes.func,
  renderCloseButton: PropTypes.func,
  contentLabel: PropTypes.string,
  overlayStyles: PropTypes.object,
  contentStyles: PropTypes.object,
  domID: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  closeText: PropTypes.string
}

Modal.defaultProps = {
  isOpen: true,
  contentLabel: "Media Manager Modal",
  onAfterOpen: () => "",
  overlayStyles: {},
  contentStyles: {},
  domID: "",
  closeText: ""
}

export default Modal
