import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Pathicon from "@pathwright/ui/src/components/pathicon/Pathicon"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import Text from "@pathwright/ui/src/components/ui/Text"
import styled from "styled-components"
import { PathPerformanceFragment } from "../../api/generated"

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
`
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Stat = styled.div`
  margin-left: 5px;
  text-align: left;
  h4 span {
    font-size: 12px;
  }
  h6 {
    text-transform: uppercase;
  }
`

const MemberPerformanceStat = ({
  stat,
  icon,
  title,
  tooltip
}: {
  stat: number
  icon: string
  title: string
  tooltip: string
}) => {
  return (
    <Tooltip title={tooltip}>
      <Center>
        <Pathicon icon={icon} />
        <Stat>
          <Text.H4>
            {stat}
            <span>%</span>
          </Text.H4>
          <Text.H6>{title}</Text.H6>
        </Stat>
      </Center>
    </Tooltip>
  )
}

const ReportPathStats = ({
  performance
}: {
  performance: PathPerformanceFragment
}) => {
  const { t } = useTranslate()

  if (!performance) {
    return <BlankSlate heading={t("path.report.nothing_to_show")} />
  }

  const {
    percentage,
    total_steps,
    steps_completed,
    total_required_steps,
    required_steps_completed
  } = performance.progress!
  const { applicable_score, applicable_score_percentage } = performance.points!
  const [earned_points, available_points] = applicable_score

  return (
    <Container>
      <MemberPerformanceStat
        icon="gauge-circle"
        title={t("path.report.complete")}
        stat={Math.floor((percentage || 0) * 100)}
        tooltip={`${steps_completed}/${total_steps} ${t(
          "path.report.steps_completed"
        )}.`}
      />
      {available_points > 0 && (
        <MemberPerformanceStat
          icon="award"
          title={t("path.report.score")}
          stat={Math.floor((applicable_score_percentage || 0) * 100)}
          tooltip={`${earned_points}/${available_points} ${t(
            "path.report.total_points"
          )}.`}
        />
      )}
    </Container>
  )
}

ReportPathStats.displayName = "ReportPathStats"

export default ReportPathStats
