// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const { getResourceCache } = require("./resource-cache")

const { put, post, patch } = require("lib/core/api/request")

const createResourceSaver = function (Resource, resourceKey, options) {
  let resourceSaver
  return (resourceSaver = function (data, initialData, options = {}) {
    // we don't handle collections currenly for update
    let cache
    if (initialData == null) {
      initialData = {}
    }
    options ||= {}
    const resource = new Resource(initialData)
    const isNew = resource.isNew()

    resource.set(data)
    const url = resource.url()

    // Grab the required data based on the requiredFields.
    // These are fields that the backend always expects to be present.
    const requiredFields = options?.requiredFields || []
    const requiredData = Object.entries(initialData).reduce(
      (acc, [key, value]) => {
        if (requiredFields.indexOf(key) !== -1) {
          acc[key] = value
        }
        return acc
      },
      {}
    )

    // Only send changed attributes in the case of
    const payload = isNew
      ? // Send everything on create (POST)
        _.assign({}, initialData, data)
      : // Only send changed attributes + id on update (PATCH)
        _.assign(
          {},
          { id: resource.id },
          requiredData,
          resource.changedAttributes()
        )

    // Use options.method to allow for overriding method.
    const saveMethod = isNew ? post : options?.method === "put" ? put : patch

    return saveMethod(url, payload)
      .then((result) => {
        cache = getResourceCache(resourceKey)
        if (cache != null) {
          // force clear cache to avoid stale data
          cache.clearAll()
        }
        // Merge result over initalData as some API responses only serialize
        // part of the model, in which case we don't want to remove those
        // missing keys from our model on the client.
        return {
          resource: new Resource(_.assign({}, initialData, result)),
          isNew
        }
      })
      .catch(function (error) {
        cache = getResourceCache(resourceKey)
        if (cache != null) {
          cache.clearAll()
        } // force clear cache to avoid stale data
        return Promise.reject(error)
      })
  })
}

// new Promise (resolve, reject)->
//   resource.save()
//     .then ->
//       cache = getResourceCache(resourceKey)
//       cache?.clearAll() # force clear cache to avoid stale data
//       resolve(resource)
//     .fail (err)->
//       reject(err)

const createResourceSaveAction = function (
  store,
  resourceSaver,
  resource_key,
  saving_key,
  error_key,
  loaded_key
) {
  const saveAction = function (data, options) {
    const actionData = {
      action: `${resource_key}.save`,
      args: arguments
    }
    store._logAction(actionData)
    store._mutate({ [saving_key]: true })
    const initialData = store.getState()[resource_key] || {}
    return resourceSaver(data, initialData, options)
      .then(function ({ resource, isNew }) {
        const update = {}
        update[saving_key] = false
        update[resource_key] = resource
        update[error_key] = null
        store._mutate(update)
        store.storeTrigger(`${resource_key}:${isNew ? "created" : "updated"}`)
        return resource
      })
      .catch(function (err) {
        const update = {}
        update[saving_key] = false
        update[error_key] = err
        store._mutate(update)
        if (err.status == null) {
          console.error(
            `${resource_key} resource save action runtime error: `,
            err
          )
          return console.trace()
        }
      })
  }
  return saveAction
}

export { createResourceSaveAction, createResourceSaver }
