import { HomeRegistrationFragment } from "modules/api/generated"
import { hasTeacherLevelAccess } from "../../../group/permissions"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { usePositioningContext } from "../../../positioning/PositioningContext"
import { canEditLibrary } from "../../../user/permissions"
import useSoftArchiveRegistration from "../../useSoftArchiveRegistration"
import GroupNotificationsFormOption from "../GroupNotificationsFormOption"
import OptionMenuButton, { OptionMenuItem } from "../ui/OptionMenuButton"
type PathCardRegistrationControlsProps = {
  registration: HomeRegistrationFragment
}

const PathCardRegistrationControls = ({
  registration
}: PathCardRegistrationControlsProps) => {
  const pwContext = usePathwrightContext()
  const positioningContext = usePositioningContext()
  const { resource, group } = registration

  function handleMoveToTop() {
    positioningContext.updatePositionedObject({
      item: registration,
      // Note: currently a bug in positional-indexing results in every
      // item being set to "!" (bug) which results in an ISO (as should be expected).
      index: 0
    })
  }

  const [softArchive] = useSoftArchiveRegistration(registration)

  return (
    <OptionMenuButton
      position="relative"
      zIndex={100}
      right={2.5}
      top={2}
      variant="solid"
      colorScheme="whiteAlpha"
    >
      {!registration.group?.parent_section_id && !!positioningContext && (
        <OptionMenuItem
          icon="arrow-up-circle"
          label="Move to top"
          onClick={handleMoveToTop}
        />
      )}
      <GroupNotificationsFormOption registration={registration} />

      <OptionMenuItem icon="inbox" label="Inbox" />

      {registration.softArchivedTime ? (
        <OptionMenuItem
          icon="sidebar-bottom"
          iconProps={{
            transform: "rotate(90deg)"
          }}
          label="Add to Home Screen"
          onClick={softArchive}
        />
      ) : (
        <OptionMenuItem
          icon="sidebar-bottom"
          iconProps={{
            transform: "rotate(-90deg)"
          }}
          label="Set aside"
          onClick={softArchive}
        />
      )}

      {/* {canEditLibrary(pwContext) && (
        //! Check action
        <OptionMenuItem
          icon="x-circle"
          label="Leave cohort"
          onClick={() => {
            console.log("Edit")
          }}
        />
      )} */}

      {hasTeacherLevelAccess(pwContext, group) && false && (
        <OptionMenuItem
          icon="gear"
          label="Edit cohort settings"
          href={`/manage/group/${resource?.id}/${group?.id}/`}
        />
      )}

      {canEditLibrary(pwContext) && false && (
        <OptionMenuItem
          icon="gear"
          label="Edit cohort settings"
          href={`/manage/resource/${resource?.id}/`}
        />
      )}
    </OptionMenuButton>
  )
}

export default PathCardRegistrationControls
