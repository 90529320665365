import { equalTo, orderByChild, orderByKey } from "firebase/database"
import React, { useContext, useEffect, useState } from "react"
import { getURLParams } from "../../inbox/utils"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import {
  FirebaseNotifications,
  NotificationNode,
  NotificationNodesHookReturn,
  NotificationsKey,
  UseFirebaseNotificationsHookReturn,
  useFirebaseNotifications,
  useNotificationNodes
} from "./hooks"

type NotificationStateContext = {
  key: NotificationsKey
  setKey: (key: NotificationsKey) => void
  items: NotificationNode[]
  select: (item: NotificationNode, queue: boolean) => void
  unselect: (item: NotificationNode) => void
  selected: NotificationNode[]
  selectedItem: NotificationNode | null
  getNode: NotificationNodesHookReturn["get"]
  remove: (items: NotificationNode[]) => void
  markRead: (items: NotificationNode | NotificationNode[]) => void
  markUnread: (items: NotificationNode | NotificationNode[]) => void
  pin: (items: NotificationNode[], pin: boolean) => void
  paginate: UseFirebaseNotificationsHookReturn["paginateNotifications"]
}

const NotificationStateContext = React.createContext<NotificationStateContext>(
  {} as NotificationStateContext
)

export const NotificationsProvider = ({
  children
}: {
  children: JSX.Element
}) => {
  const [key, setKey] = useState<NotificationsKey>("unread")
  const [notifications, setNotifications] = useState<FirebaseNotifications>({})
  const [selected, setSelected] = useState<NotificationNode[]>([])
  const { user } = usePathwrightContext()

  // Share props for keyed notifications.
  const keyedNotificationsProps = {
    notifications,
    getNotifications: setNotifications
  }
  const keyedNotifications = {
    unread: useFirebaseNotifications({
      // Only including unread notifications.
      getQueryConstraints: () => [orderByChild("read"), equalTo(false)],
      ...keyedNotificationsProps
    }),
    pinned: useFirebaseNotifications({
      // Only including pinned notifications.
      getQueryConstraints: () => [orderByChild("pinned"), equalTo(true)],
      ...keyedNotificationsProps
    }),
    all: useFirebaseNotifications({
      // Including all notifications.
      getQueryConstraints: () => [orderByKey()],
      ...keyedNotificationsProps
    })
  }

  // Only possible to paginate "all" notifications which aren't already
  // setting a start point (i.e. via .equalTo() or .startAt()).
  const paginate =
    key === "all" ? keyedNotifications[key].paginateNotifications : null

  const {
    nodes: items,
    get,
    update,
    remove: del
  } = useNotificationNodes({
    notifications,
    // Perform updates on the notifications for the currently selected key.
    updateNotification: keyedNotifications[key].updateNotification,
    deleteNotification: keyedNotifications[key].deleteNotification
  })

  let inboxItems = items.filter((item) => item.type === "item")
  let selectedItem = selected.length ? selected[0] : null

  useEffect(() => {
    // Listen to the current notifications key.
    keyedNotifications[key].listen()
    // Listen to the unread notifications automatically.
    keyedNotifications.unread.listen()

    // Optionally stop listening to a non-default key, but this may
    // be less effective then simply leaving the listeners in tact
    // esp if the user is clicking between tabs.
    // return () => {
    //   if (key !== "unread") keyedNotifications[key].unlisten()
    // }
  }, [key])

  // Clear out the notifications after user signs out.
  useEffect(() => {
    if (!user) setNotifications({})
  }, [user])

  // select the first item if nothing is selected already
  useEffect(() => {
    let params = getURLParams()

    if (!inboxItems.length) return
    if (!selected.length) {
      if (params.nid) {
        let item = inboxItems.find((m) => m.id === params.nid)!
        setSelected([item])
      } else {
        // select the first message
        // select(inboxItems[0])
      }
    }
  }, [selected, items])

  const select: NotificationStateContext["select"] = (item, queue = false) => {
    if (queue) {
      setSelected((selected) => [...selected, item])
    } else {
      setSelected([item])
    }
    // if (item) {
    //   setURLParams({ nid: item.id })
    // } else {
    //   setURLParams({})
    // }
    markRead(item)
  }

  const unselect: NotificationStateContext["unselect"] = (item) => {
    if (item) {
      setSelected((selected) =>
        selected.filter((selectedItem) => selectedItem.id !== item.id)
      )
    } else {
      setSelected([])
    }
  }

  // const updateItemData = useCallback(
  //   (data, matchFn) => {
  //     let nodes = inboxItems.filter(matchFn)

  //     nodes.forEach(item => {
  //       // reduce the data to only the fields we want to update
  //       let updateData = Object.keys(data).reduce((acc, key) => {
  //         if (item.data[key] !== data[key]) {
  //           acc[key] = data[key]
  //         }
  //         return acc
  //       }, {})

  //       // update the node
  //       if (Object.keys(updateData).length) {
  //         update(item.id, updateData)
  //       }
  //     })
  //   },
  //   [inboxItems, update]
  // )

  // Transforms a single item or list of items into
  // a list of ids using provided filter.
  const getItemIds = (
    items: NotificationNode | NotificationNode[],
    filter: (item: NotificationNode) => boolean
  ) => {
    const itemIds = ([] as NotificationNode[])
      .concat(items)
      .filter(filter)
      .map((item) => item.id)
    return itemIds
  }

  const markRead: NotificationStateContext["markRead"] = (items) => {
    const filter = (item: NotificationNode) => !item.data.read
    const itemIds = getItemIds(items, filter)
    update(itemIds, { read: true })
  }

  const markUnread: NotificationStateContext["markUnread"] = (items) => {
    const filter = (item: NotificationNode) => item.data.read
    const itemIds = getItemIds(items, filter)
    update(itemIds, { read: false })
  }

  const pin: NotificationStateContext["pin"] = (items, pinned = true) => {
    const filter = (item: NotificationNode) => item.data.pinned !== pinned
    const itemIds = getItemIds(items, filter)
    update(itemIds, { pinned })
  }

  // Unsure how to handle selected for natification UIs,
  // so providing ignoreSelected as an escape hatch.
  const remove: NotificationStateContext["remove"] = (
    items,
    ignoreSelected = false
  ) => {
    const filter = (item: NotificationNode) => {
      if (ignoreSelected) return true
      if (selectedItem && selectedItem.id === item.id) return true
      return false
    }
    const itemIds = getItemIds(items, filter)
    if (itemIds.length) {
      setSelected([])
      del(itemIds)
    }
  }

  const value = {
    key,
    setKey,
    items,
    select,
    unselect,
    selected,
    selectedItem,
    getNode: get,
    remove,
    markRead,
    markUnread,
    // updateItemData,
    pin,
    paginate
  }

  return (
    <NotificationStateContext.Provider value={value}>
      {children}
    </NotificationStateContext.Provider>
  )
}

export const useNotificationsState = () => useContext(NotificationStateContext)
