import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash/flowRight"
import get from "lodash/get"
import PropTypes from "prop-types"

import { PathwrightContext } from "../../pathwright/PathwrightContext"
import { createPaginator, flattenEdges } from "../../utils/apollo"
import { withContextAsProp } from "../../utils/component"
import BANNERS_QUERY from "./banners-query"

const withBanners = compose(
  withContextAsProp(PathwrightContext, "schoolId", "school.id"),
  graphql(BANNERS_QUERY, {
    options: ({ first, schoolId, manage }) => ({
      variables: {
        first: first ? first : 50,
        school_id: schoolId,
        manage: !!manage
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      banners: flattenEdges(get(data, "context.school.banners", [])),
      total: get(data, "context.school.banners.total", 0),
      hasMore: get(data, "context.school.banners.pageInfo.hasNextPage"),
      loadMore: createPaginator(data, "context.school.banners")
    })
  })
)

const BannersQuery = withBanners(
  ({ children, ...rest }) => children(rest) || null
)

BannersQuery.displayName = "BannersQuery"

BannersQuery.propTypes = {
  children: PropTypes.func.isRequired,
  // optionally scope query to another school
  schoolId: PropTypes.number,
  // page length
  first: PropTypes.number
}

export default BannersQuery
