import { useToast } from "@chakra-ui/react"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Card from "@pathwright/ui/src/components/card/Card"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { ConnectStores } from "lib/core/store"
import PropTypes from "prop-types"
import { useEffect } from "react"

const CancelSubscriptionCard = function (props) {
  const {
    card,
    subscription,
    cancel_subscription_success,
    clearResponses,
    cancelSubscription,
    cancel_subscription_error,
    clearErrors
  } = props

  const canCancel =
    subscription &&
    subscription.subscription &&
    !cancel_subscription_success &&
    (subscription.is_paused ||
      !subscription.subscription.is_canceled_at_cycle_end ||
      (subscription.is_trial && !subscription.is_canceled))

  // On cancellation success, toast and close the card.
  const toast = useToast()
  useEffect(() => {
    if (cancel_subscription_success) {
      card.onClose()

      toast({
        title: cancel_subscription_success,
        status: "success",
        duration: 3000,
        isClosable: true,
        icon: <Pathicon icon="check" />
      })
    }
  }, [cancel_subscription_success])

  return (
    <Card title="Cancel Subscription" card={card}>
      {cancel_subscription_error && (
        <Alert error={cancel_subscription_error} onClear={clearErrors} />
      )}
      {canCancel && (
        <BlankSlate
          icons={null}
          illustration={
            <img
              src={subscription.school_plan.image}
              style={{
                width: "100px",
                height: "100px"
              }}
            />
          }
          heading="Are you sure you want to cancel?"
          body={
            subscription.is_canceled
              ? `If you cancel your subscription, it will not be automatically unpaused and you will no longer have access to subscription-only content from ${App.school.get(
                  "name"
                )}.`
              : `If you cancel your subscription, you’ll lose access to all subscription content from ${App.school.get(
                  "name"
                )}.`
          }
          primaryAction={{
            children: "Cancel my subscription",
            dangerous: true,
            onClick: cancelSubscription
          }}
          secondaryAction={{
            children: "No, nevermind",
            onClick: card.onClose
          }}
        />
      )}
    </Card>
  )
}

CancelSubscriptionCard.displayName = "CancelSubscriptionCard"

CancelSubscriptionCard.contextTypes = { navigator: PropTypes.object }

const mapStateToProps = function (state, ownProps) {
  let cancel_subscription_error
  let { subscription, cancel_subscription_success } = state.profileStore.state
  const { clearResponses, cancelSubscription } = state.profileStore.action
  ;({ subscription, cancel_subscription_error } = state.subscribeStore.state)
  const { clearErrors } = state.subscribeStore.action

  const props = _.assign(
    {},
    {
      subscription,
      cancel_subscription_success,
      cancelSubscription,
      clearResponses,
      cancel_subscription_error,
      clearErrors
    },
    ownProps
  )
  return props
}

const stores = [
  { store: "profile", watch: ["cancel_subscription_success"] },
  {
    store: "subscribe",
    watch: [
      "subscription",
      "cancel_subscription_saving",
      "cancel_subscription_error"
    ]
  }
]

export default ConnectStores(
  CancelSubscriptionCard,
  stores,
  {},
  mapStateToProps
)
