// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
import {
  MEMBERSHIP_TYPE_ADMIN,
  MEMBERSHIP_TYPE_EDITOR
} from "invitation/constants"
import { dispatchHistoryEvent } from "lib/core/routing/router"
import get from "lodash/get"
const path = require("path-browserify")
const urls = require("lib/core/utils/urls")
const { createSchoolUrl } = require("lib/core/api/urls")
const { getStoreUrl } = require("store/utils")

class NavigationStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    ;`
A simple store to keep track of the current URL.
State is updated by any call window.App.navigate()
`

    this.prototype.storeTriggerHandlers = {
      auth: {
        "user:auth:changed"(is_authenticated) {
          // const { pathname } = this.get("location")
          const { pathname } = this.get("location") || window.location
          const preventRedirectForRoutes = [
            /\/sign-up\//,
            /^\/account\/sign-in\/$/,
            /^\/gift-subscription\/$/,
            /^\/redeem\/$/,
            /^\/subscribe\/$/,
            /^\/school-licensing\/signup\/$/,
            /^\/store\/[0-9a-zA-Z-_]+\/license\/\d+\//,
            /^\/join\/$/,
            /^\/community\/join\/$/,
            /^\/library\/[0-9a-zA-Z-_]+\/register\//,
            // Don't automatically navigate away from PW3 auth routes.
            /\/auth\//
          ]

          const shouldPreventRedirect = !!preventRedirectForRoutes.find((r) =>
            r.test(pathname)
          )
          if (shouldPreventRedirect) {
            return
          }

          if (is_authenticated) {
            return this.action.redirectAfterSignIn()
          } else {
            this._mutate({
              launched_from: null,
              previous_location: null
            })
            const { next_url } = window.App.getStore("auth").getState()
            if (next_url) {
              this.action.navigateNext(next_url)
            } else {
              this.action.goHome()
            }
          }
        }
      },

      school: {
        "bootstrapped:school"({ permissions }) {
          if (get(permissions, "user_is_teaching")) {
            if (this.request.matches("/home/")) {
              this.action.navigate("/home/teach/")
            }
          }
        }
      }
    }

    this.prototype.storeEvents = {
      "change:title"() {
        return (document.title = this.get("title"))
      }
    }

    this.prototype.actions = {
      redirectAfterSignIn() {
        const { location, url, is_showing_modal, modal_launched_from_url } =
          this.getState()
        if (url === "/auth/sign-in/") {
          // if user is on the /library/ route, let's redirect them
          if (
            is_showing_modal &&
            modal_launched_from_url &&
            modal_launched_from_url !== "/library/"
          ) {
            this.action.navigate(modal_launched_from_url)
          } else {
            let { next_url } = window.App.getStore("auth").getState()
            next_url =
              next_url ||
              (location.state != null ? location.state.nextPathname : undefined)
            if (next_url) {
              this.action.navigateNext(next_url)
            } else {
              return this.action.goHome()
            }
          }
        }
      },

      goHome() {
        const nextLocation = this.request.getHomeLocation()
        return this.action.navigate(nextLocation)
      },

      navigateRelative(relPath) {
        return this.action.navigate(this.request.relativePath(relPath))
      },

      navigate(route, state, title) {
        if (state == null) {
          state = {}
        }
        if (title == null) {
          title = ""
        }
        if (!route) {
          console.warn("navigate called with empty route")
          console.trace()
          return
        }
        if (_.isString(route)) {
          route = urls.route(route, state)
        } else {
          route.state = route.state || state
        }

        // Since route already includes state, we do not pass route.state
        // as 3rd argument as history complains about state alrady provided
        // on location-like object.
        dispatchHistoryEvent("push", route)
      },

      navigateNext(next_url) {
        if (next_url) {
          // We don't handle any .csv routes client-side, so do a hard redirect.
          if (/\.csv\/{0,1}$/.test(next_url)) {
            // Remove final "/" if exists.
            if (/\/$/.test(next_url)) next_url = next_url.slice(0, -1)
            window.location = next_url
          } else {
            this.action.navigate(next_url)
          }
        }
      },

      setParams(params) {
        const location = this.get("location")
        location.query = _.assign(location.query, params)
        return dispatchHistoryEvent("replace", location)
      },

      setLocation(location) {
        const prev_location = this.get("location")
        const state = location.state || {}

        const update = {
          location,
          prev_location,
          url: location.pathname,
          nav_panel_open: false,
          is_showing_modal: state.modal === true,
          prevent_modal_close: state.preventModalClose ? true : false,
          initial_route: this.get("initial_route") || location.pathname
        }

        update.is_direct_modal =
          state.modal === true && update.initial_route === location.pathname

        if (
          state.setLaunchedFrom &&
          location.pathname !==
            (prev_location != null ? prev_location.pathname : undefined)
        ) {
          update.launched_from = prev_location
        }

        if (
          state.setResourceLaunchedFrom &&
          location.pathname !==
            (prev_location != null ? prev_location.pathname : undefined)
        ) {
          update.resource_launched_from = prev_location
        }

        if (
          location.pathname === this.get("resource_launched_from")?.pathname
        ) {
          update.resource_launched_from = null
        }

        if (location.pathname === this.get("launched_from")?.pathname) {
          update.launched_from = null
        }

        // modal_launched_from_url clearing handled in Route
        // if (!state.modal && this.get("modal_launched_from_url")) {
        //   update.modal_launched_from_url = null
        // }

        // Close the nav panel if we've navigated somewhere
        if (this.get("nav_panel_open")) {
          this.action.toggleNavPanelOpen(false)
        }

        this._mutate(update)
      },

      goBack(currentURL) {
        return this.action.navigate(this.request.getBackTo(currentURL))
      },

      toggleNavPanelOpen(nav_panel_open) {
        return this._mutate({ nav_panel_open })
      },

      setURLTitle(title) {
        return this._mutate({ title })
      },

      setDefaultModalLaunchedFromURL(modal_launched_from_url, forceSet) {
        if (forceSet == null) {
          forceSet = false
        }
        if (!this.get("modal_launched_from_url") || forceSet) {
          return this._mutate({ modal_launched_from_url })
        }
      },

      setIsShowingModal(is_showing_modal, modal_launched_from_url) {
        if (!is_showing_modal) {
          modal_launched_from_url = null
        }

        let is_direct_modal = false
        const { initial_route, location } = this.getState()
        if (is_showing_modal && initial_route === location.pathname) {
          is_direct_modal = true
        }

        if (is_showing_modal && modal_launched_from_url == null) {
          modal_launched_from_url = this.request.defaultRoute()
        }

        return this._mutate({
          is_showing_modal,
          modal_launched_from_url,
          is_direct_modal
        })
      },

      setLaunchedFrom(url) {
        // Note: this sets to the previous URL
        return this._mutate({ launched_from: this.request.getBackTo(url) })
      },

      setResourceLaunchedFrom(location) {
        if (!location) {
          location =
            this.request.getPreviousLocation() || this.request.getHomeLocation()
        }

        if (location) {
          // ensure the location isn't /auth/sign-in/
          if (location.pathname === "/auth/sign-in/") {
            location =
              window.App.getStore("navigation").request.getHomeLocation()
          }

          this._mutate({ resource_launched_from: location })
        }
      },

      exitModal() {
        const { modal_launched_from_url } = this.getState()
        if (modal_launched_from_url) {
          return window.App.navigate(modal_launched_from_url)
        }
      },

      preventModalClose() {
        return this._mutate({ prevent_modal_close: true })
      }
    }

    this.prototype.requests = {
      isDirectModal(location) {
        const state = location.state || {}

        lastLocation = this.request.getPreviousLocation()

        const isDirect =
          !lastLocation || lastLocation.pathname === location.pathname

        return state.modal === true && isDirect
      },

      relativePath(relPath) {
        const current_path = this.get("location").pathname
        return path.join(current_path, relPath)
      },

      getHomeLocation() {
        let homeLocation = "/home/"
        // TODO: permissions here isn't necessarily up-to-date since the
        // school store is most likely bootstrapping.
        const { school, permissions } = this.getStore("school").getState()
        const { has_public_resources } = school
        const { is_authenticated, session } = this.getStore("auth").getState()
        const schoolInactiveRedirectRoute =
          this.request.getSchoolInactiveRedirectRoute()

        const isSchoolAdmin =
          permissions.user_is_school_admin ||
          get(session, "school_membership.membership_type") ===
            MEMBERSHIP_TYPE_ADMIN
        const isSchoolEditor =
          permissions.user_is_school_editor ||
          get(session, "school_membership.membership_type") ===
            MEMBERSHIP_TYPE_EDITOR

        if (!is_authenticated) {
          if (has_public_resources) {
            homeLocation = getStoreUrl()
          } else {
            homeLocation = this.request.getNextLocation()
          }
        } else {
          // is_authenticated
          if (
            permissions.user_is_teaching ||
            (isSchoolEditor && !school.is_curriculum_enabled)
          ) {
            homeLocation = "/home/teach/"
          }

          if (isSchoolEditor && school.is_curriculum_enabled) {
            homeLocation = "/home/design/"
          }

          if (isSchoolAdmin) {
            homeLocation = "/dashboard/"
          }
        }

        return schoolInactiveRedirectRoute || homeLocation
      },

      getNextLocation() {
        const location = this.getState().location || window.location

        const { pathname, search, hash } = location
        const authPathname = "/auth/sign-in/"

        if (pathname === authPathname) {
          return {
            pathname,
            search,
            hash
          }
        }

        const nextUrl = App.getStore("auth").request.getNextUrl(
          `${pathname}${search}${hash}`
        )

        if (nextUrl) {
          return {
            pathname: authPathname,
            query: {
              next: nextUrl
            }
          }
        }

        return { pathname: authPathname }
      },

      getBackTo(currentURL) {
        // returns the launched_from key if set otherwhise
        // falls back to getPreviousURL
        if (this.get("launched_from")) {
          return this.get("launched_from")
        } else {
          return (
            this.request.getPreviousURL(currentURL) ||
            this.request.getHomeLocation()
          )
        }
      },

      getPreviousLocation() {
        const { location, prev_location } = this.getState()

        if (location) {
          return window.location.pathname === location.pathname
            ? prev_location
            : location
        }

        return null
      },

      getPreviousURL(currentURL) {
        if (currentURL) {
          const pathname = currentURL.pathname || currentURL
          return path.join(pathname, "../")
        }

        const previousLocation = this.request.getPreviousLocation()

        if (previousLocation)
          previousLocation.pathname + previousLocation.search

        return null
      },

      defaultRoute() {
        return "/home/"
      },

      matches(matchUrl, ignoreModal) {
        let url
        if (ignoreModal == null) {
          ignoreModal = false
        }
        let isMatch = false
        if (ignoreModal && this.get("is_showing_modal")) {
          url = this.get("modal_launched_from_url")
        } else {
          url = this.get("url").toLowerCase()
        }
        if (url) {
          isMatch = url.indexOf(matchUrl.toLowerCase()) > -1
        }
        return isMatch
      },

      getAbsoluteURL(relativeURL) {
        // Returns an absolute URL for this school
        const schoolURL = this.getStore("school").getState().school.url
        return window.App.utils.urljoin(schoolURL, relativeURL)
      },

      getSchoolInactiveRedirectRoute(locationProp) {
        // TODO: FIX ME! The location isn't necessarily in state yet!
        const location =
          locationProp || this.getState().location || window.location
        const { school, permissions } = window.App.getStore("school").getState()
        const { is_authenticated, session, user } =
          window.App.getStore("auth").getState()
        const isSchoolStaff =
          get(permissions, "user_is_school_staff") ||
          get(session, "school_membership.can_manage_school") ||
          get(user, "is_superuser")

        if (!school.is_activated) {
          // White list any URLs that should be permitted on an inacative school
          const whiteListedURLs = ["/auth", "/inactive/"]
          const isWhitelisted = whiteListedURLs.find(
            (w) => location.pathname.indexOf(w) > -1
          )
          if (!isWhitelisted) {
            if (!is_authenticated) {
              return "/auth/sign-in/"
            } else if (!isSchoolStaff) {
              return "/inactive/"
            }
          }
        }
        return null
      }
    }
  }

  defaults() {
    return {
      url: "", // the current URL
      title: "", // the current page title if any
      previous_location: null, // the last location before the current one set by React Router
      initial_route: null, // record initial route whe app started

      // UI states
      nav_panel_open: false,
      launched_from: null, // generic key to handle "back" link state from various components (FullScreen)
      resource_launched_from: null, // specific key to handle "back" link from resource

      // modal state
      is_showing_modal: false, // if a modal window is visible
      modal_launched_from_url: null, // the url the modal was launched from
      is_direct_modal: false, // if the modal was hit directly
      prevent_modal_close: false, // disable default modal back/close actions

      // integrate features
      auth__platform: false
    }
  }

  bootstrapStore() {
    let url
    if (window.location.hostname) {
      url = window.location.pathname
    }
    return this._mutate({ url })
  }
}
NavigationStore.initClass()

export default window.App.stores.registerStore("navigation", NavigationStore)
