import { VStack } from "@chakra-ui/react"
import Button from "@pathwright/ui/src/components/button/Button"
import Image from "@pathwright/ui/src/components/image/Image"
import { usePortableContext } from "@pathwright/ui/src/components/portable/Portable"
import ScrollView, {
  ScrollContext
} from "@pathwright/ui/src/components/scroll/ScrollView"
import Text from "@pathwright/ui/src/components/ui/Text"
import isObject from "lodash/isObject"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslate } from "../lng/withTranslate"
import { Checkbox, Icon, Item, List, Row } from "./ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Scroll = styled(ScrollView)`
  ${(p) => !p.fullscreen && `max-height: 240px;`}
`

export const PickerItem = ({ pickerItem }) => (
  <Row>
    {!!pickerItem.image && (
      <Icon>
        <Image src={pickerItem.image} />
      </Icon>
    )}
    <VStack w="100%" alignItems="flex-start" spacing={1}>
      <Text.H5>{pickerItem.name}</Text.H5>
      {!!pickerItem.meta && !isObject(pickerItem.meta) && (
        <Text.Meta>{pickerItem.meta}</Text.Meta>
      )}
    </VStack>
  </Row>
)

const PickerList = ({
  pickerItems,
  getPickerItem,
  selectedPickerItemIds,
  selectPickerItem,
  unselectPickerItem,
  onPick,
  onScrollBottom,
  renderPickerItem
}) => {
  const { tca } = useTranslate()
  const { porting } = usePortableContext() || {}

  const selectMultipleEnabled =
    !!selectedPickerItemIds && !!selectPickerItem && !!unselectPickerItem

  const handleTogglePickerItemSelected = (pickerItem) => {
    if (selectedPickerItemIds.includes(pickerItem.id)) {
      unselectPickerItem(pickerItem.id)
    } else {
      selectPickerItem(pickerItem.id)
    }
  }

  return (
    <Container className="PickerListContainer">
      <Scroll onScrollBottom={onScrollBottom} fullscreen={porting}>
        <ScrollContext.Consumer>
          {({ canScroll }) => {
            const showLoadMore = !!onScrollBottom && !canScroll

            if (!pickerItems.length && !showLoadMore) return null

            return (
              <List className="PickerList">
                {pickerItems.map(getPickerItem).map((pickerItem, index) => (
                  <Item
                    className="PickerListItem"
                    key={pickerItem.id}
                    onClick={(e) => {
                      if (selectMultipleEnabled) {
                        handleTogglePickerItemSelected(pickerItem)
                      }
                      return onPick(pickerItem)
                    }}
                  >
                    <Row>
                      {selectMultipleEnabled && (
                        <Checkbox>
                          <input
                            type="checkbox"
                            checked={selectedPickerItemIds.includes(
                              pickerItem.id
                            )}
                            onChange={() => {
                              handleTogglePickerItemSelected(pickerItem)
                            }}
                            onClick={(e) => {
                              e.stopPropagation() // prevent the <Item /> onClick from firing and reverting the selection
                            }}
                          />
                        </Checkbox>
                      )}
                      {renderPickerItem ? (
                        renderPickerItem({
                          pickerItem,
                          pickerItems,
                          index,
                          selectMultipleEnabled
                        })
                      ) : (
                        <PickerItem pickerItem={pickerItem} />
                      )}
                    </Row>
                  </Item>
                ))}
                {showLoadMore && (
                  <Item style={{ backgroundColor: "transparent" }}>
                    <Button styleType="empty" onClick={onScrollBottom}>
                      {tca("load more")}
                    </Button>
                  </Item>
                )}
              </List>
            )
          }}
        </ScrollContext.Consumer>
      </Scroll>
    </Container>
  )
}

PickerList.displayName = "PickerList"

PickerList.propTypes = {
  pickerItems: PropTypes.array.isRequired,
  getPickerItem: PropTypes.func,
  onPick: PropTypes.func.isRequired,
  onScrollBottom: PropTypes.func,
  renderPickerItem: PropTypes.func
}

PickerList.defaultProps = {
  getPickerItem: (pickerItem) => pickerItem
}

export default PickerList
